import { useEffect, useRef } from 'react';

export const useMouseDownOnResizeRef = () => {
  const ref = useRef<HTMLButtonElement>(null);
  // In Reach UI we cannot control if the menu should be open or closed
  // with a prop, so we simulate a mousedown event outside of the menu to
  // close it when the window size changes.
  useEffect(() => {
    function handleResize() {
      if (ref.current) {
        document.dispatchEvent(new Event('mousedown'));
      }
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return ref;
};
