import { flow, Instance, types as t } from 'mobx-state-tree';
import { withRequest } from '../extensions';
import { maybeNullReference } from '../types';

export const Article = t
  .model('Article', {
    id: t.identifierNumber,
    number: t.number,
    name: t.string,
    price: t.number,
    is_active: t.boolean,
  })
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;

    return {
      patch: flow(function* (name: string, price: number) {
        const currentName = self.name;
        const currentPrice = self.price;

        self.name = name;
        self.price = price;

        try {
          yield request({
            method: 'PATCH',
            url: `/api/articles/${self.id}/`,
            data: {
              name,
              price,
            },
          });
        } catch (error) {
          self.name = currentName;
          self.price = currentPrice;
        }
      }),
      deactivate: flow(function* () {
        self.is_active = false;

        try {
          yield request({
            method: 'PATCH',
            url: `/api/articles/${self.id}/`,
            data: {
              is_active: false,
            },
          });
        } catch (error) {
          self.is_active = true;
        }
      }),
    };
  });

export type ArticleInstance = Instance<typeof Article>;

export const ArticleReference = maybeNullReference(Article);
