import React, { FC, memo, SVGProps } from 'react';

export const BrokenLinkIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 484.36 505.53"
    fill="currentColor"
    className="w-4"
    {...props}
  >
    <g>
      <g>
        <path
          d="M196.58 328.49c-.51.48-1 .94-1.45 1.44a25.33 25.33 0 001.45 35.79L286 455.14a106 106 0 00150-149.95l-91-88.41a26.64 26.64 0 00-2.76-2.76 25.33 25.33 0 10-32.94 38.48l90.17 88.65.51.51a54.45 54.45 0 01-.51 77 56 56 0 01-77.51 0l-88.65-90.17c-.31-.33-.61-.61-.92-.91a25.33 25.33 0 00-35.81.91"
          strokeMiterlimit="10"
          strokeWidth=".5px"
        />
        <path d="M438.73 82.79A25.33 25.33 0 00403 47.07l-32.66 32.68a25.25 25.25 0 0035.72 35.71zM45.74 465.55a25 25 0 01-17.68-42.66l32.67-32.67a25.08 25.08 0 0135.35 35.37l-32.66 32.67a24.93 24.93 0 01-17.68 7.29z" />
        <path d="M77 384.44a24.84 24.84 0 0118.89 41l-32.65 32.64a24.76 24.76 0 01-35-35l32.65-32.65a24.83 24.83 0 0116.15-6m0-.5A25.25 25.25 0 0060.55 390l-32.67 32.72a25.25 25.25 0 0035.71 35.71l32.68-32.67A25.34 25.34 0 0077 383.94z" />
        <path
          d="M.27 125.85a106.18 106.18 0 0030.39 74l90.94 88.4a25.25 25.25 0 1035.72-35.71l-90.15-88.7a4.83 4.83 0 01-.51-.5 54.41 54.41 0 01.51-77 56 56 0 0177.48 0l88.66 90.18A25.88 25.88 0 10270 140l-89.38-90.14a105.38 105.38 0 00-180.35 76"
          strokeMiterlimit="10"
          strokeWidth=".5px"
        />
        <path d="M467.24 203.89A25.33 25.33 0 00454.59 155l-44.74 11.58a25.26 25.26 0 0012.66 48.9zM196.34 442.3a25.33 25.33 0 00-48.76-13.21l-12.07 44.61a25.25 25.25 0 0048.75 13.2zM330.58 30.27a25.33 25.33 0 00-48.64-13.63l-12.47 44.49a25.25 25.25 0 0048.63 13.64z" />
      </g>
    </g>
  </svg>
));
