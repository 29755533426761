import React from 'react';
import {
  CarReturnIcon,
  CheckCircleIcon,
  CompanyIcon,
  FacilityIcon,
  UsersIcon,
} from '../../icons';

export const parkingSubPages = [
  {
    to: '/parking/recurring',
    text: 'Återkommande',
    icon: <CarReturnIcon className="w-6" />,
  },
];

export const issuesSubPages = [
  {
    to: '/issues/complete',
    text: 'Åtgärdade',
    icon: <CheckCircleIcon className="w-6" />,
  },
];

export const roundsSubPages = [
  {
    to: `/rounds/complete`,
    text: 'Genomförda',
    icon: <CheckCircleIcon className="w-6" />,
  },
];

export const defaultSettingsSubPages = [
  {
    to: '/organization/users',
    text: 'Användare',
    icon: <UsersIcon className="w-6" />,
  },
  {
    to: '/organization/facilities',
    text: 'Fastigheter',
    icon: <FacilityIcon className="w-6" />,
  },
  {
    to: '/organization/companies',
    text: 'Företag',
    icon: <CompanyIcon className="w-6" />,
  },
];
