import { CustomCheckbox } from '@reach/checkbox';
import React, { FC, useLayoutEffect, useRef } from 'react';

type CheckboxProps = {
  name: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  required?: boolean;
};

export const Checkbox: FC<CheckboxProps> = ({
  name,
  checked,
  onChange,
  required = false,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  // Reach UI doesn't expose required as a prop, so we have to set it
  // on the underlying input imperatively.
  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.required = required;
    }
  }, [required]);

  return (
    <CustomCheckbox
      className="inline-flex h-6 w-6 items-center justify-center rounded-md border border-solid border-gray-200 bg-white hover:border-blue-500 focus:outline-none"
      ref={ref}
      name={name}
      id={name}
      checked={checked !== undefined ? checked : undefined}
      onChange={(event) => onChange && onChange(event.target.checked)}
      required={required}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        className="w-5 stroke-current text-white"
      >
        <polyline points="20 6 9 17 4 12" />
      </svg>
    </CustomCheckbox>
  );
};
