import { Instance, types as t } from 'mobx-state-tree';

export enum FrequencyType {
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export const ParkingSettings = t.model('ParkingSettings', {
  restriction_frequency: t.number,
  restriction_frequency_type: t.enumeration<FrequencyType>(
    'Frequency',
    Object.values(FrequencyType),
  ),
  restriction_redirect_url: t.maybe(t.string),
});

export const Settings = t.model('Settings', {
  parking: t.maybe(ParkingSettings),
});

export type SettingsInstance = Instance<typeof ParkingSettings>;
export type ParkingSettingsInstance = Instance<typeof ParkingSettings>;
