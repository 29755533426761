import React, { FC, memo, SVGProps } from 'react';

export const FolderIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className="w-4"
    {...props}
  >
    <path d="M11 5h8a2 2 0 012 2v12a2 2 0 01-2 2H5a2 2 0 01-2-2V5c0-1.1.9-2 2-2h4l2 2zM5 7v12h14V7H5z" />
  </svg>
));
