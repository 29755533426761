import './index.less';

import React, { FC } from 'react';
import { CustomCheckbox } from '@reach/checkbox';

type CheckboxProps = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
};
export const Checkbox: FC<CheckboxProps> = ({
  checked,
  onChange,
  disabled = false,
}) => {
  return (
    <CustomCheckbox
      className={`inline-flex h-6 w-6 items-center justify-center rounded-md border border-solid border-gray-200 focus:outline-none ${
        !disabled ? 'hover:border-indigo-600' : ''
      }`}
      checked={checked}
      onChange={(event) => onChange(event.target.checked)}
      disabled={disabled}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        className="w-5 stroke-current text-white"
      >
        <polyline points="20 6 9 17 4 12" />
      </svg>
    </CustomCheckbox>
  );
};
