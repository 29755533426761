import React, { FC, memo, SVGProps } from 'react';

export const UserSwitchIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      className="w-4"
      {...props}
    >
      <path
        fill="currentColor"
        d="M13.882 4a1.981 1.981 0 10-.933 0 2.566 2.566 0 00-2.102 2.521v.508h5.137v-.508A2.566 2.566 0 0013.882 4zM3.051 12.828a1.981 1.981 0 10-.933 0 2.567 2.567 0 00-2.102 2.521v.508h5.137v-.508a2.567 2.567 0 00-2.102-2.521zm9.706-1.263a1.324 1.324 0 01-1.322 1.322H8.4l.855-.855-.934-.933-2.45 2.45L8.323 16l.933-.933-.856-.858h3.032a2.647 2.647 0 002.645-2.644V8.616h-1.32v2.949zm-9.34-7.13A1.323 1.323 0 014.74 3.113h3.032l-.855.855.933.933 2.451-2.45L7.851 0l-.933.933.855.855H4.739a2.647 2.647 0 00-2.644 2.647v2.949h1.322V4.435z"
      ></path>
    </svg>
  );
});
