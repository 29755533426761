import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { useStore } from '../../hooks';
import {
  ExclamationMarkIcon,
  TagIcon,
  CarIcon,
  DashboardIcon,
  CrossIcon,
  LogoutIcon,
  CogwheelIcon,
  RoundingIcon,
} from '../../icons';
import {
  SideMenuLink,
  SideMenuAccordion,
  parkingSubPages,
  issuesSubPages,
  defaultSettingsSubPages,
  roundsSubPages,
  SubPageLink,
} from '.';
import { Avatar } from '../Avatar';
import { Logo } from '../Logo';
import { useLocation } from 'react-router-dom';

interface MobileSideMenuState {
  showIssuesSubPage: boolean;
  setShowIssuesSubPage: (showIssuesSubPage: boolean) => void;
  toggleShowIssuesSubPage: () => void;

  showRoundsSubPage: boolean;
  setShowRoundsSubPage: (showRoundsSubPage: boolean) => void;
  toggleShowRoundsSubPage: () => void;

  showParkingSubPage: boolean;
  setShowParkingSubPage: (showParkingSubPage: boolean) => void;
  toggleShowParkingSubPage: () => void;

  showSettingsSubPage: boolean;
  setShowSettingsSubPage: (showSettingsSubPage: boolean) => void;
  toggleShowSettingsSubPage: () => void;

  closeMenuAndSubPages: () => void;

  settingsSubPages: SubPageLink[];
}

export const MobileSideMenu = observer(() => {
  const {
    authStore,
    uiStore,
    userStore: { me },
    organizationStore: { organization },
  } = useStore();

  const location = useLocation();

  const state = useLocalObservable<MobileSideMenuState>(() => ({
    showIssuesSubPage: false,
    setShowIssuesSubPage(showIssuesSubPage) {
      state.showIssuesSubPage = showIssuesSubPage;
    },
    toggleShowIssuesSubPage() {
      state.showIssuesSubPage = !state.showIssuesSubPage;
    },
    showRoundsSubPage: false,
    setShowRoundsSubPage(showRoundsSubPage) {
      state.showRoundsSubPage = showRoundsSubPage;
    },
    toggleShowRoundsSubPage() {
      state.showRoundsSubPage = !state.showRoundsSubPage;
    },
    showParkingSubPage: false,
    setShowParkingSubPage(showParkingSubPage) {
      state.showParkingSubPage = showParkingSubPage;
    },
    toggleShowParkingSubPage() {
      state.showParkingSubPage = !state.showParkingSubPage;
    },
    showSettingsSubPage: false,
    setShowSettingsSubPage(showSettingsSubPage) {
      state.showSettingsSubPage = showSettingsSubPage;
    },
    toggleShowSettingsSubPage() {
      state.showSettingsSubPage = !state.showSettingsSubPage;
    },
    closeMenuAndSubPages() {
      uiStore.closeMobileMenu();
      state.showIssuesSubPage = false;
      state.showRoundsSubPage = false;
      state.showParkingSubPage = false;
      state.showSettingsSubPage = false;
    },
    get settingsSubPages() {
      const subPages: SubPageLink[] = [];

      if (shouldShowArticles) {
        subPages.push({
          to: '/organization/articles',
          text: 'Artiklar',
          icon: <TagIcon className="w-6" />,
        });
      }

      if (shouldShowParking) {
        subPages.push({
          to: '/organization/parking',
          text: 'Besöksparkering',
          icon: <CarIcon className="w-6" />,
        });
      }
      return [...defaultSettingsSubPages, ...subPages];
    },
  }));

  if (organization === null || me === null) return null;

  const shouldShowIssues = uiStore.shouldShowIssues(organization, me);
  const shouldShowRounds = uiStore.shouldShowRounds(organization, me);
  const shouldShowParking = uiStore.shouldShowParking(organization);
  const shouldShowRecurringParking = uiStore.shouldShowRecurringParking(
    organization,
    me,
  );
  const shouldShowArticles = uiStore.shouldShowArticles(organization);
  const shouldShowSettings = uiStore.shouldShowOrganization(me);

  return (
    <div
      className={`absolute z-50 flex h-screen w-11/12 max-w-80 flex-col overflow-y-auto bg-white px-7 pb-9 pt-7 text-gray-900 shadow-md transition-transform ${
        !uiStore.isMobileMenuOpen ? '-translate-x-full' : ''
      }`}
    >
      <div className="flex items-center justify-between pb-16">
        <div className="w-24">
          <Logo color="black" />
        </div>
        <button
          className="focus:outline-none`"
          onClick={uiStore.closeMobileMenu}
        >
          <CrossIcon className="w-6" />
        </button>
      </div>

      <div className="flex flex-1 flex-col space-y-6">
        {shouldShowIssues && (
          <SideMenuLink to="/dashboard" onClick={state.closeMenuAndSubPages}>
            <DashboardIcon className="h-6 w-6" />
            <span>Dashboard</span>
          </SideMenuLink>
        )}

        {shouldShowIssues && (
          <SideMenuAccordion
            to="/issues"
            isMenuExpanded
            showSubPages={state.showIssuesSubPage}
            subPageLinks={issuesSubPages}
            onClick={() => {
              state.closeMenuAndSubPages();
              state.setShowIssuesSubPage(true);
            }}
            onSubPageClick={uiStore.closeMobileMenu}
            onChevronClick={state.toggleShowIssuesSubPage}
            end={
              location.pathname.includes('/issues/mine') ||
              location.pathname.includes('/issues/complete')
            }
          >
            <ExclamationMarkIcon className="w-6" />
            <span>Ärenden</span>
          </SideMenuAccordion>
        )}

        {shouldShowRounds && (
          <SideMenuAccordion
            to="/rounds"
            isMenuExpanded
            showSubPages={state.showRoundsSubPage}
            subPageLinks={roundsSubPages}
            onClick={() => {
              state.closeMenuAndSubPages();
              state.setShowRoundsSubPage(true);
            }}
            onSubPageClick={uiStore.closeMobileMenu}
            onChevronClick={state.toggleShowRoundsSubPage}
            end
          >
            <div className="flex w-6 justify-center">
              <RoundingIcon />
            </div>
            <span>Rondering</span>
          </SideMenuAccordion>
        )}

        {shouldShowParking && (
          <SideMenuAccordion
            to="/parking"
            showSubPages={state.showParkingSubPage}
            isMenuExpanded
            subPageLinks={shouldShowRecurringParking ? parkingSubPages : null}
            onClick={() => {
              state.closeMenuAndSubPages();
              state.setShowParkingSubPage(true);
            }}
            onSubPageClick={uiStore.closeMobileMenu}
            onChevronClick={state.toggleShowParkingSubPage}
            end
          >
            <CarIcon className="w-6" />
            <span>Besöksparkering</span>
          </SideMenuAccordion>
        )}

        {shouldShowSettings && (
          <SideMenuAccordion
            to="#"
            disableActiveClass
            isMenuExpanded
            showSubPages={state.showSettingsSubPage}
            subPageLinks={state.settingsSubPages}
            onClick={state.toggleShowSettingsSubPage}
            onSubPageClick={uiStore.closeMobileMenu}
            onChevronClick={state.toggleShowSettingsSubPage}
          >
            <CogwheelIcon className="w-6" />
            <span>Inställningar</span>
          </SideMenuAccordion>
        )}
      </div>
      <div className="flex items-center space-x-5">
        <Avatar user={me} border />
        <button
          onClick={authStore.logOut}
          className="flex items-center space-x-5"
        >
          <LogoutIcon className="w-6" />
          <span className="font-semibold">Logga ut</span>
        </button>
      </div>
    </div>
  );
});
