import React from 'react';
import { observer } from 'mobx-react-lite';
import { InfoBox } from '../../../components';
import { Checkbox } from './Checkbox';
import { IssueInstance } from '../../../models';
import { IssueStatus } from '../../../types';

type SubcontractorBarProps = {
  issue: IssueInstance;
};

export const SubcontractorBar = observer(({ issue }: SubcontractorBarProps) => {
  return (
    <div className="flex justify-between px-8 py-6">
      <div className="flex items-center">
        <Checkbox
          checked={issue.has_subcontractor_cost}
          onChange={issue.setHasSubcontractorCost}
          disabled={issue.status === IssueStatus.Done}
        />
        <span className="ml-4 text-xs">Underleverantörskostnad</span>
      </div>

      <InfoBox content="Fyll i rutan ifall det finns ett inköp kopplat till detta ärendet." />
    </div>
  );
});
