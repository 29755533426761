import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import debounce from 'lodash.debounce';
import { useStore } from '../hooks';
import { ParkingFilter } from '../types';
import { TableHeaderCell, FadeInDiv, Pagination } from '../components';
import DownloadFile from 'js-file-download';
import { RefreshIcon, SearchIcon, UploadIcon } from '../icons';
import { REQUEST_DEBOUNCE_WAIT } from '../constants';
import { useLocation, useNavigate } from 'react-router-dom';

type State = {
  query: URLSearchParams;
  searchTerm: string;
  filter: ParkingFilter;
  isAscending: boolean;
  setCurrentPage: (page: number) => void;
  setFilter: (newFilter: ParkingFilter) => void;
  setSearchTerm: (searchTerm: string) => void;
  debouncedSetSearch: (search: string) => void;
  setSearch: (search: string) => void;

  isDownloading: boolean;
  setIsDownloading: (downloading: boolean) => void;
  downloadCSV: () => void;
};

/**
 * A page component rendering all active parkings.
 */
export const ParkingPage = observer(() => {
  const {
    parkingStore,
    userStore: { me },
    uiStore,
    authStore: { authRequest },
  } = useStore();
  const location = useLocation();
  const navigate = useNavigate();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const timeoutRef = useRef<number>(0);

  const state = useLocalObservable<State>(() => ({
    query: new URLSearchParams(location.search),
    searchTerm: '',
    get filter(): ParkingFilter {
      const sortParam = state.query.get('sort');

      return (sortParam ? sortParam.replace('-', '') : '') as ParkingFilter;
    },
    get isAscending(): boolean {
      const sortParam = state.query.get('sort');

      return sortParam !== null && sortParam[0] !== '-';
    },
    setCurrentPage(page) {
      const searchParams = new URLSearchParams(parkingStore.parkingQuery);

      if (page === 1) {
        searchParams.delete('page');
      } else {
        searchParams.set('page', `${page}`);
      }

      navigate(`${location.pathname}?${searchParams.toString()}`);
    },
    setFilter(newFilter) {
      const { parkingQuery, filter, isAscending } = parkingStore;
      const searchParams = new URLSearchParams(parkingQuery);
      searchParams.delete('page');

      if (filter === newFilter) {
        if (isAscending) {
          searchParams.delete('sort');
        } else {
          searchParams.set('sort', filter);
        }
      } else {
        searchParams.set('sort', `-${newFilter}`);
      }

      navigate(`${location.pathname}?${searchParams.toString()}`);
    },
    setSearchTerm(searchTerm) {
      state.searchTerm = searchTerm;
    },
    debouncedSetSearch: debounce((search: string) => {
      state.setSearch(search);
    }, REQUEST_DEBOUNCE_WAIT),
    setSearch(search) {
      const { parkingQuery } = parkingStore;
      const searchParams = new URLSearchParams(parkingQuery);

      if (search) {
        searchParams.delete('page');
        searchParams.set('search', search);
      } else {
        searchParams.delete('search');
      }

      navigate(`${location.pathname}?${searchParams.toString()}`);
    },
    isDownloading: false,
    setIsDownloading(downloading) {
      state.isDownloading = downloading;
    },
    async downloadCSV() {
      const { parkingQuery } = parkingStore;
      try {
        state.setIsDownloading(true);
        const { data } = await authRequest({
          url: '/api/parkings/export/',
          method: 'GET',
          responseType: 'blob',
          params: {
            search: parkingQuery.get('search'),
            ordering: parkingQuery.get('sort'),
          },
        });
        const date = new Date().toLocaleDateString('sv-SE');
        const time = new Date().toLocaleTimeString('sv-SE');
        DownloadFile(data, `parkings-${date}_${time}.csv`);
      } catch (error: any) {
        console.error(error.message);
      } finally {
        state.setIsDownloading(false);
      }
    },
  }));

  useEffect(() => {
    const updateParking = () => {
      timeoutRef.current = window.setTimeout(() => {
        parkingStore.handleParkingSearchChange(
          parkingStore.parkingQuery.toString(),
        );
        updateParking();
      }, 60 * 1000);
    };
    updateParking();

    return () => clearTimeout(timeoutRef.current);
  }, []);

  useLayoutEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    state.setSearchTerm(searchParams.get('search') || '');
    parkingStore.handleParkingSearchChange(location.search);
  }, [location.search]);

  const { parking, numParkingPages } = parkingStore;

  if (parking === null || me === null) {
    return null;
  }

  const shouldShowParkingCSVExport = uiStore.shouldShowParkingCSVExport(me);

  return (
    <FadeInDiv className="flex h-full flex-col">
      <div className="mx-4 mb-5 flex flex-wrap space-y-5 md:mx-14 md:mb-10 md:mt-14 lg:space-x-10 lg:space-y-0">
        <div className="flex flex-1 flex-col rounded-md bg-white p-7 lg:inline-flex lg:flex-none">
          <span className="text-base font-semibold text-gray-900">
            Dagens datum
          </span>
          <span className="mt-1 text-xl font-extrabold text-indigo-900">
            {new Date().toLocaleDateString('sv-SE', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </span>
        </div>
        <div className="relative flex w-full lg:w-auto lg:space-x-5">
          <div className="flex w-full space-x-5 lg:w-auto lg:flex-col lg:justify-between lg:space-x-0">
            <div
              className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-md bg-white p-3 transition-colors hover:bg-indigo-600 hover:text-white"
              onClick={() =>
                parkingStore.handleParkingSearchChange(location.search)
              }
            >
              <RefreshIcon className="w-6" />
            </div>
            <div className="z-20 flex flex-1 overflow-hidden rounded-md border border-white bg-white focus-within:md:border-indigo-600 focus-within:md:text-indigo-600 lg:flex-none">
              <div className="flex w-full">
                <span
                  className="flex h-10 w-10 cursor-pointer items-center justify-center p-3 transition-colors hover:bg-indigo-600 hover:text-white"
                  onClick={() => searchInputRef.current?.focus()}
                >
                  <SearchIcon className="w-6" />
                </span>
                <input
                  ref={searchInputRef}
                  type="text"
                  placeholder="Sök"
                  className="absolut h-10 flex-1 p-3.5 pl-1.5 text-black outline-none md:w-0 md:p-0 md:pl-0 md:transition-all focus:md:p-3.5 focus:lg:w-56"
                  onChange={(e) => {
                    state.setSearchTerm(e.target.value);
                    state.debouncedSetSearch(e.target.value);
                  }}
                  value={state.searchTerm}
                />
              </div>
            </div>
          </div>
          {shouldShowParkingCSVExport && (
            <button
              disabled={state.isDownloading}
              className="absolute left-12 hidden h-10 cursor-pointer items-center self-end whitespace-nowrap rounded-md bg-white p-3 transition-colors hover:bg-indigo-600 hover:text-white lg:flex"
              onClick={state.downloadCSV}
            >
              <UploadIcon className="w-4" />
              <span className="ml-3 font-semibold">Exportera lista</span>
            </button>
          )}
        </div>
      </div>
      {parking.length !== 0 ? (
        <div className="mx-4 flex-1 md:mx-14">
          <table className="w-full rounded-md bg-white">
            <thead>
              <tr className="text-left">
                <TableHeaderCell
                  className="hidden lg:block"
                  title="Registreringsnummer"
                  isActive={
                    parkingStore.filter === ParkingFilter.RegistrationNumber
                  }
                  isAscending={parkingStore.isAscending}
                  onClick={() =>
                    state.setFilter(ParkingFilter.RegistrationNumber)
                  }
                />
                <TableHeaderCell
                  className="lg:hidden"
                  title="Reg.nr"
                  isActive={
                    parkingStore.filter === ParkingFilter.RegistrationNumber
                  }
                  isAscending={parkingStore.isAscending}
                  onClick={() =>
                    state.setFilter(ParkingFilter.RegistrationNumber)
                  }
                />
                <TableHeaderCell
                  title="Tidstämpel"
                  isActive={parkingStore.filter === ParkingFilter.Time}
                  isAscending={parkingStore.isAscending}
                  onClick={() => state.setFilter(ParkingFilter.Time)}
                />
              </tr>
            </thead>
            <tbody>
              {parking.map((parking, index, arr) => (
                <tr
                  key={parking.id}
                  className={`border-t border-gray-200 ${
                    index % 2 === 0 ? 'bg-gray-50' : ''
                  } ${index !== arr.length - 1 ? 'border-b' : ''}`}
                >
                  <td className="py-5 pl-5 text-xs xs:pl-7 md:text-base">
                    {parking.registration_number}
                  </td>
                  <td className="py-5 pl-5 text-xs xs:pl-7 md:text-base">
                    {parking.from_dt.getHours()}:
                    {(parking.from_dt.getMinutes() < 10 ? '0' : '') +
                      parking.from_dt.getMinutes()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="mx-4 flex-1 rounded-md bg-white px-7 py-7 text-center md:mx-14 md:py-28">
          <p className="text-2xl font-extrabold text-indigo-900 md:text-3xl">
            Det finns inga parkerade bilar.
          </p>
        </div>
      )}

      <div className="pb-9 pt-5 md:pb-12 md:pt-7">
        {numParkingPages! > 1 && (
          <Pagination
            page={parkingStore.page}
            numPages={numParkingPages!}
            onChange={state.setCurrentPage}
          />
        )}
      </div>
    </FadeInDiv>
  );
});
