import { useCallback, useRef } from 'react';

/**
 * A custom ref hook that makes an element draggable horizontally
 * if there is a horizontal scrollbar. If there is no horizontal
 * scrollbar on the element it does nothing.
 */
export const useHorizontalDragRef = () => {
  const elementRef = useRef<HTMLDivElement>();
  const isHoldingDownRef = useRef(false);

  const mouseOverHandler = useCallback(() => {
    const { current: el } = elementRef;
    const hasHorizontalScrollbar = el!.scrollWidth > el!.clientWidth;
    // We only want to change the cursor to a grab icon if the
    // element has a scrollbar and the user is not currently dragging
    // the element already (i.e. it already has a grabbing icon)
    if (hasHorizontalScrollbar && !isHoldingDownRef.current) {
      el!.style.cursor = 'grab';
    } else {
      el!.style.removeProperty('cursor');
    }
  }, []);

  const mouseMoveHandler = useCallback((event) => {
    elementRef.current!.scrollLeft -= event.movementX;
  }, []);

  const mouseUpHandler = useCallback(() => {
    const { current: el } = elementRef;
    el!.style.cursor = 'grab';
    // Make the user able to select text inside the element again.
    el!.style.removeProperty('user-select');

    isHoldingDownRef.current = false;

    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
  }, []);

  const mouseDownHandler = useCallback(() => {
    const { current: el } = elementRef;
    const hasHorizontalScrollbar = el!.scrollWidth > el!.clientWidth;

    if (!hasHorizontalScrollbar) return;

    el!.style.cursor = 'grabbing';
    // We don't want the user to accidentally select some
    // text while dragging.
    el!.style.userSelect = 'none';

    isHoldingDownRef.current = true;

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  }, []);

  return (element: HTMLDivElement) => {
    if (element) {
      elementRef.current = element;
      element.addEventListener('mousedown', mouseDownHandler);
      element.addEventListener('mouseover', mouseOverHandler);
    } else {
      const { current: el } = elementRef;
      el!.removeEventListener('mousedown', mouseDownHandler);
      el!.removeEventListener('mouseover', mouseOverHandler);
    }
  };
};
