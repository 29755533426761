import React, { FC, memo, SVGProps } from 'react';

export const CompanyIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    fill="none"
    className="w-4"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="0.5"
      d="M10.75.25C4.94.25.25 4.94.25 10.75s4.69 10.5 10.5 10.5 10.5-4.69 10.5-10.5S16.55.25 10.75.25zm0 19.99c-5.24 0-9.49-4.26-9.49-9.49s4.25-9.49 9.49-9.49 9.49 4.26 9.49 9.49-4.26 9.49-9.49 9.49z"
    ></path>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="0.5"
      d="M13.2 5.36H8.3v2.27H5.02v7.45h11.45V7.63h-3.28V5.36h.01zm-3.89.98h2.91v1.11H9.31V6.34zm-3.28 7.74v-2.91H10v.75h1.5v-.75h3.97v2.91H6.03zm9.44-3.92H6.03V8.61h9.46v1.55h-.03.01z"
    ></path>
  </svg>
));
