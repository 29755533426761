import React, { ChangeEvent, FC } from 'react';
import {
  Combobox as ReachCombobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from '@reach/combobox';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { FormError } from './FormError';

interface Item {
  label: string;
  value: string;
}

interface ComboboxState {
  searchText: string;
  handleSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSelect: (item: string) => void;
  filteredItems: Item[];
}

interface ComboboxProps {
  items: Item[];
  onSelect: (item: Item) => void;
  defaultValue: string;
  disabled?: boolean;
  error?: string;
  placeholder?: string;
}

export const Combobox: FC<ComboboxProps> = observer(
  ({ items, onSelect, defaultValue, disabled = false, error, placeholder }) => {
    const state = useLocalObservable<ComboboxState>(() => ({
      searchText: defaultValue,
      handleSearchChange(event) {
        state.searchText = event.target.value;
      },
      handleSelect(value) {
        state.searchText = value;
        const item = items.find((item) => item.label === value);

        if (item) {
          onSelect(item);
        }
      },
      get filteredItems(): Item[] {
        return items.filter((item) =>
          item.label.toLowerCase().includes(state.searchText.toLowerCase()),
        );
      },
    }));

    return (
      <div>
        <ReachCombobox openOnFocus onSelect={state.handleSelect}>
          <ComboboxInput
            disabled={disabled}
            placeholder={placeholder}
            value={state.searchText}
            onChange={state.handleSearchChange}
            className={`w-full truncate rounded-md border border-gray-200 bg-white p-3 text-gray-900 placeholder:text-gray-500 focus:border-indigo-600 focus:outline-none disabled:bg-gray-100
            ${error ? 'border-pink-500' : ''}`}
          />
          {state.filteredItems && (
            <ComboboxPopover className="my-1 max-h-96 w-full min-w-48 overflow-y-auto rounded-md border border-gray-200 bg-white shadow-lg">
              {state.filteredItems.length > 0 ? (
                <ComboboxList>
                  {state.filteredItems.map((item, index) => (
                    <ComboboxOption
                      key={index}
                      value={item.label}
                      className="w-full cursor-pointer overflow-y-hidden p-3 hover:bg-indigo-100"
                    />
                  ))}
                </ComboboxList>
              ) : (
                <div className="p-3">Inget resultat</div>
              )}
            </ComboboxPopover>
          )}
        </ReachCombobox>
        {error && <FormError>{error}</FormError>}
      </div>
    );
  },
);
