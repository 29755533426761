import React from 'react';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuPopover,
  MenuItems,
} from '@reach/menu-button';
import { positionMatchWidth } from '@reach/popover';
import { InfoBox } from '../../../components/InfoBox';
import { DashboardPeriod } from '../../../types';
import { formatPeriodDates } from '../../../utils';
import { observer } from 'mobx-react-lite';
import { ChevronDownIcon } from '../../../icons';
import { useMouseDownOnResizeRef } from '../../../hooks';

const PeriodMenuItem = observer(
  ({
    value,
    isSelected,
    onSelect,
  }: {
    value: string;
    isSelected: boolean;
    onSelect: () => void;
  }) => (
    <MenuItem
      className={`flex w-full cursor-pointer flex-col items-baseline p-3 hover:bg-gray-50 focus:bg-gray-50 ${
        isSelected ? 'bg-gray-50' : ''
      }`}
      onSelect={onSelect}
    >
      {value}
    </MenuItem>
  ),
);

const PeriodSelect = observer(
  ({
    period,
    onChange,
  }: {
    period: DashboardPeriod;
    onChange: (period: DashboardPeriod) => void;
  }) => {
    const ref = useMouseDownOnResizeRef();

    return (
      <Menu>
        {({ isExpanded }) => (
          <div className="relative w-full">
            <MenuButton
              ref={isExpanded ? ref : undefined}
              className="flex w-full items-center justify-between rounded-md border border-gray-200 bg-white py-3 pl-3 pr-2 focus:outline-none"
            >
              {period}
              <div className="ml-2 w-5 flex-shrink-0">
                <ChevronDownIcon
                  className={`transform fill-current text-gray-500 transition-transform ${
                    isExpanded ? 'rotate-180' : ''
                  }`}
                />
              </div>
            </MenuButton>

            {isExpanded && (
              <MenuPopover
                position={positionMatchWidth}
                className="absolute my-1 max-h-96 w-full overflow-y-auto rounded-md border border-gray-200 bg-white shadow-lg"
              >
                <MenuItems className="focus:outline-none">
                  <PeriodMenuItem
                    value={DashboardPeriod.Day}
                    isSelected={period === DashboardPeriod.Day}
                    onSelect={() => onChange(DashboardPeriod.Day)}
                  />
                  <PeriodMenuItem
                    value={DashboardPeriod.Week}
                    isSelected={period === DashboardPeriod.Week}
                    onSelect={() => onChange(DashboardPeriod.Week)}
                  />
                  <PeriodMenuItem
                    value={DashboardPeriod.Month}
                    isSelected={period === DashboardPeriod.Month}
                    onSelect={() => onChange(DashboardPeriod.Month)}
                  />
                  <PeriodMenuItem
                    value={DashboardPeriod.Year}
                    isSelected={period === DashboardPeriod.Year}
                    onSelect={() => onChange(DashboardPeriod.Year)}
                  />
                </MenuItems>
              </MenuPopover>
            )}
          </div>
        )}
      </Menu>
    );
  },
);

const PeriodButton = observer(
  ({ rotate = false, onClick }: { rotate?: boolean; onClick: () => void }) => (
    <button
      className="flex h-8 w-8 items-center justify-center rounded-full border border-solid border-gray-200 bg-white hover:bg-gray-50 focus:bg-gray-50 focus:outline-none"
      onClick={onClick}
    >
      <ChevronDownIcon
        className={`w-6 transform fill-current ${
          rotate ? 'rotate-90' : '-rotate-90'
        }`}
      />
    </button>
  ),
);

type PeriodControllerProps = {
  title: string;
  tooltip: string;
  period: DashboardPeriod;
  startDate: Date;
  endDate: Date;
  onPrevious: () => void;
  onNext: () => void;
  onChangePeriod: (period: DashboardPeriod) => void;
};

export const PeriodController = observer(
  ({
    title,
    tooltip,
    period,
    startDate,
    endDate,
    onPrevious,
    onNext,
    onChangePeriod,
  }: PeriodControllerProps) => {
    return (
      <>
        <div className="mb-4 flex items-center justify-between">
          <span className="text-lg font-semibold">{title}</span>
          <div className="flex items-center">
            <InfoBox content={tooltip} />
            <div className="ml-3 hidden w-28 2xl:block">
              <PeriodSelect period={period} onChange={onChangePeriod} />
            </div>
          </div>
        </div>
        <div className="mb-4 block 2xl:hidden">
          <PeriodSelect period={period} onChange={onChangePeriod} />
        </div>
        <div className="flex items-center justify-between">
          <PeriodButton rotate onClick={onPrevious} />
          <span className="text-center font-semibold text-gray-900">
            {formatPeriodDates(period, startDate, endDate)}
          </span>
          <PeriodButton onClick={onNext} />
        </div>
      </>
    );
  },
);
