import { flow, Instance, types as t } from 'mobx-state-tree';
import { withRequest } from '../extensions';
import { CompanyFormData } from '../pages';
import { maybeNullReference } from '../types';

export const Company = t
  .model('Company', {
    id: t.identifierNumber,
    name: t.string,
    organization_number: t.maybeNull(t.string),
    contact_name: t.maybeNull(t.string),
    contact_email: t.maybeNull(t.string),
    contact_phone: t.maybeNull(t.string),
    is_active: t.boolean,
    meta_data: t.maybeNull(t.map(t.string)),
  })
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;
    return {
      patch: flow(function* (formData: CompanyFormData) {
        const {
          name,
          orgNumber,
          contactName,
          contactEmail,
          contactPhone,
          metaData,
        } = formData;

        const data = {
          name,
          organization_number: orgNumber || null,
          contact_name: contactName || null,
          contact_email: contactEmail || null,
          contact_phone: contactPhone || null,
          meta_data: metaData?.reduce(
            (prev, current) => ({
              ...prev,
              [current.key]: current.value,
            }),
            {},
          ),
        };

        try {
          yield request({
            method: 'PATCH',
            url: `/api/companies/${self.id}/`,
            data,
          });
          self.name = name;
          self.organization_number = orgNumber;
          self.contact_name = contactName;
          self.contact_email = contactEmail;
          self.contact_phone = contactPhone;
          self.meta_data = data.meta_data as any;
        } catch (error) {
          // No need to handle this error since the general error
          // message will be shown to the user and they can try again.
        }
      }),
      deactivate: flow(function* () {
        try {
          yield request({
            method: 'PATCH',
            url: `/api/companies/${self.id}/`,
            data: {
              is_active: false,
            },
          });
          self.is_active = false;
        } catch (error) {
          // No need to handle this error since the general error
          // message will be shown to the user and they can try again.
        }
      }),
    };
  });

export type CompanyInstance = Instance<typeof Company>;
export const CompanyReference = maybeNullReference(Company);
