import {
  flow,
  Instance,
  SnapshotIn,
  types as t,
  applySnapshot,
  getSnapshot,
} from 'mobx-state-tree';
import { withRequest } from '../extensions';
import { IsoDate, IssuePriority, IssueStatus, numEnumeration } from '../types';
import { timeSince } from '../utils';
import { IssueNote, IssueNoteInstance } from './IssueNote';
import { IssueFeedback } from './IssueFeedback';
import { IssueTypeReference } from './IssueType';
import { FacilityReference } from './Facility';
import { UserInstance, UserReference } from './User';
import { CompanyReference } from './Company';
import { IssueFile } from './IssueFile';
import { IssueTimeReport, TimeReportInstance } from './IssueTimeReport';
import { ArticleInstance } from './Article';

export const Issue = t
  .model('Issue', {
    id: t.identifierNumber,
    // Every issue has a global unique id, but the number is a unique auto-incremented id for the organization,
    // so we use that in the user-facing issue number rendering.
    number: t.number,
    created_at: IsoDate,
    resolved_at: t.maybeNull(IsoDate),
    reporter_name: t.maybeNull(t.string),
    reporter_phone: t.maybeNull(t.string),
    reporter_email: t.maybeNull(t.string),
    issue_type: IssueTypeReference,
    issue: t.string,
    company: CompanyReference,
    notes: t.maybeNull(t.array(IssueNote)),
    feedbacks: t.maybeNull(t.array(IssueFeedback)),
    files: t.maybeNull(t.array(IssueFile)),
    status: t.enumeration<IssueStatus>('Status', Object.values(IssueStatus)),
    facility: FacilityReference,
    priority: numEnumeration<IssuePriority>(
      'Priority',
      Object.values(IssuePriority),
    ),
    assigned_to: UserReference,
    co_workers: t.maybeNull(t.array(UserReference)),
    extra_data: t.maybeNull(t.map(t.boolean)),
    has_subcontractor_cost: t.boolean,
    time_reports: t.maybeNull(t.array(IssueTimeReport)),
    rounding_task: t.maybeNull(t.number),
    meta_data: t.maybeNull(t.map(t.string)),
  })
  .views((self) => ({
    // MobX computeds are cached as long as they are observed,
    // so this value will be recalculated when the issue gets
    // unused and is used again. This is most likely good enough
    // for a '18 min' type value.
    get age(): string {
      return timeSince(self.created_at);
    },
    get timeToClose(): string {
      if (self.resolved_at) {
        return timeSince(self.created_at, self.resolved_at);
      }
      return '';
    },
  }))
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;

    return {
      merge(partialSnapshot: Partial<SnapshotIn<typeof self>>) {
        applySnapshot(self, {
          ...getSnapshot(self),
          ...partialSnapshot,
        });
      },
      addNote: flow(function* (
        author: number,
        content: string,
        is_external: boolean,
        files: File[],
      ) {
        const tempId = Math.random();
        const note = {
          id: tempId,
          content,
          author,
          created_at: new Date(),
          is_external,
          files: files.map((file) => ({
            id: Math.random(),
            name: file.name,
            file: '#',
          })),
        };

        const formData = new FormData();
        formData.append('content', note.content);
        formData.append('author', note.author.toString());
        formData.append('is_external', note.is_external.toString());
        files.forEach((file) => formData.append('files', file));

        self.notes!.unshift(note);

        try {
          const { data: newNote } = yield request({
            method: 'POST',
            url: `/api/issues/${self.id}/notes/`,
            data: formData,
          });

          const tempNoteIndex = self.notes!.findIndex(
            (note) => note.id === tempId,
          );

          self.notes!.splice(tempNoteIndex, 1);
          self.notes!.splice(tempNoteIndex, 0, newNote);
        } catch (error) {
          const tempNoteIndex = self.notes!.findIndex(
            (note) => note.id === tempId,
          );

          self.notes!.splice(tempNoteIndex, 1);

          throw error;
        }
      }),
      deleteNote: flow(function* (note: IssueNoteInstance) {
        try {
          yield request({
            method: 'DELETE',
            url: `/api/issues/${self.id}/notes/${note.id}/`,
          });

          const noteIndex = self.notes!.indexOf(note);
          self.notes!.splice(noteIndex, 1);
        } catch (error) {
          // No reason to do anything here since the note is not deleted optimistically.
        }
      }),
      assignTo: flow(function* (userId: number | null) {
        const currentId = self.assigned_to?.id;
        self.assigned_to = userId as any;

        const currentCoWorkersIds: number[] = [];
        self.co_workers?.forEach((coWorker) => {
          currentCoWorkersIds.push(coWorker!.id);
        });

        const data = { assigned_to: userId } as any;

        const shouldAssignCoWorker = !userId && self.co_workers!.length > 0;

        if (shouldAssignCoWorker) {
          const coWorker = self.co_workers![0] as UserInstance;
          self.assigned_to = coWorker.id as any;

          self.co_workers?.splice(0, 1);

          const coWorkerIds: number[] = [];
          self.co_workers?.forEach((user) => {
            coWorkerIds.push(user!.id);
          });

          data.assigned_to = coWorker.id;
          data.co_workers = coWorkerIds;
        }

        const index = self.co_workers?.findIndex(
          (coWorker) => coWorker?.id === userId,
        );
        const isUserInCoWorkers = index !== -1;

        if (isUserInCoWorkers) {
          self.co_workers?.splice(index as number, 1);

          const coWorkerIds: number[] = [];
          self.co_workers?.forEach((user) => {
            coWorkerIds.push(user!.id);
          });

          data.co_workers = coWorkerIds;
        }
        try {
          yield request({
            method: 'PATCH',
            url: `/api/issues/${self.id}/`,
            data,
          });
        } catch (error) {
          self.assigned_to = currentId as any;
          self.co_workers = currentCoWorkersIds as any;
          throw error;
        }
      }),
      addCoWorker: flow(function* (userId: number) {
        self.co_workers?.push(userId);

        const coWorkersIds: number[] = [];
        self.co_workers?.forEach((user) => {
          coWorkersIds.push(user!.id);
        });
        try {
          yield request({
            method: 'PATCH',
            url: `/api/issues/${self.id}/`,
            data: { co_workers: coWorkersIds },
          });
        } catch (error) {
          const index = self.co_workers!.findIndex(
            (user) => user?.id === userId,
          );
          self.co_workers?.splice(index, 1);
          throw error;
        }
      }),
      deleteCoWorker: flow(function* (userId: number) {
        const index = self.co_workers!.findIndex((user) => user?.id === userId);
        self.co_workers?.splice(index, 1);

        const coWorkersIds: number[] = [];
        self.co_workers?.forEach((user) => {
          coWorkersIds.push(user!.id);
        });

        try {
          yield request({
            method: 'PATCH',
            url: `/api/issues/${self.id}/`,
            data: { co_workers: coWorkersIds },
          });
        } catch (error) {
          self.co_workers?.splice(index, 0, userId);
          throw error;
        }
      }),
      setPriority: flow(function* (priority: IssuePriority) {
        const currentPriority = self.priority;
        self.priority = priority;
        try {
          yield request({
            method: 'PATCH',
            url: `/api/issues/${self.id}/`,
            data: { priority },
          });
        } catch (error) {
          self.priority = currentPriority;
          throw error;
        }
      }),
      setStatus: flow(function* (status: IssueStatus) {
        const currentStatus = self.status;
        self.status = status;
        try {
          yield request({
            method: 'PATCH',
            url: `/api/issues/${self.id}/`,
            data: { status },
          });
        } catch (error) {
          self.status = currentStatus;
          throw error;
        }
      }),
      setHasSubcontractorCost: flow(function* (hasSubcontractorCost: boolean) {
        const currentHasSubcontractorCost = self.has_subcontractor_cost;
        self.has_subcontractor_cost = hasSubcontractorCost;

        try {
          yield request({
            method: 'PATCH',
            url: `/api/issues/${self.id}/`,
            data: { has_subcontractor_cost: hasSubcontractorCost },
          });
        } catch (error) {
          self.has_subcontractor_cost = currentHasSubcontractorCost;
          throw error;
        }
      }),
      addTimeReport: flow(function* (
        worked_hours: number,
        chargeable_hours: number,
        reporter: number | null,
        articles: ArticleInstance[],
      ) {
        const articleIds: number[] = [];
        articles.forEach((article) => {
          articleIds.push(article.id);
        });

        const tempId = Math.random();
        const timeReport = {
          id: tempId,
          worked_hours,
          chargeable_hours,
          reporter,
          articles: articleIds,
        };

        self.time_reports?.push(timeReport);

        try {
          const { data: newTimeReport } = yield request({
            method: 'POST',
            url: `/api/issues/${self.id}/time_reports/`,
            data: {
              worked_hours,
              chargeable_hours,
              reporter,
              articles: articleIds,
            },
          });

          const index = self.time_reports!.findIndex(
            (report) => report.id === tempId,
          );

          self.time_reports!.splice(index, 1);
          self.time_reports!.splice(index, 0, newTimeReport);
        } catch (error) {
          const index = self.time_reports!.findIndex(
            (report) => report.id === tempId,
          );
          self.time_reports!.splice(index, 1);
          throw error;
        }
      }),
      deleteTimeReport: flow(function* (timeReportId: number) {
        const index = self.time_reports?.findIndex(
          (report) => report.id === timeReportId,
        );
        const deletedReport = self.time_reports?.splice(index as number, 1)[0];

        try {
          yield request({
            method: 'DELETE',
            url: `/api/issues/${self.id}/time_reports/${timeReportId}/`,
          });
        } catch (error) {
          self.time_reports?.splice(
            index as number,
            0,
            deletedReport as TimeReportInstance,
          );
        }
      }),
      closeIssue: flow(function* () {
        const currentStatus = self.status;

        try {
          self.status = IssueStatus.Done;
          self.resolved_at = new Date();

          const res = yield request({
            method: 'PATCH',
            url: `/api/issues/${self.id}/`,
            data: {
              status: IssueStatus.Done,
            },
          });

          self.resolved_at = new Date(res.data.resolved_at);
        } catch (error) {
          self.status = currentStatus;
          self.resolved_at = null;

          throw error;
        }
      }),
      reopenIssue: flow(function* () {
        const currentResolvedAt = self.resolved_at;

        try {
          self.status = IssueStatus.InProgress;
          self.resolved_at = null;

          yield request({
            method: 'PATCH',
            url: `/api/issues/${self.id}/`,
            data: {
              status: IssueStatus.InProgress,
            },
          });
        } catch (error) {
          self.status = IssueStatus.Done;
          self.resolved_at = currentResolvedAt;

          throw error;
        }
      }),
      setMetaData: flow(function* (
        keyValues: { key: string; value: string }[],
      ) {
        const currentMetaData = self.meta_data;

        const metaData = keyValues?.reduce(
          (prev, current) => ({
            ...prev,
            [current.key]: current.value,
          }),
          {},
        );

        try {
          self.meta_data = metaData as any;
          yield request({
            method: 'PATCH',
            url: `/api/issues/${self.id}/`,
            data: {
              meta_data: metaData,
            },
          });
        } catch (error) {
          self.meta_data = currentMetaData;
        }
      }),
    };
  });

export type IssueInstance = Instance<typeof Issue>;
export type IssueSnapshotIn = SnapshotIn<typeof Issue>;

const ISSUE_FIELDS_TO_LIST: (keyof IssueInstance)[] = [
  'id',
  'number',
  'created_at',
  'resolved_at',
  'reporter_name',
  'reporter_phone',
  'reporter_email',
  'issue_type',
  'issue',
  'company',
  'status',
  'facility',
  'priority',
  'assigned_to',
  'co_workers',
  'extra_data',
  'has_subcontractor_cost',
  'meta_data',
];

export const LIST_ISSUE_FIELDS = ISSUE_FIELDS_TO_LIST.join(',');
