const colors = [
  '#D9D9D9',
  '#FFDEDE',
  '#FFECD0',
  '#D3E7D2',
  '#D9FDE5',
  '#FFCC9D',
  '#CDEFFE',
  '#DEFBFF',
  '#E1DEFF',
  '#FFDEF6',
  '#B6D3FE',
  '#DDFFC9',
  '#FFF9C7',
  '#C1C8FF',
  '#FFADED',
  '#AFFFCA',
  '#FCB8B8',
  '#F8A6BA',
  '#C08EFF',
  '#989CFF',
];
export function nameToSoftColor(name: string) {
  let num = 0;

  for (let i = 0; i < name.length; i++) {
    num += name.charCodeAt(i);
  }
  return colors[num % colors.length];
}
