import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  FadeInDiv,
  ImageCrop,
  TagSelect,
  UploadImageButton,
  DeletePopup,
  InputWithError,
  Label,
  AdditionalInformation,
  UserSelect,
  Avatar,
  InfoBox,
} from '../components';
import { TrashCanIcon, UserSelectIcon } from '../icons';
import { useStore } from '../hooks';
import { AccountType, SubmitState, UserMetaData } from '../types';
import { UserInstance } from '../models';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import { useNavigate, useParams } from 'react-router-dom';
import { SaveButton } from '../components/SaveButton';
import { SAVE_BUTTON_ANIMATION_DURATION } from '../constants';

export const EditUserPage = observer(() => {
  const params = useParams<{ userId: string }>();
  const { userStore } = useStore();

  const userId = parseInt(params.userId as string, 10);
  const user = userStore.users?.find((user) => user.id === userId);

  if (!user) return null;

  return <EditUser user={user} />;
});

interface EditUserState {
  submitState: SubmitState;
  setSubmitState: (submitState: SubmitState) => void;
  onSubmit: (data: FormData) => void;

  isDeletePopupOpen: boolean;
  openDeletePopup: () => void;
  closeDeletePopup: () => void;
  handleDeleteUser: () => void;

  isImageCropOpen: boolean;
  setIsImageCropOpen: (isImageCropOpen: boolean) => void;

  imageBlob: Blob | null;
  setImageBlob: (imageBlob: Blob | null) => void;
  imageSrc: string;
  userImage: string | null;
  setUserImage: (image: string | null) => void;

  handleImageSave: (blob: Blob) => void;
  handleDeleteImage: () => void;

  activeSubstitute: (value: number | null) => UserInstance | undefined;
}

export interface FormData {
  name: string;
  phoneNumber: string;
  role: AccountType;
  metaData: { key: string; value: string }[];
  substitute: number | null;
}

const EditUser = observer(({ user }: { user: UserInstance }) => {
  const {
    userStore,
    organizationStore: { organization },
  } = useStore();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setValue,
  } = useForm<FormData>({
    shouldUnregister: true,
    reValidateMode: 'onSubmit',
    defaultValues: {
      name: user.name,
      phoneNumber: user.phone_number || '',
      role: user.role,
      metaData: user.meta_data
        ? Array.from(user.meta_data.keys()).map((key) => ({
            key,
            value: user.meta_data?.get(key) || '',
          }))
        : undefined,
      substitute: user.substitute,
    },
  });

  const state = useLocalObservable<EditUserState>(() => ({
    submitState: SubmitState.None,
    setSubmitState(submitState) {
      state.submitState = submitState;
    },
    async onSubmit(data) {
      const { name, role, phoneNumber, metaData, substitute } = data;

      const updatedUser = {
        id: user.id,
        email: user.email,
        name,
        role,
        phone_number: phoneNumber,
        image: state.userImage,
        meta_data: metaData?.reduce(
          (prev, current) => ({
            ...prev,
            [current.key]: current.value,
          }),
          {},
        ),
        substitute,
      };

      await userStore.editUser(updatedUser as UserInstance, state.imageBlob);

      state.setSubmitState(SubmitState.Success);
      setTimeout(() => {
        state.setSubmitState(SubmitState.None);
      }, SAVE_BUTTON_ANIMATION_DURATION);
    },

    isDeletePopupOpen: false,
    openDeletePopup() {
      state.isDeletePopupOpen = true;
    },
    closeDeletePopup() {
      state.isDeletePopupOpen = false;
    },
    async handleDeleteUser() {
      await userStore.deleteUser(user.id);
      navigate('/organization/users');
    },
    isImageCropOpen: false,
    setIsImageCropOpen(isImageCropOpen) {
      state.isImageCropOpen = isImageCropOpen;
    },
    imageBlob: null,
    setImageBlob(imageBlob) {
      state.imageBlob = imageBlob;
    },
    get imageSrc(): string {
      if (state.imageBlob) {
        return URL.createObjectURL(state.imageBlob);
      }
      return state.userImage ?? '';
    },
    userImage: user.image,
    setUserImage(image) {
      state.userImage = image;
    },
    handleImageSave(blob) {
      state.setImageBlob(blob);
      state.setIsImageCropOpen(false);
    },
    handleDeleteImage() {
      if (state.imageBlob) {
        state.setImageBlob(null);
      } else {
        state.setUserImage(null);
      }
    },
    activeSubstitute(value) {
      return userStore.users?.find((u) => u.id === value);
    },
  }));

  const canDeleteUser =
    userStore.me &&
    user !== userStore.me &&
    user.role !== AccountType.Organization;

  return (
    <FadeInDiv className="p-4 md:p-14">
      <div className="block rounded-md bg-white p-7 md:inline-block">
        <span className="w-full text-xl font-extrabold text-indigo-900">
          Redigera användare
        </span>
      </div>
      <form onSubmit={handleSubmit(state.onSubmit)}>
        <div className="mt-5 space-y-6 rounded-md bg-white p-7 md:mt-10">
          <div className="mb-8 flex max-w-fit flex-col space-y-3">
            <span className="font-semibold text-gray-900">Profilbild</span>
            <UploadImageButton
              imageSrc={state.imageSrc}
              onClick={() => state.setIsImageCropOpen(true)}
              onDelete={state.handleDeleteImage}
            />
          </div>
          <div className="max-w-xs">
            <span className="font-semibold text-gray-900">Roll</span>
            <div className="mt-1">
              <Controller
                control={control}
                name="role"
                render={({ field: { value, onChange } }) => (
                  <TagSelect
                    type="role"
                    disabled={value === AccountType.Organization}
                    onSelect={onChange}
                    selected={value}
                    showBorderAndChevron
                  />
                )}
              />
            </div>
          </div>

          <div className="max-w-xs">
            <Label required>Namn</Label>
            <InputWithError
              type="text"
              placeholder="Namn"
              {...register('name', {
                required: 'Fyll i detta fältet',
                maxLength: {
                  value: 150,
                  message: 'Max 150 tecken',
                },
              })}
              error={errors.name?.message}
            />
          </div>
          <div className="flex flex-col space-y-6 xl:flex-row xl:space-y-0">
            <div className="max-w-xs flex-1 xl:mr-7">
              <Label>E-post</Label>
              <InputWithError type="text" disabled value={user.email} />
            </div>
            <div className="max-w-xs flex-1">
              <Label>Telefonnummer</Label>
              <InputWithError
                type="tel"
                placeholder="Telefonnummer"
                {...register('phoneNumber', {
                  maxLength: {
                    value: 20,
                    message: 'Max 20 tecken',
                  },
                })}
                error={errors.phoneNumber?.message}
              />
            </div>
          </div>
          <div>
            <div className="mb-2 inline-flex items-center gap-2">
              <span className="font-semibold text-gray-900">
                Temporär överlämning
              </span>
              <InfoBox content="Alla nya automatisk tilldelade ärenden/ronderingar kommer vidarebefordras till denna användare." />
            </div>
            <div className="flex">
              <Controller
                control={control}
                name="substitute"
                render={({ field: { onChange, value } }) => (
                  <UserSelect
                    users={
                      userStore.users?.filter(
                        (u) => u.id !== user.id && u.role > AccountType.Parking,
                      ) as UserInstance[]
                    }
                    selected={state.activeSubstitute(value)}
                    onSelect={(user) => onChange(user?.id)}
                    onDelete={
                      value ? () => setValue('substitute', null) : undefined
                    }
                    renderButton={() => {
                      const substitute = state.activeSubstitute(value);

                      return substitute ? (
                        <Avatar user={substitute} />
                      ) : (
                        <UserSelectIcon className="w-8" />
                      );
                    }}
                    tooltip
                  />
                )}
              />
            </div>
          </div>
        </div>
        {organization?.hasEntreFeature && (
          <div className="mt-5 md:mt-7 2xl:max-w-1/2">
            <Controller
              control={control}
              name="metaData"
              render={({ field: { onChange } }) => (
                <AdditionalInformation
                  fields={Object.values(UserMetaData).map((key) => ({
                    key,
                    value: user.meta_data?.get(key) || '',
                  }))}
                  onChange={onChange}
                />
              )}
            />
          </div>
        )}
        <div className="mt-5 flex items-center md:mt-7">
          <SaveButton
            disabled={isSubmitting || state.submitState === SubmitState.Success}
            isSaved={state.submitState === SubmitState.Success}
          />
          {canDeleteUser && (
            <button
              type="button"
              className="ml-5 flex items-center justify-center rounded-md bg-rose-300 p-3 transition duration-200 hover:bg-rose-500"
              onClick={state.openDeletePopup}
            >
              <TrashCanIcon className="mr-3 w-4 text-white" />
              <span className="font-semibold text-white">Radera</span>
            </button>
          )}
        </div>
      </form>
      {state.isDeletePopupOpen && (
        <DeletePopup
          onDismiss={state.closeDeletePopup}
          onConfirm={state.handleDeleteUser}
          title="Radera användare"
        >
          Om du raderar användaren går det inte att återställas. Är du säker på
          att du vill radera användaren?
        </DeletePopup>
      )}
      {state.isImageCropOpen && (
        <DialogOverlay
          className="absolute top-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-40"
          isOpen
          onDismiss={() => state.setIsImageCropOpen(false)}
        >
          <DialogContent
            aria-label="dialog"
            className="mx-3 w-[40rem] max-w-screen rounded-md bg-white p-3 sm:p-10"
          >
            <ImageCrop onSave={state.handleImageSave} />
          </DialogContent>
        </DialogOverlay>
      )}
    </FadeInDiv>
  );
});
