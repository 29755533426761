import { types as t } from 'mobx-state-tree';
import { IsoDate } from '../types';
import { CompanyReference } from './Company';

export const Parking = t.model('Parking', {
  id: t.number,
  from_dt: IsoDate,
  registration_number: t.string,
  company: CompanyReference,
  email: t.string,
});
