import { flow, types as t } from 'mobx-state-tree';
import debounce from 'lodash.debounce';
import { withRequest } from '../extensions';
import { RecurringParking } from './RecurringParking';
import { RecurringParkingFilter } from '../types';
import { PARKING_PER_PAGE, REQUEST_DEBOUNCE_WAIT } from '../constants';
import { startOfDay, endOfDay } from 'date-fns';

const RecurringParkingArray = t.array(RecurringParking);

export const RecurringParkingStore = t
  .model('RecurringParkingStore', {
    recurringParking: t.maybeNull(RecurringParkingArray),
    num_parking: t.maybeNull(t.number),
    parkingSearch: '',
  })
  .views((self) => ({
    get parkingQuery(): URLSearchParams {
      return new URLSearchParams(self.parkingSearch);
    },
  }))
  .views((self) => ({
    get numParkingPages(): null | number {
      const { num_parking } = self;

      if (num_parking === null) return null;

      return Math.ceil(num_parking / PARKING_PER_PAGE);
    },
    get page(): number {
      const pageParam = self.parkingQuery.get('page');

      return pageParam ? Number(pageParam) : 1;
    },
    get filter(): RecurringParkingFilter {
      const sortParam = self.parkingQuery.get('sort');

      if (!sortParam) return '' as RecurringParkingFilter;

      return sortParam.replace('-', '') as RecurringParkingFilter;
    },
    get isAscending(): boolean {
      const sortParam = self.parkingQuery.get('sort');

      return sortParam !== null && sortParam[0] !== '-';
    },
    get dateRange(): [Date, Date] {
      const from = self.parkingQuery.get('from');
      const to = self.parkingQuery.get('to');

      if (from && to) {
        return [startOfDay(new Date(from)), endOfDay(new Date(to))];
      }

      return [startOfDay(new Date()), endOfDay(new Date())];
    },
  }))
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;

    return {
      fetchParking: flow(function* () {
        const [from_dt, to_dt] = self.dateRange;

        const promise = request({
          method: 'GET',
          url: '/api/parkings/recurrent/',
          params: {
            limit: PARKING_PER_PAGE,
            offset: (self.page - 1) * PARKING_PER_PAGE,
            ordering: self.parkingQuery.get('sort'),
            from_dt,
            to_dt,
            search: self.parkingQuery.get('search'),
          },
        });

        const { data } = yield promise;

        const parkings = data.results;

        if (self.recurringParking === null) {
          self.recurringParking = RecurringParkingArray.create();
        }

        self.recurringParking.replace(parkings);
        self.num_parking = data.count;
      }),
    };
  })
  .actions((self) => {
    const debouncedFetchParking = debounce(
      self.fetchParking,
      REQUEST_DEBOUNCE_WAIT,
    );

    return {
      resetRecurringParkingSearch() {
        self.parkingSearch = '';
      },
      handleRecurringParkingSearchChange(search: string) {
        self.parkingSearch = search;

        // We debounce parking requests that result from the user
        // navigating as to not overwhelm the network when navigating
        // back and forth quickly.
        debouncedFetchParking();
      },
    };
  });
