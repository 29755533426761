import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { ChangeEvent, FC, FormEvent, useRef } from 'react';
import { FormError } from './create-issue';
import { Label } from './Label';
import {
  DustingOffHandsIcon,
  CrossIcon,
  CrossIcon3,
  PaperClipIcon,
} from '../icons';
import { useStore } from '../hooks';

interface ConfirmRoundingState {
  handleSubmit: (event: FormEvent, taskId: number | null) => void;
  submitState: SubmitState;
  setSubmitState: (submitState: SubmitState) => void;
  formError: string;
  setFormError: (error: string) => void;
  filesError: string;
  setFilesError: (error: string) => void;
  handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
  filesToUpload: File[];
  setFilesToUpload: (filesToUpload: File[]) => void;
  handleClose: () => void;
  reset: () => void;
  formKey: number;
  setFormKey: (key: number) => void;
}

enum SubmitState {
  None = 'none',
  Pending = 'pending',
  Error = 'error',
  Success = 'success',
}

interface ConfirmRoundingProps {
  taskId: number | null;
  onClose: () => void;
}

export const ConfirmRounding: FC<ConfirmRoundingProps> = observer(
  ({ onClose, taskId }) => {
    const { roundingStore } = useStore();

    const fileInputRef = useRef<HTMLInputElement>(null);

    const state = useLocalObservable<ConfirmRoundingState>(() => ({
      submitState: SubmitState.None,
      setSubmitState(submitState) {
        state.submitState = submitState;
      },
      handleClose() {
        state.setSubmitState(SubmitState.None);
        state.setFilesToUpload([]);
        state.setFormError('');
        onClose();
      },
      reset() {
        state.setSubmitState(SubmitState.None);
        state.setFilesToUpload([]);
        state.setFormError('');
      },
      async handleSubmit(event, taskId) {
        event.preventDefault();
        const { comment } = event.target as any;

        if (comment.value.trim() === '' && state.filesToUpload.length === 0) {
          state.setFormError(
            'Skriv antingen en kommentar eller ladda upp minst en fil.',
          );
          return;
        }

        const currentTask = roundingStore.roundingTasks?.find(
          (task) => task.id === taskId,
        );

        if (!currentTask) {
          return;
        }
        try {
          state.setSubmitState(SubmitState.Pending);

          const taskDetails = {
            comment: comment.value,
          } as any;

          if (state.filesToUpload.length > 0) {
            taskDetails.files = state.filesToUpload;
          }

          await currentTask.approve(taskDetails);

          state.setSubmitState(SubmitState.Success);
          state.setFilesToUpload([]);
          state.setFilesError('');
        } catch (error) {
          state.setSubmitState(SubmitState.Error);
        }
      },
      formError: '',
      setFormError(error: string) {
        state.formError = error;
      },
      filesError: '',
      setFilesError(error) {
        state.filesError = error;
      },
      filesToUpload: [],
      setFilesToUpload(filesToUpload) {
        state.filesToUpload = filesToUpload;
      },
      handleFileChange(e) {
        const maxUploads = 6;
        const files = e.target.files!;
        const filesArray: File[] = [];
        state.setFilesError('');
        state.setFormError('');

        if (state.filesToUpload.length + files.length > maxUploads) {
          state.setFilesError(`Max ${maxUploads} bilder`);
        }

        if (!state.filesError) {
          Array.from(files).forEach((file) => {
            filesArray.push(file);
          });
          state.setFilesToUpload([...state.filesToUpload, ...filesArray]);
        }
        fileInputRef.current!.value = '';
      },
      formKey: 1,
      setFormKey(key) {
        state.formKey = key;
      },
    }));

    if (state.submitState === SubmitState.Success) {
      return <ConfirmRoundingSuccess onClose={state.handleClose} />;
    }

    return (
      <div className="scrollbar-none flex h-full flex-col overflow-y-auto bg-white px-4 py-4 shadow-md md:px-14 md:py-12">
        <div className="mb-10 flex items-center justify-end md:w-96 md:justify-between">
          <span className="hidden whitespace-nowrap text-3xl font-extrabold text-indigo-900 md:block">
            Genomför rondering
          </span>
          <button onClick={state.handleClose}>
            <CrossIcon3 className="w-7 text-gray-300" />
          </button>
        </div>
        <div className="mb-7 block md:hidden">
          <span className="whitespace-nowrap text-3xl font-extrabold text-indigo-900">
            Genomför rondering
          </span>
        </div>
        <form
          key={state.formKey}
          onSubmit={(event) => state.handleSubmit(event, taskId)}
          className="flex flex-grow flex-col justify-between"
        >
          <div>
            <div className="mb-7">
              <Label>Kommentar</Label>
              <textarea
                id="comment"
                name="comment"
                className="block w-full resize-none rounded-md border border-solid border-gray-200 p-3 text-sm focus:border-blue-500 focus:outline-none"
                placeholder="Lägg en kommentar"
                rows={6}
              ></textarea>
            </div>
            <div className="flex flex-col items-baseline">
              <input
                type="file"
                id="confirmRoundingAttachments"
                name="confirmRoundingAttachments"
                className="hidden"
                onChange={state.handleFileChange}
                ref={fileInputRef}
                multiple
              />
              <div className="-mt-3 mb-7 flex flex-wrap md:w-96">
                <label
                  htmlFor="confirmRoundingAttachments"
                  className="mr-3 mt-3 cursor-pointer rounded-md border border-solid border-gray-200 bg-gray-50 p-3 text-sm font-semibold text-gray-900 transition hover:bg-indigo-600 hover:text-white focus:border-blue-500 focus:outline-none"
                >
                  Bifoga bilder eller dokument
                </label>
                {state.filesToUpload.length > 0 &&
                  state.filesToUpload.map((file, index) => (
                    <div
                      key={index}
                      className="mr-3 mt-3 flex cursor-pointer items-center rounded-md border border-solid border-gray-200 p-3 text-sm font-semibold text-gray-900"
                    >
                      <label
                        htmlFor="attachments"
                        className="group mr-3 inline-flex h-4 w-4 items-center justify-center text-gray-300"
                      >
                        <PaperClipIcon className="text-black" />
                      </label>
                      {file.name}
                      <span
                        className="ml-6 block h-5 w-5 cursor-pointer text-gray-500 hover:text-blue-500"
                        onClick={() => {
                          const filesToUploadClone = [...state.filesToUpload];
                          filesToUploadClone.splice(index, 1);

                          state.setFilesToUpload(filesToUploadClone);
                        }}
                      >
                        <CrossIcon className="w-5" />
                      </span>
                    </div>
                  ))}
                {state.formError && (
                  <div className="w-full">
                    <FormError error={state.formError} />
                  </div>
                )}
                {state.filesError && (
                  <div className="w-full">
                    <FormError error={state.filesError} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <button
            className="h-12 w-full rounded-md bg-indigo-600 text-white transition hover:bg-indigo-900"
            type="submit"
            disabled={state.submitState === SubmitState.Pending}
          >
            Genomför
          </button>
        </form>
      </div>
    );
  },
);

interface ConfirmRoundingSuccessProps {
  onClose: () => void;
}

const ConfirmRoundingSuccess: FC<ConfirmRoundingSuccessProps> = ({
  onClose,
}) => {
  return (
    <div className="flex h-full flex-col bg-white px-5 py-5 shadow-md md:px-14 md:py-12">
      <div className="mb-7 flex items-center justify-end">
        <button onClick={onClose}>
          <CrossIcon3 className="w-7 text-gray-300" />
        </button>
      </div>
      <div className="mb-32 flex flex-grow justify-center md:w-96">
        <div className="flex flex-col items-center justify-center">
          <DustingOffHandsIcon className="mb-7 w-10 text-indigo-900" />
          <span className="text-2xl font-extrabold text-indigo-900">
            Ronderingen
          </span>
          <span className="text-2xl font-extrabold text-indigo-900">
            är genomförd!
          </span>
        </div>
      </div>
      <button
        className="mr-3 h-12 w-full whitespace-nowrap rounded-md border border-indigo-600 text-indigo-600 transition hover:border-indigo-900 hover:text-indigo-900"
        onClick={onClose}
      >
        Tillbaka till ronderingar
      </button>
    </div>
  );
};
