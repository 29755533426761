import React, { useEffect, FC } from 'react';
import Portal from '@reach/portal';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { ChevronDownIcon } from '../../../icons';

const SlideButton: FC<{
  onClick: () => void;
  rotate?: boolean;
}> = ({ onClick, rotate = false }) => (
  <button
    className="group flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-white transition-colors duration-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-800"
    onClick={(event) => {
      event.stopPropagation();
      onClick();
    }}
  >
    <ChevronDownIcon
      className={`w-5 transform fill-current ${
        rotate ? 'rotate-180 md:rotate-90' : 'md:-rotate-90'
      }`}
    />
  </button>
);

type LightboxProps = {
  files: { name: string; file: string }[];
  initialSlide: number;
  onClose: () => void;
};

export const Lightbox = observer(
  ({ files, initialSlide, onClose }: LightboxProps) => {
    const state = useLocalObservable(() => ({
      slide: initialSlide,
      prevSlide() {
        state.slide--;
        if (state.slide < 0) {
          state.slide = files.length - 1;
        }
      },
      nextSlide() {
        state.slide = (state.slide + 1) % files.length;
      },
    }));

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') onClose();
      };

      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);

    return (
      <Portal>
        <div className="absolute top-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-40">
          <div
            onClick={onClose}
            className="relative flex h-full w-full max-w-7xl flex-col items-center justify-between p-14 md:flex-row"
          >
            {files.length > 1 && (
              <div className="md:mb-0 md:mr-5">
                <SlideButton onClick={state.nextSlide} rotate />
              </div>
            )}
            <div
              className="mx-auto"
              onClick={(event) => event.stopPropagation()}
            >
              <img
                src={files[state.slide].file}
                className="mx-auto max-h-[60vh] md:max-h-[80vh]"
              />
            </div>
            {files.length > 1 && (
              <div className="md:ml-5 md:mt-0">
                <SlideButton onClick={state.nextSlide} />
              </div>
            )}
          </div>
        </div>
      </Portal>
    );
  },
);
