import React, { FC, memo, SVGProps } from 'react';

export const CrossIcon2: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="w-4"
    {...props}
  >
    <path
      transform="rotate(45.004 -4.535 7.535)"
      stroke="currentColor"
      strokeWidth="2"
      d="M0-1h9.999"
    />
    <path
      transform="rotate(134.996 4.706 4.293)"
      stroke="currentColor"
      strokeWidth="2"
      d="M0-1h9.999"
    />
  </svg>
));
