import { Instance, types as t } from 'mobx-state-tree';
import { ISSUES_PRIORITY_TEXTS, STATUS_TEXTS } from '../constants';
import { IssuePriority, IssueStatus, IssuesFilter } from '../types';
import { CompanyReference } from './Company';
import { FacilityReference } from './Facility';
import { IssueTypeReference } from './IssueType';
import { UserReference } from './User';

const FacilityFilter = t
  .model('FacilityFilter', {
    name: t.string,
    value: FacilityReference,
  })
  .views((self) => ({
    get displayName() {
      return self.value?.name;
    },
  }));

const CompanyFilter = t
  .model('CompanyFilter', {
    name: t.string,
    value: CompanyReference,
  })
  .views((self) => ({
    get displayName() {
      return self.value?.name;
    },
  }));

const UserFilter = t
  .model('UserFilter', {
    name: t.string,
    value: UserReference,
  })
  .views((self) => ({
    get displayName() {
      return self.value?.name;
    },
  }));

const IssueTypeFilter = t
  .model('IssueTypeFilter', {
    name: t.string,
    value: IssueTypeReference,
  })
  .views((self) => ({
    get displayName() {
      return self.value?.name;
    },
  }));

const DefaultFilter = t
  .model('DefaultFilter', {
    name: t.string,
    value: t.string,
  })
  .views((self) => ({
    get displayName() {
      if (self.name === IssuesFilter.Priority) {
        return ISSUES_PRIORITY_TEXTS[Number(self.value) as IssuePriority];
      } else if (self.name === IssuesFilter.Status) {
        return STATUS_TEXTS[self.value as IssueStatus];
      } else {
        return self.value;
      }
    },
  }));

export const IssueFilter = t.union(
  {
    dispatcher(snapshot) {
      switch (snapshot.name) {
        case IssuesFilter.Facility:
          return FacilityFilter;
        case IssuesFilter.Company:
          return CompanyFilter;
        case IssuesFilter.AssignedTo:
          return UserFilter;
        case IssuesFilter.IssueType:
          return IssueTypeFilter;
        default:
          return DefaultFilter;
      }
    },
  },
  FacilityFilter,
  CompanyFilter,
  UserFilter,
  IssueTypeFilter,
  DefaultFilter,
);

export type IssueFilterInstance = Instance<typeof IssueFilter>;
