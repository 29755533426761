import { applySnapshot, flow, types as t } from 'mobx-state-tree';
import { withRequest } from '../extensions';
import { AccountType } from '../types';
import { User, UserInstance, UserReference } from './User';

export const UserStore = t
  .model('UserStore', {
    users: t.maybeNull(t.array(User)),
    me: UserReference,
  })
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;

    return {
      fetchUsers: flow(function* () {
        try {
          const [listUsersRes, getMeRes] = yield Promise.all([
            request({
              method: 'GET',
              url: '/api/users/',
            }),
            request({
              method: 'GET',
              url: '/api/user/',
            }),
          ]);

          self.users = listUsersRes.data.results;
          self.me = getMeRes.data.id;
        } catch (error) {
          console.error('Failed to fetch users', error);
        }
      }),
      createUser: flow(function* (
        name: string,
        email: string,
        role: AccountType,
        phone_number: string,
        imageBlob: Blob | null,
      ) {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('role', role.toString());
        formData.append('phone_number', phone_number);
        if (imageBlob) {
          formData.append('image', imageBlob, 'profile_picture.png');
        }

        const { data: user } = yield request({
          method: 'POST',
          url: '/api/users/',
          data: formData,
        });
        if (user) {
          self.users!.push(user);
        }
      }),
      editUser: flow(function* (
        updatedUser: UserInstance,
        imageBlob: Blob | null,
      ) {
        const formData = new FormData();
        formData.append('name', updatedUser.name);
        formData.append('email', updatedUser.email);
        formData.append('role', updatedUser.role.toString());
        formData.append('phone_number', updatedUser.phone_number || '');

        if (imageBlob) {
          formData.append('image', imageBlob, 'profile_picture.png');
        } else {
          const currentUser = self.users?.find((u) => u.id === updatedUser.id);
          if (currentUser?.image && !updatedUser.image) {
            formData.append('image', '');
          }
        }

        formData.append('substitute', updatedUser.substitute?.toString() || '');

        if (updatedUser.meta_data) {
          formData.append('meta_data', JSON.stringify(updatedUser.meta_data));
        }

        try {
          yield request({
            method: 'PATCH',
            url: `/api/users/${updatedUser.id}/`,
            data: formData,
          });
        } catch (error) {
          // No need to handle this error since the general error
          // message will be shown to the user and they can try again.
        }
      }),
      deleteUser: flow(function* (userId: number) {
        try {
          yield request({
            method: 'DELETE',
            url: `/api/users/${userId}`,
          });

          const userIndex = self.users!.findIndex((user) => user.id === userId);
          self.users!.splice(userIndex, 1);
        } catch (error) {
          // No need to handle this error since the general error
          // message will be shown to the user and they can try again.
        }
      }),
      reset() {
        applySnapshot(self, {
          users: null,
          me: null,
        });
      },
    };
  });
