import { isToday, startOfToday } from 'date-fns';
import { applySnapshot, flow, types as t } from 'mobx-state-tree';
import { NOTIFICATIONS_PER_PAGE } from '../constants';
import { withRequest } from '../extensions';
import { Notification } from './Notification';

export const NotificationStore = t
  .model('NotificationStore', {
    newCount: t.maybeNull(t.number),
    todaysNotifications: t.maybeNull(t.array(Notification)),
    oldNotifications: t.maybeNull(t.array(Notification)),
    oldNotificationsCount: 0,
  })
  .extend(withRequest)
  .views((self) => ({
    get hasMoreOldNotications() {
      return self.oldNotifications
        ? self.oldNotifications.length < self.oldNotificationsCount
        : false;
    },
  }))
  .volatile(() => ({
    todaysDate: startOfToday(),
  }))
  .actions((self) => ({
    removeStaleOldNotifications() {
      // This is to handle the edge case where the app is running past midnight.
      // Todays notifications will continue to work as expected since they are
      // overwritten completely with the latest response.
      if (!isToday(self.todaysDate)) {
        self.oldNotifications = null;
        self.todaysDate = startOfToday();
      }
    },
  }))
  .actions((self) => {
    const { request } = self;

    return {
      fetchNewCount: flow(function* () {
        const res = yield request({
          method: 'GET',
          url: '/api/notifications/new_count/',
        });

        self.newCount = res.data.counter;
      }),
      resetNotifications: flow(function* () {
        const currentNewCount = self.newCount;

        if (currentNewCount === 0) return;

        try {
          self.newCount = 0;

          yield request({
            method: 'POST',
            url: '/api/notifications/reset/',
          });
        } catch (error) {
          self.newCount = currentNewCount;
        }
      }),
      fetchTodaysNotifications: flow(function* () {
        const res = yield request({
          method: 'GET',
          url: '/api/notifications/',
          params: {
            created_at_gte: startOfToday(),
          },
        });

        self.todaysNotifications = res.data.results;
      }),
      fetchOldNotifications: flow(function* () {
        self.removeStaleOldNotifications();

        const { data } = yield request({
          method: 'GET',
          url: '/api/notifications/',
          params: {
            created_at_lt: self.todaysDate,
            offset: self.oldNotifications?.length || 0,
            limit: NOTIFICATIONS_PER_PAGE,
          },
        });

        self.oldNotificationsCount = data.count;

        if (!self.oldNotifications) {
          self.oldNotifications = data.results;
          return;
        }

        self.oldNotifications.push(...data.results);
      }),
      reset() {
        applySnapshot(self, {
          newCount: null,
          todaysNotifications: null,
          oldNotifications: null,
          oldNotificationsCount: 0,
        });
      },
    };
  })
  .actions((self) => {
    return {
      fetchNotifications() {
        self.removeStaleOldNotifications();

        // If notifications already have been fetched we only want to fetch todays notifications
        if (!self.oldNotifications) {
          self.fetchOldNotifications();
        }

        self.fetchTodaysNotifications();
      },
    };
  });
