import { startOfDay } from 'date-fns';
import { getParent, Instance, types as t } from 'mobx-state-tree';
import {
  IsoDate,
  maybeNullReference,
  IntervalType,
  RoundingType,
} from '../types';
import { addInterval } from '../utils';
import { Company } from './Company';
import { AssignmentInstance } from './Facility';

const PERIODS_OF_TASKS_TO_ADD = 2;

export const RoundingStep = t
  .model('RoundingStep', {
    id: t.identifierNumber,
    name: t.string,
    setpoints: t.string,
    instruction: t.string,
    from_dt: IsoDate,
    to_dt: IsoDate,
    interval: t.number,
    interval_type: t.enumeration('IntervalType', Object.values(IntervalType)),
    company: maybeNullReference(Company),
    rounding_type: t.enumeration<keyof typeof RoundingType>(
      'RoundingType',
      Object.keys(RoundingType) as any,
    ),
    deleted_at: t.maybeNull(IsoDate),
  })
  .views((self) => {
    return {
      get facility() {
        return getParent<{
          name: string;
          assignments: AssignmentInstance[];
          id: number;
        }>(self, 2);
      },
    };
  })
  .views((self) => {
    return {
      get facilityName() {
        return self.facility?.name || '';
      },
      get companyName() {
        return self.company?.name || '';
      },
      get roundingTaskDates() {
        const dates: Date[] = [];
        const todaysYear = new Date().getFullYear();
        const isEndDateNextYear =
          self.from_dt.getFullYear() !== self.to_dt.getFullYear();

        const currentDate = new Date(self.from_dt);
        const toDate = new Date(self.to_dt);
        toDate.setFullYear(isEndDateNextYear ? todaysYear + 1 : todaysYear);

        let addedPeriods = 0;
        while (addedPeriods < PERIODS_OF_TASKS_TO_ADD) {
          dates.push(startOfDay(currentDate));
          addInterval(currentDate, self.interval_type, self.interval);

          if (currentDate >= toDate) {
            addedPeriods += 1;

            currentDate.setFullYear(self.from_dt.getFullYear() + addedPeriods);
            currentDate.setMonth(self.from_dt.getMonth());
            currentDate.setDate(self.from_dt.getDate());
            toDate.setFullYear(toDate.getFullYear() + addedPeriods);
          }
        }

        return dates;
      },
    };
  });

export type RoundingStepInstance = Instance<typeof RoundingStep>;
export const RoundingStepReference = maybeNullReference(RoundingStep);
