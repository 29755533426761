import React, { FC, memo, SVGProps } from 'react';

export const UserSelectIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    className="w-4"
    {...props}
  >
    <circle
      cx="15"
      cy="15"
      r="14.5"
      fill="#fff"
      stroke="#BBB"
      strokeDasharray="4 4"
    />
    <path
      d="M19.966 16.483a3.035 3.035 0 1 0 0 6.07 3.035 3.035 0 0 0 0-6.07Zm0 5.517a2.483 2.483 0 1 1 0-4.966 2.483 2.483 0 0 1 0 4.966Z"
      fill="#BBB"
    />
    <path
      d="M20.241 19.241v-1.655h-.551v1.655h-1.655v.552h1.655v1.655h.551v-1.655h1.656v-.552H20.24ZM10.862 20.069h-.552V22H7.552v-2.902a1.928 1.928 0 0 1 1.207-1.791l3.641-1.471c.051-.02.108-.035.162-.052.212 1.034 1.215 1.802 2.438 1.802s2.227-.77 2.438-1.804c.053.017.11.031.16.052l.885.355.206-.511-.883-.355a2.894 2.894 0 0 0-.406-.131.268.268 0 0 1-.193-.263V13.58a3.029 3.029 0 0 0 1.103-2.339V9.034A3.029 3.029 0 0 0 15.276 6h-.552a3.038 3.038 0 0 0-3.034 3.034v2.207a3.03 3.03 0 0 0 1.103 2.34v1.35a.267.267 0 0 1-.193.262 2.886 2.886 0 0 0-.407.132l-3.642 1.47A2.478 2.478 0 0 0 7 19.099v3.178a.276.276 0 0 0 .276.276h9.655V22h-6.069v-1.931Zm3.862-13.517h.552a2.48 2.48 0 0 1 2.483 2.482v.515c-.69-.171-1.547-.894-1.888-1.228a.288.288 0 0 0-.33-.09.276.276 0 0 0-.164.194 2.676 2.676 0 0 1-2.49 1.713h-.646V9.034a2.486 2.486 0 0 1 2.483-2.482Zm-2.483 4.69v-.552h.646a3.232 3.232 0 0 0 2.856-1.738c.429.376 1.256 1.02 2.016 1.158v1.131a2.486 2.486 0 0 1-2.483 2.483h-.552a2.486 2.486 0 0 1-2.483-2.483Zm2.483 3.034h.552c.48 0 .953-.115 1.38-.335v.988a.826.826 0 0 0 .262.61c-.093.833-.925 1.496-1.918 1.496-.993 0-1.823-.66-1.918-1.495a.829.829 0 0 0 .263-.61v-.989c.426.22.9.335 1.38.335Z"
      fill="#BBB"
    />
  </svg>
));
