import React from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
  Mention,
  MentionsInput,
  OnChangeHandlerFunc,
  SuggestionDataItem,
} from 'react-mentions';
import { useStore } from '../../../../hooks';
import { Avatar } from '../../../../components';
import './mentions.less';

type TextAreaProps = {
  value: string;
  onChange: OnChangeHandlerFunc;
};

const renderSuggestion = (suggestion: any) => {
  return (
    <div className="flex items-center justify-start space-x-2">
      <Avatar user={suggestion.user} />
      <p>{suggestion.display}</p>
    </div>
  );
};

export const TextArea = observer(({ value, onChange }: TextAreaProps) => {
  const { userStore } = useStore();

  const state = useLocalObservable(() => ({
    get mentionsData() {
      const { users } = userStore;
      if (users === null) return [];

      return users.map((u) => ({ id: u.id, display: u.name, user: u }));
    },
  }));

  return (
    <MentionsInput
      value={value}
      onChange={onChange}
      placeholder="Skriv en anteckning."
      className="mentions"
    >
      <Mention
        className="mentions__mention"
        trigger="@"
        data={state.mentionsData as SuggestionDataItem[]}
        renderSuggestion={renderSuggestion}
        appendSpaceOnAdd
      />
    </MentionsInput>
  );
});
