import React, { FC, memo, SVGProps } from 'react';

export const InfoIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    className="w-4"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10 18.75a8 8 0 100-16 8 8 0 000 16zm0 2c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm0-11a1 1 0 011 1v4a1 1 0 11-2 0v-4a1 1 0 011-1zm0-1.5a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
      clipRule="evenodd"
    ></path>
  </svg>
));
