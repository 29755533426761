import { Instance, types as t } from 'mobx-state-tree';
import { AccountType, numEnumeration, maybeNullReference } from '../types';

export const User = t
  .model('User', {
    id: t.identifierNumber,
    role: numEnumeration<AccountType>('Role', Object.values(AccountType)),
    name: t.string,
    email: t.string,
    image: t.maybeNull(t.string),
    phone_number: t.maybeNull(t.string),
    meta_data: t.maybeNull(t.map(t.string)),
    substitute: t.maybeNull(t.number),
  })
  .views((self) => ({
    get canAssignIssues() {
      return self.role >= AccountType.Admin;
    },
  }));

export type UserInstance = Instance<typeof User>;

export const UserReference = maybeNullReference(User);
