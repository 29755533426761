import React, { FC } from 'react';
import { FadeInDiv } from './FadeInDiv';

export const FormError: FC = ({ children }) => (
  <FadeInDiv className="relative">
    <div className="absolute w-full rounded-b-md text-xs text-pink-500">
      {children}
    </div>
  </FadeInDiv>
);
