export enum ClosedIssuesSortFilter {
  None = '',
  Number = 'number',
  ResolvedAt = 'resolved_at',
  IssueType = 'issue_type__name',
  Facility = 'facility__name',
  Company = 'company__name',
  Priority = 'priority',
  AssignedTo = 'assigned_to__name',
  TimeToClose = 'time_to_close',
}
