import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { CrossIcon3 } from '../icons';
import { useStore } from '../hooks';

interface SlideInMenuProps {
  icon?: JSX.Element;
  isMenuExpanded: boolean;
  className?: string;
  onClick: () => void;
  title: string;
}

export const SlideInMenu: FC<SlideInMenuProps> = observer(
  ({ icon, className, onClick, ...props }) => {
    const { uiStore } = useStore();
    return (
      <>
        <button onClick={onClick} className={className}>
          {icon}
        </button>
        {uiStore.isMobile ? (
          <MobileMenu {...props} onClick={onClick} />
        ) : (
          <DesktopMenu {...props} onClick={onClick} />
        )}
      </>
    );
  },
);
const MobileMenu: FC<SlideInMenuProps> = observer(
  ({ title, children, isMenuExpanded, onClick }) => {
    return (
      <div
        className={`absolute bottom-0 right-0 z-50 h-[32rem] w-full transform transition-transform
          ${isMenuExpanded ? '' : 'translate-y-full'}`}
      >
        <div className="scrollbar-none flex h-full flex-col overflow-y-auto rounded-t-2xl bg-white px-4 py-5 shadow-[0px_-1px_30px_rgba(0,0,0,0.3)]">
          <div className="mb-8 flex items-center justify-between">
            <span className="whitespace-nowrap text-3xl font-extrabold text-gray-900">
              {title}
            </span>
            <button onClick={onClick}>
              <CrossIcon3 className="w-5 text-gray-900" />
            </button>
          </div>
          {children}
        </div>
      </div>
    );
  },
);
const DesktopMenu: FC<SlideInMenuProps> = observer(
  ({ title, children, isMenuExpanded, onClick }) => {
    return (
      <div
        className={`absolute right-0 top-0 z-50 h-screen w-[33rem] transform overflow-x-visible transition-transform
          ${isMenuExpanded ? '' : 'translate-x-full'}`}
      >
        <div className="scrollbar-none flex h-full flex-col overflow-y-auto bg-white px-14 py-12 shadow-md">
          <div className="mb-12 flex items-center justify-between">
            <span className="whitespace-nowrap text-3xl font-extrabold text-gray-900">
              {title}
            </span>
            <button onClick={onClick}>
              <CrossIcon3 className="w-7 text-gray-300" />
            </button>
          </div>
          {children}
        </div>
      </div>
    );
  },
);
