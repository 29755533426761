import { flow, getParentOfType, Instance, types as t } from 'mobx-state-tree';
import { withRequest } from '../extensions';
import { IsoDate } from '../types';
import { Issue } from './Issue';
import { IssueNoteFile } from './IssueNoteFile';
import { UserReference } from './User';

export const IssueNote = t
  .model('IssueNote', {
    id: t.identifierNumber,
    author: UserReference,
    content: t.string,
    created_at: IsoDate,
    updated_at: t.maybeNull(IsoDate),
    is_external: t.boolean,
    files: t.array(IssueNoteFile),
  })
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;
    const issue = getParentOfType(self, Issue) as any;

    return {
      editContent: flow(function* (content: string) {
        const currentContent = self.content;
        const currentUpdatedAt = self.updated_at;
        try {
          self.content = content;
          self.updated_at = new Date();

          yield request({
            method: 'PATCH',
            url: `/api/issues/${issue.id}/notes/${self.id}/`,
            data: {
              content,
            },
          });
        } catch (error) {
          self.content = currentContent;
          self.updated_at = currentUpdatedAt;
        }
      }),
    };
  });

export type IssueNoteInstance = Instance<typeof IssueNote>;
