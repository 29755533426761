import { observer } from 'mobx-react-lite';
import React from 'react';
import { Menu, MenuButton, MenuPopover } from '@reach/menu-button';

type InfoBoxProps = {
  content: string;
};

export const InfoBox = observer(({ content }: InfoBoxProps) => {
  return (
    <Menu>
      {({ isExpanded }) => (
        <div className="relative flex items-center">
          <MenuButton className="focus:outline-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={20}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="stroke-current text-gray-300"
            >
              <circle cx={12} cy={12} r={10} />
              {isExpanded ? (
                <path d="M15 9l-6 6M9 9l6 6" stroke="#312E81" />
              ) : (
                <path d="M9.09 9a3 3 0 015.83 1c0 2-3 3-3 3M12 17h.01" />
              )}
            </svg>
          </MenuButton>
          {isExpanded && (
            <MenuPopover>
              <div className="absolute -top-8 right-1 z-50 flex text-sm font-semibold">
                <div className="w-40 rounded-md bg-white p-3 shadow-lg">
                  {content}
                </div>
                <div
                  className="relative top-3 h-0 w-0"
                  style={{
                    borderTop: '10px solid transparent',
                    borderBottom: '10px solid transparent',
                    borderLeft: '10px solid white',
                    filter: 'drop-shadow(1px 3px 1px rgba(0, 0, 0, 0.05))',
                  }}
                />
              </div>
            </MenuPopover>
          )}
        </div>
      )}
    </Menu>
  );
});
