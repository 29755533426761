import React, { FC } from 'react';
import { Menu, MenuButton, MenuItem, MenuPopover } from '@reach/menu-button';
import { Position, positionMatchWidth } from '@reach/popover';
import { ArticleInstance } from '../models';
import { Observer, observer, useLocalObservable } from 'mobx-react-lite';
import { DeleteIcon, ArticleSelectIcon } from '../icons';
import { useMouseDownOnResizeRef } from '../hooks';
import { localeCompareSv } from '../utils';

type ArticleMenuItemProps = {
  article: null | ArticleInstance;
  onSelect: (selected: null | ArticleInstance) => void;
  disabled?: boolean;
};

const ArticleMenuItem = observer(
  ({ article, onSelect, disabled = false }: ArticleMenuItemProps) => (
    <MenuItem
      disabled={disabled}
      className={`group flex w-full cursor-pointer flex-col items-baseline p-3 text-sm hover:bg-indigo-100 focus:bg-indigo-100 ${
        disabled ? 'text-gray-300' : ''
      }`}
      onSelect={() => onSelect(article)}
    >
      <div className="flex w-full items-center justify-between">
        <span>{article?.name}</span>
      </div>
    </MenuItem>
  ),
);

interface ArticleSelectProps {
  articles: ArticleInstance[];
  selected: null | ArticleInstance;
  onSelect: (selected: null | ArticleInstance) => void;
  onDelete?: () => void;
  renderButton: (selected: null | ArticleInstance) => JSX.Element;
  disabled?: boolean;
  position?: Position;
}

interface ArticleSelectState {
  searchText: string;
  setSearchText: (searchText: string) => void;
  sortedArticles: ArticleInstance[];
  filteredArticles: ArticleInstance[];
  handleSearchChange: (event: any) => void;
  reset: () => void;
  isMouseOver: boolean;
  setIsMouseOver: (isMouseOver: boolean) => void;
}

export const ArticleSelect: FC<ArticleSelectProps> = observer(
  ({
    articles,
    selected,
    onSelect,
    onDelete,
    renderButton,
    disabled = false,
    position = positionMatchWidth,
  }) => {
    const ref = useMouseDownOnResizeRef();
    const state = useLocalObservable<ArticleSelectState>(() => ({
      searchText: '',
      setSearchText(text) {
        state.searchText = text;
      },
      get sortedArticles(): ArticleInstance[] {
        return articles.slice().sort((a, b) => localeCompareSv(b.name, a.name));
      },
      get filteredArticles(): ArticleInstance[] {
        const searchText = state.searchText.toLowerCase();
        return state.sortedArticles.filter((article) =>
          article.name.toLowerCase().includes(searchText),
        );
      },
      handleSearchChange(event: any) {
        state.setSearchText(event.target.value);
      },
      reset() {
        state.setSearchText('');
      },
      isMouseOver: false,
      setIsMouseOver(isMouseOver) {
        state.isMouseOver = isMouseOver;
      },
    }));

    return (
      <div
        className="relative"
        onMouseOver={() => state.setIsMouseOver(true)}
        onMouseLeave={() => state.setIsMouseOver(false)}
      >
        {state.isMouseOver && onDelete && (
          <button
            className="absolute -right-1 -top-1 z-50 rounded-full"
            onClick={onDelete}
          >
            <DeleteIcon />
          </button>
        )}
        <Menu>
          {({ isExpanded }) => (
            <Observer>
              {() => (
                <div className="relative flex items-center justify-center">
                  <MenuButton
                    className={`rounded-full ${
                      disabled ? 'cursor-default' : ''
                    }`}
                    ref={isExpanded ? ref : undefined}
                    disabled={disabled}
                  >
                    {renderButton(selected)}
                  </MenuButton>
                  {isExpanded && (
                    <MenuPopover
                      position={position}
                      className="absolute z-50 max-h-96 w-52 min-w-48 overflow-y-auto rounded-md border border-gray-200 bg-white shadow-lg"
                    >
                      <div className="flex items-center justify-between p-3 font-bold">
                        <input
                          onBlur={state.reset}
                          value={state.searchText}
                          onChange={state.handleSearchChange}
                          placeholder="Sök"
                          type="text"
                          className="w-3/4 focus:outline-none"
                        />
                        <ArticleSelectIcon className="h-8 w-8" />
                      </div>
                      <div className="reach-menu-items focus:outline-none">
                        {state.filteredArticles.length === 0 && (
                          <div className="p-3">Inga artiklar hittades</div>
                        )}
                        {state.filteredArticles.map((article) => {
                          return (
                            <ArticleMenuItem
                              key={article.id}
                              article={article}
                              onSelect={onSelect}
                            />
                          );
                        })}
                      </div>
                    </MenuPopover>
                  )}
                </div>
              )}
            </Observer>
          )}
        </Menu>
      </div>
    );
  },
);
