import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { ChangeEvent, FC, FormEvent, useEffect, useRef } from 'react';
import { useStore } from '../hooks';
import { Checkbox, FormError, Input, Select } from './create-issue';
import { Label } from './Label';
import {
  DustingOffHandsIcon,
  ImageIcon,
  ApplicationIcon,
  CrossIcon,
  CrossIcon3,
} from '../icons';
import { isImage } from '../utils';
import { useNavigate } from 'react-router-dom';

enum IssueType {
  None = '',
  ErrorReporting = 'Felanmälan',
  KeysAndTags = 'Nycklar och taggar',
  ParkingPermit = 'Beställ P-tillstånd',
}

interface IdNameObj {
  id: number;
  name: string;
}

interface InitialValues {
  company: string;
  facility: string;
  issueType: string;
}

interface CreateIssueState {
  issueType: IssueType;
  setIssueType: (issueType: IssueType) => void;
  handleSubmit: (event: FormEvent) => void;
  submitState: SubmitState;
  setSubmitState: (submitState: SubmitState) => void;
  keysChecked: boolean;
  handleKeysChange: (keysChecked: boolean) => void;
  tagsChecked: boolean;
  handleTagsChange: (tagsChecked: boolean) => void;
  keysAndTagsError: string;
  setKeysAndTagsError: (error: string) => void;
  filesError: string;
  setFilesError: (error: string) => void;
  handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
  filesToUpload: File[];
  setFilesToUpload: (filesToUpload: File[]) => void;
  createdIssueId: number | null;
  setCreatedIssueId: (id: number) => void;
  redirectToNewIssue: () => void;
  handleClose: () => void;
  reset: () => void;
  initialValues: InitialValues | null;
  setInitialValues: (initialValues: InitialValues | null) => void;
  formKey: number;
  setFormKey: (key: number) => void;
}

enum SubmitState {
  None = 'none',
  Pending = 'pending',
  Error = 'error',
  Success = 'success',
}

export const CreateIssue = observer(() => {
  const { organizationStore, issueStore, uiStore, roundingStore } = useStore();
  const navigate = useNavigate();

  const companies = organizationStore.organization?.companies as IdNameObj[];
  const issueTypes = organizationStore.organization?.issue_types as IdNameObj[];
  const facilities = organizationStore.organization?.facilities as IdNameObj[];
  const errorTypeChildren = organizationStore.organization?.issue_types.find(
    (i) => i.name === IssueType.ErrorReporting,
  )?.children as IdNameObj[];

  const fileInputRef = useRef<HTMLInputElement>(null);

  const state = useLocalObservable<CreateIssueState>(() => ({
    issueType: IssueType.None,
    setIssueType(issueType: IssueType) {
      state.issueType = issueType;
    },
    submitState: SubmitState.None,
    setSubmitState(submitState) {
      state.submitState = submitState;
    },
    createdIssueId: null,
    setCreatedIssueId(id) {
      state.createdIssueId = id;
    },
    redirectToNewIssue() {
      navigate(`/issues/${state.createdIssueId}`);
      uiStore.toggleIsCreateIssueOpen();
      state.reset();
    },
    handleClose() {
      state.setSubmitState(SubmitState.None);
      uiStore.toggleIsCreateIssueOpen();
      state.setFilesToUpload([]);
      roundingStore.resetRoundingIssue();
    },
    reset() {
      state.setSubmitState(SubmitState.None);
      state.setIssueType(IssueType.None);
      state.setFilesToUpload([]);
      state.keysChecked = false;
      state.tagsChecked = false;
      roundingStore.resetRoundingIssue();
    },
    async handleSubmit(event) {
      event.preventDefault();

      if (
        state.issueType === IssueType.KeysAndTags &&
        !state.keysChecked &&
        !state.tagsChecked
      ) {
        state.setKeysAndTagsError('Var vänlig ange vad du behöver beställa');
        return;
      }

      try {
        state.setSubmitState(SubmitState.Pending);

        const {
          facility,
          company,
          issueType,
          issue_type_child,
          description,
          email,
        } = event.target as any;

        const newIssue = {
          issue_type: issueTypes.find((i) => i.name === issueType?.value)?.id,
          issue: description.value,
          company: companies.find((c) => c.name === company?.value)?.id,
          reporter_email: email.value,
        } as any;

        if (facility) {
          newIssue.facility = facilities.find(
            (f) => f.name === facility.value,
          )?.id;
        }

        if (issueType.value === IssueType.ErrorReporting) {
          newIssue.issue_type = errorTypeChildren.find(
            (i) => i.name === issue_type_child.value,
          )?.id;
        }

        if (issueType.value === IssueType.KeysAndTags) {
          if (!newIssue.extra_data) newIssue.extra_data = {};

          newIssue.extra_data.nycklar = state.keysChecked;
          newIssue.extra_data.taggar = state.tagsChecked;
        }

        if (state.filesToUpload.length > 0) {
          newIssue.files = state.filesToUpload;
        }

        let roundingTask;
        if (roundingStore.roundingIssue) {
          roundingTask = roundingStore.roundingTasks?.find(
            (x) => x.id === roundingStore.roundingIssue!.roundingTaskId,
          );
          newIssue.rounding_task = roundingTask?.id;
        }

        const issue = await issueStore.createIssue(newIssue);

        if (roundingTask) {
          roundingTask.addIssue(issue);
        }

        state.setCreatedIssueId(issue.id);
        state.setSubmitState(SubmitState.Success);
        state.setFilesToUpload([]);
        state.setKeysAndTagsError('');
        state.setFilesError('');
      } catch (error) {
        state.setSubmitState(SubmitState.Error);
      }
    },
    keysChecked: false,
    handleKeysChange(keysChecked) {
      state.keysChecked = keysChecked;
    },
    tagsChecked: false,
    handleTagsChange(tagsChecked) {
      state.tagsChecked = tagsChecked;
    },
    keysAndTagsError: '',
    setKeysAndTagsError(error) {
      state.keysAndTagsError = error;
    },
    filesError: '',
    setFilesError(error) {
      state.filesError = error;
    },
    filesToUpload: [],
    setFilesToUpload(filesToUpload) {
      state.filesToUpload = filesToUpload;
    },
    handleFileChange(e) {
      const maxUploads = 4;
      const files = e.target.files!;
      const filesArray: File[] = [];
      state.setFilesError('');

      Array.from(files).forEach((file) => {
        if (!isImage(file)) {
          state.setFilesError('Endast bilder går att bifoga');
        }
      });

      if (state.filesToUpload.length + files.length > maxUploads) {
        state.setFilesError(`Max ${maxUploads} bilder`);
      }

      if (!state.filesError) {
        Array.from(files).forEach((file) => {
          filesArray.push(file);
        });
        state.setFilesToUpload([...state.filesToUpload, ...filesArray]);
      }
      fileInputRef.current!.value = '';
    },
    initialValues: null,
    setInitialValues(initialValues) {
      state.initialValues = initialValues;
    },
    formKey: 1,
    setFormKey(key) {
      state.formKey = key;
    },
  }));

  useEffect(() => {
    const { roundingIssue } = roundingStore;

    if (roundingIssue === null) {
      state.setInitialValues(null);
      return;
    }
    // If a RoundingTask triggers the opening of the CreateIssue component
    // we need to populate the form with data from that RoundingTask.
    state.setInitialValues({
      company: roundingIssue.companyName,
      facility: roundingIssue.facilityName,
      issueType: IssueType.ErrorReporting,
    });
    state.setIssueType(IssueType.ErrorReporting);
    // When the initial values changes it will not rerender the select components,
    // so we have to give the form a new key to make it rerender.
    state.setFormKey(state.formKey + 1);
  }, [roundingStore.roundingIssue]);

  if (state.submitState === SubmitState.Success) {
    return (
      <CreateIssueSuccess
        createNewIssue={state.reset}
        showIssue={state.redirectToNewIssue}
        onClose={state.handleClose}
      />
    );
  }

  return (
    <div className="scrollbar-none flex h-full flex-col overflow-y-auto bg-white px-4 py-4 shadow-md md:px-14 md:py-12">
      <div className="mb-10 flex items-center justify-end md:w-96 md:justify-between">
        <span className="hidden whitespace-nowrap text-3xl font-extrabold text-indigo-900 md:block">
          Anmäl ett ärende
        </span>
        <button onClick={state.handleClose}>
          <CrossIcon3 className="w-7 text-gray-300" />
        </button>
      </div>
      <div className="mb-7 block md:hidden">
        <span className="whitespace-nowrap text-3xl font-extrabold text-indigo-900">
          Anmäl ett ärende
        </span>
      </div>
      <form
        key={state.formKey}
        onSubmit={state.handleSubmit}
        className="flex flex-grow flex-col justify-between"
      >
        <div>
          <div className="mb-7">
            <Select
              name="company"
              label="Företag/organisation"
              placeholder="Ange ett företag"
              noMatchText="Vi hittade inget matchande företag"
              required
              items={companies}
              initialValue={state.initialValues?.company}
            />
          </div>

          <div className="mb-7">
            <Select
              required
              name="issueType"
              label="Typ av ärende"
              placeholder="Välj ärende"
              items={issueTypes}
              onChange={(e) => state.setIssueType(e.name as IssueType)}
              initialValue={state.initialValues?.issueType}
            />
          </div>
          {state.issueType === IssueType.ErrorReporting && (
            <>
              <div className="mb-7">
                <Select
                  name="facility"
                  label="Fastighet"
                  placeholder="Ange fastighet"
                  noMatchText="Vi hittade ingen matchande fastighet"
                  required
                  items={facilities}
                  initialValue={state.initialValues?.facility}
                  combobox
                />
              </div>
              <div className="mb-7">
                <Select
                  name="issue_type_child"
                  label="Vad gäller ärendet?"
                  required
                  placeholder="Ange ett alternativ"
                  items={errorTypeChildren}
                />
              </div>
            </>
          )}

          {state.issueType === IssueType.KeysAndTags && (
            <div className="mb-7 outline-none">
              <Label required>Vad behöver du beställa?</Label>
              <div className="mt-2">
                <div className="inline-flex items-center">
                  <Checkbox
                    checked={state.keysChecked}
                    onChange={state.handleKeysChange}
                    name="keysTags"
                  />
                  <span className="ml-3 text-sm">Nycklar</span>
                </div>
                <div className="ml-7 inline-flex items-center">
                  <Checkbox
                    checked={state.tagsChecked}
                    onChange={state.handleTagsChange}
                    name="keysTags"
                  />
                  <span className="ml-3 text-sm">Taggar</span>
                </div>
              </div>
              {state.keysAndTagsError && (
                <FormError error={state.keysAndTagsError} />
              )}
            </div>
          )}
          <div className="mb-7">
            <Input
              title="Ange i formatet namn@example.se"
              label="E-postadress kontaktperson"
              name="email"
              type="email"
              placeholder="namn.namnsson@e-post.se"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            />
          </div>
          <div className="mb-7">
            <Label required>Ärendebeskrivning</Label>
            <textarea
              id="description"
              name="description"
              className="block w-full resize-none rounded-md border border-solid border-gray-200 p-3 text-sm focus:border-blue-500 focus:outline-none"
              placeholder="Ange en ärendebeskrivning"
              rows={6}
              required
            ></textarea>
          </div>
          <div className="flex flex-col items-baseline">
            {state.issueType === IssueType.ErrorReporting && (
              <>
                <input
                  type="file"
                  id="createIssueAttachments"
                  name="createIssueAttachments"
                  accept="image/png, image/jpeg"
                  className="hidden"
                  onChange={state.handleFileChange}
                  ref={fileInputRef}
                  multiple
                />
                <div className="-mt-3 mb-7 flex flex-wrap md:w-96">
                  <label
                    htmlFor="createIssueAttachments"
                    className="mr-3 mt-3 cursor-pointer rounded-md border border-solid border-gray-200 bg-gray-50 p-3 text-sm font-semibold text-gray-900 transition hover:bg-indigo-600 hover:text-white focus:border-blue-500 focus:outline-none"
                  >
                    Bifoga bilder
                  </label>
                  {state.filesToUpload.length > 0 &&
                    state.filesToUpload.map((file, index) => (
                      <div
                        key={index}
                        className="mr-3 mt-3 flex items-center rounded-md border border-solid border-gray-200 p-3 text-sm font-semibold text-gray-900"
                      >
                        {file.type === 'application/pdf' ? (
                          <ApplicationIcon className="mr-3 h-4 w-4 text-gray-300" />
                        ) : (
                          <ImageIcon className="mr-3 w-4 text-gray-300" />
                        )}
                        {file.name}
                        <span
                          className="ml-6 block h-5 w-5 cursor-pointer text-gray-500 hover:text-blue-500"
                          onClick={() => {
                            const filesToUploadClone = [...state.filesToUpload];
                            filesToUploadClone.splice(index, 1);

                            state.setFilesToUpload(filesToUploadClone);
                          }}
                        >
                          <CrossIcon className="w-5" />
                        </span>
                      </div>
                    ))}
                  {state.filesError && (
                    <div className="w-full">
                      <FormError error={state.filesError} />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <button
          className="h-12 w-full rounded-md bg-indigo-600 text-white transition hover:bg-indigo-900"
          type="submit"
          disabled={state.submitState === SubmitState.Pending}
        >
          Skicka
        </button>
      </form>
    </div>
  );
});

interface CreateIssueSuccessProps {
  showIssue: () => void;
  createNewIssue: () => void;
  onClose: () => void;
}

const CreateIssueSuccess: FC<CreateIssueSuccessProps> = ({
  showIssue,
  createNewIssue,
  onClose,
}) => {
  return (
    <div className="flex h-full flex-col bg-white px-5 py-5 shadow-md md:px-14 md:py-12">
      <div className="mb-7 flex items-center justify-end">
        <button onClick={onClose}>
          <CrossIcon3 className="w-7 text-gray-300" />
        </button>
      </div>
      <div className="mb-32 flex flex-grow justify-center md:w-96">
        <div className="flex flex-col items-center justify-center">
          <DustingOffHandsIcon className="mb-7 w-10 text-indigo-900" />
          <span className="text-2xl font-extrabold text-indigo-900">
            Ditt ärende
          </span>
          <span className="text-2xl font-extrabold text-indigo-900">
            är registrerat!
          </span>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <button
          className="mr-3 h-12 w-full whitespace-nowrap rounded-md border border-indigo-600 text-indigo-600 transition hover:border-indigo-900 hover:text-indigo-900"
          onClick={showIssue}
        >
          Visa ärendet
        </button>
        <button
          className="h-12 w-full whitespace-nowrap rounded-md border border-indigo-600 bg-indigo-600 text-white transition hover:bg-indigo-900"
          onClick={createNewIssue}
        >
          Nytt ärende
        </button>
      </div>
    </div>
  );
};
