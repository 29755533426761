import React, { ReactNode } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@reach/accordion';

type IssueAccordionProps = {
  items: { key: string; title: string; content: ReactNode }[];
};
type IssueAccordionState = {
  indices: number[];
  toggleItem: (toggledIndex: number) => void;
};

export const IssueAccordion = observer(({ items }: IssueAccordionProps) => {
  const state = useLocalObservable<IssueAccordionState>(() => ({
    // We want the first item in every accordion to be open by default.
    indices: [0],
    toggleItem(toggledIndex) {
      const index = state.indices.indexOf(toggledIndex);

      if (index !== -1) {
        state.indices.splice(index, 1);
      } else {
        state.indices.push(toggledIndex);
      }
    },
  }));
  const { indices, toggleItem } = state;

  return (
    <Accordion
      className="rounded-md bg-white"
      index={indices}
      onChange={toggleItem}
    >
      {items.map((item, index, arr) => {
        const hasButtonBorder = index !== arr.length - 1;

        return (
          <AccordionItem key={item.key}>
            <AccordionButton
              className={`flex w-full items-center justify-between border-solid border-indigo-100 px-7 py-5  text-left font-medium ${
                hasButtonBorder ? 'border-b' : ''
              }`}
            >
              <span className="break-all font-semibold">{item.title}</span>
              <svg
                className={`flex-shrink-0 transform transition-transform ${
                  indices.includes(index) ? 'rotate-180' : ''
                }`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="black"
                width="24px"
                height="24px"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
              </svg>
            </AccordionButton>
            <AccordionPanel
              className={`border-solid border-indigo-100 focus:outline-none ${
                !hasButtonBorder ? 'border-t' : ''
              }`}
            >
              {item.content}
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
});
