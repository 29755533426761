import { flow, types as t } from 'mobx-state-tree';
import debounce from 'lodash.debounce';
import { withRequest } from '../extensions';
import { Parking } from './Parking';
import { ParkingFilter } from '../types';
import { PARKING_PER_PAGE, REQUEST_DEBOUNCE_WAIT } from '../constants';

const ParkingArray = t.array(Parking);

export const ParkingStore = t
  .model('ParkingStore', {
    parking: t.maybeNull(ParkingArray),
    num_parking: t.maybeNull(t.number),
    parkingSearch: '',
  })
  .views((self) => ({
    get parkingQuery(): URLSearchParams {
      return new URLSearchParams(self.parkingSearch);
    },
  }))
  .views((self) => ({
    get numParkingPages(): null | number {
      const { num_parking } = self;

      if (num_parking === null) return null;

      return Math.ceil(num_parking / PARKING_PER_PAGE);
    },
    get page(): number {
      const pageParam = self.parkingQuery.get('page');

      return pageParam ? Number(pageParam) : 1;
    },
    get filter(): ParkingFilter {
      const sortParam = self.parkingQuery.get('sort');

      if (!sortParam) return '' as ParkingFilter;

      return sortParam.replace('-', '') as ParkingFilter;
    },
    get isAscending(): boolean {
      const sortParam = self.parkingQuery.get('sort');

      return sortParam !== null && sortParam[0] !== '-';
    },
  }))
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;

    return {
      fetchParking: flow(function* () {
        const promise = request({
          method: 'GET',
          url: '/api/parkings/',
          params: {
            limit: PARKING_PER_PAGE,
            offset: (self.page - 1) * PARKING_PER_PAGE,
            ordering: self.parkingQuery.get('sort'),
            search: self.parkingQuery.get('search'),
          },
        });

        const { data } = yield promise;

        const parkings = data.results;

        if (self.parking === null) {
          self.parking = ParkingArray.create();
        }

        self.parking.replace(parkings);
        self.num_parking = data.count;
      }),
    };
  })
  .actions((self) => {
    const debouncedFetchParking = debounce(
      self.fetchParking,
      REQUEST_DEBOUNCE_WAIT,
    );

    return {
      handleParkingSearchChange(search: string) {
        self.parkingSearch = search;
        // We debounce parking requests that result from the user
        // navigating as to not overwhelm the network when navigating
        // back and forth quickly.
        debouncedFetchParking();
      },
    };
  });
