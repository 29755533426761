import React, { FC, memo, SVGProps } from 'react';

export const CarIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    viewBox="0 0 24 24"
    fill="currentColor"
    className="w-4"
    {...props}
  >
    <path d="M3 18v-5a2 2 0 012-2V8a4 4 0 014-4h6a4 4 0 014 4v3a2 2 0 012 2v5a2 2 0 11-4 0H7a2 2 0 11-4 0zM9 6a2 2 0 00-2 2v3h10V8a2 2 0 00-2-2H9zm-3 9a1 1 0 100-2 1 1 0 000 2zm12 0a1 1 0 100-2 1 1 0 000 2z" />
  </svg>
));
