const colors = [
  '#FBCD13',
  '#EC4899',
  '#5046E5',
  '#000000',
  '#13B5FB',
  '#A313FB',
  '#CDEFFE',
  '#FB1313',
  '#E813FB',
  '#302E81',
  '#AD3793',
  '#6F6F77',
  '#71AD25',
  '#4461C6',
  '#873495',
  '#B59823',
  '#67CAA6',
  '#635930',
  '#175454',
  '#4A9A7D',
];
export function nameToStrongColor(name: string) {
  let num = 0;

  for (let i = 0; i < name.length; i++) {
    num += name.charCodeAt(i);
  }
  return colors[num % colors.length];
}
