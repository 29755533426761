import React, { FC, memo, SVGProps } from 'react';

export const FacilityIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    viewBox="0 0 22 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="w-4"
    {...props}
  >
    <g clipPath="url(#clip0_1900_13277)">
      <path
        d="M9.72748 8.96533H8.92748C8.64748 8.96533 8.41748 9.19533 8.41748 9.47533C8.41748 9.75533 8.64748 9.98533 8.92748 9.98533H9.72748C10.0075 9.98533 10.2375 9.75533 10.2375 9.47533C10.2375 9.19533 10.0075 8.96533 9.72748 8.96533Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M13.2275 8.96533H12.4275C12.1475 8.96533 11.9175 9.19533 11.9175 9.47533C11.9175 9.75533 12.1475 9.98533 12.4275 9.98533H13.2275C13.5075 9.98533 13.7375 9.75533 13.7375 9.47533C13.7375 9.19533 13.5075 8.96533 13.2275 8.96533Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M9.72748 5.47534H8.92748C8.64748 5.47534 8.41748 5.70534 8.41748 5.98534C8.41748 6.26534 8.64748 6.49534 8.92748 6.49534H9.72748C10.0075 6.49534 10.2375 6.26534 10.2375 5.98534C10.2375 5.70534 10.0075 5.47534 9.72748 5.47534Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M13.2275 5.47534H12.4275C12.1475 5.47534 11.9175 5.70534 11.9175 5.98534C11.9175 6.26534 12.1475 6.49534 12.4275 6.49534H13.2275C13.5075 6.49534 13.7375 6.26534 13.7375 5.98534C13.7375 5.70534 13.5075 5.47534 13.2275 5.47534Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M9.72748 12.4653H8.92748C8.64748 12.4653 8.41748 12.6953 8.41748 12.9753C8.41748 13.2553 8.64748 13.4853 8.92748 13.4853H9.72748C10.0075 13.4853 10.2375 13.2553 10.2375 12.9753C10.2375 12.6953 10.0075 12.4653 9.72748 12.4653Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M13.2275 12.4653H12.4275C12.1475 12.4653 11.9175 12.6953 11.9175 12.9753C11.9175 13.2553 12.1475 13.4853 12.4275 13.4853H13.2275C13.5075 13.4853 13.7375 13.2553 13.7375 12.9753C13.7375 12.6953 13.5075 12.4653 13.2275 12.4653Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M20.9872 17.4754V5.57535C20.9872 5.02535 20.7672 4.50535 20.3872 4.12535C19.9972 3.73535 19.4772 3.52535 18.9372 3.52535H16.5172V3.03535C16.5172 2.48535 16.2972 1.96535 15.9172 1.58535C15.5272 1.19535 15.0072 0.985352 14.4672 0.985352H7.69721C7.14721 0.985352 6.62721 1.20535 6.24721 1.58535C5.85721 1.97535 5.64721 2.49535 5.64721 3.03535V9.25535H2.43721C1.88721 9.25535 1.36721 9.47535 0.987207 9.85535C0.597207 10.2454 0.387207 10.7654 0.387207 11.3054V17.4754C0.387207 17.6154 0.437207 17.7454 0.537207 17.8354C0.637207 17.9354 0.767207 17.9854 0.897207 17.9854H20.4672C20.6072 17.9854 20.7372 17.9354 20.8272 17.8354C20.9272 17.7354 20.9772 17.6054 20.9772 17.4754H20.9872ZM4.87721 16.9654H1.40721V11.3154C1.40721 11.0454 1.51721 10.7854 1.70721 10.5854C1.89721 10.3954 2.15721 10.2854 2.43721 10.2854H5.72721C5.99721 10.2854 6.25721 10.3954 6.45721 10.5854C6.64721 10.7754 6.75721 11.0354 6.75721 11.3154V16.9654H4.87721ZM10.9172 16.9654H7.78721V11.3154C7.78721 10.9354 7.67721 10.5754 7.48721 10.2554C7.29721 9.93535 7.01721 9.67535 6.68721 9.49535V3.03535C6.68721 2.76535 6.79721 2.50535 6.98721 2.30535C7.17721 2.11535 7.43721 2.00535 7.71721 2.00535H14.4772C14.7472 2.00535 15.0072 2.11535 15.2072 2.30535C15.3972 2.49535 15.5072 2.75535 15.5072 3.03535V16.9654H10.9372H10.9172ZM19.9572 16.9654H16.5172V4.54535H18.9372C19.2072 4.54535 19.4672 4.65535 19.6672 4.84535C19.8572 5.03535 19.9672 5.29535 19.9672 5.57535V16.9554L19.9572 16.9654Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_1900_13277">
        <rect
          width="21.11"
          height="17.51"
          fill="white"
          transform="translate(0.127441 0.725342)"
        />
      </clipPath>
    </defs>
  </svg>
));
