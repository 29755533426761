export function nameToInitials(name: string) {
  let initials = name.substring(0, 1);

  const names = name.split(' ');
  if (names.length > 1) {
    initials =
      names[0].substring(0, 1) + names[names.length - 1].substring(0, 1);
  }

  return initials;
}
