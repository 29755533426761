import React from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { ChevronDownIcon } from '../../../icons';
import { IssueFileInstance } from '../../../models/IssueFile';

export const ImageSlider = observer(
  ({ slides }: { slides: IssueFileInstance[] }) => {
    const { length } = slides;

    const state = useLocalObservable(() => ({
      current: 0,
      setCurrent(current: number) {
        state.current = current;
      },
      setNextSlide() {
        state.setCurrent(state.current === length - 1 ? 0 : state.current + 1);
      },

      setPrevSlide() {
        state.setCurrent(state.current === 0 ? length - 1 : state.current - 1);
      },

      setDotSlide(current: number) {
        state.setCurrent(current);
      },
    }));

    return (
      <div className="relative grid h-96 w-full justify-items-center 2xl:h-[37rem]">
        {slides.map((image, index) => {
          return (
            <div key={image.id} className="w-full">
              {index === state.current && (
                <div
                  style={{ backgroundImage: `url(${image.file})` }}
                  className="h-96 w-full rounded-md bg-cover bg-center 2xl:h-[37rem]"
                ></div>
              )}
            </div>
          );
        })}
        {slides.length !== 1 && (
          <>
            <button
              className="absolute left-0 top-1/2 ml-4 rounded-full bg-white p-2 transition duration-200 hover:bg-pink-500"
              onClick={state.setPrevSlide}
            >
              <ChevronDownIcon className="w-5 rotate-90" />
            </button>
            <button
              className="absolute right-0 top-1/2 mr-4 rounded-full bg-white p-2 transition duration-200 hover:bg-pink-500"
              onClick={state.setNextSlide}
            >
              <ChevronDownIcon className="w-5 -rotate-90" />
            </button>
            <div className="absolute bottom-0 pb-4">
              {slides.map((image, index) => {
                return (
                  <button
                    key={image.id}
                    className={`${
                      index === state.current ? 'bg-pink-500' : 'bg-white'
                    } mx-1 rounded-full p-2 transition duration-200 hover:bg-pink-500`}
                    onClick={() => state.setDotSlide(index)}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
    );
  },
);
