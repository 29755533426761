import React, { FC, memo, SVGProps } from 'react';

export const AddUserIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16.42 13.75"
    fill="currentColor"
    className="w-4"
    {...props}
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="user-plus">
          <g id="Path_28" data-name="Path 28">
            <path d="M10.88,13.75a.87.87,0,0,1-.88-.87V11.54A1.79,1.79,0,0,0,8.21,9.75H3.54a1.79,1.79,0,0,0-1.79,1.79v1.34a.88.88,0,0,1-1.75,0V11.54A3.55,3.55,0,0,1,3.54,8H8.21a3.55,3.55,0,0,1,3.54,3.54v1.34A.87.87,0,0,1,10.88,13.75Z" />
          </g>
          <g id="Ellipse_3" data-name="Ellipse 3">
            <path d="M5.88,7.08A3.54,3.54,0,1,1,9.42,3.54,3.54,3.54,0,0,1,5.88,7.08Zm0-5.33A1.79,1.79,0,1,0,7.67,3.54,1.8,1.8,0,0,0,5.88,1.75Z" />
          </g>
          <g id="Line_10" data-name="Line 10">
            <path d="M13.54,9.08a.87.87,0,0,1-.87-.87v-4a.88.88,0,1,1,1.75,0v4A.87.87,0,0,1,13.54,9.08Z" />
          </g>
          <g id="Line_11" data-name="Line 11">
            <path d="M15.54,7.08h-4a.88.88,0,0,1,0-1.75h4a.88.88,0,1,1,0,1.75Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
));
