import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC, FormEvent } from 'react';
import { ArticleSelect, Avatar, UserSelect } from '../../../components';
import { ArticleTag } from '../../../components/ArticleTag';
import { useStore } from '../../../hooks';
import { ArticleInstance, IssueInstance, UserInstance } from '../../../models';
import { positionRight } from '@reach/popover';
import { IssueStatus } from '../../../types';
import {
  DeleteIcon,
  ArticleSelectIcon,
  UserSelectIcon,
  PlusIcon,
} from '../../../icons';

interface TimeReportProps {
  issue: IssueInstance;
}

export const TimeReport: FC<TimeReportProps> = observer(({ issue }) => {
  return (
    <div className="relative flex-col pt-5">
      <table className="mt-5 w-full">
        {issue.status !== IssueStatus.Done ? (
          <CreateTimeReportRow issue={issue} />
        ) : (
          <thead>
            <tr className="pb-2">
              <td className="pb-5 pl-7">
                <div className="relative flex flex-col">
                  <span className="mb-2 w-12 whitespace-nowrap text-xxs xs:w-16 xs:text-xs">
                    Arbetad tid
                  </span>
                </div>
              </td>
              <td className="pb-5 pl-7">
                <div className="relative flex flex-col">
                  <span className="mb-2 w-12 whitespace-nowrap text-xxs xs:w-16 xs:text-xs">
                    Varav debiterbart
                  </span>
                </div>
              </td>
            </tr>
          </thead>
        )}
        <TimeReportRows issue={issue} />
      </table>
    </div>
  );
});

interface CreateTimeReportRowProps {
  issue: IssueInstance;
}

interface CreateTimeReportRowState {
  workedHours: string;
  setWorkedHours: (hours: string) => void;
  chargeableHours: string;
  setChargeableHours: (chargebleHours: string) => void;
  isChargeableHoursChanged: boolean;
  selectedUser: UserInstance | null;
  setSelectedUser: (user: UserInstance | null) => void;
  selectedArticles: ArticleInstance[];
  setSelectedArticles: (articles: ArticleInstance[]) => void;
  addArticle: (article: ArticleInstance) => void;
  removeArticle: (articleId: number) => void;
  handleBlur: () => void;
  handleChargeableHoursChanged: (event: FormEvent<HTMLInputElement>) => void;
  handleWorkedHoursChanged: (event: FormEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
  filteredUsers: UserInstance[];
  reset: () => void;
}

const CreateTimeReportRow: FC<CreateTimeReportRowProps> = observer(
  ({ issue }: CreateTimeReportRowProps) => {
    const {
      userStore: { users },
      articleStore: { articles },
    } = useStore();
    const state = useLocalObservable<CreateTimeReportRowState>(() => ({
      workedHours: '',
      chargeableHours: '',
      isChargeableHoursChanged: false,
      selectedUser: null,
      setWorkedHours(hours) {
        state.workedHours = hours;
      },
      setChargeableHours(chargeableHours) {
        state.chargeableHours = chargeableHours;
      },
      setSelectedUser(user) {
        state.selectedUser = user;
      },
      selectedArticles: [],
      setSelectedArticles(articles) {
        state.selectedArticles = articles;
      },
      addArticle(article) {
        state.selectedArticles.push(article);
      },
      removeArticle(articleId) {
        const index = state.selectedArticles.findIndex(
          (article) => article.id === articleId,
        );
        state.selectedArticles.splice(index, 1);
      },
      handleWorkedHoursChanged(event: FormEvent<HTMLInputElement>) {
        const { value } = event.target as any;

        const hours = value < 0 ? '0' : value;

        if (!state.isChargeableHoursChanged) {
          state.workedHours = hours;
          state.chargeableHours = hours;
        } else {
          state.workedHours = hours;
        }
      },
      handleChargeableHoursChanged(event: FormEvent<HTMLInputElement>) {
        const { value } = event.target as any;

        if (!state.isChargeableHoursChanged) {
          state.isChargeableHoursChanged = true;
        }
        state.chargeableHours = value < 0 ? '0' : value;
      },
      handleBlur() {
        if (!state.chargeableHours || !state.workedHours) {
          if (state.chargeableHours > state.workedHours) {
            state.chargeableHours = state.workedHours;
          }
          return;
        }

        if (parseFloat(state.chargeableHours) > parseFloat(state.workedHours)) {
          state.chargeableHours = state.workedHours;
        }

        state.workedHours = (
          Math.round(parseFloat(this.workedHours) * 100) / 100
        ).toFixed(2);

        state.chargeableHours = (
          Math.round(parseFloat(this.chargeableHours) * 100) / 100
        ).toFixed(2);
      },
      async handleSubmit() {
        const currentWorkedHours = state.workedHours;
        const currentChargeableHours = state.chargeableHours;
        const currentSelectedUser = state.selectedUser;
        const currentSelectedArticles = state.selectedArticles;

        try {
          state.reset();

          await issue.addTimeReport(
            parseFloat(currentWorkedHours) || 0,
            parseFloat(currentChargeableHours) || 0,
            currentSelectedUser ? currentSelectedUser.id : null,
            currentSelectedArticles,
          );
        } catch (error) {
          state.setWorkedHours(currentWorkedHours);
          state.setChargeableHours(currentChargeableHours);
          state.setSelectedUser(currentSelectedUser);
          state.setSelectedArticles(currentSelectedArticles);
        }
      },
      get filteredUsers(): UserInstance[] {
        return users!
          .slice()
          .filter(
            (user) =>
              user.id === issue.assigned_to?.id ||
              issue.co_workers?.some((worker) => worker?.id === user.id),
          );
      },
      reset() {
        state.setWorkedHours('');
        state.setChargeableHours('');
        state.setSelectedUser(null);
        state.setSelectedArticles([]);
      },
    }));
    return (
      <thead>
        <tr className="pb-2">
          <td className="w-16 pb-5 pl-7">
            <div className="relative flex flex-col">
              <span className="absolute -top-6 mb-2 whitespace-nowrap text-xxs xs:text-xs">
                Arbetad tid
              </span>
              <input
                placeholder="0,00"
                onBlur={state.handleBlur}
                onChange={state.handleWorkedHoursChanged}
                value={state.workedHours}
                type="number"
                name="workedHours"
                className="time-indication h-11 w-12 rounded-md border border-gray-300 text-center xs:w-16"
              />
              <div className="relative w-12 xs:w-16">
                <span className="absolute -bottom-4 left-1/2 -translate-x-1/2 transform text-center text-xxs text-gray-400 xs:text-xs">
                  timmar
                </span>
              </div>
            </div>
          </td>

          <td className="w-16 pb-5 pl-2">
            <div className="relative">
              <span className="absolute -top-6 mb-2 whitespace-nowrap text-xxs xs:text-xs">
                Varav debiterbart
              </span>
              <input
                disabled={+state.workedHours <= 0}
                placeholder="0,00"
                onBlur={state.handleBlur}
                onChange={state.handleChargeableHoursChanged}
                value={state.chargeableHours}
                type="number"
                name="chargeableHours"
                className="time-indication h-11 w-12 rounded-md border border-gray-300 text-center xs:w-16"
              />
            </div>
          </td>
          <td className="w-12 pb-5 pl-2 lg:pl-5">
            <UserSelect
              users={state.filteredUsers}
              selected={state.selectedUser}
              onSelect={state.setSelectedUser}
              onDelete={
                state.selectedUser
                  ? () => state.setSelectedUser(null)
                  : undefined
              }
              renderButton={() => {
                return state.selectedUser ? (
                  <div className="flex items-center">
                    <Avatar user={state.selectedUser} />
                  </div>
                ) : (
                  <UserSelectIcon className="w-8" />
                );
              }}
            />
          </td>
          <td className="w-44 pb-5 pl-2 lg:pl-5">
            <div className="flex items-center">
              <div className="flex flex-col items-start space-y-1">
                {state.selectedArticles?.map((article, index) => {
                  const isLastItem =
                    index === state.selectedArticles.length - 1;
                  return (
                    <div
                      key={article!.id}
                      className="flex items-center justify-center space-x-1"
                    >
                      <ArticleTag
                        onDelete={() => state.removeArticle(article.id)}
                        name={article!.name}
                      />
                      {isLastItem && (
                        <ArticleSelect
                          articles={articles as any}
                          selected={null}
                          onSelect={(article) =>
                            state.addArticle(article as ArticleInstance)
                          }
                          position={positionRight}
                          renderButton={() => (
                            <ArticleSelectIcon className="h-4 w-4" />
                          )}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="ml-2 flex h-6 items-center justify-center">
                {state.selectedArticles.length === 0 && (
                  <ArticleSelect
                    articles={articles as any}
                    selected={null}
                    onSelect={(article) => state.addArticle(article as any)}
                    position={positionRight}
                    renderButton={() => {
                      return <ArticleSelectIcon className="h-8 w-8" />;
                    }}
                  />
                )}
              </div>
            </div>
          </td>
          <td className="pb-12">
            <div className="relative">
              <button
                className={`absolute right-1 flex h-8 w-8 items-center justify-center rounded-md bg-indigo-600 transition disabled:cursor-not-allowed disabled:opacity-75 xxs:right-2 sm:right-3 lg:right-4 ${
                  state.chargeableHours && state.workedHours
                    ? 'hover:bg-indigo-900'
                    : ''
                }`}
                onClick={state.handleSubmit}
                disabled={+state.workedHours <= 0}
              >
                <PlusIcon className="w-6 text-white" />
              </button>
            </div>
          </td>
        </tr>
      </thead>
    );
  },
);

interface TimeReportRowsProps {
  issue: IssueInstance;
}

interface TimeReportsState {
  isMouseOverReport: number | null;
  setIsMouseOverReport: (timeReportId: number | null) => void;
  filteredUsers: UserInstance[];
}

const TimeReportRows: FC<TimeReportRowsProps> = observer(({ issue }) => {
  const {
    articleStore: { activeArticles },
  } = useStore();

  const state = useLocalObservable<TimeReportsState>(() => ({
    isMouseOverReport: null,
    setIsMouseOverReport(timeReportId) {
      state.isMouseOverReport = timeReportId;
    },
    get filteredUsers(): UserInstance[] {
      const users: UserInstance[] = [];

      if (issue.assigned_to) {
        users.push(issue.assigned_to);
      }
      if (issue.co_workers) {
        const co_workers = issue.co_workers.filter(Boolean) as UserInstance[];
        users.push(...co_workers);
      }

      return users;
    },
  }));

  return (
    <tbody>
      {issue.time_reports?.map((report, index) => {
        const isEven = index % 2 === 0;
        return (
          <tr
            key={report.id}
            className={`${isEven ? 'bg-gray-50' : 'bg-white'}`}
            onMouseOver={() => state.setIsMouseOverReport(report.id)}
            onMouseLeave={() => state.setIsMouseOverReport(null)}
          >
            <td className="w-12 py-4 pl-7 text-center">
              {report.worked_hours.toString().replace('.', ',')}
            </td>
            <td className="w-12 py-4 text-center">
              {report.chargeable_hours.toString().replace('.', ',')}
            </td>
            <td className="w-12 py-4 pl-2 lg:pl-5">
              <div className="flex">
                <UserSelect
                  disabled={issue.status === IssueStatus.Done}
                  users={state.filteredUsers}
                  selected={report.reporter}
                  onSelect={(user) =>
                    report.assignTimeReport(user!.id, issue.id)
                  }
                  onDelete={
                    report.reporter
                      ? () => report.assignTimeReport(null, issue.id)
                      : undefined
                  }
                  renderButton={() => {
                    return report.reporter ? (
                      <div className="flex items-center">
                        <Avatar
                          user={report.reporter}
                          border={issue.assigned_to?.id === report.reporter.id}
                        />
                      </div>
                    ) : (
                      <UserSelectIcon className="w-8" />
                    );
                  }}
                  tooltip
                />
              </div>
            </td>
            <td className="py-4 pl-2 lg:pl-5">
              <div
                className={`mr-7 flex ${
                  report.articles!.length > 1 ? 'items-center' : 'items-center'
                }`}
              >
                <div className="flex flex-col items-start space-y-1">
                  {report.articles?.map((article, index) => {
                    const isLastItem = index === report.articles!.length - 1;
                    return (
                      <div
                        className="flex items-center justify-center space-x-1"
                        key={`${index}-${article?.id}`}
                      >
                        <ArticleTag
                          disabled={issue.status === IssueStatus.Done}
                          onDelete={() =>
                            report.deleteArticle(article!.id, issue.id)
                          }
                          name={article!.name}
                        />
                        {issue.status !== IssueStatus.Done && isLastItem && (
                          <ArticleSelect
                            articles={activeArticles}
                            selected={null}
                            onSelect={(article) =>
                              report.addArticle(article!.id, issue.id)
                            }
                            position={positionRight}
                            renderButton={() => (
                              <ArticleSelectIcon className="h-4 w-4" />
                            )}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>

                <div className="ml-2 flex h-6 items-center justify-center">
                  {issue.status !== IssueStatus.Done &&
                    report.articles?.length === 0 && (
                      <ArticleSelect
                        articles={activeArticles}
                        selected={null}
                        onSelect={(article) =>
                          report.addArticle(article!.id, issue.id)
                        }
                        position={positionRight}
                        renderButton={() => (
                          <ArticleSelectIcon className="h-8 w-8" />
                        )}
                      />
                    )}
                </div>
              </div>
            </td>
            <td className="relative">
              {state.isMouseOverReport === report.id &&
                issue.status !== IssueStatus.Done && (
                  <button
                    onClick={() => issue.deleteTimeReport(report.id)}
                    className="absolute right-4 top-1/2 -translate-y-1/2 transform"
                  >
                    <DeleteIcon />
                  </button>
                )}
            </td>
          </tr>
        );
      })}
    </tbody>
  );
});
