import { DateObj, useDayzed } from 'dayzed';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import { ChevronDownIcon } from '../icons';

const DAYS = ['mån', 'tis', 'ons', 'tors', 'fre', 'lör', 'sön'];

const MONTHS = [
  'Januari',
  'Februari',
  'Mars',
  'April',
  'Maj',
  'Juni',
  'Juli',
  'Augusti',
  'September',
  'Oktober',
  'November',
  'December',
];

interface CalenderProps {
  selected: Date;
  onDateSelected: (date: DateObj) => void;
  showOutsideDays: boolean;
  firstDayOfWeek?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
}

const Calender: FC<CalenderProps> = ({
  selected,
  onDateSelected,
  showOutsideDays,
  firstDayOfWeek = 1,
}) => {
  const { calendars, getBackProps, getForwardProps, getDateProps } = useDayzed({
    selected,
    onDateSelected,
    showOutsideDays,
    firstDayOfWeek,
  });
  const { year, month, weeks } = calendars[0];
  return (
    <>
      <div className="mb-6 flex items-center justify-between">
        <button
          {...getBackProps({ calendars })}
          className="rounded-full bg-gray-50 p-2 text-gray-900 hover:bg-indigo-600 hover:text-white focus:outline-none"
        >
          <ChevronDownIcon className="w-6 rotate-90" />
        </button>
        <span className="text-xl font-semibold">
          {MONTHS[month]} {year}
        </span>
        <button
          {...getForwardProps({ calendars })}
          className="rounded-full bg-gray-50 p-2 text-gray-900 hover:bg-indigo-600 hover:text-white focus:outline-none"
        >
          <ChevronDownIcon className="w-6 -rotate-90" />
        </button>
      </div>
      <div className="w-full">
        <div className="mt-1 grid grid-cols-7 gap-y-2">
          {DAYS.map((day) => (
            <div
              key={day}
              className="flex flex-1 items-center justify-center font-medium text-gray-300"
            >
              {day}
            </div>
          ))}
          {weeks.map((week, weekIndex) =>
            week.map((dateObj, index) => {
              const key = `${month}${year}${weekIndex}${index}`;
              if (!dateObj) {
                return null;
              }
              const { date, selected, selectable } = dateObj;
              return (
                <div
                  key={key}
                  className="relative flex items-center justify-center text-sm font-normal"
                >
                  <button
                    disabled={!selectable}
                    {...getDateProps({ dateObj })}
                    className={`flex h-8 w-8 items-center justify-center rounded-full focus:border focus:border-indigo-300 focus:outline-none ${
                      selected
                        ? 'bg-indigo-600 text-white'
                        : date.getMonth() === month
                        ? 'text-gray-900'
                        : 'text-gray-300'
                    }`}
                  >
                    {date.getDate()}
                  </button>
                </div>
              );
            }),
          )}
        </div>
      </div>
    </>
  );
};

interface State {
  selectedDate: Date;
  handleOnDateSelected: (date: any) => void;
  shouldRender: boolean;
  setShouldRender: (shouldRender: boolean) => void;
}

export const Datepicker = observer(
  ({
    date,
    onApply,
    onCancel,
    show,
  }: {
    date: Date | null;
    onApply: (date: Date) => void;
    onCancel: () => void;
    show: boolean;
  }) => {
    const state = useLocalObservable<State>(() => ({
      selectedDate: date ? date : new Date(),
      handleOnDateSelected({ date }) {
        state.selectedDate = date;
      },
      shouldRender: show,
      setShouldRender(shouldRender: boolean) {
        state.shouldRender = shouldRender;
      },
    }));

    useEffect(() => {
      if (show) state.setShouldRender(true);
    }, [show]);

    // We wait for animation to end
    // before we stop rendering component.
    const onAnimationEnd = () => {
      if (!show) state.setShouldRender(false);
    };

    if (!state.shouldRender) return null;

    return (
      <div
        className={show ? 'lg:animate-fadeIn' : 'lg:animate-fadeOut'}
        onAnimationEnd={onAnimationEnd}
      >
        <span
          className={`absolute inset-0 z-50 bg-indigo-900 bg-opacity-30 lg:hidden ${
            show ? 'animate-fadeIn' : 'animate-fadeOut'
          }`}
        ></span>
        <div
          className={`absolute bottom-0 left-0 right-0 z-50 lg:bottom-auto lg:left-auto lg:right-auto lg:top-full lg:mt-7 ${
            show
              ? 'animate-slideUp lg:animate-none'
              : 'animate-slideDown lg:animate-none'
          }`}
          onAnimationEnd={onAnimationEnd}
        >
          <div className="w-full rounded-t-md bg-white p-7 shadow-lg lg:w-80 lg:rounded-md">
            <Calender
              selected={state.selectedDate}
              onDateSelected={state.handleOnDateSelected}
              showOutsideDays
            />
            <div className="mt-7 flex justify-end">
              <button
                onClick={() => onApply(state.selectedDate)}
                className="rounded-md bg-indigo-600 p-3 font-semibold text-white"
              >
                Tillämpa
              </button>
              <button
                onClick={() => onCancel()}
                className="ml-3 rounded-md border border-gray-200 p-3 font-semibold text-gray-900"
              >
                Avbryt
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  },
);
