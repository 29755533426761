import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FadeInDiv, InputWithError, Label } from '../components';
import { CheckIcon } from '../icons';
import { useStore } from '../hooks';
import { useNavigate } from 'react-router-dom';

interface FormData {
  name: string;
  price: string;
}

interface State {
  isSubmitting: boolean;
  setIsSubmitting: (isSubmiting: boolean) => void;
  onSubmit: (name: string, price: number) => void;
}

export const CreateArticlePage = observer(() => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    shouldUnregister: true,
    reValidateMode: 'onSubmit',
  });
  const { articleStore } = useStore();

  const state = useLocalObservable<State>(() => ({
    isSubmitting: false,
    setIsSubmitting(isSubmitting) {
      state.isSubmitting = isSubmitting;
    },
    async onSubmit(name, price) {
      try {
        state.setIsSubmitting(true);

        await articleStore.createArticle(name, price);

        navigate('/organization/articles');
      } finally {
        state.setIsSubmitting(false);
      }
    },
  }));

  return (
    <FadeInDiv className="h-full w-full p-4 md:p-14">
      <div className="block rounded-md bg-white p-7 md:inline-block">
        <span className="w-full text-xl font-extrabold text-indigo-900">
          Skapa ny artikel
        </span>
      </div>
      <form
        onSubmit={handleSubmit(({ name, price }) => {
          state.onSubmit(name, Number(price));
        })}
      >
        <div className="mt-5 space-y-6 rounded-md bg-white p-7 md:mt-10">
          <div className="max-w-xs">
            <Label required>Namn</Label>
            <InputWithError
              type="text"
              placeholder="Borrmaskin"
              {...register('name', {
                required: 'Fyll i detta fältet',
                maxLength: {
                  value: 50,
                  message: 'Max 50 tecken',
                },
              })}
              error={errors.name?.message}
            />
          </div>
          <div className="max-w-xs">
            <Label required>Pris (kr)</Label>
            <InputWithError
              type="number"
              step="1"
              placeholder="500"
              {...register('price', {
                required: 'Fyll i detta fältet',
                min: '0',
              })}
              error={errors.price?.message}
            />
          </div>
        </div>
        <button
          disabled={state.isSubmitting}
          type="submit"
          className="mt-5 flex w-full items-center justify-center rounded-md bg-white p-3 font-semibold text-gray-900 transition-colors hover:bg-indigo-600 hover:text-white md:mt-7 md:w-auto md:justify-start"
        >
          <CheckIcon className="w-5" />
          <span className="ml-3">Skapa</span>
        </button>
      </form>
    </FadeInDiv>
  );
});
