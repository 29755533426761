import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { nameToSoftColor } from '../utils';
import { CrossIcon2 } from '../icons';

interface Props {
  name: string;
  onDelete?: () => void;
  disabled?: boolean;
}

interface ArticleTagState {
  isMouseOver: boolean;
  setIsMouseOver: (isMouseOver: boolean) => void;
}

export const ArticleTag: React.FC<Props> = observer(
  ({ name, onDelete, disabled = false }) => {
    const color = useMemo(() => {
      return nameToSoftColor(name);
    }, [name]);

    const state = useLocalObservable<ArticleTagState>(() => ({
      isMouseOver: false,
      setIsMouseOver(isMouseOver) {
        state.isMouseOver = isMouseOver;
      },
    }));

    return (
      <div
        onMouseOver={() => state.setIsMouseOver(true)}
        onMouseLeave={() => state.setIsMouseOver(false)}
        className="relative inline-block max-w-14 overflow-hidden truncate rounded-s px-1.5 text-xxs text-black lg:max-w-16"
        style={{ backgroundColor: color }}
        title={name}
      >
        {state.isMouseOver && onDelete && !disabled && (
          <button
            disabled={disabled}
            onClick={onDelete}
            style={{ backgroundColor: color }}
            className="absolute right-0 top-1/2 z-50 -translate-y-1/2 transform"
          >
            <CrossIcon2 className="w-4 text-white" />
          </button>
        )}
        {name}
      </div>
    );
  },
);
