import React, { useEffect } from 'react';
import {
  Avatar,
  DeletePopup,
  Label,
  Popup,
  UserSelect,
} from '../../../components';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FacilityAssignment, FacilityFormValues } from '..';
import { useStore } from '../../../hooks';
import { IssueTypeInstance, UserInstance } from '../../../models';
import { UserSelectIcon } from '../../../icons';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { AssignmentTable } from './AssignmentTable';

type State = {
  errorMessage: string;
  setErrorMessage: (message: string) => void;

  assignmentToRemove: null | { user: any; index: number };
  setAssignmentToRemove: (
    assignment: null | { user: any; index: number },
  ) => void;
  userToRemove: null | UserInstance | undefined;

  issueTypes: IssueTypeInstance[];
  disabledUsers: UserInstance[];
  setDisabledUsers: (newAssignments: FacilityAssignment[]) => void;
};

type AssignmentData = {
  checked: boolean;
  assignmentType: number | string;
  user: number;
  index: number;
};
const ROUNDING_TYPES = ['T1', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'T8'];

export const Assignments = observer(() => {
  const {
    userStore: { users },
    organizationStore: { organization },
  } = useStore();
  const { control } = useFormContext<FacilityFormValues>();

  const {
    fields: assignments,
    append,
    remove,
    update,
  } = useFieldArray({
    control,
    name: 'assignments',
  });

  const state = useLocalObservable<State>(() => ({
    errorMessage: '',
    setErrorMessage(message: string) {
      state.errorMessage = message;
    },
    assignmentToRemove: null,
    setAssignmentToRemove(assignment: any) {
      state.assignmentToRemove = assignment;
    },
    get userToRemove(): null | UserInstance {
      if (!state.assignmentToRemove) return null;

      return users?.find(
        (user) => user.id === state.assignmentToRemove?.user,
      ) as UserInstance;
    },
    get issueTypes() {
      const issueTypes = [] as IssueTypeInstance[];

      organization!.issue_types.forEach((it) => {
        if (it.children && it.children.length > 0) {
          issueTypes.push(...it.children);
        } else {
          issueTypes.push(it);
        }
      });

      return issueTypes;
    },
    disabledUsers: [],
    setDisabledUsers(newAssignments) {
      state.disabledUsers = users!.filter((u) =>
        newAssignments.some((assignment) => assignment.user === u.id),
      );
    },
  }));

  const changeCategoryAssignment = (data: AssignmentData) => {
    const { checked, assignmentType, user, index } = data;

    if (!checked) return;

    const indexToRemoveFrom = assignments.findIndex((a) =>
      a.issue_types.some((type) => type === assignmentType),
    );

    if (indexToRemoveFrom >= 0) {
      update(indexToRemoveFrom, {
        user: assignments[indexToRemoveFrom].user,
        issue_types: assignments[indexToRemoveFrom].issue_types.filter(
          (type) => type !== assignmentType,
        ),
        rounding_types: assignments[indexToRemoveFrom].rounding_types,
      });
    }

    update(index, {
      user,
      issue_types: [
        ...assignments[index].issue_types,
        assignmentType as number,
      ],
      rounding_types: assignments[index].rounding_types,
    });
  };

  const changeRoundingAssignment = (data: AssignmentData) => {
    const { checked, assignmentType, user, index } = data;

    if (!checked) return;

    const indexToRemoveFrom = assignments.findIndex((a) =>
      a.rounding_types?.some((type) => type === assignmentType),
    );

    if (indexToRemoveFrom >= 0) {
      update(indexToRemoveFrom, {
        user: assignments[indexToRemoveFrom].user,
        issue_types: assignments[indexToRemoveFrom].issue_types,
        rounding_types: assignments[indexToRemoveFrom].rounding_types?.filter(
          (type) => type !== assignmentType,
        ) as string[],
      });
    }

    update(index, {
      user,
      issue_types: assignments[index].issue_types,
      rounding_types: [
        ...assignments[index].rounding_types!,
        assignmentType as string,
      ],
    });
  };

  const addNewAssignment = (user: UserInstance | null) => {
    if (!user) return;

    append({
      user: user?.id,
      issue_types:
        assignments.length === 0 ? state.issueTypes.map((x) => x.id) : [],
      rounding_types:
        assignments.length === 0 && organization?.hasRoundsFeature
          ? ROUNDING_TYPES
          : [],
    });
  };

  const removeAssignment = (assignment: FacilityAssignment, index: number) => {
    if (
      (assignment.issue_types.length > 0 ||
        (assignment.rounding_types && assignment.rounding_types.length > 0)) &&
      assignments.length > 2
    ) {
      state.setErrorMessage(
        'Du måste ta bort allt ansvar innan det går att ta bort denna användare',
      );
    } else {
      state.setAssignmentToRemove({ user: assignment.user, index });
    }
  };

  const handleConfirmRemoval = () => {
    // If there are only 2 assigned users left we move all issue types and rounding types to the first user.
    if (assignments.length == 2 && state.assignmentToRemove) {
      update(0, {
        user: assignments[0].user,
        issue_types: [...state.issueTypes.map((issueType) => issueType.id)],
        rounding_types: organization?.hasRoundsFeature ? ROUNDING_TYPES : [],
      });
    }
    remove(state.assignmentToRemove!.index);
    state.setAssignmentToRemove(null);
  };
  useEffect(() => {
    state.setDisabledUsers(assignments);
  }, [assignments]);
  return (
    <div>
      <Label>Automatisk tilldelning</Label>
      <div className="mb-2 flex space-x-1">
        {assignments.map((assignment, index) => {
          const user = users?.find((u) => u.id === assignment.user);
          if (user)
            return (
              <Avatar
                key={assignment.id}
                user={user}
                onDelete={() => removeAssignment(assignment, index)}
                tooltip
              />
            );
        })}

        <UserSelect
          users={users as UserInstance[]}
          disabledUsers={state.disabledUsers}
          onSelect={addNewAssignment}
          renderButton={() => <UserSelectIcon className="w-8" />}
        />
      </div>
      <div className="text-xs text-gray-300">
        Ange användare som automatiskt ska tilldelas ärenden som skapas för
        denna fastigheten
      </div>
      <div className="mt-10 space-y-10">
        {assignments.length > 0 && organization?.hasRoundsFeature && (
          <AssignmentTable
            type="rounding"
            assignments={assignments}
            assignmentTypes={ROUNDING_TYPES.map((roundingType) => ({
              id: roundingType,
              name: roundingType,
            }))}
            onChange={changeRoundingAssignment}
          />
        )}
        {assignments.length > 0 && (
          <AssignmentTable
            type="category"
            assignments={assignments}
            assignmentTypes={state.issueTypes}
            onChange={changeCategoryAssignment}
          />
        )}
      </div>
      {state.errorMessage && (
        <Popup isOpen onDismiss={() => state.setErrorMessage('')}>
          {state.errorMessage}
        </Popup>
      )}
      {state.assignmentToRemove && (
        <DeletePopup
          title={`Ta bort ${state.userToRemove?.name}`}
          onConfirm={handleConfirmRemoval}
          onDismiss={() => state.setAssignmentToRemove(null)}
        >
          Är du säkert på att du vill ta bort {state.userToRemove?.name || ''}?
        </DeletePopup>
      )}
    </div>
  );
});
