import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { FilterPicker, FilterObj, Filter } from '.';
import { useStore } from '../hooks';
import { RoundingFilter } from '../types/RoundingFilter';
import { RoundingStatus } from '../types';
import { ROUNDING_STATUS_TEXTS } from '../constants';
import { RoundingFilterInstance } from '../models';

const filterTypeName = {
  [RoundingFilter.Name]: 'Namn',
  [RoundingFilter.Facility]: 'Fastighet',
  [RoundingFilter.Company]: 'Företag',
  [RoundingFilter.AssignedTo]: 'Tilldelad',
  [RoundingFilter.EndsAt]: 'Avslutas',
  [RoundingFilter.ApprovedAt]: 'Genomförd',
  [RoundingFilter.Status]: 'Status',
};

interface RoundingFilterPickerState {
  getFilterValueItems: (filter: RoundingFilter) => FilterObj[];
  filterTypeItems: FilterObj[];
  filters: Filter[];
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  handleSearch: (term: string) => void;
}

export const RoundingFilterPicker = observer(() => {
  const {
    userStore: { users },
    organizationStore: { organization },
    roundingStore: { roundingActiveFilters, closedRoundingActiveFilters },
  } = useStore();

  const state: RoundingFilterPickerState =
    useLocalObservable<RoundingFilterPickerState>(() => ({
      searchTerm: '',
      setSearchTerm(term: string) {
        this.searchTerm = term;
      },
      handleSearch(term: string) {
        this.setSearchTerm(term);
      },
      get filters() {
        let filters: RoundingFilterInstance[] = [];
        if (location.pathname === '/rounds' && roundingActiveFilters) {
          filters = roundingActiveFilters;
        } else if (
          location.pathname === '/rounds/complete' &&
          closedRoundingActiveFilters
        ) {
          filters = closedRoundingActiveFilters;
        }

        return filters.map((filter) => {
          const filterType = {
            name: filterTypeName[filter.name as RoundingFilter],
            value: filter.name,
            type:
              filter.name === RoundingFilter.EndsAt ||
              filter.name === RoundingFilter.ApprovedAt
                ? 'date'
                : filter.name === RoundingFilter.Facility
                ? 'search'
                : 'text',
          };

          const value =
            typeof filter.value === 'string'
              ? filter.value
              : filter.value?.id.toString();

          if (!value) return;

          const filterValue = {
            name: filter.displayName || '',
            value,
          };

          return { filterType, filterValue };
        }) as Filter[];
      },
      getFilterValueItems(filter) {
        switch (filter) {
          case RoundingFilter.AssignedTo:
            return users!.map((u) => {
              return { name: u.name, value: u.id.toString() };
            });
          case RoundingFilter.Company:
            return organization!.companies.map((c) => ({
              name: c.name,
              value: c.id.toString(),
            }));
          case RoundingFilter.Facility:
            if (!state.searchTerm) {
              return organization!.facilities.map((f) => ({
                name: f.name,
                value: f.id.toString(),
              }));
            }
            return organization!.facilities
              .filter((facility) =>
                facility.name
                  .toLowerCase()
                  .includes(state.searchTerm.toLowerCase()),
              )
              .map((f) => ({
                name: f.name,
                value: f.id.toString(),
              }));
          case RoundingFilter.Status:
            return Object.values(RoundingStatus)
              .filter((status) => status !== RoundingStatus.Approved)
              .map((value) => ({
                name: ROUNDING_STATUS_TEXTS[value],
                value,
              }));
          default:
            return [];
        }
      },
      get filterTypeItems() {
        let items = Object.values(RoundingFilter)
          .map((value) => ({
            name: filterTypeName[value],
            value,
            type:
              value === RoundingFilter.EndsAt ||
              value === RoundingFilter.ApprovedAt
                ? 'date'
                : value === RoundingFilter.Facility
                ? 'search'
                : 'text',
          }))
          .filter((type) => type.value !== RoundingFilter.Name);

        if (
          state.filters.some(
            (f) => f.filterType?.value === RoundingFilter.EndsAt,
          )
        ) {
          items = items.filter((i) => i.value !== RoundingFilter.EndsAt);
        }

        if (
          state.filters.some(
            (f) => f.filterType?.value === RoundingFilter.ApprovedAt,
          )
        ) {
          items = items.filter((i) => i.value !== RoundingFilter.ApprovedAt);
        }

        if (
          state.filters.some(
            (f) => f.filterType?.value === RoundingFilter.AssignedTo,
          )
        ) {
          items = items.filter((i) => i.value !== RoundingFilter.AssignedTo);
        }

        if (location.pathname !== '/rounds') {
          items = items.filter((i) => i.value !== RoundingFilter.Status);
        }

        return items;
      },
    }));

  return (
    <FilterPicker
      filters={state.filters}
      filterTypeItems={state.filterTypeItems}
      filterTypes={Object.values(RoundingFilter)}
      getFilterValueItems={state.getFilterValueItems}
      onSearch={state.handleSearch}
    />
  );
});
