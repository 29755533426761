import React from 'react';
import { observer } from 'mobx-react-lite';
import { IssueInstance } from '../../../models';
import { TagSelect } from '../../../components';
import { IssuePriority, IssueStatus } from '../../../types';

export const StatusBar = observer(({ issue }: { issue: IssueInstance }) => (
  <div className="flex flex-col px-5 py-8 xs:flex-row">
    <div className="min-w-36">
      <div className="mb-2 px-2 text-sm font-semibold text-gray-900">
        Status
      </div>
      <TagSelect
        type="status"
        selected={issue.status}
        onSelect={(status) => issue.setStatus(status as IssueStatus)}
        disabled={issue.status !== IssueStatus.Waiting}
      />
    </div>
    <div className="min-w-36 xs:ml-4">
      <div className="mb-2 mt-8 px-2 text-sm font-semibold text-gray-900 xs:mt-0">
        Prioritet
      </div>
      <TagSelect
        type="priority"
        selected={issue.priority}
        onSelect={(priority) => issue.setPriority(priority as IssuePriority)}
        disabled={issue.status === IssueStatus.Done}
      />
    </div>
  </div>
));
