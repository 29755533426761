export enum RoundingFilter {
  Name = 'name',
  Facility = 'facility',
  Company = 'company',
  EndsAt = 'ends_at',
  AssignedTo = 'assigned_to',
  ApprovedAt = 'approved_at',
  Status = 'status',
}

export const roundingFilters = {
  [RoundingFilter.Name]: 'rounding_step__name',
  [RoundingFilter.Facility]: 'rounding_step__facility__name',
  [RoundingFilter.Company]: 'rounding_step__company__name',
  [RoundingFilter.EndsAt]: 'ends_at',
  [RoundingFilter.AssignedTo]: 'assigned_to__name',
  [RoundingFilter.ApprovedAt]: 'approved_at',
  [RoundingFilter.Status]: 'status',
};
