import { applySnapshot, flow, types as t } from 'mobx-state-tree';
import { SEARCH_ISSUES_PER_PAGE } from '../constants';
import { withRequest } from '../extensions';
import { SearchIssue, SEARCH_ISSUE_FIELDS } from './SearchIssue';

const SearchIssueArray = t.array(SearchIssue);

export const SearchStore = t
  .model('SearchStore', {
    search: '',
    myIssues: t.maybeNull(SearchIssueArray),
    myIssuesCount: 0,
    issues: t.maybeNull(SearchIssueArray),
    issuesCount: 0,
  })
  .views((self) => ({
    get searchQuery(): URLSearchParams {
      return new URLSearchParams(self.search);
    },
  }))
  .views((self) => ({
    get q(): string {
      return self.searchQuery.get('q') || '';
    },
  }))
  .views((self) => ({
    get lowerCasedQ() {
      return self.q.toLowerCase();
    },
    get hasMoreMyIssues() {
      return self.myIssues ? self.myIssues.length < self.myIssuesCount : false;
    },
    get hasMoreIssues() {
      return self.issues ? self.issues.length < self.issuesCount : false;
    },
  }))
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;

    let isFetchingMyIssues = false;
    let isFetchingIssues = false;

    return {
      fetchMyIssues: flow(function* () {
        if (isFetchingMyIssues) return;

        isFetchingMyIssues = true;

        try {
          const res = yield request({
            method: 'GET',
            url: '/api/issues/',
            params: {
              offset: self.myIssues?.length || 0,
              search: self.q,
              limit: SEARCH_ISSUES_PER_PAGE,
              assigned_to_or_co_worker: 'me',
              fields: SEARCH_ISSUE_FIELDS,
            },
          });

          if (self.myIssues === null) {
            self.myIssues = SearchIssueArray.create();
          }

          self.myIssues.push(...res.data.results);
          self.myIssuesCount = res.data.count;
        } catch (error) {
          // We don't need to handle this error since the user can just try again
        } finally {
          isFetchingMyIssues = false;
        }
      }),
      fetchIssues: flow(function* () {
        if (isFetchingIssues) return;

        isFetchingIssues = true;

        try {
          const res = yield request({
            method: 'GET',
            url: '/api/issues/',
            params: {
              offset: self.issues?.length || 0,
              search: self.q,
              limit: SEARCH_ISSUES_PER_PAGE,
              fields: SEARCH_ISSUE_FIELDS,
            },
          });

          if (self.issues === null) {
            self.issues = SearchIssueArray.create();
          }

          self.issues.push(...res.data.results);
          self.issuesCount = res.data.count;
        } catch (error) {
          // We don't need to handle this error since the user can just try again
        } finally {
          isFetchingIssues = false;
        }
      }),
    };
  })
  .actions((self) => ({
    handleSearchChange(search: string) {
      if (self.search === search) return;

      self.search = search;
      self.myIssues = null;
      self.myIssuesCount = 0;
      self.issues = null;
      self.issuesCount = 0;

      self.fetchMyIssues();
      self.fetchIssues();
    },
    reset() {
      applySnapshot(self, {
        search: '',
        myIssues: null,
        myIssuesCount: 0,
        issues: null,
        issuesCount: 0,
      });
    },
  }));
