import React from 'react';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuPopover,
  MenuItems,
} from '@reach/menu-button';
import { positionMatchWidth } from '@reach/popover';
import { observer } from 'mobx-react-lite';
import { IssueStatus, IssuePriority, AccountType } from '../types';
import { StatusTag } from './StatusTag';
import { PriorityTag } from './PriorityTag';
import { AccountTypeTag } from './AccountTypeTag';
import { CheckIcon, ChevronDownIcon } from '../icons';
import { useMouseDownOnResizeRef, useStore } from '../hooks';

const STATUS_ORDER = [IssueStatus.InProgress, IssueStatus.Waiting];
const PRIORITY_ORDER = [
  IssuePriority.None,
  IssuePriority.Low,
  IssuePriority.Medium,
  IssuePriority.High,
];
const ACCOUNT_ORDER = [
  AccountType.Parking,
  AccountType.Worker,
  AccountType.Admin,
];

interface TagSelectProps {
  type: 'status' | 'priority' | 'role';
  selected: IssueStatus | IssuePriority | AccountType;
  onSelect: (selected: IssueStatus | IssuePriority | AccountType) => void;
  disabled?: boolean;
  showBorderAndChevron?: boolean;
}
export const TagSelect = observer(
  ({
    type,
    selected,
    onSelect,
    disabled = false,
    showBorderAndChevron = false,
  }: TagSelectProps) => {
    const {
      uiStore,
      organizationStore: { organization },
    } = useStore();
    const ref = useMouseDownOnResizeRef();

    if (organization === null) return null;

    const shouldShowParking = uiStore.shouldShowParking(organization);

    const items =
      type === 'status'
        ? STATUS_ORDER
        : type === 'priority'
        ? PRIORITY_ORDER
        : ACCOUNT_ORDER.filter((accountType) =>
            shouldShowParking
              ? accountType
              : accountType !== AccountType.Parking,
          );

    const TagComponent =
      type === 'status'
        ? StatusTag
        : type === 'priority'
        ? PriorityTag
        : AccountTypeTag;

    return (
      <div className="w-full">
        <Menu>
          {({ isExpanded }) => (
            <div className="relative w-full">
              <MenuButton
                ref={isExpanded ? ref : undefined}
                className={`flex w-full items-center justify-between rounded-md border px-2 py-1 focus:outline-none ${
                  showBorderAndChevron
                    ? 'border-gray-200'
                    : 'border-transparent'
                } ${isExpanded ? 'border-gray-200 bg-white' : ''} ${
                  !disabled
                    ? 'group hover:border-gray-200 hover:bg-white focus:border-gray-200 focus:bg-white'
                    : 'cursor-default'
                } ${
                  type === 'role'
                    ? 'border-gray-200 py-1.5 focus:border-indigo-600'
                    : ''
                }`}
                disabled={disabled}
              >
                {/* @ts-ignore */}
                <TagComponent type={selected} />

                <div className="ml-2 w-5 flex-shrink-0">
                  <ChevronDownIcon
                    className={`transform transition-transform ${
                      isExpanded
                        ? 'rotate-180'
                        : 'group-hover:block group-focus:block'
                    } ${showBorderAndChevron && !disabled ? '' : 'hidden'}`}
                  />
                </div>
              </MenuButton>

              {isExpanded && (
                <MenuPopover
                  position={positionMatchWidth}
                  className="absolute z-30 my-1 max-h-96 w-full min-w-48 rounded-md border border-gray-200 bg-white shadow-lg"
                >
                  <MenuItems className="reach-menu-items focus:outline-none">
                    {/* @ts-ignore */}
                    {items.map((item) => {
                      const isSelected = item === selected;

                      return (
                        <MenuItem
                          key={item}
                          className="flex w-full cursor-pointer flex-col items-baseline overflow-y-hidden p-3 hover:bg-indigo-100"
                          onSelect={() => onSelect(item)}
                        >
                          <div className="flex w-full items-center justify-between">
                            <span
                              className={
                                isSelected ? 'font-semibold' : undefined
                              }
                            >
                              {/* @ts-ignore */}
                              <TagComponent type={item} />
                            </span>

                            {isSelected && (
                              <CheckIcon className="w-5 text-indigo-900" />
                            )}
                          </div>
                        </MenuItem>
                      );
                    })}
                  </MenuItems>
                </MenuPopover>
              )}
            </div>
          )}
        </Menu>
      </div>
    );
  },
);
