import React from 'react';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuPopover,
  MenuItems,
} from '@reach/menu-button';
import { positionMatchWidth } from '@reach/popover';
import { observer } from 'mobx-react-lite';
import { CheckIcon, ChevronDownIcon } from '../icons';
import { useMouseDownOnResizeRef } from '../hooks';
import { FormError } from './FormError';

interface SelectItem {
  name: string;
  value: string;
}

interface SelectProps {
  selected: SelectItem | null;
  onSelect: (selected: SelectItem) => void;
  disabled?: boolean;
  items: SelectItem[];
  placeholder?: string;
  error?: string;
  search?: boolean;
  onSearch?: (term: string) => void;
}

export const Select = observer(
  ({
    selected,
    onSelect,
    items,
    placeholder,
    disabled = false,
    error,
    search,
    onSearch,
  }: SelectProps) => {
    const ref = useMouseDownOnResizeRef();

    return (
      <div className="w-full">
        <Menu>
          {({ isExpanded }) => (
            <div className="relative w-full">
              <MenuButton
                ref={isExpanded ? ref : undefined}
                className={`first-letter flex w-full items-center justify-between truncate whitespace-nowrap rounded-md border border-gray-200 bg-white p-3 text-gray-900 focus:outline-none disabled:bg-gray-100
                ${error ? 'border-pink-500' : ''}`}
                disabled={disabled}
              >
                {selected?.name ? (
                  <span className="truncate">{selected?.name}</span>
                ) : (
                  <span className="text-gray-400">{placeholder}</span>
                )}

                <div className="ml-2 w-5 flex-shrink-0">
                  <ChevronDownIcon
                    className={`transform transition-transform ${
                      isExpanded
                        ? 'rotate-180'
                        : 'group-hover:block group-focus:block'
                    }`}
                  />
                </div>
              </MenuButton>

              {isExpanded && (
                <MenuPopover
                  position={positionMatchWidth}
                  className="absolute z-50 my-1 max-h-96 w-full min-w-48 overflow-y-auto rounded-md border border-gray-200 bg-white shadow-lg"
                >
                  {search && (
                    <input
                      type="text"
                      placeholder="Sök..."
                      onChange={(e) => onSearch?.(e.target.value)}
                      className="w-full border-b border-gray-200 p-2"
                    />
                  )}
                  <MenuItems className="reach-menu-items focus:outline-none">
                    {items.map((item) => {
                      const isSelected = item.value === selected?.value;

                      return (
                        <MenuItem
                          key={item.value}
                          className="flex w-full cursor-pointer flex-col items-baseline overflow-y-hidden p-3 text-gray-900 hover:bg-indigo-100"
                          onSelect={() => onSelect(item)}
                        >
                          <div className="flex w-full items-center justify-between">
                            <span
                              className={
                                isSelected ? 'font-semibold' : undefined
                              }
                            >
                              {item.name}
                            </span>

                            {isSelected && (
                              <CheckIcon className="w-5 text-indigo-900" />
                            )}
                          </div>
                        </MenuItem>
                      );
                    })}
                  </MenuItems>
                </MenuPopover>
              )}
            </div>
          )}
        </Menu>
        {error && <FormError>{error}</FormError>}
      </div>
    );
  },
);
