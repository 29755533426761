import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { ChangeEvent, FC, SyntheticEvent, useRef } from 'react';
import { CrossIcon3, UploadIcon2 } from '../icons';
import ReactCrop, { centerCrop, Crop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { FormError } from './create-issue';

interface ImageCropProps {
  onSave: (blob: Blob) => void;
  circularCrop?: boolean;
}

interface ImageCropPropsState {
  crop: Crop;
  setCrop: (crop: Crop) => void;
  cropError: string;
  setCropError: (error: string) => void;

  srcImage: string | null;
  setSrcImage: (src: string | null) => void;

  onSelectFile: (e: ChangeEvent<HTMLInputElement>) => void;
  onImageLoad: (e: SyntheticEvent<HTMLImageElement>) => void;
  getCroppedImage: () => Promise<Blob>;
  handleSave: () => void;

  isOverDropZone: boolean;
  setIsOverDropZone: (isOverDropZone: boolean) => void;
}

export const ImageCrop: FC<ImageCropProps> = observer(({ onSave }) => {
  const imgRef = useRef<HTMLImageElement | null>(null);

  const state = useLocalObservable<ImageCropPropsState>(() => ({
    crop: {
      unit: 'px',
      width: 250,
      aspect: 1,
      x: 0,
      y: 0,
      height: 0,
    },
    setCrop(crop) {
      state.crop = crop;
    },
    cropError: '',
    setCropError(error) {
      state.cropError = error;
    },
    srcImage: null,
    setSrcImage(src) {
      state.srcImage = src;
    },
    onSelectFile(e) {
      state.setSrcImage(URL.createObjectURL(e.target.files![0]));
    },
    onImageLoad(e) {
      imgRef.current = e.currentTarget;
      const { width, height } = e.currentTarget;

      const crop = centerCrop(
        makeAspectCrop(
          {
            unit: 'px',
            width: 250,
          },
          1,
          width,
          height,
        ),
        width,
        height,
      );

      state.setCrop(crop);
    },
    getCroppedImage() {
      const image = imgRef.current!;
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = state.crop.width;
      canvas.height = state.crop.height;
      const ctx = canvas.getContext('2d');
      ctx!.drawImage(
        image,
        state.crop.x * scaleX,
        state.crop.y * scaleY,
        state.crop.width * scaleX,
        state.crop.height * scaleY,
        0,
        0,
        state.crop.width,
        state.crop.height,
      );

      return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error('Canvas is empty'));
            return;
          }

          resolve(blob);
        }, 'image/jpeg');
      });
    },
    async handleSave() {
      try {
        const blob = await state.getCroppedImage();
        onSave(blob);
      } catch (error) {
        state.setCropError('Det gick inte beskära bilden, försök igen.');
      }
    },
    isOverDropZone: false,
    setIsOverDropZone(isOverDropZone) {
      state.isOverDropZone = isOverDropZone;
    },
  }));

  return (
    <div className="flex flex-col items-center">
      <div
        className={`relative flex h-72 w-full items-center justify-center rounded-md border border-dashed 
        ${state.srcImage ? 'bg-black bg-opacity-50' : ''} 
        ${state.isOverDropZone ? 'border-pink-500' : 'border-gray-400'}`}
        onDrop={(e) => {
          e.preventDefault();
          state.setSrcImage(URL.createObjectURL(e.dataTransfer.files[0]));
          state.setIsOverDropZone(false);
        }}
        onDragOver={(e) => {
          e.stopPropagation();
          e.preventDefault();
          state.setIsOverDropZone(true);
        }}
        onDragLeave={() => state.setIsOverDropZone(false)}
      >
        {state.srcImage ? (
          <ReactCrop
            className="max-h-full max-w-full"
            crop={state.crop}
            onChange={state.setCrop}
            aspect={1}
            ruleOfThirds
            circularCrop
          >
            <img
              src={state.srcImage}
              onLoad={state.onImageLoad}
              className="h-72 w-full"
            />
          </ReactCrop>
        ) : (
          <SelectImageFile onChange={state.onSelectFile} />
        )}
        {state.srcImage && (
          <button
            className="absolute right-5 top-5"
            onClick={() => {
              state.setSrcImage(null);
              state.setCropError('');
            }}
          >
            <CrossIcon3 className="w-7 text-white" />
          </button>
        )}
      </div>
      {state.cropError && (
        <div className="w-full">
          <FormError error={state.cropError} />
        </div>
      )}
      {state.srcImage && (
        <button
          className="mt-5 rounded-md bg-indigo-600 px-8 py-2 text-white"
          onClick={state.handleSave}
        >
          Spara
        </button>
      )}
    </div>
  );
});

interface SelectImageFileProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const SelectImageFile: FC<SelectImageFileProps> = observer(({ onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className="flex flex-col items-center justify-center space-y-2 text-gray-900">
      <UploadIcon2 className="w-7" />
      <span>Dra och droppa här</span>
      <span className="text-gray-500">eller</span>
      <button
        onClick={() => inputRef.current?.click()}
        className="rounded-md bg-indigo-600 px-8 py-2 text-white"
      >
        Välj fil
      </button>
      <input
        accept="image/*"
        type="file"
        ref={inputRef}
        onChange={onChange}
        hidden
      />
    </div>
  );
});
