import { IAnyModelType, Instance, types as t } from 'mobx-state-tree';
import { maybeNullReference } from '../types';

export const IssueType = t.model('IssueType', {
  id: t.identifierNumber,
  name: t.string,
  parent: t.maybeNull(t.late((): IAnyModelType => IssueType)),
  children: t.maybeNull(t.array(t.late((): IAnyModelType => IssueType))),
});

export const IssueTypeReference = maybeNullReference(IssueType);
export type IssueTypeInstance = Instance<typeof IssueType>;
