import React, { FC } from 'react';

interface CardProps {
  title: string;
  border?: boolean;
}

export const Card: FC<CardProps> = ({ title, border = false, children }) => {
  return (
    <div
      className={`flex items-center pb-3 md:pb-0 ${
        border
          ? 'border-b border-gray-300 pr-12 md:border-b-0 2xl:border-r'
          : ''
      }`}
    >
      <div className="space-y-1">
        <span className="text-gray-900">{title}</span>
        <div className="flex items-end text-xl leading-6 text-indigo-900">
          {children}
        </div>
      </div>
    </div>
  );
};
