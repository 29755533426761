import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FormEvent } from 'react';
import { useStore } from '../hooks';
import { Select } from './create-issue';
import { CrossIcon3 } from '../icons';
import { localeCompareSv } from '../utils';

interface IdNameObj {
  id: number;
  name: string;
}

interface InitialValues {
  facility: string;
}

interface CopyExistingRoundingState {
  handleClose: () => void;
  formKey: number;
  handleSubmit: (event: FormEvent) => void;
  setSubmitState: (submitState: SubmitState) => void;
  submitState: SubmitState;
  initialValues: InitialValues | null;
}

enum SubmitState {
  None = 'none',
  Pending = 'pending',
  Error = 'error',
  Success = 'success',
}

export const CopyExistingRounding = observer(() => {
  const { organizationStore, uiStore } = useStore();
  const facilities = organizationStore.organization?.facilities as IdNameObj[];

  let facilityId = uiStore.copyToFacilityId;

  const state = useLocalObservable<CopyExistingRoundingState>(() => ({
    submitState: SubmitState.None,
    setSubmitState(submitState) {
      state.submitState = submitState;
    },
    handleClose() {
      uiStore.setCopyToFacilityId(0);
      state.setSubmitState(SubmitState.None);
      uiStore.toggleIsCopyExistingRoundsOpen();
    },
    async handleSubmit(event: FormEvent) {
      event.preventDefault();
      facilityId = uiStore.copyToFacilityId;
      try {
        const { facility } = event.target as any;

        const selectedFacility = facilities.find(
          (f) => f.name === facility.value,
        );

        if (selectedFacility) {
          await organizationStore.copyExistingRounding(
            selectedFacility.id,
            facilityId,
          );
          uiStore.setCopyToFacilityId(0);
          state.setSubmitState(SubmitState.Success);
          uiStore.toggleIsCopyExistingRoundsOpen();
        }
      } catch (error) {
        state.setSubmitState(SubmitState.Error);
      }
    },
    initialValues: null,
    setInitialValues(initialValues: InitialValues) {
      state.initialValues = initialValues;
    },
    formKey: 1,
    setFormKey(key: number) {
      state.formKey = key;
    },
  }));

  return (
    <div className="scrollbar-none flex h-full flex-col overflow-y-auto bg-white px-4 py-4 shadow-md md:px-14 md:py-12">
      <div className="mb-10 flex items-center justify-end md:w-96 md:justify-between">
        <span className="hidden whitespace-nowrap text-3xl font-extrabold text-indigo-900 md:block">
          Kopiera ronderingar
        </span>
        <button onClick={state.handleClose}>
          <CrossIcon3 className="w-7 text-gray-300" />
        </button>
      </div>
      <form
        key={state.formKey}
        onSubmit={state.handleSubmit}
        className="flex flex-grow flex-col justify-between"
      >
        <div>
          <div className="mb-7">
            {uiStore.isCopyExistingRoundingOpen && (
              <Select
                name="facility"
                label="Från fastighet"
                placeholder="Ange fastighet"
                noMatchText="Vi hittade ingen matchande fastighet"
                required
                items={facilities
                  .filter((x) => x.id !== facilityId)
                  .sort((a, b) => localeCompareSv(a.name, b.name))}
                initialValue={state.initialValues?.facility}
                combobox
              />
            )}
          </div>
        </div>
        <button
          className="h-12 w-full rounded-md bg-indigo-600 text-white transition hover:bg-indigo-900"
          type="submit"
          disabled={state.submitState === SubmitState.Pending}
        >
          Kopiera
        </button>
      </form>
    </div>
  );
});
