import { DialogContent, DialogOverlay } from '@reach/dialog';
import React, { FC } from 'react';

interface Props {
  isOpen: boolean;
  onDismiss: () => void;
}

export const Popup: FC<Props> = ({ isOpen, onDismiss, children }) => {
  return (
    <DialogOverlay
      className="absolute top-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-40"
      isOpen={isOpen}
      onDismiss={onDismiss}
    >
      <DialogContent aria-label="dialog" className="focus:outline-none">
        <div className="mx-3 min-w-72 max-w-md rounded-md bg-white p-7 shadow-lg">
          {children}
        </div>
      </DialogContent>
    </DialogOverlay>
  );
};
