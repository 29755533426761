import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  AdditionalInformation,
  DeletePopup,
  FadeInDiv,
  Label,
} from '../components';
import { TrashCanIcon } from '../icons';
import { InputWithError } from '../components/InputWithError';
import { useStore } from '../hooks';
import { CompanyInstance } from '../models';
import { useNavigate, useParams } from 'react-router-dom';
import { CompanyMetaData, SubmitState } from '../types';
import { SaveButton } from '../components/SaveButton';
import { SAVE_BUTTON_ANIMATION_DURATION } from '../constants';

interface CompanyParams {
  companyId: string;
}

export const EditCompanyPage = observer(() => {
  const {
    organizationStore: { organization },
  } = useStore();
  const params = useParams<keyof CompanyParams>() as CompanyParams;

  const companyId = parseInt(params.companyId, 10);
  const company = organization?.companies.find((c) => c.id === companyId);

  if (!company) return null;

  return <EditCompany company={company} />;
});

export interface CompanyFormData {
  orgNumber: string;
  name: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  metaData: { key: string; value: string }[];
}

interface EditCompanyState {
  submitState: SubmitState;
  setSubmitState: (submitState: SubmitState) => void;
  onSubmit: (formData: CompanyFormData) => void;

  isDeletePopupOpen: boolean;
  toggleDeletePopup: () => void;

  handleDeleteCompany: () => void;
}

const EditCompany = observer(({ company }: { company: CompanyInstance }) => {
  const navigate = useNavigate();
  const {
    organizationStore: { organization },
  } = useStore();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm<CompanyFormData>({
    shouldUnregister: true,
    reValidateMode: 'onSubmit',
    defaultValues: {
      name: company.name,
      contactEmail: company.contact_email || '',
      contactName: company.contact_name || '',
      contactPhone: company.contact_phone || '',
      orgNumber: company.organization_number || '',
      metaData: company.meta_data
        ? Array.from(company.meta_data.keys()).map((key) => ({
            key,
            value: company.meta_data?.get(key) || '',
          }))
        : undefined,
    },
  });

  const state = useLocalObservable<EditCompanyState>(() => ({
    submitState: SubmitState.None,
    setSubmitState(submitState) {
      state.submitState = submitState;
    },
    async onSubmit(formData) {
      await company.patch(formData);

      state.setSubmitState(SubmitState.Success);
      setTimeout(() => {
        state.setSubmitState(SubmitState.None);
      }, SAVE_BUTTON_ANIMATION_DURATION);
    },
    isDeletePopupOpen: false,
    toggleDeletePopup() {
      state.isDeletePopupOpen = !state.isDeletePopupOpen;
    },
    async handleDeleteCompany() {
      await company.deactivate();
      navigate('/organization/companies');
    },
  }));

  return (
    <FadeInDiv className="h-full w-full p-4 md:p-14">
      <div className="block rounded-md bg-white p-7 md:inline-block">
        <span className="w-full text-xl font-extrabold text-indigo-900">
          Redigera företag
        </span>
      </div>
      <form onSubmit={handleSubmit(state.onSubmit)}>
        <div className="mt-5 space-y-6 rounded-md bg-white p-7 md:mt-10">
          <div className="max-w-xs">
            <Label>Organisationsnummer</Label>
            <InputWithError
              type="text"
              placeholder="Organisationsnummer"
              {...register('orgNumber', {
                pattern: {
                  value: /\d{6}-\d{4}/,
                  message: 'Ange giltigt format XXXXXX-XXXX',
                },
              })}
              error={errors.orgNumber?.message}
            />
          </div>

          <div className="flex flex-col space-y-6 xl:flex-row xl:space-y-0">
            <div className="max-w-xs flex-1 xl:mr-7">
              <Label required>Namn</Label>
              <InputWithError
                type="text"
                placeholder="Namn"
                {...register('name', {
                  required: 'Fyll i detta fältet',
                  maxLength: {
                    value: 50,
                    message: 'Max 50 tecken',
                  },
                })}
                error={errors.name?.message}
              />
            </div>
            <div className="max-w-xs flex-1">
              <Label>Kontaktperson</Label>
              <input
                type="text"
                className="w-full rounded-md border border-solid py-2 pl-3 outline-none focus:border-indigo-600"
                placeholder="Kontaktperson"
                {...register('contactName', {
                  maxLength: {
                    value: 150,
                    message: 'Max 150 tecken',
                  },
                })}
              />
            </div>
          </div>
          <div className="flex flex-col space-y-6 xl:flex-row xl:space-y-0">
            <div className="max-w-xs flex-1 xl:mr-7">
              <Label> E-post</Label>
              <InputWithError
                type="text"
                placeholder="namn.namnsson@e-post.se"
                {...register('contactEmail', {
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Ange en giltig e-post',
                  },
                })}
                error={errors.contactEmail?.message}
              />
            </div>
            <div className="max-w-xs flex-1">
              <Label>Telefonnummer</Label>
              <input
                type="text"
                className="w-full rounded-md border border-solid py-2 pl-3 outline-none focus:border-indigo-600"
                placeholder="Telefonnummer"
                {...register('contactPhone', {
                  maxLength: {
                    value: 20,
                    message: 'Max 20 tecken',
                  },
                })}
              />
            </div>
          </div>
        </div>
        {organization?.hasEntreFeature && (
          <div className="mt-5 md:mt-7 2xl:max-w-1/2">
            <Controller
              control={control}
              name="metaData"
              render={({ field: { onChange } }) => (
                <AdditionalInformation
                  fields={Object.values(CompanyMetaData).map((key) => ({
                    key,
                    value: company.meta_data?.get(key) || '',
                  }))}
                  onChange={onChange}
                />
              )}
            />
          </div>
        )}
        <div className="mt-5 flex items-center md:mt-7">
          <SaveButton
            disabled={isSubmitting || state.submitState === SubmitState.Success}
            isSaved={state.submitState === SubmitState.Success}
          />
          <button
            disabled={state.submitState === SubmitState.Pending}
            type="button"
            className="ml-5 flex items-center justify-center rounded-md bg-rose-300 p-3 transition duration-200 hover:bg-rose-500"
            onClick={state.toggleDeletePopup}
          >
            <TrashCanIcon className="mr-3 w-4 text-white" />
            <span className="font-semibold text-white">Radera</span>
          </button>
        </div>
      </form>
      {state.isDeletePopupOpen && (
        <DeletePopup
          title="Radera företag"
          onConfirm={state.handleDeleteCompany}
          onDismiss={state.toggleDeletePopup}
        >
          Om du raderar företaget går det inte att återställas. Är du säker på
          att du vill radera företaget?
        </DeletePopup>
      )}
    </FadeInDiv>
  );
});
