import React, { FC, memo, SVGProps } from 'react';

export const RoundingIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 17 21"
    className="w-4"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.25 2h-1.925A3 3 0 009.5 0h-2a3 3 0 00-2.825 2H2.75A2.75 2.75 0 000 4.75v13.5A2.75 2.75 0 002.75 21h11.5A2.75 2.75 0 0017 18.25V4.75A2.75 2.75 0 0014.25 2zM6 3a1.5 1.5 0 011.5-1.5h2A1.5 1.5 0 0111 3v.5H6V3zm9.5 15.25a1.25 1.25 0 01-1.25 1.25H2.75a1.25 1.25 0 01-1.25-1.25V4.75A1.25 1.25 0 012.75 3.5H4.5v.75a.75.75 0 00.75.75h6.5a.75.75 0 00.75-.75V3.5h1.75a1.25 1.25 0 011.25 1.25v13.5z"
    ></path>
    <path
      fill="currentColor"
      d="M5.25 6.5a2.25 2.25 0 100 4.498 2.25 2.25 0 000-4.498zm0 3.08v-.001a.829.829 0 110-1.658.829.829 0 010 1.658zM13.205 8h-3.91a.805.805 0 00-.688.375.713.713 0 000 .75.805.805 0 00.688.375h3.91a.805.805 0 00.688-.375.713.713 0 000-.75.805.805 0 00-.688-.375zM5.25 13c-.597 0-1.17.264-1.591.732A2.648 2.648 0 003 15.5c0 .663.237 1.299.659 1.768a2.14 2.14 0 001.59.732c.598 0 1.17-.263 1.592-.732A2.648 2.648 0 007.5 15.5a2.65 2.65 0 00-.66-1.767A2.145 2.145 0 005.25 13zm0 3.421a.79.79 0 01-.586-.27.975.975 0 01-.243-.65c0-.245.087-.48.243-.652a.79.79 0 01.586-.27c.22 0 .43.097.586.27a.975.975 0 01.243.651.975.975 0 01-.243.651.79.79 0 01-.586.27zM13.205 15h-3.91a.805.805 0 00-.688.375.713.713 0 000 .75.805.805 0 00.688.375h3.91a.805.805 0 00.688-.375.713.713 0 000-.75.805.805 0 00-.688-.375z"
    ></path>
  </svg>
));
