import React, { FC, memo, SVGProps } from 'react';

export const HighFiveIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 83.98 89.85"
    fill="currentColor"
    className="w-4"
    {...props}
  >
    <g>
      <g>
        <path d="M12.83 12.9l6.2 4a1.45 1.45 0 00.7.2 1.1 1.1 0 001-.6 1.19 1.19 0 00-.4-1.7l-6.2-4a1.23 1.23 0 00-1.3 2.1z" />
        <path d="M19.73 17.35a1.62 1.62 0 01-.81-.23l-6.23-4a1.57 1.57 0 01-.68-.93 1.37 1.37 0 01.21-1.12 1.58 1.58 0 01.92-.68 1.44 1.44 0 011.13.21l6.19 4a1.43 1.43 0 01.47 2.05 1.33 1.33 0 01-1.2.7zM13 12.69l6.2 4a1.2 1.2 0 00.57.16.83.83 0 00.77-.46 1 1 0 00-.35-1.39L14 11a1 1 0 00-1.36.33.9.9 0 00-.14.72 1.08 1.08 0 00.5.64zM27.43 11.9a1.21 1.21 0 001.2.8.6.6 0 00.4-.1 1.31 1.31 0 00.8-1.6l-3.4-9.9a1.31 1.31 0 00-1.6-.8A1.31 1.31 0 0024 1.9z" />
        <path d="M28.63 13a1.44 1.44 0 01-1.43-1L23.79 2a1.56 1.56 0 011-1.92 1.55 1.55 0 011.91 1l3.4 9.9a1.56 1.56 0 01-.91 1.9.85.85 0 01-.56.12zm-1-1.14a1 1 0 001 .64.49.49 0 00.22 0l.1-.06a1.06 1.06 0 00.64-1.29l-3.4-9.89A1.06 1.06 0 0024.9.54a1.06 1.06 0 00-.63 1.29zM38.23 11a.6.6 0 00.4.1 1.08 1.08 0 001.1-.8l2.5-6.9a1.35 1.35 0 00-.7-1.6 1.35 1.35 0 00-1.6.7l-2.5 6.9a1.31 1.31 0 00.8 1.6z" />
        <path d="M38.63 11.35a.88.88 0 01-.53-.13 1.55 1.55 0 01-.91-1.89l2.5-6.92a1.63 1.63 0 011.92-.85 1.59 1.59 0 01.85 1.92L40 10.38a1.31 1.31 0 01-1.37.97zm-.23-.53a.56.56 0 00.23 0 .84.84 0 00.85-.6L42 3.31A1.1 1.1 0 0041.42 2a1.11 1.11 0 00-1.27.59l-2.49 6.89a1 1 0 00.65 1.28zM47 80a1.24 1.24 0 00-1.5.8l-2.1 7a1.24 1.24 0 00.8 1.5c.1 0 .2.1.4.1a1.26 1.26 0 001.2-.9l2.1-7A1.31 1.31 0 0047 80z" />
        <path d="M44.63 89.65a1 1 0 01-.37-.08h-.07a1.49 1.49 0 01-1-1.82l2.1-7a1.51 1.51 0 01.72-.85 1.4 1.4 0 011.09-.12 1.56 1.56 0 011.07 1.78l-2.1 7a1.52 1.52 0 01-1.44 1.09zm-.35-.6a.48.48 0 01.18.06.43.43 0 00.17 0 1 1 0 001-.73l2.1-7a1.07 1.07 0 00-.73-1.14 1 1 0 00-1.19.64l-2.09 7a1 1 0 00.56 1.17zM57.43 78.2a1.23 1.23 0 00-2.3.9l4 9.7a1.27 1.27 0 001.1.8.9.9 0 00.5-.1 1.35 1.35 0 00.7-1.6z" />
        <path d="M60.23 89.85a1.54 1.54 0 01-1.34-1l-4-9.7a1.48 1.48 0 012.75-1.1l4 9.7a1.62 1.62 0 01-.82 1.92 1.11 1.11 0 01-.59.18zm-4-12.16a.79.79 0 00-.3 0 1.08 1.08 0 00-.57 1.31l4 9.68a1 1 0 00.87.65.7.7 0 00.39-.07 1.12 1.12 0 00.57-1.3l-4-9.68a1.12 1.12 0 00-.98-.59zM72 75.8l-6.4-3.6a1.25 1.25 0 00-1.2 2.2l6.4 3.6a1.42 1.42 0 00.6.2 1.38 1.38 0 001.1-.6 1.41 1.41 0 00-.5-1.8z" />
        <path d="M71.43 78.45a1.57 1.57 0 01-.71-.23l-6.42-3.6A1.5 1.5 0 0165.74 72l6.43 3.61a1.67 1.67 0 01.59 2.11 1.64 1.64 0 01-1.33.73zm-6.38-6.14a1.1 1.1 0 00-.92.53 1.07 1.07 0 00.44 1.35L71 77.78a1.16 1.16 0 00.48.17 1.14 1.14 0 00.9-.5A1.16 1.16 0 0071.9 76l-6.4-3.59a.91.91 0 00-.45-.1zM49.6 61.44l-.08-.17a5.57 5.57 0 01-.34-4.27 9 9 0 00-.54-6.77l-.07-.15L42.54 38 40 32.91l-1.29-2.58a5.62 5.62 0 00-5.57-3.1 5.75 5.75 0 00-2 .56 5.44 5.44 0 00-1.85 1.48l-.74-1.46a5.64 5.64 0 00-7.48-2.67A5.76 5.76 0 0019 26.86 5.65 5.65 0 0014.33 25a5.44 5.44 0 00-2 .56 5.64 5.64 0 00-2.83 3.25 5.73 5.73 0 00-.18 2.93 4.19 4.19 0 00-.5 0 5.61 5.61 0 00-4.59 8v.08l8.35 16.8-4.08-1.31-1-.34a5.91 5.91 0 00-2.36-.27 5.67 5.67 0 00-2.08.61 5.61 5.61 0 00.78 10.33l8.53 2.87c8.17 2.75 8.17 2.75 10.22 3.32.24.06.52.14.83.24l1.4 2.82a3.45 3.45 0 003.39 1.89 3.53 3.53 0 001.22-.34L38 72.17 48.5 67a3.43 3.43 0 001.55-4.6zM27.91 73.36l-1.61-3.25a2.4 2.4 0 00-1.47-1.23c-4.34-1.23-.78-.08-19.89-6.5a2.18 2.18 0 01-.31-4 2.08 2.08 0 01.84-.24 2.31 2.31 0 01.94.1l8.9 3a1.93 1.93 0 00.74.09 1.78 1.78 0 001.42-2.56L7.34 38.35a2.19 2.19 0 011-2.88 2.26 2.26 0 01.82-.24 2.17 2.17 0 012.15 1.17l7 14a1.79 1.79 0 001.75 1 1.63 1.63 0 00.64-.19 1.78 1.78 0 00.77-2.4l-4.69-9.42-2.34-4.59-1.57-3.17a2.2 2.2 0 011-2.95 2 2 0 01.78-.22 2.22 2.22 0 012.17 1.21C26 48.15 25.36 47 25.63 47.23a1.79 1.79 0 00.73.48 1.82 1.82 0 00.72.08 1.78 1.78 0 001.44-2.56l-6.95-14v-.09a2.21 2.21 0 011-2.93 2.47 2.47 0 01.74-.2 2.19 2.19 0 012.19 1.26l6.94 14a1.78 1.78 0 001.74 1 1.83 1.83 0 00.66-.19 1.77 1.77 0 00.75-2.39l-3.9-7.87a2.19 2.19 0 011-2.95 2.16 2.16 0 01.77-.21 2.19 2.19 0 012.18 1.2L39 38.45l2.51 5.06 4.1 8.25a5.74 5.74 0 01.58 2A5.65 5.65 0 0145.9 56a9.83 9.83 0 00-.29 1.19 9.19 9.19 0 00.83 5.65l.56 1.03c-13.07 6.48-9.68 4.82-19.09 9.49z" />
        <path d="M83.87 42.3a5.4 5.4 0 00-4.23-4.25A5.25 5.25 0 0078 38a5.72 5.72 0 00-2.59.91l-.84.57-2.72 1.83L75.05 25a.25.25 0 010-.07 5.46 5.46 0 00-4.22-6.26 5.51 5.51 0 00-1.67-.11c-.2 0-.41.05-.61.09A5.47 5.47 0 0064.2 14a5.75 5.75 0 00-1.55-.09 5.49 5.49 0 00-4.21 2.69 5.37 5.37 0 00-2.15-.87 5.21 5.21 0 00-1.42-.06A5.48 5.48 0 0050 20.11l-.21 1.07a5.78 5.78 0 00-1.79-.67 5.37 5.37 0 00-1.55-.08 5.49 5.49 0 00-4.86 4.39L40 32.91l-1 5.54 2.51 5.06L42.54 38 45 25.48a2 2 0 011.8-1.63 2.33 2.33 0 01.58 0A2 2 0 0149 26.26l-1.55 7.91a1.63 1.63 0 001.26 1.94 1.59 1.59 0 00.48 0 1.67 1.67 0 001.46-1.29v-.06l2.76-14.06a2 2 0 011.81-1.67 2.43 2.43 0 01.53 0 2 2 0 011.64 2.33v.09l-2.81 14.13a1.64 1.64 0 001.76 1.94 1.66 1.66 0 00.75-.27 1.69 1.69 0 00.54-.59c.16-.32-.11.91 3.54-17.72A2 2 0 0163 17.32a1.82 1.82 0 01.57 0 2 2 0 011.6 2.37l-.63 3.19-.9 4.58L61.75 37A1.63 1.63 0 0063 38.93a1.68 1.68 0 00.5 0 1.62 1.62 0 001.44-1.28.09.09 0 000-.05l2.76-14.06A2 2 0 0169.5 22a1.8 1.8 0 01.61 0 2 2 0 011.56 2.31l-3.73 19.08h-3.27a1.74 1.74 0 00-1.16.44l-2.33 2.09a1.72 1.72 0 001.3 3 1.66 1.66 0 001-.44l1.84-1.64h4.11a1.72 1.72 0 00.76-.28l7.17-4.81a2 2 0 011-.35 1.93 1.93 0 01.63 0 2 2 0 01.7 3.62c-15.38 10.34-12.5 8.46-16 10.6a2.19 2.19 0 00-1 1.44L62 60.35l-13-2.53-3.37-.67a9.06 9.06 0 00.08 3.52l3.91.77 3.06.6 8.65 1.69a3.52 3.52 0 001 .05 3.43 3.43 0 003-2.76l.54-2.73.56-.34c1.7-1 1.7-1 8.27-5.43l6.87-4.63a5.45 5.45 0 002.3-5.59z" />
      </g>
    </g>
  </svg>
));
