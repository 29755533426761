import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@reach/accordion';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect, useLayoutEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import {
  AccountTypeTag,
  Avatar,
  FadeInDiv,
  Pagination,
  TableHeaderCell,
} from '../components';
import { AddUserIcon, ChevronDownIcon, EditIcon } from '../icons';
import { USERS_PER_PAGE } from '../constants';
import { useHorizontalDragRef, useStore } from '../hooks';
import { UserInstance } from '../models';
import { AccountType } from '../types';
import { localeCompareSv } from '../utils';

// Higher index in the array equals higher priority.
const ACCOUNT_PRIORITY = [
  AccountType.Worker,
  AccountType.Parking,
  AccountType.Admin,
  AccountType.Organization,
];

type Filter = '' | 'type' | 'name' | 'email' | 'phone_number';

type State = {
  indices: number[];
  toggleItem: (toggledIndex: number) => void;

  query: URLSearchParams;
  currentPage: number;
  filter: Filter;
  isAscending: boolean;
  handleSearchChange: (search: string) => void;
  setFilter: (newFilter: Filter) => void;

  numPages: number;
  startIndex: number;
  endIndex: number;
  setCurrentPage: (page: number) => void;

  sortedUsers: UserInstance[];
  filteredUsers: UserInstance[];

  canCreateAndEdit: boolean;
};

export const OrganizationPage = observer(() => {
  const { organizationStore, userStore, uiStore } = useStore();
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useHorizontalDragRef();

  const state = useLocalObservable<State>(() => ({
    indices: [],
    toggleItem(toggledIndex) {
      const index = state.indices.indexOf(toggledIndex);
      if (index !== -1) {
        state.indices.splice(index, 1);
      } else {
        state.indices.push(toggledIndex);
      }
    },

    query: new URLSearchParams(location.search),
    get currentPage(): number {
      const pageParam = state.query.get('page');

      return pageParam ? Number(pageParam) : 1;
    },
    get filter(): Filter {
      const sortParam = state.query.get('sort');

      return (sortParam ? sortParam.replace('-', '') : '') as Filter;
    },
    get isAscending(): boolean {
      const sortParam = state.query.get('sort');

      return sortParam !== null && sortParam[0] !== '-';
    },
    handleSearchChange(search) {
      state.query = new URLSearchParams(search);
      // We want to close all accordion items when
      // the user changes the filter or page.
      state.indices = [];
    },
    setFilter(newFilter) {
      const { query, filter, isAscending } = state;
      const searchParams = new URLSearchParams(query);
      searchParams.delete('page');

      if (filter === newFilter) {
        if (isAscending) {
          searchParams.delete('sort');
        } else {
          searchParams.set('sort', filter);
        }
      } else {
        searchParams.set('sort', `-${newFilter}`);
      }

      navigate(`${location.pathname}?${searchParams.toString()}`);
    },

    get numPages() {
      return Math.ceil(userStore.users!.length / USERS_PER_PAGE);
    },
    get startIndex(): number {
      return (state.currentPage - 1) * USERS_PER_PAGE;
    },
    get endIndex(): number {
      return state.startIndex + USERS_PER_PAGE;
    },
    setCurrentPage(page) {
      const searchParams = new URLSearchParams(state.query);

      if (page === 1) {
        searchParams.delete('page');
      } else {
        searchParams.set('page', `${page}`);
      }

      navigate(`${location.pathname}?${searchParams.toString()}`);
    },

    get sortedUsers(): UserInstance[] {
      const users = userStore.users!.slice();
      const { filter, isAscending } = state;

      if (filter === '') return users;

      if (filter === 'type') {
        return users.sort((a, b) => {
          if (isAscending) [b, a] = [a, b];

          return (
            ACCOUNT_PRIORITY.indexOf(b.role) - ACCOUNT_PRIORITY.indexOf(a.role)
          );
        });
      }

      if (filter === 'phone_number') {
        return users.sort((a, b) => {
          if (isAscending) [b, a] = [a, b];

          const aPhoneNumber = a.phone_number;
          const bPhoneNumber = b.phone_number;

          if (!aPhoneNumber && !bPhoneNumber) {
            return 0;
          } else if (aPhoneNumber && !bPhoneNumber) {
            return 1;
          } else if (!aPhoneNumber && bPhoneNumber) {
            return -1;
          }

          return localeCompareSv(bPhoneNumber!, aPhoneNumber!);
        });
      }

      return users.sort((a, b) => {
        if (isAscending) [b, a] = [a, b];

        return localeCompareSv(b[filter], a[filter]);
      });
    },
    get filteredUsers(): UserInstance[] {
      const { sortedUsers, startIndex, endIndex } = state;

      return sortedUsers.slice(startIndex, endIndex);
    },
    get canCreateAndEdit() {
      return userStore.me!.role >= AccountType.Admin;
    },
  }));

  useLayoutEffect(() => {
    state.handleSearchChange(location.search);
  }, [location.search]);

  useEffect(() => {
    userStore.fetchUsers();
  }, []);

  if (userStore.users === null) return null;

  return (
    <FadeInDiv className="flex h-full flex-col bg-neutral-100">
      <div
        ref={ref}
        className="scrollbar-none mb-5 w-full flex-shrink-0 overflow-x-auto whitespace-nowrap px-4 md:mb-0 md:flex md:flex-col md:overflow-hidden md:px-14 md:pb-10 md:pt-14 lg:flex-row lg:space-x-9"
      >
        <div className="mr-5 inline-block rounded-md bg-white px-7 py-7 md:mr-0 md:mt-2 lg:mt-0">
          <div className="flex flex-col">
            <span className="text-base font-semibold text-gray-900">
              Organisation
            </span>
            <span className="mt-1 text-xl font-extrabold text-indigo-900">
              {organizationStore.organization?.name}
            </span>
          </div>
        </div>
        <div className="mr-4 inline-block w-80 rounded-md bg-white px-7 py-7 md:mt-2 md:w-full md:min-w-0 lg:mt-0 lg:max-w-xs">
          <div className="flex flex-col">
            <span className="whitespace-nowrap text-base font-semibold text-gray-900">
              Antal användare
            </span>
            <span className="mt-1 text-xl font-extrabold text-indigo-900">
              {userStore.users?.length}
            </span>
          </div>
        </div>
        {state.canCreateAndEdit && (
          <div className="mt-2 hidden flex-col justify-end md:flex lg:mr-10">
            <Link
              className="text flex items-center justify-center rounded-md bg-white p-3 text-gray-900 transition duration-300 hover:bg-indigo-600 hover:text-white"
              to={`/organization/create-user`}
            >
              <AddUserIcon className="h-5 w-5" />
              <span className="ml-3 text-base font-semibold">
                Skapa ny användare
              </span>
            </Link>
          </div>
        )}
      </div>
      {uiStore.isMobile ? (
        <div className="mx-4 flex-1">
          {state.canCreateAndEdit && (
            <Link
              className="mb-5 flex w-full items-center justify-center rounded-md bg-white p-3 text-gray-900 hover:bg-indigo-600 hover:text-white"
              to={`/organization/create-user`}
            >
              <AddUserIcon className="h-5 w-5" />
              <span className="ml-3 text-base font-semibold">
                Skapa ny användare
              </span>
            </Link>
          )}

          <Accordion
            index={state.indices}
            onChange={state.toggleItem}
            className="w-full rounded-md bg-white font-semibold text-gray-900"
          >
            <div className="border-b border-gray-200 px-7 py-4">
              <span className="font-semibold">Användare</span>
            </div>
            {state.filteredUsers.map((user, index) => (
              <AccordionItem
                key={user.id}
                className={`${
                  index !== state.filteredUsers.length - 1
                    ? 'border-b border-gray-200'
                    : ''
                }`}
              >
                <AccordionButton className="flex w-full items-center justify-between px-7 py-4 focus:outline-none">
                  <h3 className="break-all">{user.name}</h3>
                  <ChevronDownIcon
                    className={`w-6 flex-shrink-0 transform transition-transform ${
                      state.indices.includes(index) ? 'rotate-180' : ''
                    }`}
                  />
                </AccordionButton>

                <AccordionPanel className="space-y-4 border-t border-gray-200 bg-gray-50 px-7 py-7 outline-none">
                  <div className="flex items-center">
                    <span className="mr-2 w-28 text-sm font-semibold text-gray-900">
                      Profilbild
                    </span>
                    <Avatar user={user} border />
                  </div>
                  <div className="flex items-center">
                    <span className="mr-2 w-28 text-sm font-semibold text-gray-900">
                      Roll
                    </span>
                    <div className="bg-white">
                      <AccountTypeTag type={user.role} />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <span className="mr-2 w-28 whitespace-nowrap text-sm font-semibold text-gray-900">
                      E-post
                    </span>
                    <span className="break-all font-normal text-gray-900">
                      {user.email}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <span className="mr-2 w-28 whitespace-nowrap text-sm font-semibold text-gray-900">
                      Telefonnummer
                    </span>
                    <span className="break-all font-normal text-gray-900">
                      {user.phone_number}
                    </span>
                  </div>
                  {state.canCreateAndEdit && (
                    <Link
                      key={user.name}
                      className="mt-7 flex justify-center rounded-md bg-white p-3 text-black transition duration-300 hover:bg-indigo-600 hover:text-white"
                      to={`/organization/edit-user/${user.id}`}
                    >
                      <EditIcon />
                      <span className="ml-3 font-semibold">
                        Redigera användare
                      </span>
                    </Link>
                  )}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      ) : (
        <div className="mx-14 flex-1">
          <table className="w-full rounded-md bg-white">
            <thead>
              <tr>
                <TableHeaderCell
                  title="Roll"
                  isActive={state.filter === 'type'}
                  isAscending={state.isAscending}
                  onClick={() => state.setFilter('type')}
                />
                <TableHeaderCell title="Profilbild" />
                <TableHeaderCell
                  title="Namn"
                  isActive={state.filter === 'name'}
                  isAscending={state.isAscending}
                  onClick={() => state.setFilter('name')}
                />
                <TableHeaderCell
                  className="hidden lg:table-cell"
                  title="E-post"
                  isActive={state.filter === 'email'}
                  isAscending={state.isAscending}
                  onClick={() => state.setFilter('email')}
                />
                <TableHeaderCell
                  className="hidden xl:table-cell"
                  title="Telefonnummer"
                  isActive={state.filter === 'phone_number'}
                  isAscending={state.isAscending}
                  onClick={() => state.setFilter('phone_number')}
                />
                <th></th>
              </tr>
            </thead>
            <tbody>
              {state.filteredUsers.map((user, index, arr) => (
                <tr
                  key={user.id}
                  className={`border-t border-gray-200 ${
                    index % 2 === 0 ? 'bg-gray-50' : ''
                  } ${index !== arr.length - 1 ? 'border-b' : ''}`}
                >
                  <td className="h-16 min-w-44 py-3 pl-7">
                    <div className="my-1 mr-2 inline-block">
                      <AccountTypeTag type={user.role} />
                    </div>
                    {user.role === AccountType.Organization && (
                      <div className="my-1 inline-block">
                        <AccountTypeTag type={AccountType.Admin} />
                      </div>
                    )}
                  </td>
                  <td className="py-3 pl-7 ">
                    <Avatar user={user} border />
                  </td>
                  <td className="h-16 min-w-36 py-5 pl-7">
                    <span>{user.name}</span>
                  </td>
                  <td className="hidden min-w-48 break-all py-5 pl-7 lg:table-cell">
                    {user.email}
                  </td>
                  <td className="hidden min-w-48 break-all py-5 pl-7 xl:table-cell">
                    {user.phone_number}
                  </td>
                  <td className="px-7 text-right">
                    {state.canCreateAndEdit && (
                      <Link
                        key={user.name}
                        className="inline-block rounded-md bg-white p-3 text-black transition duration-300 hover:bg-indigo-600 hover:text-white"
                        to={`/organization/edit-user/${user.id}`}
                      >
                        <EditIcon />
                      </Link>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="pb-9 pt-5 md:pb-12 md:pt-7">
        {state.numPages > 1 && (
          <Pagination
            page={state.currentPage}
            numPages={state.numPages}
            onChange={state.setCurrentPage}
          />
        )}
      </div>
    </FadeInDiv>
  );
});
