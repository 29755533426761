import { endOfDay, startOfDay } from 'date-fns';
import { types as t, Instance, flow } from 'mobx-state-tree';
import { withRequest } from '../extensions';
import { FacilityFormValues, RoundingStepForm } from '../pages';
import { maybeNullReference } from '../types';
import { RoundingStep } from './RoundingStep';
import { User } from './User';
import { IssueType } from './IssueType';

const Assignment = t.model('Assignment', {
  id: t.identifierNumber,
  issue_types: t.array(maybeNullReference(IssueType)),
  rounding_types: t.array(t.string),
  user: maybeNullReference(User),
});

export const Facility = t
  .model('Facility', {
    id: t.identifierNumber,
    name: t.string,
    is_active: t.boolean,
    assignments: t.array(Assignment),
    rounding_steps: t.array(RoundingStep),
    meta_data: t.maybeNull(t.map(t.string)),
    roundings_to_copy: t.array(RoundingStep),
    roundings_to_remove: t.array(t.integer),
  })
  .extend(withRequest)
  .views((self) => {
    return {
      get filteredRoundingSteps() {
        return self.rounding_steps.filter((step) => step.deleted_at === null);
      },
      get getCopyRoundings() {
        return self.roundings_to_copy;
      },
    };
  })
  .actions((self) => {
    const { request } = self;

    return {
      deactivate: flow(function* () {
        try {
          yield request({
            method: 'patch',
            url: `/api/facilities/${self.id}/`,
            data: {
              is_active: false,
            },
          });
          self.is_active = false;
        } catch (error) {
          // No need to handle this error since the general error
          // message will be shown to the user and they can try again.
        }
      }),
      resetRoundingsToCopy: () => {
        self.roundings_to_copy.clear();
      },
      copyRoundings: (copyRoundings: any) => {
        copyRoundings.forEach((x: any) => {
          self.roundings_to_copy.push(x);
        });
      },
      appendRoundingToRemove: (step: any) => {
        self.roundings_to_remove.push(step);
      },
      save: flow(function* (
        formValues: FacilityFormValues,
        dirtyValues: RoundingStepForm[],
      ) {
        const { facilityName, assignments, roundingSteps, metaData } =
          formValues;

        const stepsToEdit: any[] = [];

        if (dirtyValues.length > 0) {
          dirtyValues.map((x: any) => {
            stepsToEdit.push({
              id: x.id,
              company: x.company ? x.company.value : '',
              from_dt: startOfDay(x.period.fromDate),
              instruction: x.instruction,
              interval: Number(x.interval),
              interval_type: x.intervalType.value,
              name: x.name,
              rounding_type: x.roundingType.value,
              setpoints: x.setpoints,
              to_dt: endOfDay(x.period.toDate),
            });
          });
        }

        const stepsToAdd = roundingSteps
          .filter((rs) => !rs.isSaved)
          .map((s) => {
            return {
              name: s.name,
              setpoints: s.setpoints,
              instruction: s.instruction,
              from_dt: startOfDay(s.period.fromDate),
              to_dt: endOfDay(s.period.toDate),
              interval: Number(s.interval),
              interval_type: s.intervalType.value,
              company: s.company ? s.company.value : '',
              rounding_type: s.roundingType.value,
            };
          });

        const data = {
          name: facilityName,
          assignments,
          meta_data: metaData?.reduce(
            (prev, current) => ({
              ...prev,
              [current.key]: current.value,
            }),
            {},
          ),
        };

        try {
          // We need update the facility before we update the rounding steps,
          // to make sure the rounding steps gets the correct assignment.
          const {
            data: { name, assignments, meta_data },
          } = yield request({
            method: 'PATCH',
            url: `/api/facilities/${self.id}/`,
            data,
          });

          self.name = name;
          self.assignments = assignments;
          self.meta_data = meta_data;
        } catch (error) {
          // No need to handle this error since the general error
          // message will be shown to the user and they can try again.
        }

        const promises: Promise<any>[] = [];

        if (stepsToAdd.length > 0) {
          promises.push(
            request({
              method: 'POST',
              url: `/api/facilities/${self.id}/rounding_steps/`,
              data: stepsToAdd,
            }),
          );
        }

        self.roundings_to_remove.forEach((id: number) => {
          promises.push(
            request({
              method: 'DELETE',
              url: `/api/facilities/${self.id}/rounding_steps/${id}`,
            }),
          );
        });

        if (stepsToEdit.length > 0) {
          stepsToEdit.forEach((step) => {
            promises.push(
              request({
                method: 'PUT',
                url: `/api/facilities/${self.id}/rounding_steps/${step.id}/`,
                data: step,
              }),
            );
          });
        }

        try {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [stepsToAdd, ...rest] = yield Promise.all(promises);

          stepsToEdit.forEach((x) => {
            const index = self.rounding_steps.findIndex((r) => r.id === x.id);
            self.rounding_steps[index] = x;
          });

          self.roundings_to_remove.map((x) => {
            const rounding = self.rounding_steps.find((r) => r.id == x);
            if (rounding) rounding.deleted_at = new Date();
          });

          if (stepsToAdd?.data) {
            stepsToAdd.data.forEach((step: any) => {
              self.rounding_steps.push(step);
            });
          }

          self.roundings_to_remove.length = 0;
        } catch (error) {
          // No need to handle this error since the general error
          // message will be shown to the user and they can try again.
        }
      }),
    };
  });

export type FacilityInstance = Instance<typeof Facility>;
export type AssignmentInstance = Instance<typeof Assignment>;
export const FacilityReference = maybeNullReference(Facility);
