import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  PriorityTag,
  Pagination,
  TableHeaderCell,
  IssueDetailsButton,
  FadeInDiv,
  Avatar,
  SlideInMenu,
  ActiveFilters,
} from '../components';
import { ISSUES_PER_PAGE } from '../constants';
import { useStore } from '../hooks';
import { FilterIcon, UploadIcon, UserSwitchIcon } from '../icons';
import { ClosedIssuesSortFilter, IssuesFilter } from '../types';
import { formatDate } from '../utils';
import { IssuesFilterPicker } from '../components/IssuesFilterPicker';
import { IssueFilterInstance } from '../models';

type State = {
  search: string;
  setSearch: (search: string) => void;

  setFilter: (newFilter: ClosedIssuesSortFilter) => void;
  toggleMineFilter: () => void;
  isMineFilterActive: boolean;

  startIndex: number;
  endIndex: number;
  setCurrentPage: (page: number) => void;
};

export const ClosedIssuesPage = observer(() => {
  const { organizationStore, userStore, issueStore, uiStore } = useStore();
  const location = useLocation();
  const navigate = useNavigate();

  const state = useLocalObservable<State>(() => ({
    search: location.search,
    setSearch(search) {
      state.search = search;
    },

    setFilter(newFilter: ClosedIssuesSortFilter) {
      const {
        closedIssuesQuery,
        closedIssuesSortFilter,
        closedIssuesIsAscending,
      } = issueStore;
      const searchParams = new URLSearchParams(closedIssuesQuery);
      searchParams.delete('page');

      if (closedIssuesSortFilter === newFilter) {
        if (closedIssuesIsAscending) {
          searchParams.delete('sort');
        } else {
          searchParams.set('sort', closedIssuesSortFilter);
        }
      } else {
        searchParams.set('sort', `-${newFilter}`);
      }
      navigate(`${location.pathname}?${searchParams.toString()}`);
    },
    toggleMineFilter() {
      const { me } = userStore;
      const searchParams = new URLSearchParams(state.search);

      const hasAssignedToFilter = searchParams.has(IssuesFilter.AssignedTo);
      const currentFilter = searchParams.get(IssuesFilter.AssignedTo);

      searchParams.delete(IssuesFilter.AssignedTo);

      if (currentFilter !== me?.id.toString() || !hasAssignedToFilter) {
        searchParams.append(IssuesFilter.AssignedTo, me?.id.toString() || '');
      }

      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    },
    get isMineFilterActive() {
      const searchParams: URLSearchParams = new URLSearchParams(state.search);
      return (
        searchParams.get(IssuesFilter.AssignedTo) ===
        userStore.me?.id.toString()
      );
    },

    get startIndex(): number {
      return (issueStore.closedIssuesPage - 1) * ISSUES_PER_PAGE;
    },
    get endIndex(): number {
      return state.startIndex + ISSUES_PER_PAGE;
    },
    setCurrentPage(page) {
      const searchParams = new URLSearchParams(issueStore.closedIssuesQuery);

      if (page === 1) {
        searchParams.delete('page');
      } else {
        searchParams.set('page', `${page}`);
      }
      navigate(`${location.pathname}?${searchParams.toString()}`);
    },
  }));

  useLayoutEffect(() => {
    state.setSearch(location.search);
    issueStore.handleClosedIssuesSearchChange(location.search);
  }, [location.search]);

  const { closedIssues, numClosedIssuesPages } = issueStore;

  if (
    organizationStore.organization === null ||
    userStore.users === null ||
    closedIssues === null
  ) {
    return null;
  }

  return (
    <FadeInDiv className="flex h-full flex-col">
      <div className="mx-4 mb-5 rounded-md bg-white px-7 py-7 md:mx-14 md:mb-10 md:mt-14 lg:max-w-xs">
        <div className="flex flex-col">
          <span className="font-semibold text-gray-900">Åtgärdade</span>
          <span className="mt-1 text-xl font-extrabold text-indigo-900">
            {issueStore.numClosedIssues}
          </span>
        </div>
      </div>
      <div className="mx-4 mb-3 flex justify-between space-x-6 space-y-2 md:mx-14">
        <div className="flex min-w-0 flex-wrap items-center space-y-2 whitespace-nowrap">
          <SlideInMenu
            title="Filter"
            icon={
              <div className="mr-2 mt-2 flex items-center space-x-2 px-1.5 py-0.5 text-sm text-gray-900 md:mr-4">
                <FilterIcon /> <span>Filter</span>
              </div>
            }
            isMenuExpanded={uiStore.isIssuesFilterPickerOpen}
            onClick={uiStore.toggleIsIssuesFilterPickerOpen}
          >
            <IssuesFilterPicker />
          </SlideInMenu>
          {issueStore.closedIssuesActiveFilters !== null && (
            <ActiveFilters<IssueFilterInstance>
              filters={issueStore.closedIssuesActiveFilters}
            />
          )}
        </div>
        <div className="flex items-start space-x-1 text-sm md:space-x-4">
          <button
            className={`flex items-center space-x-1 rounded px-1.5 py-0.5 transition-colors duration-200 hover:bg-pink-500 hover:text-white ${
              state.isMineFilterActive ? 'text-pink-500' : ''
            }`}
            onClick={state.toggleMineFilter}
          >
            <span>{state.isMineFilterActive ? 'Mina' : 'Alla'}</span>
            <UserSwitchIcon />
          </button>
          <button
            onClick={issueStore.downloadClosedIssuesCSV}
            className="flex items-center space-x-1 rounded-md px-1.5 py-0.5 text-gray-900 transition-colors duration-200 hover:bg-gray-900 hover:text-white"
          >
            <span>Exportera</span>
            <UploadIcon />
          </button>
        </div>
      </div>
      {closedIssues.length !== 0 ? (
        <div className="mx-4 flex-1 md:mx-14">
          <table className="w-full rounded-md bg-white">
            <thead>
              <tr className="text-left">
                <TableHeaderCell
                  title="Fastighet"
                  isActive={
                    issueStore.closedIssuesSortFilter ===
                    ClosedIssuesSortFilter.Facility
                  }
                  isAscending={issueStore.closedIssuesIsAscending}
                  onClick={() =>
                    state.setFilter(ClosedIssuesSortFilter.Facility)
                  }
                />
                <TableHeaderCell
                  title="Typ av ärende"
                  isActive={
                    issueStore.closedIssuesSortFilter ===
                    ClosedIssuesSortFilter.IssueType
                  }
                  isAscending={issueStore.closedIssuesIsAscending}
                  onClick={() =>
                    state.setFilter(ClosedIssuesSortFilter.IssueType)
                  }
                />
                <TableHeaderCell
                  className="hidden lg:table-cell"
                  title="Ärendenr."
                  isActive={
                    issueStore.closedIssuesSortFilter ===
                    ClosedIssuesSortFilter.Number
                  }
                  isAscending={issueStore.closedIssuesIsAscending}
                  onClick={() => state.setFilter(ClosedIssuesSortFilter.Number)}
                />
                <TableHeaderCell
                  className="hidden 2xl:table-cell"
                  title="Avslutades"
                  isActive={
                    issueStore.closedIssuesSortFilter ===
                    ClosedIssuesSortFilter.ResolvedAt
                  }
                  isAscending={issueStore.closedIssuesIsAscending}
                  onClick={() =>
                    state.setFilter(ClosedIssuesSortFilter.ResolvedAt)
                  }
                />
                <TableHeaderCell
                  className="hidden xl:table-cell"
                  title="Företag/organisation"
                  isActive={
                    issueStore.closedIssuesSortFilter ===
                    ClosedIssuesSortFilter.Company
                  }
                  isAscending={issueStore.closedIssuesIsAscending}
                  onClick={() =>
                    state.setFilter(ClosedIssuesSortFilter.Company)
                  }
                />
                <TableHeaderCell
                  className="hidden lg:table-cell"
                  title="Prioritet"
                  isActive={
                    issueStore.closedIssuesSortFilter ===
                    ClosedIssuesSortFilter.Priority
                  }
                  isAscending={issueStore.closedIssuesIsAscending}
                  onClick={() =>
                    state.setFilter(ClosedIssuesSortFilter.Priority)
                  }
                />
                <TableHeaderCell
                  className="hidden xl:table-cell"
                  title="Tid att stänga"
                  isActive={
                    issueStore.closedIssuesSortFilter ===
                    ClosedIssuesSortFilter.TimeToClose
                  }
                  isAscending={issueStore.closedIssuesIsAscending}
                  onClick={() =>
                    state.setFilter(ClosedIssuesSortFilter.TimeToClose)
                  }
                />
                <TableHeaderCell
                  className="hidden lg:table-cell"
                  title="Tilldelad"
                  isActive={
                    issueStore.closedIssuesSortFilter ===
                    ClosedIssuesSortFilter.AssignedTo
                  }
                  isAscending={issueStore.closedIssuesIsAscending}
                  onClick={() =>
                    state.setFilter(ClosedIssuesSortFilter.AssignedTo)
                  }
                />
                <th></th>
              </tr>
            </thead>
            <tbody>
              {closedIssues.map((issue, index, arr) => (
                <tr
                  key={issue.id}
                  className={`border-t border-gray-200 ${
                    index % 2 === 0 ? 'bg-gray-50' : ''
                  } ${index !== arr.length - 1 ? 'border-b' : ''}`}
                >
                  <td className="py-5 pl-7 text-xs md:text-base xl:table-cell">
                    {issue.facility?.name || (
                      <span className="text-gray-500">Ingen angiven</span>
                    )}
                  </td>
                  <td className="py-5 pl-5 text-xs xs:pl-7 md:text-base">
                    {issue.issue_type?.name}
                  </td>

                  <td className="hidden py-5 pl-7 lg:table-cell">
                    {issue.number}
                  </td>
                  <td className="hidden py-5 pl-7 text-xs md:text-base 2xl:table-cell">
                    {issue.resolved_at && formatDate(issue.resolved_at).date}
                  </td>

                  <td className="hidden py-5 pl-7 text-xs md:text-base xl:table-cell">
                    {issue.company?.name || (
                      <span className="text-gray-500">Inget angivet</span>
                    )}
                  </td>
                  <td className="hidden py-5 pl-7 lg:table-cell">
                    <div className="inline-block">
                      <PriorityTag type={issue.priority} />
                    </div>
                  </td>
                  <td className="hidden py-5 pl-7 xl:table-cell">
                    <div className="inline-block">{issue.timeToClose}</div>
                  </td>
                  <td className="hidden py-5 pl-7 text-xs md:text-base lg:table-cell">
                    {issue.assigned_to ? (
                      <div className="flex items-center -space-x-3">
                        <div className="z-20">
                          <Avatar user={issue.assigned_to} border tooltip />
                        </div>
                        {issue.co_workers
                          ?.filter(Boolean)
                          .map((user, index) => (
                            <div key={user!.id} style={{ zIndex: 19 - index }}>
                              <Avatar user={user!} />
                            </div>
                          ))}
                      </div>
                    ) : (
                      <span className="text-gray-500">Ingen tilldelad</span>
                    )}
                  </td>

                  <td className="h-10 w-10 px-5 xs:px-7">
                    <IssueDetailsButton
                      to={`${location.pathname.replace(/\/$/, '')}/${issue.id}`}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="mx-4 flex-1 rounded-md bg-white px-7 py-7 text-center md:mx-14 md:py-28">
          <p className="text-2xl font-extrabold text-indigo-900 md:text-3xl">
            Det finns inga åtgärdade ärenden.
          </p>
        </div>
      )}

      <div className="pb-9 pt-5 md:pb-12 md:pt-7">
        {numClosedIssuesPages! > 1 && (
          <Pagination
            page={issueStore.closedIssuesPage}
            numPages={numClosedIssuesPages!}
            onChange={state.setCurrentPage}
          />
        )}
      </div>
    </FadeInDiv>
  );
});
