import { Instance, SnapshotIn, types as t } from 'mobx-state-tree';
import { UserReference } from './User';
import { IsoDate } from '../types';

export const SearchIssue = t
  .model('SearchIssue', {
    // We use t.number instead of t.identifierNumber here since the SearchIssue can
    // be duplicated between "Mina ärenden" and "Ärenden", so it will not be unique.
    id: t.number,
    number: t.number,
    created_at: IsoDate,
    issue: t.string,
    resolved_at: t.maybeNull(t.string),
    assigned_to: UserReference,
    co_workers: t.maybeNull(t.array(UserReference)),
    has_subcontractor_cost: t.boolean,
  })
  .views((self) => ({
    get formattedCreatedAt() {
      return self.created_at.toLocaleDateString('sv-SE');
    },
  }));

export type SearchIssueInstance = Instance<typeof SearchIssue>;
export type SearchIssueSnapshotIn = SnapshotIn<typeof SearchIssue>;

export const SEARCH_ISSUE_FIELDS = Object.keys(SearchIssue.properties).join(
  ',',
);
