import React, { FC, memo, SVGProps } from 'react';

export const BellIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 20"
    fill="none"
    className="w-4"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 15h5l-1.405-1.405A2.032 2.032 0 0115 12.158V9a6.002 6.002 0 00-4-5.659V3a2 2 0 10-4 0v.341C4.67 4.165 3 6.388 3 9v3.159c0 .538-.214 1.055-.595 1.436L1 15h5m6 0v1a3 3 0 11-6 0v-1m6 0H6"
    ></path>
  </svg>
));
