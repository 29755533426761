import { types as t } from 'mobx-state-tree';
import { IsoDate } from '../types';
import { CompanyReference } from './Company';

export const RecurringParking = t.model('RecurringParking', {
  registration_number: t.string,
  company: CompanyReference,
  email: t.string,
  latest_parking: IsoDate,
  occurences: t.number,
});
