import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FadeInDiv, Label } from '../components';
import { CheckIcon } from '../icons';
import { InputWithError } from '../components/InputWithError';
import { useStore } from '../hooks';
import { CompanyInstance } from '../models';
import { useNavigate } from 'react-router-dom';

interface FormData {
  orgNumber: string;
  name: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
}

interface State {
  isSubmitting: boolean;
  setIsSubmitting: (isSubmiting: boolean) => void;
  onSubmit: (formData: FormData) => void;
}

export const CreateCompanyPage = observer(() => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    shouldUnregister: true,
    reValidateMode: 'onSubmit',
  });

  const { organizationStore } = useStore();

  const state = useLocalObservable<State>(() => ({
    isSubmitting: false,
    setIsSubmitting(isSubmitting) {
      state.isSubmitting = isSubmitting;
    },
    async onSubmit(formData) {
      const { name, orgNumber, contactName, contactEmail, contactPhone } =
        formData;
      const newCompany = {
        name,
        organization_number: orgNumber,
        contact_name: contactName,
        contact_email: contactEmail,
        contact_phone: contactPhone,
      } as CompanyInstance;

      try {
        state.setIsSubmitting(true);
        await organizationStore.createCompany(newCompany);
        navigate('/organization/companies');
      } finally {
        state.setIsSubmitting(false);
      }
    },
  }));
  return (
    <FadeInDiv className="h-full w-full p-4 md:p-14">
      <div className="block rounded-md bg-white p-7 md:inline-block">
        <span className="w-full text-xl font-extrabold text-indigo-900">
          Skapa nytt företag
        </span>
      </div>
      <form onSubmit={handleSubmit(state.onSubmit)}>
        <div className="mt-5 space-y-6 rounded-md bg-white p-7 md:mt-10">
          <div className="max-w-xs">
            <Label>Organisationsnummer</Label>
            <InputWithError
              type="text"
              placeholder="Organisationsnummer"
              {...register('orgNumber', {
                pattern: {
                  value: /\d{6}-\d{4}/,
                  message: 'Ange giltigt format XXXXXX-XXXX',
                },
              })}
              error={errors.orgNumber?.message}
            />
          </div>

          <div className="flex flex-col space-y-6 xl:flex-row xl:space-y-0">
            <div className="max-w-xs flex-1 xl:mr-7">
              <Label required>Namn</Label>
              <InputWithError
                type="text"
                placeholder="Namn"
                {...register('name', {
                  required: 'Fyll i detta fältet',
                  maxLength: {
                    value: 150,
                    message: 'Max 150 tecken',
                  },
                })}
                error={errors.name?.message}
              />
            </div>
            <div className="max-w-xs flex-1">
              <Label>Kontaktperson</Label>
              <input
                type="text"
                className="w-full rounded-md border border-solid py-2 pl-3 outline-none focus:border-indigo-600"
                placeholder="Kontaktperson"
                {...register('contactName', {
                  maxLength: {
                    value: 50,
                    message: 'Max 50 tecken',
                  },
                })}
              />
            </div>
          </div>
          <div className="flex flex-col space-y-6 xl:flex-row xl:space-y-0">
            <div className="max-w-xs flex-1 xl:mr-7">
              <Label>E-post</Label>
              <InputWithError
                type="text"
                placeholder="namn.namnsson@e-post.se"
                {...register('contactEmail', {
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Ange en giltig e-post',
                  },
                })}
                error={errors.contactEmail?.message}
              />
            </div>
            <div className="max-w-xs flex-1">
              <Label>Telefonnummer</Label>
              <input
                type="text"
                className="w-full rounded-md border border-solid py-2 pl-3 outline-none focus:border-indigo-600"
                placeholder="Telefonnummer"
                {...register('contactPhone', {
                  maxLength: {
                    value: 20,
                    message: 'Max 20 tecken',
                  },
                })}
              />
            </div>
          </div>
        </div>
        <button
          disabled={state.isSubmitting}
          type="submit"
          className="mt-5 flex w-full items-center justify-center rounded-md bg-white p-3 font-semibold text-gray-900 transition-colors hover:bg-indigo-600 hover:text-white md:mt-7 md:w-auto md:justify-start"
        >
          <CheckIcon className="h-5 w-5" />
          <span className="ml-3">Skapa</span>
        </button>
      </form>
    </FadeInDiv>
  );
});
