import React, { FC, memo, SVGProps } from 'react';

export const UserIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    viewBox="0 0 15 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className="w-4"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.614 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm.251 3.799a5 5 0 1 0-6.502 0A7 7 0 0 0 .614 15a1 1 0 1 0 2 0 5 5 0 0 1 10 0 1 1 0 1 0 2 0 7 7 0 0 0-3.749-6.201Z"
      fill="currentColor"
    />
  </svg>
));
