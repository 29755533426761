import React from 'react';
import { observer } from 'mobx-react-lite';
import { TrashCanIcon } from '../../../icons';

export const DeleteButton = observer(({ onClick }: { onClick: () => void }) => (
  <button
    onClick={onClick}
    className="flex flex-grow items-center justify-center rounded-md bg-rose-300 p-3 transition duration-200 hover:bg-rose-500"
  >
    <TrashCanIcon className="mr-3 w-4 text-white" />
    <span className="font-semibold text-white">Radera</span>
  </button>
));
