import {
  IntervalType,
  IssuePriority,
  IssueStatus,
  RoundingStatus,
} from './types';

export const IS_DEV = process.env.NODE_ENV === 'development';

export const USERS_PER_PAGE = 10;
export const ISSUES_PER_PAGE = 10;
export const PARKING_PER_PAGE = 15;
export const ARTICLES_PER_PAGE = 10;
export const COMPANIES_PER_PAGE = 10;
export const FACILITIES_PER_PAGE = 10;
export const NOTES_PER_PAGE = 2;
export const NOTIFICATIONS_PER_PAGE = 5;
export const SEARCH_ISSUES_PER_PAGE = 5;
export const REQUEST_DEBOUNCE_WAIT = 300;
export const ROUNDS_PER_PAGE = 10;

export const FEEDBACK_COLORS = [
  'bg-indigo-900',
  'bg-indigo-600',
  'bg-pink-500',
  'bg-rose-300',
  'bg-yellow-400',
];

export const MIN_SEARCH_QUERY_LENGTH = 1;

export const ISSUES_PRIORITY_TEXTS = {
  [IssuePriority.None]: 'Ingen',
  [IssuePriority.Low]: 'Låg',
  [IssuePriority.Medium]: 'Medium',
  [IssuePriority.High]: 'Hög',
};

export const INTERVAL_TYPE_TEXTS = {
  [IntervalType.Day]: 'Dag',
  [IntervalType.Month]: 'Månad',
  [IntervalType.Week]: 'Vecka',
  [IntervalType.Year]: 'År',
};

export const STATUS_TEXTS = {
  [IssueStatus.Waiting]: 'Ohanterad',
  [IssueStatus.InProgress]: 'Åtgärdas',
  [IssueStatus.Done]: 'Åtgärdat',
};

export const ROUNDING_STATUS_TEXTS = {
  [RoundingStatus.Waiting]: 'Ohanterad',
  [RoundingStatus.Approved]: 'Genomförd',
  [RoundingStatus.ActionIsRequired]: 'Åtgärd krävs',
};

export const SAVE_BUTTON_ANIMATION_DURATION = 1500;
