import React, { ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuPopover,
} from '@reach/menu-button';
import { FadeInDiv } from '../../../components';
import { ChatIcon, PaperClipIcon, SendIcon } from '../../../icons';
import { OnChangeHandlerFunc } from 'react-mentions';
import { TextArea } from './TextArea';

export enum NoteType {
  Internal = 'internal',
  External = 'external',
}

type WriteNoteBoxProps = {
  value: string;
  onChange: OnChangeHandlerFunc;

  noteType: NoteType;
  onNoteTypeChange: (noteType: NoteType) => void;

  onSubmit: () => void;
  onFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
  filesToUpload: File[];

  hasReporter: boolean;
};

const noteOptions = {
  [NoteType.Internal]: {
    label: 'Kommentera',
    bgColor: 'bg-indigo-600',
    borderColor: 'border-indigo-700',
    icon: <ChatIcon className="w-4 text-white" />,
  },
  [NoteType.External]: {
    label: 'Skicka till kontakt',
    bgColor: 'bg-pink-500',
    borderColor: 'border-black border-opacity-10',
    icon: <SendIcon className="w-4 text-white" />,
  },
};

export const WriteNoteBox = observer(
  ({
    value,
    onChange,
    noteType,
    onNoteTypeChange,
    onSubmit,
    onFileChange,
    filesToUpload,
    hasReporter,
  }: WriteNoteBoxProps) => (
    <div className="relative">
      <TextArea value={value} onChange={onChange} />
      <div className="absolute bottom-0 right-0 mb-1 mr-1 flex flex-row rounded-md md:mb-3 md:mr-3">
        {noteType === NoteType.Internal && (
          <div className="mb-0 inline-flex flex-row flex-wrap items-end justify-end">
            <input
              type="file"
              id="attachments"
              name="attachments"
              accept="image/png, image/jpeg"
              className="hidden"
              onChange={onFileChange}
              multiple
            />
            <label
              htmlFor="attachments"
              className="group inline-flex h-8 w-8 cursor-pointer items-center justify-center rounded border bg-white hover:bg-indigo-600 md:h-10 md:w-10"
            >
              <PaperClipIcon className="h-4 w-4 text-black group-hover:text-white" />
            </label>
          </div>
        )}
        {(value !== '' || filesToUpload.length > 0) && (
          <FadeInDiv>
            <Menu>
              {({ isExpanded }) => {
                const selectedOption = noteOptions[noteType];

                return (
                  <div className="ml-1 md:ml-3">
                    <div
                      className={`flex ${selectedOption.bgColor} rounded-md`}
                    >
                      <button
                        className="flex items-center justify-center p-2 md:p-3"
                        onClick={onSubmit}
                      >
                        {selectedOption.icon}
                        <span className="ml-2 inline text-xs font-semibold text-white">
                          {selectedOption.label}
                        </span>
                      </button>
                      {hasReporter && (
                        <MenuButton
                          className={`flex w-10 items-center justify-center border-l text-white ${selectedOption.borderColor}`}
                        >
                          <svg
                            className={`w-5 transform transition-transform ${
                              isExpanded ? 'rotate-180' : ''
                            }`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="black"
                          >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path
                              d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
                              fill="currentColor"
                            />
                          </svg>
                        </MenuButton>
                      )}
                    </div>
                    {isExpanded && (
                      <MenuPopover
                        className="absolute right-0 z-10 my-1 min-w-36 rounded-md border border-gray-200 bg-white shadow-lg"
                        portal={false}
                      >
                        <MenuItems className="reach-menu-items comment-menu-items focus:outline-none">
                          {Object.keys(noteOptions).map((key) => {
                            const { label } = noteOptions[key as NoteType];

                            return (
                              <MenuItem
                                key={key}
                                className="group flex w-full cursor-pointer flex-col items-baseline p-3 hover:bg-indigo-100 focus:bg-indigo-100"
                                onSelect={() =>
                                  onNoteTypeChange(key as NoteType)
                                }
                              >
                                <div className="flex w-full items-center justify-between whitespace-nowrap text-xs">
                                  {label}
                                  {noteType === key && (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      fill="none"
                                      className="ml-7 w-4 stroke-current text-gray-900"
                                    >
                                      <polyline points="20 6 9 17 4 12" />
                                    </svg>
                                  )}
                                </div>
                              </MenuItem>
                            );
                          })}
                        </MenuItems>
                      </MenuPopover>
                    )}
                  </div>
                );
              }}
            </Menu>
          </FadeInDiv>
        )}
      </div>
    </div>
  ),
);
