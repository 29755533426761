import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC, useMemo } from 'react';
import { nameToInitials, nameToStrongColor } from '../utils';
import { DeleteIcon } from '../icons';
import { UserTooltip } from './UserTooltip';
import { UserInstance } from '../models';

interface Props {
  user: UserInstance;
  border?: boolean;
  onDelete?: () => void;
  tooltip?: boolean;
}

interface AvatarState {
  isMouseOver: boolean;
  setIsMouseOver: (isMouseOver: boolean) => void;
}

export const Avatar: FC<Props> = observer(
  ({ user, border = false, onDelete, tooltip = false }) => {
    const initials = nameToInitials(user.name);

    const bgColor = useMemo(() => nameToStrongColor(user.name), [user.name]);

    const state = useLocalObservable<AvatarState>(() => ({
      isMouseOver: false,
      setIsMouseOver(isMouseOver) {
        state.isMouseOver = isMouseOver;
      },
    }));

    return (
      <UserTooltip disabled={!tooltip} user={user}>
        <div
          style={{ backgroundColor: bgColor }}
          className={`relative flex h-8 w-8 items-center justify-center rounded-full border-2 text-sm text-white ${
            border ? 'border-pink-600' : ''
          }`}
          onMouseOver={() => state.setIsMouseOver(true)}
          onMouseLeave={() => state.setIsMouseOver(false)}
        >
          {state.isMouseOver && onDelete && (
            <button
              type="button"
              className="absolute -right-1.5 -top-1.5 rounded-full"
              onClick={onDelete}
            >
              <DeleteIcon />
            </button>
          )}
          {user.image ? (
            <img src={user.image} className="rounded-full" />
          ) : (
            <span>{initials}</span>
          )}
        </div>
      </UserTooltip>
    );
  },
);
