import React, { FC, memo, SVGProps } from 'react';

export const ApplicationIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    enableBackground="new 0 0 12.7 15.5"
    viewBox="0 0 12.7 15.5"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    className="w-4"
    {...props}
  >
    <g transform="translate(-3.25 -1.25)">
      <path
        className="fill-current"
        d="M15.7,6.4l-4.9-4.9c-0.1-0.1-0.3-0.2-0.5-0.2H5.4c-1.2,0-2.2,1-2.2,2.2v11.2c0,1.2,1,2.2,2.2,2.2h8.4 c1.2,0,2.2-1,2.2-2.2V6.9C16,6.7,15.9,6.5,15.7,6.4z M9,2.8h1l4.5,4.5v1H9V2.8z M14.5,14.6c0,0.4-0.3,0.7-0.7,0.7H5.4 c-0.4,0-0.7-0.3-0.7-0.7V3.4C4.8,3,5,2.8,5.4,2.8h2V9c0,0.4,0.3,0.8,0.8,0.8h6.2V14.6z"
      />
    </g>
  </svg>
));
