import { observer } from 'mobx-react-lite';
import React from 'react';
import { InfoBox } from '../../../components/InfoBox';

type FeedbackAmountProps = {
  numResolvedIssues: number;
  numIssueFeedbacks: number;
  percentIssueFeedback: number;
};

export const FeedbackAmount = observer(
  ({
    numResolvedIssues,
    numIssueFeedbacks,
    percentIssueFeedback,
  }: FeedbackAmountProps) => (
    <div className="w-full rounded bg-white p-7">
      <div className="mb-12 flex items-center justify-between">
        <span className="mr-5 text-lg font-semibold">Feedback mängd</span>
        <InfoBox content="Hur stor andel av åtgärdade ärenden som fått återkoppling." />
      </div>
      <div className="flex items-center justify-between">
        <div className="flex flex-wrap items-center">
          <div className="mr-7">
            <div className="mb-2 text-sm font-semibold text-gray-300">
              Åtgärdade
            </div>
            <div className="text-xl font-extrabold text-indigo-900">
              {numResolvedIssues}
            </div>
          </div>
          <div className="mr-7">
            <div className="mb-2 text-sm font-semibold text-gray-300">Svar</div>
            <div className="text-xl font-extrabold text-indigo-900">
              {numIssueFeedbacks}
            </div>
          </div>
        </div>

        <div className="text-4xl font-extrabold text-indigo-900">
          {percentIssueFeedback}%
        </div>
      </div>
    </div>
  ),
);
