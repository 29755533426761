import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { endOfWeek, startOfMonth, startOfWeek, startOfYear } from 'date-fns';
import { ActiveFilters, FadeInDiv, SlideInMenu } from '../../components';
import { useStore } from '../../hooks';
import {
  RightNow,
  FeedbackGrade,
  FeedbackAmount,
  IssueTypeChart,
  LineChart,
} from './components';
import { DashboardPeriod, IssuesFilter } from '../../types';
import { getNextPeriod, getPreviousPeriod, periodToDates } from '../../utils';
import { endOfMonth, endOfYear } from 'date-fns/esm';
import sv from 'date-fns/esm/locale/sv/index.js';
import { BarChart } from './components/BarChart';
import { Rounds } from './components/Rounds';
import { FilterIcon, UserSwitchIcon } from '../../icons';
import { IssuesFilterPicker } from '../../components/IssuesFilterPicker';
import { useLocation, useNavigate } from 'react-router-dom';
import { IssueFilterInstance } from '../../models';

export const Dashboard = observer(() => {
  const {
    authStore,
    uiStore,
    issueStore,
    organizationStore: { organization },
    userStore: { me },
  } = useStore();
  const { dashboardActiveFilters, dashboardQuery: query } = issueStore;
  const location = useLocation();
  const navigate = useNavigate();

  const isMineFilterActive =
    new URLSearchParams(query).get(IssuesFilter.AssignedTo) ===
    me?.id.toString();

  // We can safely assume that the organization has been loaded here
  // already since the Dashboard will not be rendered at all until it is.
  const shouldShowParkingInfo = uiStore.shouldShowParking(organization!);
  const shouldShowRounds = uiStore.shouldShowRounds(organization!, me!);

  const issueTypeStatsPromise = useRef<Promise<any> | null>(null);
  const createdIssuesStatsPromise = useRef<Promise<any> | null>(null);
  const completedIssuesStatsPromise = useRef<Promise<any> | null>(null);
  const parkingsStatsPromise = useRef<Promise<any> | null>(null);
  const errorReportingStatsPromise = useRef<Promise<any> | null>(null);
  const facilityIssuesStatsPromise = useRef<Promise<any> | null>(null);

  const state = useLocalObservable(() => ({
    isLoading: true,
    setIsLoading(isLoading: boolean) {
      state.isLoading = isLoading;
    },

    numInProgressIssues: 0,
    numWaitingIssues: 0,
    numParkedCars: 0,
    numMyIssues: 0,

    numWaitingRounds: 0,
    numMyRounds: 0,

    setRightNowStats(
      numInProgressIssues: number,
      numWaitingIssues: number,
      numParkedCars: number,
      numMyIssues: number,
    ) {
      state.numInProgressIssues = numInProgressIssues;
      state.numWaitingIssues = numWaitingIssues;
      state.numParkedCars = numParkedCars;
      state.numMyIssues = numMyIssues;
    },

    setRoundsStats(numWaitingRounds: number, numMyRounds: number) {
      state.numWaitingRounds = numWaitingRounds;
      state.numMyRounds = numMyRounds;
    },

    overallRating: 0,
    satisfactionRating: 0,
    speedRating: 0,

    setFeedbackGradeStats(
      overallRating: number,
      satisfactionRating: number,
      speedRating: number,
    ) {
      state.overallRating = overallRating;
      state.satisfactionRating = satisfactionRating;
      state.speedRating = speedRating;
    },

    numResolvedIssues: 0,
    numIssueFeedbacks: 0,
    percentIssueFeedback: 0,
    setFeedbackAmountStats(
      numResolvedIssues: number,
      numIssueFeedbacks: number,
      percentIssueFeedback: number,
    ) {
      state.numResolvedIssues = numResolvedIssues;
      state.numIssueFeedbacks = numIssueFeedbacks;
      state.percentIssueFeedback = percentIssueFeedback;
    },

    issueTypePeriod: DashboardPeriod.Year,
    issueTypeStartDate: startOfYear(new Date()),
    issueTypeEndDate: endOfYear(new Date()),
    issueTypeStats: {},
    setIssueTypePeriod(period: DashboardPeriod) {
      const [startDate, endDate] = periodToDates(period);

      state.issueTypePeriod = period;
      state.issueTypeStartDate = startDate;
      state.issueTypeEndDate = endDate;

      state.fetchIssueTypeStats();
    },
    previousIssueTypeDates() {
      const [startDate, endDate] = getPreviousPeriod(
        state.issueTypePeriod,
        state.issueTypeStartDate,
      );
      state.issueTypeStartDate = startDate;
      state.issueTypeEndDate = endDate;

      state.fetchIssueTypeStats();
    },
    nextIssueTypeDates() {
      const [startDate, endDate] = getNextPeriod(
        state.issueTypePeriod,
        state.issueTypeStartDate,
      );
      state.issueTypeStartDate = startDate;
      state.issueTypeEndDate = endDate;

      state.fetchIssueTypeStats();
    },
    setIssueTypeStats(issueTypeStats: { [key: string]: number }) {
      state.issueTypeStats = issueTypeStats;
    },
    async fetchIssueTypeStats() {
      const params = state.activeFilter;
      params.append('start_dt', state.issueTypeStartDate.toISOString());
      params.append('end_dt', state.issueTypeEndDate.toISOString());

      const promise = authStore.authRequest({
        url: '/api/stats/issue-types/',
        params,
      });

      issueTypeStatsPromise.current = promise;

      const {
        data: { counter },
      } = await promise;

      if (issueTypeStatsPromise.current === promise) {
        state.setIssueTypeStats(counter);
      }
    },

    createdIssuesPeriod: DashboardPeriod.Week,
    createdIssuesStartDate: startOfWeek(new Date(), {
      weekStartsOn: 1,
      locale: sv,
    }),
    createdIssuesEndDate: endOfWeek(new Date(), {
      weekStartsOn: 1,
      locale: sv,
    }),
    createdIssuesStats: {} as any,
    setCreatedIssuesPeriod(period: DashboardPeriod) {
      const [startDate, endDate] = periodToDates(period);

      state.createdIssuesPeriod = period;
      state.createdIssuesStartDate = startDate;
      state.createdIssuesEndDate = endDate;

      state.fetchCreatedIssuesStats();
    },
    previousCreatedIssuesDates() {
      const [startDate, endDate] = getPreviousPeriod(
        state.createdIssuesPeriod,
        state.createdIssuesStartDate,
      );
      state.createdIssuesStartDate = startDate;
      state.createdIssuesEndDate = endDate;

      state.fetchCreatedIssuesStats();
    },
    nextCreatedIssuesDates() {
      const [startDate, endDate] = getNextPeriod(
        state.createdIssuesPeriod,
        state.createdIssuesStartDate,
      );
      state.createdIssuesStartDate = startDate;
      state.createdIssuesEndDate = endDate;

      state.fetchCreatedIssuesStats();
    },
    setCreatedIssuesStats(createdIssuesStats: any) {
      state.createdIssuesStats = createdIssuesStats;
    },
    async fetchCreatedIssuesStats() {
      const params = state.activeFilter;
      params.append('start_dt', state.createdIssuesStartDate.toISOString());
      params.append('end_dt', state.createdIssuesEndDate.toISOString());

      const promise = authStore.authRequest({
        url: '/api/stats/created-issues/',
        params,
      });

      createdIssuesStatsPromise.current = promise;

      const { data } = await promise;

      if (createdIssuesStatsPromise.current === promise) {
        state.setCreatedIssuesStats(data);
      }
    },

    completedIssuesPeriod: DashboardPeriod.Month,
    completedIssuesStartDate: startOfMonth(new Date()),
    completedIssuesEndDate: endOfMonth(new Date()),
    completedIssuesStats: {} as any,
    setCompletedIssuesPeriod(period: DashboardPeriod) {
      const [startDate, endDate] = periodToDates(period);

      state.completedIssuesPeriod = period;
      state.completedIssuesStartDate = startDate;
      state.completedIssuesEndDate = endDate;

      state.fetchCompletedIssuesStats();
    },
    previousCompletedIssuesDates() {
      const [startDate, endDate] = getPreviousPeriod(
        state.completedIssuesPeriod,
        state.completedIssuesStartDate,
      );
      state.completedIssuesStartDate = startDate;
      state.completedIssuesEndDate = endDate;

      state.fetchCompletedIssuesStats();
    },
    nextCompletedIssuesDates() {
      const [startDate, endDate] = getNextPeriod(
        state.completedIssuesPeriod,
        state.completedIssuesStartDate,
      );
      state.completedIssuesStartDate = startDate;
      state.completedIssuesEndDate = endDate;

      state.fetchCompletedIssuesStats();
    },
    setCompletedIssuesStats(completedIssuesStats: any) {
      state.completedIssuesStats = completedIssuesStats;
    },
    async fetchCompletedIssuesStats() {
      const params = state.activeFilter;
      params.append('start_dt', state.completedIssuesStartDate.toISOString());
      params.append('end_dt', state.completedIssuesEndDate.toISOString());

      const promise = authStore.authRequest({
        url: '/api/stats/finished-issues/',
        params,
      });

      completedIssuesStatsPromise.current = promise;

      const { data } = await promise;

      if (completedIssuesStatsPromise.current === promise) {
        state.setCompletedIssuesStats(data);
      }
    },

    parkingsPeriod: DashboardPeriod.Month,
    parkingsStartDate: startOfMonth(new Date()),
    parkingsEndDate: endOfMonth(new Date()),
    parkingsStats: {} as any,
    setparkingsPeriod(period: DashboardPeriod) {
      const [startDate, endDate] = periodToDates(period);

      state.parkingsPeriod = period;
      state.parkingsStartDate = startDate;
      state.parkingsEndDate = endDate;

      state.fetchParkingsStats();
    },
    previousParkingsDates() {
      const [startDate, endDate] = getPreviousPeriod(
        state.parkingsPeriod,
        state.parkingsStartDate,
      );
      state.parkingsStartDate = startDate;
      state.parkingsEndDate = endDate;

      state.fetchParkingsStats();
    },
    nextParkingsDates() {
      const [startDate, endDate] = getNextPeriod(
        state.parkingsPeriod,
        state.parkingsStartDate,
      );
      state.parkingsStartDate = startDate;
      state.parkingsEndDate = endDate;

      state.fetchParkingsStats();
    },
    setParkingsStats(parkingsStats: any) {
      state.parkingsStats = parkingsStats;
    },
    async fetchParkingsStats() {
      const promise = authStore.authRequest({
        url: '/api/stats/parking/',
        params: {
          start_dt: state.parkingsStartDate,
          end_dt: state.parkingsEndDate,
        },
      });

      parkingsStatsPromise.current = promise;

      const { data } = await promise;

      if (parkingsStatsPromise.current === promise) {
        state.setParkingsStats(data);
      }
    },

    errorReportingPeriod: DashboardPeriod.Week,
    errorReportingStartDate: startOfWeek(new Date(), {
      weekStartsOn: 1,
      locale: sv,
    }),
    errorReportingEndDate: endOfWeek(new Date(), {
      weekStartsOn: 1,
      locale: sv,
    }),
    errorReportingStats: {} as any,
    setErrorReportingPeriod(period: DashboardPeriod) {
      const [startDate, endDate] = periodToDates(period);

      state.errorReportingPeriod = period;
      state.errorReportingStartDate = startDate;
      state.errorReportingEndDate = endDate;

      state.fetchErrorReportingStats();
    },
    previouserrorReportingDates() {
      const [startDate, endDate] = getPreviousPeriod(
        state.errorReportingPeriod,
        state.errorReportingStartDate,
      );
      state.errorReportingStartDate = startDate;
      state.errorReportingEndDate = endDate;

      state.fetchErrorReportingStats();
    },
    nexterrorReportingDates() {
      const [startDate, endDate] = getNextPeriod(
        state.errorReportingPeriod,
        state.errorReportingStartDate,
      );
      state.errorReportingStartDate = startDate;
      state.errorReportingEndDate = endDate;

      state.fetchErrorReportingStats();
    },
    setErrorReportingStats(errorReportingStats: any) {
      state.errorReportingStats = errorReportingStats;
    },
    async fetchErrorReportingStats() {
      const params = state.activeFilter;
      params.append('start_dt', state.errorReportingStartDate.toISOString());
      params.append('end_dt', state.errorReportingEndDate.toISOString());

      const promise = authStore.authRequest({
        url: '/api/stats/error-report-issues/',
        params,
      });

      errorReportingStatsPromise.current = promise;

      const {
        data: { counter },
      } = await promise;

      if (errorReportingStatsPromise.current === promise) {
        state.setErrorReportingStats(counter);
      }
    },

    facilityIssuesPeriod: DashboardPeriod.Week,
    facilityIssuesStartDate: startOfWeek(new Date(), {
      weekStartsOn: 1,
      locale: sv,
    }),
    facilityIssuesEndDate: endOfWeek(new Date(), {
      weekStartsOn: 1,
      locale: sv,
    }),
    facilityIssuesStats: {} as any,
    setFacilityIssuesPeriod(period: DashboardPeriod) {
      const [startDate, endDate] = periodToDates(period);

      state.facilityIssuesPeriod = period;
      state.facilityIssuesStartDate = startDate;
      state.facilityIssuesEndDate = endDate;

      state.fetchFacilityIssuesStats();
    },
    previousFacilityIssuesDates() {
      const [startDate, endDate] = getPreviousPeriod(
        state.facilityIssuesPeriod,
        state.facilityIssuesStartDate,
      );
      state.facilityIssuesStartDate = startDate;
      state.facilityIssuesEndDate = endDate;

      state.fetchFacilityIssuesStats();
    },
    nextFacilityIssuesDates() {
      const [startDate, endDate] = getNextPeriod(
        state.facilityIssuesPeriod,
        state.facilityIssuesStartDate,
      );
      state.facilityIssuesStartDate = startDate;
      state.facilityIssuesEndDate = endDate;

      state.fetchFacilityIssuesStats();
    },
    setFacilityIssuesStats(facilityIssuesStats: any) {
      state.facilityIssuesStats = facilityIssuesStats;
    },
    async fetchFacilityIssuesStats() {
      const params = state.activeFilter;
      params.append('start_dt', state.facilityIssuesStartDate.toISOString());
      params.append('end_dt', state.facilityIssuesEndDate.toISOString());

      const promise = authStore.authRequest({
        url: '/api/stats/facility-issues/',
        params,
      });

      facilityIssuesStatsPromise.current = promise;

      const {
        data: { counter },
      } = await promise;

      if (facilityIssuesStatsPromise.current === promise) {
        state.setFacilityIssuesStats(counter);
      }
    },
    get activeFilter(): URLSearchParams {
      const params = new URLSearchParams();

      for (const [
        key,
        value,
      ] of issueStore.dashboardActiveFilterParams.entries()) {
        params.append(key, value);
      }

      return params;
    },
  }));

  const toggleMineFilter = () => {
    const searchParams = new URLSearchParams(location.search);

    const hasAssignedToFilter = searchParams.has(IssuesFilter.AssignedTo);
    const currentFilter = searchParams.get(IssuesFilter.AssignedTo);

    searchParams.delete(IssuesFilter.AssignedTo);

    if (currentFilter !== me?.id.toString() || !hasAssignedToFilter) {
      searchParams.append(IssuesFilter.AssignedTo, me?.id.toString() || '');
    }

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  useEffect(() => {
    async function getRightNowStats() {
      const params = state.activeFilter;

      const {
        data: { in_progress_issues, waiting_issues, parked_cars, my_issues },
      } = await authStore.authRequest({
        url: '/api/stats/right-now/',
        params,
      });

      state.setRightNowStats(
        in_progress_issues,
        waiting_issues,
        parked_cars,
        my_issues,
      );
    }

    async function getRoundsStats() {
      const params = state.activeFilter;

      const {
        data: { waiting_rounding_tasks, my_rounding_tasks },
      } = await authStore.authRequest({
        url: '/api/stats/rounds/',
        params,
      });

      state.setRoundsStats(waiting_rounding_tasks, my_rounding_tasks);
    }

    async function getFeedbackGradeStats() {
      const params = state.activeFilter;

      const {
        data: { overall_rating, satisfaction_rating, speed_rating },
      } = await authStore.authRequest({
        url: '/api/stats/issue-feedback-rating/',
        params,
      });

      state.setFeedbackGradeStats(
        overall_rating,
        satisfaction_rating,
        speed_rating,
      );
    }

    async function getFeedbackAmountStats() {
      const params = state.activeFilter;

      const {
        data: { resolved_issues, issue_feedbacks, percent },
      } = await authStore.authRequest({
        url: '/api/stats/issue-feedback-amount/',
        params,
      });

      state.setFeedbackAmountStats(resolved_issues, issue_feedbacks, percent);
    }

    const promises = [
      getRightNowStats(),
      getRoundsStats(),
      getFeedbackGradeStats(),
      getFeedbackAmountStats(),
      state.fetchIssueTypeStats(),
      state.fetchCreatedIssuesStats(),
      state.fetchCompletedIssuesStats(),
      state.fetchErrorReportingStats(),
      state.fetchFacilityIssuesStats(),
    ];

    if (shouldShowParkingInfo) {
      promises.push(state.fetchParkingsStats());
    }

    Promise.all(promises).then(() => {
      state.setIsLoading(false);
    });
  }, [location.search]);

  useLayoutEffect(() => {
    issueStore.handleDashboardSearchChange(location.search);
  }, [location.search]);

  if (state.isLoading) return null;

  return (
    <FadeInDiv className="px-4 pb-4 pt-0 md:p-14">
      <div className="mb-3 flex justify-between space-x-6 space-y-2">
        <div className="flex min-w-0 flex-wrap items-center space-y-2 whitespace-nowrap">
          <SlideInMenu
            title="Filter"
            icon={
              <div className="mr-2 mt-2 flex items-center space-x-2 px-1.5 py-0.5 text-sm text-gray-900 md:mr-4">
                <FilterIcon /> <span>Filter</span>
              </div>
            }
            isMenuExpanded={uiStore.isIssuesFilterPickerOpen}
            onClick={uiStore.toggleIsIssuesFilterPickerOpen}
          >
            <IssuesFilterPicker />
          </SlideInMenu>
          {dashboardActiveFilters !== null && (
            <ActiveFilters<IssueFilterInstance>
              filters={dashboardActiveFilters}
            />
          )}
        </div>
        <div className="flex items-start space-x-1 text-sm md:space-x-4">
          <button
            className={`flex items-center space-x-1 rounded px-1.5 py-0.5 transition-colors duration-200 hover:bg-pink-500 hover:text-white ${
              isMineFilterActive ? 'text-pink-500' : ''
            }`}
            onClick={toggleMineFilter}
          >
            <span>{isMineFilterActive ? 'Mina' : 'Alla'}</span>
            <UserSwitchIcon />
          </button>
        </div>
      </div>

      <div className="lg:flex">
        <div className="mb-5 flex-shrink-0 lg:mb-0 lg:mr-5 lg:flex lg:w-1/2 2xl:mr-10">
          <RightNow
            numInProgressIssues={state.numInProgressIssues}
            numWaitingIssues={state.numWaitingIssues}
            numParkedCars={state.numParkedCars}
            numMyIssues={state.numMyIssues}
            showParkedCars={shouldShowParkingInfo}
          />
        </div>
        {shouldShowRounds ? (
          <Rounds
            numWaitingRounds={state.numWaitingRounds}
            numMyRounds={state.numMyRounds}
          />
        ) : (
          <div className="flex-col md:flex lg:w-1/2 2xl:flex-row">
            <div className="mb-5 flex-grow 2xl:mb-0 2xl:mr-10 2xl:flex">
              <FeedbackGrade
                grade={state.overallRating}
                satisfaction={state.satisfactionRating}
                speed={state.speedRating}
              />
            </div>
            <div className="flex-grow 2xl:flex">
              <FeedbackAmount
                numResolvedIssues={state.numResolvedIssues}
                numIssueFeedbacks={state.numIssueFeedbacks}
                percentIssueFeedback={state.percentIssueFeedback}
              />
            </div>
          </div>
        )}
      </div>
      {shouldShowRounds && (
        <div className="mt-5 xl:flex 2xl:mt-10">
          <div className="mb-5 flex flex-shrink-0 xl:mb-0 xl:mr-5 xl:w-1/2 2xl:mr-10">
            <FeedbackGrade
              grade={state.overallRating}
              satisfaction={state.satisfactionRating}
              speed={state.speedRating}
            />
          </div>
          <div className="flex xl:w-1/2">
            <FeedbackAmount
              numResolvedIssues={state.numResolvedIssues}
              numIssueFeedbacks={state.numIssueFeedbacks}
              percentIssueFeedback={state.percentIssueFeedback}
            />
          </div>
        </div>
      )}
      <div className="mt-5 xl:flex 2xl:mt-10">
        <div className="mb-5 flex flex-shrink-0 xl:mb-0 xl:mr-5 xl:w-1/2 2xl:mr-10">
          <LineChart
            title="Inkomna ärenden"
            tooltip="Inkomna ärenden under den valda tidsperioden."
            color="#EC4899"
            period={state.createdIssuesPeriod}
            startDate={state.createdIssuesStartDate}
            endDate={state.createdIssuesEndDate}
            onChangePeriod={state.setCreatedIssuesPeriod}
            onPrevious={state.previousCreatedIssuesDates}
            onNext={state.nextCreatedIssuesDates}
            periods={state.createdIssuesStats}
          />
        </div>
        <div className="flex xl:w-1/2">
          <IssueTypeChart
            period={state.issueTypePeriod}
            startDate={state.issueTypeStartDate}
            endDate={state.issueTypeEndDate}
            onChangePeriod={state.setIssueTypePeriod}
            onPrevious={state.previousIssueTypeDates}
            onNext={state.nextIssueTypeDates}
            issueTypes={state.issueTypeStats}
          />
        </div>
      </div>

      <div className="mt-5 xl:flex 2xl:mt-10">
        <div className="mb-5 flex flex-shrink-0 xl:mb-0 xl:mr-5 xl:w-1/2 2xl:mr-10">
          <LineChart
            title="Åtgärdade ärenden"
            tooltip="Åtgärdade ärenden under den valda tidsperioden."
            color="#5046E5"
            period={state.completedIssuesPeriod}
            startDate={state.completedIssuesStartDate}
            endDate={state.completedIssuesEndDate}
            onChangePeriod={state.setCompletedIssuesPeriod}
            onPrevious={state.previousCompletedIssuesDates}
            onNext={state.nextCompletedIssuesDates}
            periods={state.completedIssuesStats}
          />
        </div>
        <div className="flex xl:w-1/2">
          <BarChart
            title="Felanmälan"
            tooltip="Distribution av olika typer av felanmälan under den valda tidsperioden."
            color="#5046E5"
            period={state.errorReportingPeriod}
            startDate={state.errorReportingStartDate}
            endDate={state.errorReportingEndDate}
            onChangePeriod={state.setErrorReportingPeriod}
            onPrevious={state.previouserrorReportingDates}
            onNext={state.nexterrorReportingDates}
            data={state.errorReportingStats}
          />
        </div>
      </div>

      <div className="mt-5 xl:flex 2xl:mt-10">
        <div className="mb-5 flex flex-shrink-0 xl:mb-0 xl:mr-5 xl:w-1/2 2xl:mr-10">
          <BarChart
            title="Ärenden per fastighet"
            tooltip="Distribution av ärenden på olika fastigheter under den valda tidsperioden."
            color="#FEA4AF"
            period={state.facilityIssuesPeriod}
            startDate={state.facilityIssuesStartDate}
            endDate={state.facilityIssuesEndDate}
            onChangePeriod={state.setFacilityIssuesPeriod}
            onPrevious={state.previousFacilityIssuesDates}
            onNext={state.nextFacilityIssuesDates}
            data={state.facilityIssuesStats}
          />
        </div>
        {shouldShowParkingInfo && (
          <div className="flex xl:w-1/2">
            <LineChart
              title="Antal parkerade bilar"
              tooltip="Hur många besöksparkeringar det varit under den valda tidsperioden."
              color="#FBCD13"
              period={state.parkingsPeriod}
              startDate={state.parkingsStartDate}
              endDate={state.parkingsEndDate}
              onChangePeriod={state.setparkingsPeriod}
              onPrevious={state.previousParkingsDates}
              onNext={state.nextParkingsDates}
              periods={state.parkingsStats}
            />
          </div>
        )}
      </div>
    </FadeInDiv>
  );
});
