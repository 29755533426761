import Downshift from 'downshift';
import React, { FC } from 'react';
import { ChevronDownIcon } from '../../icons/ChevronDownIcon';

type IdNameObj = { id: number; name: string };

type SelectProps = {
  combobox?: boolean;
  name: string;
  label: string;
  required?: boolean;
  placeholder: string;
  items: IdNameObj[];
  noMatchText?: string;
  initialValue?: string;
  onChange?: (selection: IdNameObj) => void;
};

export const Select: FC<SelectProps> = ({
  combobox = false,
  name,
  label,
  required = false,
  placeholder,
  items,
  noMatchText = '',
  initialValue,
  onChange,
}) => {
  return (
    <Downshift
      onChange={(selection) => {
        onChange && onChange(selection);
      }}
      itemToString={(item) => (item ? item.name : '')}
      initialInputValue={initialValue}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        getToggleButtonProps,
        isOpen,
        inputValue,
        highlightedIndex,
        getRootProps,
        clearSelection,
      }) => {
        const lowercasedInputValue =
          inputValue?.toLowerCase().replace(' ', '') ?? '';
        // When the component is not a combobox (i.e. it's a regular select) we don't
        // want to filter the items by the input.
        const filteredItems = !combobox
          ? items
          : items.filter((item) =>
              item.name
                .toLowerCase()
                .replace(' ', '')
                .includes(lowercasedInputValue),
            );

        return (
          <div>
            <label
              {...getLabelProps({
                className: 'inline-block font-semibold mb-2',
              })}
            >
              {label} {required && <span className="text-red-500">*</span>}
            </label>
            <div
              {...getRootProps({ className: 'relative' } as any, {
                suppressRefError: true,
              })}
            >
              {combobox ? (
                <input
                  {...getInputProps({
                    name,
                    required,
                    className:
                      'block w-full text-sm py-3 pl-3 pr-10 border border-solid border-gray-200 rounded-md focus:outline-none focus:border-blue-500',
                    type: 'text',
                    placeholder,
                    autoComplete: 'off',
                    onChange: (e) => {
                      if (e.target.value === '') {
                        clearSelection();
                      }
                    },
                  })}
                />
              ) : (
                <>
                  {/* We use a real select as button for our custom select so we can take advantage
                    of the native focus/error handling of the browser. */}
                  <select
                    {...getToggleButtonProps({
                      name,
                      required,
                      value: inputValue!,
                      className: `block relative bg-white w-full text-sm p-3 border border-solid border-gray-200 rounded-md appearance-none text-left focus:outline-none focus:border-blue-500 ${
                        inputValue ? '' : 'text-gray-400'
                      }`,
                      onMouseDown: (e) => e.preventDefault(),
                    })}
                  >
                    <option value="">{placeholder}</option>
                    {/* We still need to render the options so the select "button" content is rendered properly. */}
                    {items.map((item) => (
                      <option key={item.id} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </>
              )}
              <button
                {...getToggleButtonProps({
                  className:
                    'absolute top-0 right-0 focus:outline-none h-full px-2',
                  type: 'button',
                  tabIndex: -1,
                })}
              >
                <ChevronDownIcon
                  className={`w-6 transform text-gray-500 transition-transform ${
                    isOpen ? 'rotate-180' : ''
                  }`}
                />
              </button>
            </div>
            <div className="relative">
              <ul
                {...getMenuProps({
                  className:
                    'absolute w-full bg-white max-h-56 overflow-y-auto rounded-md shadow-md mt-1 z-10',
                })}
              >
                {isOpen ? (
                  filteredItems.length !== 0 ? (
                    filteredItems.map((item, index) => (
                      <li
                        key={item.id}
                        {...getItemProps({
                          className: `text-sm px-3 py-2 border-b border-solid border-gray-200 cursor-pointer ${
                            highlightedIndex === index ? 'bg-gray-200' : ''
                          }`,
                          item,
                          index,
                        })}
                      >
                        {item.name}
                      </li>
                    ))
                  ) : (
                    <li className="border-b border-solid border-gray-200 px-3 py-2 text-sm">
                      {noMatchText}
                    </li>
                  )
                ) : null}
              </ul>
            </div>
          </div>
        );
      }}
    </Downshift>
  );
};
