import React, { forwardRef, InputHTMLAttributes } from 'react';
import { FormError } from './FormError';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: string | false;
}

export const InputWithError = forwardRef<HTMLInputElement, Props>(
  ({ error, ...rest }, ref) => {
    return (
      <>
        <input
          className={`z-10 w-full rounded-md border border-solid p-3 text-gray-900 outline-none placeholder:text-gray-400 disabled:bg-gray-100 ${
            error ? 'border-pink-500' : 'focus:border-indigo-600'
          }`}
          ref={ref}
          {...rest}
        />
        {error && <FormError>{error}</FormError>}
      </>
    );
  },
);
