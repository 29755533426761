import { flow, Instance, types as t } from 'mobx-state-tree';
import { withRequest } from '../extensions';
import { ParkingRestrictionFormData } from '../pages';
import { OrganizationFeatures } from '../types';
import { Company } from './Company';
import { Facility } from './Facility';
import { IssueType } from './IssueType';
import { ParkingSettingsInstance, Settings } from './Settings';

export const Organization = t
  .model('Organization', {
    id: t.identifierNumber,
    name: t.string,
    slug: t.string,
    issue_types: t.array(IssueType),
    facilities: t.array(Facility),
    companies: t.array(Company),
    features: t.array(
      t.enumeration<OrganizationFeatures>(
        'features',
        Object.values(OrganizationFeatures),
      ),
    ),
    settings: t.maybeNull(Settings),
  })
  .views((self) => ({
    get hasIssuesFeature() {
      return self.features.includes(OrganizationFeatures.Issues);
    },
    get hasParkingFeature() {
      return self.features.includes(OrganizationFeatures.Parking);
    },
    get hasIssueCostsFeature() {
      return self.features.includes(OrganizationFeatures.IssueCosts);
    },
    get hasRoundsFeature() {
      return self.features.includes(OrganizationFeatures.Rounds);
    },
    get hasEntreFeature() {
      return self.features.includes(OrganizationFeatures.Entre);
    },
    get activeFacilities() {
      return self.facilities.filter((f) => f.is_active);
    },
    get activeCompanies() {
      return self.companies.filter((c) => c.is_active);
    },
  }))
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;

    const patchSettingsRequest = (data: any) => {
      return request({
        method: 'PATCH',
        url: '/api/organization/settings/parking/',
        data,
      });
    };

    return {
      setParkingSettings: flow(function* (data: ParkingRestrictionFormData) {
        const currentSettings = self.settings;
        const { frequency, frequencyType, redirectUrl } = data;

        const parkingSetting = {
          restriction_frequency: frequency,
          restriction_frequency_type: frequencyType,
          restriction_redirect_url: redirectUrl,
        };

        self.settings = {
          ...self.settings,
          parking: parkingSetting as ParkingSettingsInstance,
        };

        try {
          yield patchSettingsRequest(parkingSetting);
        } catch (error) {
          self.settings = currentSettings;
        }
      }),
      deleteParkingSettings: flow(function* () {
        const { parking, ...restSettings } = self.settings!;

        self.settings = restSettings as any;

        try {
          yield patchSettingsRequest(null);
        } catch (error) {
          self.settings = {
            ...restSettings,
            parking,
          };
        }
      }),
    };
  });

export type OrganizationInstance = Instance<typeof Organization>;
