import React, { useLayoutEffect, ChangeEvent, FC } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { IssueInstance } from '../../../models';
import { NoteType, WriteNoteBox } from './WriteNoteBox';
import { Note } from './Note';
import { Pagination } from '../../../components';
import { useStore } from '../../../hooks';
import { Attachment } from './Attachment';
import { NOTES_PER_PAGE } from '../../../constants';
import { isImage } from '../../../utils';

interface NotesProps {
  issue: IssueInstance;
  page: number;
  handlePageChange: (page: number) => void;
}

export const Notes: FC<NotesProps> = observer(
  ({ issue, page, handlePageChange }) => {
    const { userStore } = useStore();
    const state = useLocalObservable(() => ({
      issue: issue,
      setIssue(issue: IssueInstance) {
        state.issue = issue;
      },
      noteValue: '',
      setNoteValue(value: string) {
        state.noteValue = value;
      },

      noteType: NoteType.Internal,
      setNoteType(noteType: NoteType) {
        state.noteType = noteType;
      },

      fileErrorMessage: '',
      filesToUpload: [] as File[],
      setFilesToUpload(files: File[]) {
        state.filesToUpload = files;
      },
      handleFileChange(e: ChangeEvent<HTMLInputElement>) {
        const maxUploads = 4;
        const files = e.target.files!;

        state.fileErrorMessage = '';

        Array.from(files).forEach((file) => {
          if (!isImage(file)) {
            state.fileErrorMessage = 'Endast bilder går att bifoga';
          }
        });

        if (state.filesToUpload.length + files.length > maxUploads) {
          state.fileErrorMessage = `Max ${maxUploads} bilder`;
        }

        if (!state.fileErrorMessage) {
          Array.from(files).forEach((file) => {
            state.filesToUpload.push(file);
          });
        }

        e.target.value = '';
      },
      removeFile(index: number) {
        state.fileErrorMessage = '';

        state.filesToUpload.splice(index, 1);
      },
      async submitNote() {
        const currentValue = state.noteValue;
        const currentFiles = state.filesToUpload;
        const isExternal = state.noteType === NoteType.External;

        state.fileErrorMessage = '';

        try {
          state.setNoteValue('');
          state.setFilesToUpload([]);

          await issue.addNote(
            userStore.me!.id,
            currentValue,
            isExternal,
            // We don't want to add any attachments to external notes
            isExternal ? [] : currentFiles,
          );

          state.setNoteType(NoteType.Internal);
        } catch (error) {
          state.setNoteValue(currentValue);
          state.setFilesToUpload(currentFiles);
        }
      },
      notePage: page,
      setNotePage(page: number) {
        state.notePage = page;
      },
      get filteredNotes() {
        const { notes } = state.issue;
        if (!notes) return null;

        return notes.slice(
          (state.notePage - 1) * NOTES_PER_PAGE,
          state.notePage * NOTES_PER_PAGE,
        );
      },
      get numNotePages() {
        const notes = issue.notes;
        if (!notes) return 1;

        return Math.max(1, Math.ceil(notes.length / NOTES_PER_PAGE));
      },
    }));

    // Deleting the last note of the last page will put the user on an empty
    // page, so we change the page to the new last page.
    useLayoutEffect(() => {
      if (state.notePage > state.numNotePages) {
        handlePageChange(state.numNotePages);
      }
    }, [state.notePage, state.numNotePages]);

    useLayoutEffect(() => {
      state.setIssue(issue);
    }, [issue]);

    useLayoutEffect(() => {
      state.setNotePage(page);
    }, [page]);

    return (
      <div className="p-7">
        <span className="mb-3 hidden font-semibold text-gray-900 md:block">
          Anteckningar
        </span>

        <div className="flex flex-col 2xl:flex-row">
          <div className="w-auto max-w-3xl 2xl:mr-7 2xl:w-1/2">
            <WriteNoteBox
              value={state.noteValue}
              onChange={(e) => state.setNoteValue(e.target.value)}
              noteType={state.noteType}
              onNoteTypeChange={state.setNoteType}
              onFileChange={state.handleFileChange}
              onSubmit={state.submitNote}
              filesToUpload={state.filesToUpload}
              hasReporter={Boolean(issue.reporter_email)}
            />
            {state.fileErrorMessage.length > 0 && (
              <div className="w-100 mt-1 py-3 text-xs text-red-500">
                {state.fileErrorMessage}
              </div>
            )}
            {state.filesToUpload.length > 0 &&
              state.noteType === NoteType.Internal &&
              state.filesToUpload.map((file, index) => (
                <div
                  key={`${index}-${file.name}`}
                  className="mr-3 mt-1 inline-block md:mr-3 md:mt-3"
                >
                  <Attachment
                    name={file.name}
                    onDelete={() => state.removeFile(index)}
                  />
                </div>
              ))}
          </div>

          {state.filteredNotes !== null && state.filteredNotes.length !== 0 && (
            <div className="w-auto flex-grow 2xl:w-1/2">
              {state.filteredNotes!.map((note, index) => (
                <div
                  key={note.id}
                  className={`${
                    index === 0 ? 'mt-5 2xl:mt-0' : 'mt-7 2xl:mt-5'
                  }`}
                >
                  <Note note={note} />
                </div>
              ))}

              {state.numNotePages > 1 && (
                <div className="mt-7">
                  <Pagination
                    page={page}
                    numPages={state.numNotePages}
                    onChange={handlePageChange}
                    hasBorder
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  },
);
