import React, { FC, memo, SVGProps } from 'react';

export const DashboardIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 16"
    fill="none"
    className="w-4"
    {...props}
  >
    <path
      fill="currentColor"
      d="M2.64 15.857h14.079c.282 0 .54-.117.704-.352 3.215-4.27 2.347-10.348-1.924-13.563C11.229-1.273 5.15-.404 1.936 3.866a9.715 9.715 0 000 11.639.9.9 0 00.704.352zm-.446-8.753l1.572.916c.235.117.516.047.634-.165l.399-.704a.455.455 0 00-.165-.633l-1.69-.986a7.963 7.963 0 015.844-3.707v2.112a.47.47 0 00.47.469h.82a.47.47 0 00.47-.47v-2.11c2.417.258 4.575 1.642 5.866 3.707l-1.69.986c-.234.14-.305.422-.164.633l.4.704a.442.442 0 00.633.165l1.572-.916a8.017 8.017 0 01-.892 6.993H3.11a7.933 7.933 0 01-1.337-4.411c0-.869.14-1.737.422-2.582z"
    ></path>
    <path
      fill="currentColor"
      d="M10.477 10.953c1.267-1.267 2.23-4.2 2.23-4.2.164-.446-.071-.68-.54-.517 0 0-2.933.962-4.2 2.23a1.77 1.77 0 000 2.487 1.8 1.8 0 002.51 0z"
    ></path>
  </svg>
));
