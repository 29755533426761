import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { Label } from '../Label';

type InputProps = {
  title?: string;
  label?: string;
  required?: boolean;
  name: string;
  type?: string;
  placeholder?: string;
  autoFocus?: boolean;
  pattern?: string;
  patternText?: string;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      title,
      label,
      required = false,
      name,
      type = 'text',
      placeholder,
      autoFocus = false,
      pattern,
      patternText,
    },
    ref,
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
      if (autoFocus) {
        // We need a timeout of length 0 here to do the focus in the next event
        // loop iteration to circumvent Reach UI's own focus management.
        setTimeout(() => innerRef.current?.focus(), 0);
      }
    }, []);

    useImperativeHandle(ref, () => innerRef.current as HTMLInputElement);

    return (
      <>
        {label && (
          <Label htmlFor={name} required={required}>
            {label}
          </Label>
        )}
        <input
          title={title}
          ref={innerRef}
          id={name}
          name={name}
          type={type}
          placeholder={placeholder}
          pattern={pattern}
          className="block w-full rounded-md border border-solid border-gray-200 p-3 text-sm focus:border-blue-500 focus:outline-none"
          required={required}
        />
        {patternText && (
          <span className="mt-2 text-xs text-gray-500">{patternText}</span>
        )}
      </>
    );
  },
);
