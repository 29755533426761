import { endOfDay, startOfDay } from 'date-fns';

export const getDateRangeParams = (dateRange: string, name: string) => {
  const dates = dateRange.split('/');
  const params = new URLSearchParams();

  if (dates.length > 1) {
    params.append(
      `${name}_gte`,
      startOfDay(new Date(dates[0].trim())).toISOString(),
    );
    params.append(
      `${name}_lte`,
      endOfDay(new Date(dates[1].trim())).toISOString(),
    );
  }

  return params;
};
