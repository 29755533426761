import { startOfDay } from 'date-fns';
import { endOfDay } from 'date-fns/esm';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CrossIcon2 } from '../icons';
import { Daterangepicker } from './Daterangepicker';
import { Select } from './Select';

export interface FilterObj {
  name: string;
  value: string;
}

export interface FilterTypeObj extends FilterObj {
  type?: 'text' | 'date' | 'search';
}

export interface Filter {
  filterType: FilterTypeObj | null;
  filterValue: FilterObj | null;
}

interface FilterPickerState {
  search: string;
  setSearch: (search: string) => void;
  activeFilters: Filter[];
  addFilter: (filter: Filter) => void;
  updateFilterType: (index: number, type: FilterTypeObj) => void;
  updateFilterValue: (index: number, value: FilterObj) => void;
  removeFilter: (index: number) => void;
  clearFilters: () => void;
  applyFilters: () => void;
}

interface FilterPickerProps {
  filters: Filter[];
  filterTypes: string[];
  getFilterValueItems(filter: string): FilterObj[];
  filterTypeItems: FilterTypeObj[];
  onSearch?: (term: string) => void;
}

export const FilterPicker: FC<FilterPickerProps> = observer(
  ({
    filterTypes,
    filters,
    getFilterValueItems,
    filterTypeItems,
    onSearch,
  }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const state = useLocalObservable<FilterPickerState>(() => ({
      search: location.search,
      setSearch(search) {
        state.search = search;
      },
      activeFilters: [],
      addFilter(filter) {
        state.activeFilters.push(filter);
      },
      updateFilterType(index, type) {
        const filter = state.activeFilters[index];
        filter.filterType = type;
        filter.filterValue = null;
      },
      updateFilterValue(index, value) {
        state.activeFilters[index].filterValue = value;
        state.applyFilters();
      },
      removeFilter(index) {
        state.activeFilters.splice(index, 1);
        state.applyFilters();
      },
      clearFilters() {
        state.activeFilters = [];
      },
      applyFilters() {
        const searchParams = new URLSearchParams(state.search);
        searchParams.delete('page');

        filterTypes.forEach((filter) => {
          if (searchParams.has(filter)) {
            searchParams.delete(filter);
          }
        });

        state.activeFilters.forEach((filter) => {
          if (filter.filterType && filter.filterValue) {
            searchParams.append(
              filter.filterType.value,
              filter.filterValue.value,
            );
          }
        });

        navigate(`${location.pathname}?${searchParams.toString()}`, {
          replace: true,
        });
      },
    }));

    useLayoutEffect(() => {
      state.clearFilters();
      state.setSearch(location.search);

      if (filters && filters?.length < 1) {
        state.addFilter({ filterType: null, filterValue: null });
        return;
      }

      filters?.forEach((filter) => {
        state.addFilter(filter);
      });
    }, [filters, location.search]);

    return (
      <div className="h-full w-full space-y-2 text-gray-500">
        {state.activeFilters.map((filter, index) => (
          <FilterRow
            key={`${filter.filterType?.value}-${filter.filterValue?.value}-${index}`}
            filterTypeItems={filterTypeItems}
            selectedType={filter.filterType}
            onSelectedTypeChange={(filterType) =>
              state.updateFilterType(index, filterType)
            }
            filterValueItems={getFilterValueItems(
              filter.filterType?.value as string,
            )}
            selectedValue={filter.filterValue}
            onSelectedValueChange={(filterValue) =>
              state.updateFilterValue(index, filterValue)
            }
            onRemove={() => state.removeFilter(index)}
            onSearch={onSearch}
          />
        ))}
        <button
          className="rounded-md bg-indigo-600 p-3 text-white transition-colors duration-200 hover:bg-indigo-900"
          onClick={() =>
            state.addFilter({ filterType: null, filterValue: null })
          }
        >
          Lägg till filter
        </button>
      </div>
    );
  },
);

interface FilterRowProps {
  filterTypeItems: FilterTypeObj[];
  selectedType: FilterTypeObj | null;
  onSelectedTypeChange: (filterType: FilterTypeObj) => void;

  filterValueItems: FilterObj[];
  selectedValue: FilterObj | null;
  onSelectedValueChange: (filterValue: FilterObj) => void;

  onRemove?: () => void;
  onSearch?: (term: string) => void;
}

interface FilterRowState {
  showDatePicker: boolean;
  toggleShowDatePicker: () => void;
  handleApplyDates: (dates: [Date, Date]) => void;
  selectedDates: [Date, Date];
  searchTerm: string;
}

const FilterRow: FC<FilterRowProps> = observer(
  ({
    filterTypeItems,
    selectedType,
    onSelectedTypeChange,
    filterValueItems,
    selectedValue,
    onSelectedValueChange,
    onRemove,
    onSearch,
  }) => {
    const state = useLocalObservable<FilterRowState>(() => ({
      showDatePicker: false,
      toggleShowDatePicker() {
        state.showDatePicker = !state.showDatePicker;
      },
      handleApplyDates(dates) {
        state.toggleShowDatePicker();

        const dateString = `${dates[0].toLocaleDateString(
          'sv-SE',
        )} / ${dates[1].toLocaleDateString('sv-SE')}`;

        onSelectedValueChange({ name: dateString, value: dateString });
      },
      get selectedDates() {
        if (selectedValue?.value && selectedType?.type === 'date') {
          const dates = selectedValue.value.split('/');
          return [
            startOfDay(new Date(dates[0].trim())),
            endOfDay(new Date(dates[1].trim())),
          ] as [Date, Date];
        }
        return [new Date(), new Date()] as [Date, Date];
      },
      searchTerm: '',
      setSearchTerm(term: string) {
        state.searchTerm = term;
      },
    }));

    return (
      <>
        <div className="flex items-center space-x-2">
          <div className="min-w-36">
            <Select
              placeholder="Välj filter"
              selected={selectedType}
              items={filterTypeItems}
              onSelect={(filterType) => onSelectedTypeChange(filterType)}
            />
          </div>
          <div className="rounded-md border border-solid border-gray-200 p-3">
            är
          </div>
          <div className="min-w-0 flex-1">
            {selectedType?.type === 'date' ? (
              <>
                <button
                  onClick={state.toggleShowDatePicker}
                  className="w-full truncate rounded-md border border-solid border-gray-200 p-3 text-left"
                >
                  {selectedValue?.name || 'Välj'}
                </button>
              </>
            ) : selectedType?.type === 'search' ? (
              <Select
                disabled={!selectedType}
                placeholder="Välj"
                selected={selectedValue as FilterObj}
                items={filterValueItems}
                onSelect={(filterValue) => onSelectedValueChange(filterValue)}
                search
                onSearch={onSearch}
              />
            ) : (
              <Select
                disabled={!selectedType}
                placeholder="Välj"
                selected={selectedValue as FilterObj}
                items={filterValueItems}
                onSelect={(filterValue) => onSelectedValueChange(filterValue)}
              />
            )}
          </div>
          {onRemove && (
            <button onClick={onRemove}>
              <CrossIcon2 />
            </button>
          )}
        </div>
        {state.showDatePicker && selectedType?.type === 'date' && (
          <div className="z-50 md:absolute md:-left-12 md:-top-10">
            <Daterangepicker
              className="rounded-t-2xl"
              show
              dateRange={state.selectedDates}
              onApply={state.handleApplyDates}
              onCancel={state.toggleShowDatePicker}
            />
          </div>
        )}
      </>
    );
  },
);
