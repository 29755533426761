import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { useForm } from 'react-hook-form';
import {
  FadeInDiv,
  ImageCrop,
  TagSelect,
  UploadImageButton,
  InputWithError,
  Label,
} from '../components';
import { CheckIcon } from '../icons';
import { useStore } from '../hooks';
import { AccountType } from '../types';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import { useNavigate } from 'react-router-dom';

interface FormData {
  name: string;
  email: string;
  phoneNumber: string;
}

interface State {
  isSubmitting: boolean;
  setIsSubmitting: (isSubmiting: boolean) => void;
  onSubmit: (email: string, name: string, phoneNumber: string) => void;

  hasFormError: boolean;
  setHasFormError: (hasForError: boolean) => void;

  accountType: AccountType;
  setAccountType: (type: AccountType) => void;

  isImageCropOpen: boolean;
  setIsImageCropOpen: (isImageCropOpen: boolean) => void;

  imageBlob: Blob | null;
  setImageBlob: (imageBlob: Blob | null) => void;
  imageSrc: string;

  handleImageSave: (blob: Blob) => void;
}

export const CreateUserPage = observer(() => {
  const navigate = useNavigate();
  const { userStore } = useStore();

  const state = useLocalObservable<State>(() => ({
    isSubmitting: false,
    setIsSubmitting(isSubmitting) {
      state.isSubmitting = isSubmitting;
    },

    hasFormError: false,
    setHasFormError(hasFormError) {
      state.hasFormError = hasFormError;
    },

    async onSubmit(email, name, phoneNumber) {
      try {
        state.setHasFormError(false);
        state.setIsSubmitting(true);
        await userStore.createUser(
          name,
          email,
          state.accountType,
          phoneNumber,
          state.imageBlob,
        );
        navigate('/organization/users');
      } catch (error) {
        state.setHasFormError(true);
      }

      state.setIsSubmitting(false);
    },

    accountType: AccountType.Worker,
    setAccountType(type) {
      state.accountType = type;
    },
    isImageCropOpen: false,
    setIsImageCropOpen(isImageCropOpen) {
      state.isImageCropOpen = isImageCropOpen;
    },
    imageBlob: null,
    setImageBlob(imageBlob) {
      state.imageBlob = imageBlob;
    },
    get imageSrc() {
      let imageSrc = '';
      if (state.imageBlob) {
        imageSrc = URL.createObjectURL(state.imageBlob);
      }
      return imageSrc;
    },
    handleImageSave(blob) {
      state.setImageBlob(blob);
      state.setIsImageCropOpen(false);
    },
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    shouldUnregister: true,
    reValidateMode: 'onSubmit',
  });

  return (
    <FadeInDiv className="h-full w-full p-4 md:p-14">
      <div className="block rounded-md bg-white p-7 md:inline-block">
        <span className="w-full text-xl font-extrabold text-indigo-900">
          Skapa ny användare
        </span>
      </div>
      <form
        onSubmit={handleSubmit(({ email, name, phoneNumber }) =>
          state.onSubmit(email, name, phoneNumber),
        )}
      >
        <div className="mt-5 space-y-6 rounded-md bg-white p-7 md:mt-10">
          <div className="mb-8 flex max-w-fit flex-col space-y-3">
            <span className="font-semibold text-gray-900">Profilbild</span>
            <UploadImageButton
              imageSrc={state.imageSrc}
              onClick={() => state.setIsImageCropOpen(true)}
              onDelete={() => state.setImageBlob(null)}
            />
          </div>
          <div className="max-w-xs">
            <span className="font-semibold text-gray-900">Roll</span>
            <div className="mt-1">
              <TagSelect
                type="role"
                onSelect={(type) => state.setAccountType(type as AccountType)}
                selected={state.accountType}
                showBorderAndChevron
              />
            </div>
          </div>

          <div className="max-w-xs">
            <Label required>Namn</Label>
            <InputWithError
              type="text"
              placeholder="Namn"
              {...register('name', {
                required: 'Fyll i detta fältet',
                maxLength: {
                  value: 150,
                  message: 'Max 150 tecken',
                },
              })}
              error={errors.name?.message}
            />
          </div>
          <div className="flex flex-col space-y-6 xl:flex-row xl:space-y-0">
            <div className="max-w-xs flex-1 xl:mr-7">
              <Label required>E-post</Label>
              <InputWithError
                type="text"
                placeholder="namn.namnsson@e-post.se"
                {...register('email', {
                  required: 'Fyll i detta fältet',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Ange en giltig e-post',
                  },
                })}
                error={
                  errors.email
                    ? errors.email?.message
                    : state.hasFormError && 'E-posten är upptagen'
                }
              />
            </div>
            <div className="max-w-xs">
              <Label>Telefonnummer</Label>
              <InputWithError
                type="tel"
                placeholder="Telefonnummer"
                {...register('phoneNumber', {
                  maxLength: {
                    value: 20,
                    message: 'Max 20 tecken',
                  },
                })}
                error={errors.phoneNumber?.message}
              />
            </div>
          </div>
        </div>
        <button
          disabled={state.isSubmitting}
          type="submit"
          className="mt-5 flex w-full items-center justify-center rounded-md bg-white p-3 font-semibold text-gray-900 transition-colors hover:bg-indigo-600 hover:text-white md:mt-7 md:w-auto md:justify-start"
        >
          <CheckIcon className="w-5" />
          <span className="ml-3">Skapa</span>
        </button>
      </form>
      {state.isImageCropOpen && (
        <DialogOverlay
          className="absolute top-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-40"
          isOpen
          onDismiss={() => state.setIsImageCropOpen(false)}
        >
          <DialogContent
            aria-label="dialog"
            className="mx-3 w-[40rem] max-w-screen rounded-md bg-white p-3 sm:p-10"
          >
            <ImageCrop onSave={state.handleImageSave} />
          </DialogContent>
        </DialogOverlay>
      )}
    </FadeInDiv>
  );
});
