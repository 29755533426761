import React from 'react';
import { useForm } from 'react-hook-form';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useStore } from '../hooks';
import { FadeInDiv, Logo, InputWithError, Label } from '../components';
import { Link } from 'react-router-dom';
import { LoginIcon } from '../icons';

type FormData = {
  email: string;
  password: string;
};

export const LoginPage = observer(() => {
  const rootStore = useStore();
  const { authStore } = rootStore;

  const state = useLocalObservable(() => ({
    isSubmiting: false,
    setIsSubmitting(isSubmiting: boolean) {
      state.isSubmiting = isSubmiting;
    },

    hasFormError: false,
    setHasFormError(hasFormError: boolean) {
      state.hasFormError = hasFormError;
    },
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const onSubmit = handleSubmit(async ({ email, password }) => {
    if (state.isSubmiting) return;

    state.setIsSubmitting(true);
    state.setHasFormError(false);

    try {
      await authStore.logIn(email.toLowerCase(), password);
    } catch (error) {
      state.setHasFormError(true);
    } finally {
      state.setIsSubmitting(false);
    }
  });

  return (
    <FadeInDiv>
      <div className="flex h-screen w-full items-center justify-center">
        <div className="mx-4 w-full max-w-md rounded-md bg-white p-7 shadow-xl md:p-14 ">
          <div className="mb-14 flex justify-center">
            <div className="w-36">
              <Logo />
            </div>
          </div>
          {state.hasFormError && (
            <div
              className="mb-7 rounded-md border border-solid border-pink-500 p-3 text-pink-500"
              style={{ backgroundColor: '#EC48990F' }}
            >
              Vi känner tyvärr inte igen den här kombinationen av e-post och
              lösenord. Var vänlig försök igen.
            </div>
          )}
          <form onSubmit={onSubmit}>
            <div className="mb-5">
              <Label>E-post</Label>
              <InputWithError
                type="text"
                placeholder="namn.namnsson@e-post.se"
                {...register('email', {
                  required: 'Fyll i detta fältet',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Ange en giltig e-post',
                  },
                })}
                error={errors.email?.message}
              />
            </div>
            <div>
              <Label>Lösenord</Label>
              <input
                type="password"
                className="w-full rounded-md border border-solid py-2 pl-3"
                placeholder="**********"
                {...register('password', { required: true })}
              />
            </div>
            <div className="relative mt-2">
              <Link to="/forgot-password" className="absolute text-xs">
                Glömt ditt lösenord?
              </Link>
            </div>
            <div className="mt-14 text-center">
              <button className="inline-flex items-center rounded-md bg-indigo-900 p-3 text-white">
                <LoginIcon className="mr-3 inline-block w-5 text-white" />
                <span className="font-semibold">Logga in</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </FadeInDiv>
  );
});
