import React, { FC, memo, SVGProps } from 'react';

export const ChatIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className="w-4"
    {...props}
  >
    <path d="M4.4,21c-0.1,0-0.3,0-0.4-0.1c-0.4-0.2-0.6-0.5-0.6-0.9V5.8C3.4,4.2,4.6,3,6.1,3h12.4h0c1.5,0,2.8,1.2,2.8,2.8 v8.9c0,0.7-0.3,1.4-0.8,2c-0.5,0.5-1.2,0.8-2,0.8H8.3l-3.3,3.3C4.9,20.9,4.6,21,4.4,21z M6.1,5C5.9,5,5.7,5.1,5.6,5.2 C5.4,5.4,5.4,5.6,5.4,5.8v11.8l1.8-1.8c0.2-0.2,0.4-0.3,0.7-0.3h10.7c0.2,0,0.4-0.1,0.6-0.2c0.1-0.1,0.2-0.3,0.2-0.5v0V5.8 C19.4,5.3,19,5,18.6,5l0,0H6.1z" />
  </svg>
));
