import axios, { AxiosRequestConfig } from 'axios';
import { applySnapshot, flow, getRoot, types as t } from 'mobx-state-tree';
import { RootStore } from './RootStore';

export const AuthStore = t
  .model('AuthStore', {
    access_token: t.maybeNull(t.string),
    refresh_token: t.maybeNull(t.string),
  })
  .views((self) => ({
    get isLoggedIn() {
      return self.refresh_token !== null;
    },
  }))
  .actions((self) => {
    const rootStore = getRoot<typeof RootStore>(self);

    let refreshTokensPromise: null | Promise<any> = null;
    const refreshTokens = flow(function* () {
      // No catch clause because we want the caller to handle the error.
      try {
        const res = yield axios({
          method: 'POST',
          url: '/api/token/refresh/',
          data: {
            refresh: self.refresh_token,
          },
        });

        self.access_token = res.data.access;
      } finally {
        refreshTokensPromise = null;
      }
    });

    return {
      // Multiple requests could fail at the same time because of an old
      // access_token, so we want to make sure only one token refresh
      // request is sent.
      refreshTokens() {
        if (refreshTokensPromise) return refreshTokensPromise;

        refreshTokensPromise = refreshTokens();
        return refreshTokensPromise;
      },
      logIn: flow(function* (email, password) {
        const tokenRes = yield axios({
          method: 'POST',
          url: '/api/token/',
          data: {
            email,
            password,
          },
        });

        // token is not locked per organization, but the user associated
        // with the token is, so we try to fetch said user and if it fails
        // we know the user is trying to log into the wrong organization.
        yield axios({
          method: 'GET',
          url: '/api/user/',
          headers: {
            Authorization: `Bearer ${tokenRes.data.access}`,
          },
        });

        self.access_token = tokenRes.data.access;
        self.refresh_token = tokenRes.data.refresh;

        rootStore.init();
      }),
      logOut() {
        rootStore.reset();
      },
      reset() {
        applySnapshot(self, {
          access_token: null,
          refresh_token: null,
        });
      },
    };
  })
  .actions((self) => {
    const { uiStore } = getRoot<typeof RootStore>(self);

    return {
      authRequest: flow(function* (conf: AxiosRequestConfig) {
        const authConf = {
          ...conf,
          headers: {
            ...conf.headers,
            Authorization: `Bearer ${self.access_token}`,
          },
        };

        try {
          return yield axios(authConf);
        } catch (error: any) {
          const { status } = error.response;

          // Show the general error message unless it's an authorization or bad request error.
          if (![400, 401].includes(status)) {
            uiStore.showError();
          }
          // Throw the error to the caller if it's not an authorization error.
          if (status !== 401) {
            throw error;
          }

          try {
            yield self.refreshTokens();
          } catch (err) {
            // If refreshing of the tokens fail we have an old
            // refresh_token and we must log out the user.
            self.logOut();
            // We still throw the error so the caller doesn't
            // treat it as a successful request.
            throw err;
          }

          authConf.headers.Authorization = `Bearer ${self.access_token}`;

          return yield axios(authConf);
        }
      }),
    };
  });
