import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { SideMenuLink } from '.';
import { ChevronDownIcon } from '../../icons';

export interface SubPageLink {
  to: string;
  text: string;
  icon: JSX.Element;
  disableActiveClass?: boolean;
}

interface SideMenuAccordionProps {
  to: string;
  end?: boolean;
  isMenuExpanded: boolean;
  subPageLinks: SubPageLink[] | null;
  showSubPages: boolean;
  disableActiveClass?: boolean;
  onClick?: () => void;
  onChevronClick: () => void;
  onSubPageClick?: () => void;
}

export const SideMenuAccordion: FC<SideMenuAccordionProps> = observer(
  ({
    isMenuExpanded,
    to,
    end = false,
    subPageLinks,
    showSubPages,
    disableActiveClass = false,
    onClick,
    onChevronClick,
    onSubPageClick,
    children,
  }) => {
    return (
      <div className="relative mt-6">
        <SideMenuLink
          to={to}
          onClick={onClick}
          end={end}
          disableActiveClass={disableActiveClass}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">{children}</div>
          </div>
        </SideMenuLink>
        {showSubPages && (
          <div className={`space-y-6 ${isMenuExpanded ? 'ml-10' : ''}`}>
            {subPageLinks?.map((link, index) => (
              <div key={index} className={`${index === 0 ? 'mt-6' : ''}`}>
                <SideMenuLink
                  to={link.to}
                  onClick={onSubPageClick}
                  disableActiveClass={link.disableActiveClass}
                >
                  {link.icon}
                  {isMenuExpanded && <span>{link.text}</span>}
                </SideMenuLink>
              </div>
            ))}
          </div>
        )}
        {isMenuExpanded && (
          <button
            onClick={onChevronClick}
            className="absolute right-0 top-0 transition-colors duration-300 hover:text-pink-500"
          >
            <ChevronDownIcon
              className={`w-6 ${showSubPages ? 'rotate-180' : ''}`}
            />
          </button>
        )}
      </div>
    );
  },
);
