import React from 'react';
import { IssueStatus } from '../types';

interface Props {
  type: IssueStatus;
}

const colors = {
  [IssueStatus.Waiting]: 'bg-yellow-400',
  [IssueStatus.InProgress]: 'bg-indigo-900',
  [IssueStatus.Done]: 'bg-rose-300',
};

const texts = {
  [IssueStatus.Waiting]: 'Ohanterad',
  [IssueStatus.InProgress]: 'Åtgärdas',
  [IssueStatus.Done]: 'Åtgärdat',
};

export const StatusTag: React.FC<Props> = ({ type }) => {
  const color = colors[type];
  const text = texts[type];

  return (
    <div
      className={`${color} flex items-center justify-center rounded-md px-3 py-1 text-sm font-semibold text-white`}
    >
      {text}
    </div>
  );
};
