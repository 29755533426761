import React, { ButtonHTMLAttributes, FC } from 'react';
import { CheckIcon } from '../icons';

interface SaveButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isSaved: boolean;
}

export const SaveButton: FC<SaveButtonProps> = ({ isSaved, ...restProps }) => {
  return (
    <button
      type="submit"
      className={`flex w-full items-center justify-center space-x-3 rounded-md p-3 font-semibold transition-colors md:w-auto md:justify-start ${
        isSaved
          ? 'bg-emerald-400 text-white'
          : 'bg-white text-gray-900 hover:bg-indigo-600 hover:text-white'
      }`}
      {...restProps}
    >
      <CheckIcon className="h-5 w-5" />
      <span>{isSaved ? 'Sparad' : 'Spara'}</span>
    </button>
  );
};
