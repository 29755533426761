import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { FilterPicker, FilterObj, Filter } from '.';
import { ISSUES_PRIORITY_TEXTS, STATUS_TEXTS } from '../constants';
import { useStore } from '../hooks';
import { IssueFilterInstance } from '../models';
import { IssuePriority, IssueStatus, IssuesFilter } from '../types';

const filterTypeName = {
  [IssuesFilter.Facility]: 'Fastighet',
  [IssuesFilter.IssueType]: 'Ärendetyp',
  [IssuesFilter.Company]: 'Företag',
  [IssuesFilter.Priority]: 'Prioritet',
  [IssuesFilter.AssignedTo]: 'Tilldelad',
  [IssuesFilter.CreatedAt]: 'Skapad',
  [IssuesFilter.ResolvedAt]: 'Avslutad',
  [IssuesFilter.Status]: 'Status',
};

interface IssuesFilterPickerState {
  getFilterValueItems: (filter: IssuesFilter) => FilterObj[];
  filterTypeItems: FilterObj[];
  filters: Filter[];
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  handleSearch: (term: string) => void;
}

export const IssuesFilterPicker = observer(() => {
  const location = useLocation();

  const {
    organizationStore: { organization },
    userStore: { users },
    issueStore: {
      openIssuesActiveFilters,
      closedIssuesActiveFilters,
      dashboardActiveFilters,
    },
  } = useStore();

  const state: IssuesFilterPickerState =
    useLocalObservable<IssuesFilterPickerState>(() => ({
      searchTerm: '',
      setSearchTerm(term: string) {
        this.searchTerm = term;
      },
      handleSearch(term: string) {
        this.setSearchTerm(term);
      },
      get filters() {
        let filters: IssueFilterInstance[] = [];
        if (location.pathname === '/issues' && openIssuesActiveFilters) {
          filters = openIssuesActiveFilters;
        } else if (
          location.pathname === '/issues/complete' &&
          closedIssuesActiveFilters
        ) {
          filters = closedIssuesActiveFilters;
        } else if (
          location.pathname === '/dashboard' &&
          dashboardActiveFilters
        ) {
          filters = dashboardActiveFilters;
        }

        return filters!.map((filter) => {
          const filterType = {
            name: filterTypeName[filter.name as IssuesFilter],
            value: filter.name,
            type:
              filter.name === IssuesFilter.CreatedAt ||
              filter.name === IssuesFilter.ResolvedAt
                ? 'date'
                : filter.name === IssuesFilter.Facility
                ? 'search'
                : 'text',
          };

          const value =
            typeof filter.value === 'string'
              ? filter.value
              : filter.value?.id.toString();

          if (!value) return;

          const filterValue = {
            name: filter.displayName || '',
            value,
          };

          return { filterType, filterValue };
        }) as Filter[];
      },
      getFilterValueItems(filter) {
        switch (filter) {
          case IssuesFilter.AssignedTo:
            return users!.map((u) => {
              return { name: u.name, value: u.id.toString() };
            });
          case IssuesFilter.Company:
            return organization!.companies.map((c) => ({
              name: c.name,
              value: c.id.toString(),
            }));
          case IssuesFilter.Facility:
            if (!state.searchTerm) {
              return organization!.facilities.map((f) => ({
                name: f.name,
                value: f.id.toString(),
              }));
            }
            return organization!.facilities
              .filter((facility) =>
                facility.name
                  .toLowerCase()
                  .includes(state.searchTerm.toLowerCase()),
              )
              .map((f) => ({
                name: f.name,
                value: f.id.toString(),
              }));
          case IssuesFilter.IssueType:
            return organization!.issue_types
              .map((t) => {
                if (t.children && t.children.length > 0) {
                  return t.children.map(({ name, id }) => ({
                    name,
                    value: id.toString(),
                  }));
                }
                return [
                  {
                    name: t.name,
                    value: t.id.toString(),
                  },
                ];
              })
              .flat();
          case IssuesFilter.Priority:
            return Object.values(IssuePriority)
              .filter((value) => Number.isInteger(value))
              .map((value) => ({
                name: ISSUES_PRIORITY_TEXTS[value as IssuePriority],
                value: value as string,
              }));
          case IssuesFilter.Status:
            return Object.values(IssueStatus)
              .filter((status) => status !== IssueStatus.Done)
              .map((status) => ({
                name: STATUS_TEXTS[status as IssueStatus],
                value: status as string,
              }));
          default:
            return [];
        }
      },
      get filterTypeItems() {
        let items = Object.values(IssuesFilter).map((value) => ({
          name: filterTypeName[value],
          value,
          type:
            value === IssuesFilter.CreatedAt ||
            value === IssuesFilter.ResolvedAt
              ? 'date'
              : value === IssuesFilter.Facility
              ? 'search'
              : 'text',
        }));

        if (
          state.filters.some(
            (f) => f.filterType?.value === IssuesFilter.AssignedTo,
          )
        ) {
          items = items.filter((i) => i.value !== IssuesFilter.AssignedTo);
        }

        if (
          state.filters.some(
            (f) => f.filterType?.value === IssuesFilter.CreatedAt,
          )
        ) {
          items = items.filter((i) => i.value !== IssuesFilter.CreatedAt);
        }

        if (
          state.filters.some(
            (f) => f.filterType?.value === IssuesFilter.ResolvedAt,
          ) ||
          location.pathname === '/issues'
        ) {
          items = items.filter((i) => i.value !== IssuesFilter.ResolvedAt);
        }

        if (location.pathname !== '/issues') {
          items = items.filter((i) => i.value !== IssuesFilter.Status);
        }

        return items;
      },
    }));

  return (
    <FilterPicker
      filters={state.filters}
      filterTypes={Object.values(IssuesFilter)}
      filterTypeItems={state.filterTypeItems}
      getFilterValueItems={state.getFilterValueItems}
      onSearch={state.handleSearch}
    />
  );
});
