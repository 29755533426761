import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FadeInDiv, DeletePopup, InputWithError } from '../components';
import { TrashCanIcon } from '../icons';
import { useStore } from '../hooks';
import { ArticleInstance } from '../models';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitState } from '../types';
import { SaveButton } from '../components/SaveButton';
import { SAVE_BUTTON_ANIMATION_DURATION } from '../constants';

interface ArticleParams {
  articleId: string;
}

export const EditArticlePage = observer(() => {
  const params = useParams<keyof ArticleParams>() as ArticleParams;
  const { articleStore } = useStore();

  const articleId = parseInt(params.articleId, 10);
  const article = articleStore.articles?.find(
    (article) => article.id === articleId,
  );

  if (!article) return null;

  return <EditArticle article={article} />;
});

interface EditArticleState {
  submitState: SubmitState;
  setSubmitState: (submitState: SubmitState) => void;
  onSubmit: (name: string, price: number) => void;

  isDeletePopupOpen: boolean;
  openDeletePopup: () => void;
  closeDeletePopup: () => void;
  handleDeactiveArticle: () => void;
}

interface FormData {
  name: string;
  price: string;
}

const EditArticle = observer(({ article }: { article: ArticleInstance }) => {
  const navigate = useNavigate();

  const state = useLocalObservable<EditArticleState>(() => ({
    submitState: SubmitState.None,
    setSubmitState(submitState) {
      state.submitState = submitState;
    },

    async onSubmit(name: string, price: number) {
      await article.patch(name, price);

      state.setSubmitState(SubmitState.Success);
      setTimeout(() => {
        state.setSubmitState(SubmitState.None);
      }, SAVE_BUTTON_ANIMATION_DURATION);
    },

    isDeletePopupOpen: false,
    openDeletePopup() {
      state.isDeletePopupOpen = true;
    },
    closeDeletePopup() {
      state.isDeletePopupOpen = false;
    },

    async handleDeactiveArticle() {
      await article.deactivate();
      navigate('/organization/articles');
    },
  }));

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    shouldUnregister: true,
    reValidateMode: 'onSubmit',
    defaultValues: {
      name: article.name,
      price: String(article.price),
    },
  });

  return (
    <FadeInDiv className="h-full w-full p-4 md:p-14">
      <div className="block rounded-md bg-white p-7 md:inline-block">
        <span className="w-full text-xl font-extrabold text-indigo-900">
          Redigera artikel
        </span>
      </div>
      <form
        onSubmit={handleSubmit(({ name, price }) =>
          state.onSubmit(name, Number(price)),
        )}
      >
        <div className="mt-5 rounded-md bg-white p-7 md:mt-10">
          <div className="max-w-xs">
            <span className="font-semibold text-gray-900">Namn</span>
            <InputWithError
              type="text"
              placeholder="Namn"
              {...register('name', {
                required: 'Fyll i detta fältet',
                maxLength: {
                  value: 50,
                  message: 'Max 50 tecken',
                },
              })}
              error={errors.name?.message}
            />
          </div>
          <div className="mt-3 max-w-xs md:mt-5">
            <span className="font-semibold text-gray-900">Pris (kr)</span>
            <InputWithError
              type="number"
              step="1"
              placeholder="500"
              {...register('price', {
                required: 'Fyll i detta fältet',
                min: '0',
              })}
              error={errors.price?.message}
            />
          </div>
        </div>
        <div className="mt-5 flex items-center md:mt-7">
          <SaveButton
            disabled={isSubmitting || state.submitState === SubmitState.Success}
            isSaved={state.submitState === SubmitState.Success}
          />
          <button
            type="button"
            className="ml-5 flex items-center justify-center rounded-md bg-rose-300 p-3 transition duration-200 hover:bg-rose-500"
            onClick={state.openDeletePopup}
          >
            <TrashCanIcon className="w-4 text-white" />
            <span className="ml-3 font-semibold text-white">Radera</span>
          </button>
        </div>
      </form>
      {state.isDeletePopupOpen && (
        <DeletePopup
          onDismiss={state.closeDeletePopup}
          onConfirm={state.handleDeactiveArticle}
          title="Radera artikel"
        >
          Om du raderar artikeln går det inte att återställas. Är du säker på
          att du vill radera artikeln?
        </DeletePopup>
      )}
    </FadeInDiv>
  );
});
