import { types as t } from 'mobx-state-tree';
import { OrganizationStore } from './OrganizationStore';
import { AuthStore } from './AuthStore';
import { IssueStore } from './IssueStore';
import { UIStore } from './UIStore';
import { UserStore } from './UserStore';
import { ParkingStore } from './ParkingStore';
import { RecurringParkingStore } from './RecurringParkingStore';
import { ArticleStore } from './ArticleStore';
import { NotificationStore } from './NotificationStore';
import { SearchModalStore } from './SearchModalStore';
import { SearchStore } from './SearchStore';
import { RoundingStore } from './RoundingStore';

export const RootStore = t
  .model('RootStore', {
    organizationStore: OrganizationStore,
    authStore: AuthStore,
    userStore: UserStore,
    issueStore: IssueStore,
    articleStore: ArticleStore,
    parkingStore: ParkingStore,
    recurringParkingStore: RecurringParkingStore,
    notificationStore: NotificationStore,
    searchModalStore: SearchModalStore,
    searchStore: SearchStore,
    uiStore: UIStore,
    roundingStore: RoundingStore,
  })
  .actions((self) => ({
    init() {
      self.organizationStore.fetchOrganization();
      self.userStore.fetchUsers();
      self.articleStore.fetchArticles();
      self.notificationStore.fetchNewCount();
    },
    reset() {
      self.organizationStore.reset();
      self.authStore.reset();
      self.userStore.reset();
      self.issueStore.reset();
      self.articleStore.reset();
      self.notificationStore.reset();
      self.searchModalStore.reset();
      self.searchStore.reset();
      self.uiStore.reset();
    },
  }));
