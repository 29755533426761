// @ts-nocheck
import React, { useLayoutEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { DashboardPeriod } from '../../../types';
import { PeriodController } from './PeriodController';
import { observer } from 'mobx-react-lite';

const MOBILE_MIN_ROTATION = 90;
const MOBILE_LABEL_OFFSET = -7;

type IssueTypeChartProps = {
  title: string;
  tooltip: string;
  color: string;
  period: DashboardPeriod;
  startDate: Date;
  endDate: Date;
  onPrevious: () => void;
  onNext: () => void;
  onChangePeriod: (period: DashboardPeriod) => void;
  data: any;
};

export const BarChart = observer(
  ({
    title,
    tooltip,
    color,
    period,
    startDate,
    endDate,
    onPrevious,
    onNext,
    onChangePeriod,
    data,
  }: IssueTypeChartProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const chartRef = useRef(null);

    useLayoutEffect(() => {
      const ctx = canvasRef.current!.getContext('2d');
      let windowWidth = window.innerWidth;

      chartRef.current = new Chart(ctx, {
        type: 'bar',
        data: {},
        options: {
          onResize(chart) {
            const newWindowWidth = window.innerWidth;

            if (windowWidth >= 768 && newWindowWidth < 768) {
              chart.config.options.scales.x.ticks.minRotation =
                MOBILE_MIN_ROTATION;
              chart.config.options.scales.x.ticks.labelOffset =
                MOBILE_LABEL_OFFSET;
              chart.update();
            } else if (windowWidth < 768 && newWindowWidth >= 768) {
              chart.config.options.scales.x.ticks.minRotation = 0;
              chart.config.options.scales.x.ticks.labelOffset = 0;
              chart.update();
            }

            windowWidth = newWindowWidth;
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              intersect: false,
              usePointStyle: true,
              boxWidth: 8,
              borderWidth: 1.5,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              suggestedMax: 20,
              grid: {
                drawBorder: false,
              },
              ticks: {
                autoSkip: false,
                maxTicksLimit: 4,
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
              },
              ticks: {
                labelOffset: windowWidth < 768 ? MOBILE_LABEL_OFFSET : 0,
                autoSkip: false,
                maxRotation: 90,
                minRotation: windowWidth < 768 ? MOBILE_MIN_ROTATION : 0,
              },
            },
          },
        },
      });

      return () => {
        chartRef.current.destroy();
      };
    }, []);

    useLayoutEffect(() => {
      const chart = chartRef.current;

      chart.data.labels.pop();
      chart.data.datasets.forEach((dataset) => {
        dataset.data.pop();
      });

      const labels = [];
      const chartData = [];

      Object.keys(data).forEach((key) => {
        const value = data[key];
        if (value !== 0) {
          labels.push(key);
          chartData.push(value);
        }
      });

      chart.data.labels = labels;
      chart.data.datasets = [
        {
          data: chartData,
          backgroundColor: color,
          barThickness: 14,
          maxBarThickness: 14,
          borderRadius: 9999,
          borderSkipped: false,
        },
      ];

      chart.update();
    }, [data]);

    return (
      <div className="w-full rounded bg-white p-7">
        <PeriodController
          title={title}
          tooltip={tooltip}
          period={period}
          startDate={startDate}
          endDate={endDate}
          onPrevious={onPrevious}
          onNext={onNext}
          onChangePeriod={onChangePeriod}
        />
        <div className="mt-7 flex-shrink-0 2xl:mr-10">
          <canvas ref={canvasRef} />
        </div>
      </div>
    );
  },
);
