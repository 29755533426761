import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CrossIcon2 } from '../icons';

interface ActiveFiltersProps<TFilter> {
  filters: TFilter[];
}

interface FilterObj {
  name: string;
  value: string | { id: number } | null;
  displayName: string | undefined;
}

interface ActiveFiltersState<TFilter> {
  search: string;
  setSearch: (search: string) => void;
  removeAllFilters: () => void;
  removeFilter: (filter: TFilter) => void;
}

export const ActiveFilters = observer(
  <TFilter extends FilterObj>({ filters }: ActiveFiltersProps<TFilter>) => {
    const navigate = useNavigate();
    const state = useLocalObservable<ActiveFiltersState<TFilter>>(() => ({
      search: location.search,
      setSearch(search: string) {
        state.search = search;
      },
      removeAllFilters() {
        const searchParams = new URLSearchParams(state.search);
        Object.values(filters).forEach((filter) => {
          searchParams.delete(filter.name);
        });
        navigate(`${location.pathname}?${searchParams.toString()}`, {
          replace: true,
        });
      },
      removeFilter(filterToRemove) {
        // We create a new searchparams to get the current params and then sort out only
        // the params that's in the filter prop to avoid removing any other params that's not supposed to be removed.
        const searchParams = new URLSearchParams(state.search);
        Object.values(filters).forEach((filter) => {
          searchParams.delete(filter.name);
        });
        filters.forEach((filter) => {
          const activeFilterValue =
            typeof filter.value === 'string'
              ? filter.value
              : filter.value?.id.toString();
          if (filterToRemove.value !== filter.value) {
            searchParams.append(filter.name, activeFilterValue!);
          }
        });
        navigate(`${location.pathname}?${searchParams.toString()}`, {
          replace: true,
        });
      },
    }));

    useEffect(() => {
      state.setSearch(location.search);
    }, [location.search]);

    return (
      <>
        {filters.length !== 0 && (
          <button
            className="mr-2 flex h-fit items-center rounded px-1.5 py-0.5 text-sm text-pink-500 transition hover:bg-pink-500 hover:text-white md:mr-4"
            onClick={state.removeAllFilters}
          >
            <span>Rensa alla</span> <CrossIcon2 className="w-4" />
          </button>
        )}
        {filters.map((filter, key) => {
          return (
            <button
              key={`${filter.name}-${filter.value}-${key}`}
              className="mr-2 flex h-fit items-center rounded px-1.5 py-0.5 text-sm text-pink-500 transition hover:bg-pink-500 hover:text-white md:mr-4"
              onClick={() => state.removeFilter(filter)}
            >
              <span>{filter.displayName}</span>
              <CrossIcon2 className="w-4" />
            </button>
          );
        })}
      </>
    );
  },
);
