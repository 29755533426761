import React, { FC, ReactElement } from 'react';
import Portal from '@reach/portal';
import { TooltipPopup, useTooltip } from '@reach/tooltip';

import '@reach/tooltip/styles.css';

const TOP_OFFSET = 12;

// Center the tooltip, but collisions will win.
const centered: any = (triggerRect: any, tooltipRect: any) => {
  const triggerCenter = triggerRect.left + triggerRect.width / 2;
  const left = triggerCenter - tooltipRect.width / 2;
  const maxLeft = window.innerWidth - tooltipRect.width - 2;

  return {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: triggerRect.top - tooltipRect.height - TOP_OFFSET,
  };
};

type ToolTipProps = {
  children: ReactElement;
  label: string;
  'aria-label': string;
};

export const Tooltip: FC<ToolTipProps> = ({
  children,
  label,
  'aria-label': ariaLabel,
}) => {
  const [trigger, tooltip] = useTooltip();
  const { isVisible, triggerRect } = tooltip;

  return (
    <>
      {React.cloneElement(children, trigger)}
      {isVisible && (
        // The Triangle. We position it relative to the trigger, not the popup
        // so that collisions don't have a triangle pointing off to nowhere.
        // Using a Portal may seem a little extreme, but we can keep the
        // positioning logic simpler here instead of needing to consider
        // the popup's position relative to the trigger and collisions.
        <Portal>
          <div
            style={{
              position: 'absolute',
              left: triggerRect
                ? triggerRect.left - 10 + triggerRect.width / 2
                : undefined,
              top: triggerRect ? triggerRect.top - TOP_OFFSET : undefined,
              width: 0,
              height: 0,
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent',
              borderTop: '10px solid rgba(49, 46, 129)',
            }}
          />
        </Portal>
      )}
      <TooltipPopup
        {...tooltip}
        label={label}
        aria-label={ariaLabel}
        style={{
          fontSize: '0.75rem',
          background: 'rgba(49, 46, 129)',
          color: 'white',
          border: 'none',
          borderRadius: '3px',
          padding: '0.5em 1em',
        }}
        position={centered}
      />
    </>
  );
};
