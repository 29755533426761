import React from 'react';
import ReactDOM from 'react-dom';
import '@reach/checkbox/styles.css';
import '../../styles/globals.less';
import '../../styles/tailwind.less';
import App from './App';
import { RootStore } from '../../models';
import { StoreProvider } from '../../hooks';
import { onSnapshot } from 'mobx-state-tree';
import { BrowserRouter as Router } from 'react-router-dom';

const emptyState = {
  organizationStore: {},
  authStore: {},
  userStore: {},
  issueStore: {},
  parkingStore: {},
  recurringParkingStore: {},
  articleStore: {},
  notificationStore: {},
  searchModalStore: {},
  searchStore: {},
  uiStore: {},
  roundingStore: {},
};
const initialState = {
  ...emptyState,
};

const savedAuthStore = localStorage.getItem('authStore');
if (savedAuthStore) {
  initialState.authStore = JSON.parse(savedAuthStore);
}

let rootStore;
try {
  rootStore = RootStore.create(initialState);
} catch (error) {
  // Creating the RootStore instance can fail if it is given
  // broken data. This can happen if the authStore snapshot in
  // localStorage is out of date. If this happens we fall back
  // to the empty state.
  rootStore = RootStore.create(emptyState);
}

onSnapshot(rootStore.authStore, (newSnapshot) => {
  localStorage.setItem('authStore', JSON.stringify(newSnapshot));
});

// The rootStore is initialized automatically on login, but
// if the user is already logged in we initialize it manually.
if (rootStore.authStore.isLoggedIn) {
  rootStore.init();
}

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider value={rootStore}>
      <Router>
        <App />
      </Router>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
