import { Instance, types as t } from 'mobx-state-tree';
import { IsoDate } from '../types';

export const IssueFeedback = t
  .model('IssueFeedback', {
    id: t.identifierNumber,
    content: t.string,
    created_at: IsoDate,
    satisfaction: t.number,
    speed: t.number,
  })
  .views((self) => ({
    get grade() {
      return Math.ceil((self.satisfaction + self.speed) / 2);
    },
    get hasContent() {
      return self.content.trim() !== '';
    },
  }));
export type FeedbackInstance = Instance<typeof IssueFeedback>;
