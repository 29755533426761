import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC } from 'react';
import { InputWithError } from './InputWithError';

interface KeyValue {
  key: string;
  value: string;
  isReadonly?: boolean;
}

interface AdditionalInformationProps {
  fields: KeyValue[];
  onChange?: (data: KeyValue[]) => void;
  onBlur?: (data: KeyValue[]) => void;
}

interface AdditionalInformationState {
  fields: KeyValue[];
  setField: (field: KeyValue, index: number) => void;
}

export const AdditionalInformation: FC<AdditionalInformationProps> = observer(
  ({ fields, onChange, onBlur }) => {
    const state = useLocalObservable<AdditionalInformationState>(() => ({
      fields: fields,
      setField(field, index) {
        state.fields[index] = field;
        onChange && onChange(state.fields);
      },
    }));

    return (
      <div className="rounded-lg bg-white p-8 text-gray-900">
        <span className="mb-8 block text-2xl font-bold">
          Ytterligare uppgifter
        </span>
        <div className="grid grid-cols-3 gap-x-4 gap-y-2">
          <span className="font-semibold">Namn</span>
          <span className="col-span-2 font-semibold">Innehåll</span>
          {state.fields.map((field, index) => (
            <KeyValueRow
              key={field.key}
              field={field}
              onChange={(field) => state.setField(field, index)}
              onBlur={onBlur ? () => onBlur(state.fields) : undefined}
            />
          ))}
        </div>
      </div>
    );
  },
);

interface KeyValueRowProps {
  field: KeyValue;
  onChange: (keyValue: KeyValue) => void;
  onBlur?: () => void;
}

const KeyValueRow: FC<KeyValueRowProps> = observer(
  ({ field, onChange, onBlur }) => {
    const state = useLocalObservable(() => ({
      value: field.value,
      setValue(value: string) {
        state.value = value;
        onChange({ key: field.key, value });
      },
    }));

    return (
      <>
        <InputWithError value={field.key} disabled />
        <div className="col-span-2">
          <InputWithError
            value={state.value}
            onChange={(e) => state.setValue(e.target.value)}
            disabled={field.isReadonly}
            onBlur={onBlur ? onBlur : undefined}
          />
        </div>
      </>
    );
  },
);
