import React from 'react';
import { AccountType } from '../types';

interface Props {
  type: AccountType;
  size?: 'small' | 'normal';
}

const colors = {
  [AccountType.Parking]: 'bg-indigo-600',
  [AccountType.Worker]: 'bg-pink-500',
  [AccountType.Admin]: 'bg-rose-300',
  [AccountType.Organization]: 'bg-yellow-400',
};

const texts = {
  [AccountType.Parking]: 'Parkeringsbolag',
  [AccountType.Worker]: 'Handläggare',
  [AccountType.Admin]: 'Admin',
  [AccountType.Organization]: 'Organisation',
};

export const AccountTypeTag: React.FC<Props> = ({ type, size = 'normal' }) => {
  const color = colors[type];
  const text = texts[type];

  const sizeClasses =
    size === 'normal'
      ? 'py-1 px-3 text-sm font-semibold'
      : 'py-1 px-1 text-xxs';

  return (
    <div
      className={`inline-block rounded-md text-white ${color} ${sizeClasses}`}
    >
      {text}
    </div>
  );
};
