import { flow, Instance, types as t } from 'mobx-state-tree';
import { withRequest } from '../extensions';
import { ArticleReference } from './Article';
import { UserReference } from './User';

export const IssueTimeReport = t
  .model('IssueTimeReport', {
    id: t.identifierNumber,
    reporter: t.maybeNull(UserReference),
    worked_hours: t.number,
    chargeable_hours: t.number,
    articles: t.maybeNull(t.array(ArticleReference)),
  })
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;

    return {
      assignTimeReport: flow(function* (
        userId: number | null,
        issueId: number,
      ) {
        const currentReporter = self.reporter;
        self.reporter = userId as any;

        try {
          yield request({
            method: 'PATCH',
            url: `/api/issues/${issueId}/time_reports/${self.id}/`,
            data: {
              reporter: userId,
            },
          });
        } catch (error) {
          self.reporter = currentReporter;
        }
      }),
      deleteArticle: flow(function* (articleId: number, issueId: number) {
        const index = self.articles?.findIndex(
          (article) => article?.id === articleId,
        );

        self.articles?.splice(index as number, 1);

        const articleIds: number[] = [];
        self.articles?.forEach((article) => {
          articleIds.push(article!.id);
        });

        try {
          yield request({
            method: 'PATCH',
            url: `/api/issues/${issueId}/time_reports/${self.id}/`,
            data: {
              articles: articleIds,
            },
          });
        } catch (error) {
          self.articles?.splice(index as number, 0, articleId);
        }
      }),
      addArticle: flow(function* (articleId: number, issueId: number) {
        self.articles?.push(articleId);

        const articleIds: number[] = [];
        self.articles?.forEach((article) => {
          articleIds.push(article!.id);
        });

        try {
          yield request({
            method: 'PATCH',
            url: `/api/issues/${issueId}/time_reports/${self.id}/`,
            data: {
              articles: articleIds,
            },
          });
        } catch (error) {
          const index = self.articles?.findIndex(
            (article) => article!.id === articleId,
          );
          self.articles?.splice(index as number, 1);
        }
      }),
    };
  });

export type TimeReportInstance = Instance<typeof IssueTimeReport>;
