import React, { FC } from 'react';

type LabelProps = {
  htmlFor?: string;
  required?: boolean;
};

export const Label: FC<LabelProps> = ({
  children,
  htmlFor,
  required = false,
}) => (
  <label
    htmlFor={htmlFor}
    className="mb-2 inline-block whitespace-nowrap font-semibold"
  >
    {children} {required && <span className="text-red-500">*</span>}
  </label>
);
