import React, { FC, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { formatDate } from '../../../utils';
import { Card } from './Card';

interface DateCardProps {
  title: string;
  date: Date;
  border?: boolean;
}

export const DateCard: FC<DateCardProps> = observer(
  ({ title, date, border }) => {
    const formattedDate = useMemo(() => formatDate(date), [date]);

    return (
      <Card title={title} border={border}>
        <span>{formattedDate.date}</span>
        <span className="ml-4">{formattedDate.time}</span>
      </Card>
    );
  },
);
