import { flow, Instance, types as t } from 'mobx-state-tree';
import { UserReference } from './User';
import { withRequest } from '../extensions';
import { IsoDate } from '../types';
import { NotificationType } from '../types/NotificationTypes';

export const Notification = t
  .model('Notification', {
    id: t.identifierNumber,
    type: t.enumeration<NotificationType>(
      'Type',
      Object.values(NotificationType),
    ),
    created_at: IsoDate,
    read_at: t.maybeNull(IsoDate),
    creator: UserReference,
    issue: t.model({ id: t.number, number: t.number }),
    note: t.maybeNull(t.number),
  })
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;

    return {
      read: flow(function* () {
        if (self.read_at) return;

        self.read_at = new Date();

        try {
          yield request({
            method: 'POST',
            url: `/api/notifications/${self.id}/read/`,
          });
        } catch (error) {
          self.read_at = null;
        }
      }),
    };
  });

export type NotificationInstance = Instance<typeof Notification>;
