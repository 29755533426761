import React, { FC, memo, SVGProps } from 'react';

export const LogoIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 127.09 109.18"
    fill="currentColor"
    className="w-4"
    {...props}
  >
    <path d="M38.26 46.62a47.2 47.2 0 00-21.55 29.19A8.44 8.44 0 016.68 82h-.09A8.44 8.44 0 01.25 71.71a64.34 64.34 0 0140-44.81v18.59c-.68.36-1.34.74-1.99 1.13z" />
    <path d="M95 31.52v21a43.66 43.66 0 00-3.83-3.21 47.36 47.36 0 00-52.88-2.66c-1.58 1-3.13 2.09-3.41 2.28s-1.22.91-1.32 1A8.79 8.79 0 0122 46.79c-2.06-3.42-1.67-8.44 3-11.69.21-.15 2.91-2.05 4.55-3a66.06 66.06 0 0123.18-8.45A64.61 64.61 0 0195 31.52z" />
    <path d="M127 83.63h-17c-.1-1.27-.25-2.53-.45-3.79a47.41 47.41 0 00-28.5-36.34c-.75-.31-1.3-.54-1.67-.71a8.5 8.5 0 01-4.83-11.19c1.28-3.06 5.35-6.76 11.35-4.44l1.67.68A64.45 64.45 0 01126.3 77.2a63.46 63.46 0 01.7 6.43zM64.69 10.2a6.2 6.2 0 01-4.43 2.32 80.81 80.81 0 00-8.52 1 83.41 83.41 0 00-30.35 11.69 81.06 81.06 0 00-9.52 7.46l-.79.67a6.15 6.15 0 01-5.53 1.32 6.21 6.21 0 01-1.41-.54L4 34.06A6.19 6.19 0 012.86 23.9l.74-.64a91.5 91.5 0 0111.13-8.66A96 96 0 0149.61 1.17 92.16 92.16 0 0159.44 0 6.19 6.19 0 0166 5.21v.16a6.15 6.15 0 01-1.31 4.83z" />
    <rect
      x={75.26}
      y={0.93}
      width={14.22}
      height={14.22}
      rx={6.19}
      transform="rotate(-76.06 82.37 8.044)"
    />
    <path d="M125.18 102.73a8.44 8.44 0 01-10.18 6.2L36.16 89.36A8.43 8.43 0 0130 79.14v-.09a8.44 8.44 0 0110.23-6.16L110 90.23a46.43 46.43 0 00-.48-10.39l-.63-4.08c-.31-2.13-1.12-6.11 2.64-9.15a8.44 8.44 0 018.34-1.32c4.52 1.74 5.23 6.11 5.76 8.37.21.89.37 1.72.65 3.54a63.93 63.93 0 01-1.1 25.53z" />
  </svg>
));
