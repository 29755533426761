import { applySnapshot, flow, types as t } from 'mobx-state-tree';
import { withRequest } from '../extensions';
import { CompanyInstance } from './Company';
import { Organization } from './Organization';
import { UserInstance } from './User';

export const OrganizationStore = t
  .model('OrganizationStore', {
    organization: t.maybeNull(Organization),
  })
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;

    return {
      fetchOrganization: flow(function* () {
        try {
          const { data } = yield request({
            method: 'GET',
            url: '/api/organization/',
          });

          self.organization = data;
        } catch (error) {
          console.error('Failed to fetch users', error);
        }
      }),
      createCompany: flow(function* (company: CompanyInstance) {
        try {
          const { data: newCompany } = yield request({
            method: 'POST',
            url: '/api/companies/',
            data: {
              name: company.name,
              organization_number: company.organization_number || null,
              contact_name: company.contact_name || null,
              contact_email: company.contact_email || null,
              contact_phone: company.contact_phone || null,
            },
          });
          self.organization!.companies.push(newCompany);
        } catch (error) {
          // No need to handle this error since the general error
          // message will be shown to the user and they can try again.
        }
      }),
      createFacility: flow(function* (
        name: string,
        assignments: UserInstance[],
      ) {
        try {
          const { data: newFacility } = yield request({
            method: 'POST',
            url: '/api/facilities/',
            data: {
              name,
              assignments: assignments.map((user) => user.id),
            },
          });

          self.organization!.facilities.push(newFacility);
        } catch (error) {
          // No need to handle this error since the general error
          // message will be shown to the user and they can try again.
        }
      }),
      reset() {
        applySnapshot(self, {
          organization: null,
        });
      },
      copyExistingRounding: flow(function* (
        fromFacility: any,
        toFacility: any,
      ) {
        try {
          const { data: copyRoundings } = yield request({
            method: 'GET',
            url: '/api/rounding_task/copy/',
            params: {
              fromFacilityId: fromFacility,
            },
          });

          const facility = self.organization!.facilities.find(
            (x) => x.id == toFacility,
          );

          copyRoundings.forEach((x: any) => {
            x.isSaved = false;
          });

          facility?.copyRoundings(copyRoundings);
        } catch (error) {
          // No need to handle this error since the general error
          // message will be shown to the user and they can try again.
        }
      }),
    };
  });
