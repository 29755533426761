import React, { MouseEvent } from 'react';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import { useStore } from '../hooks';
import {
  AddUserIcon,
  ChevronDownIcon,
  CompanyIcon,
  CrossIcon,
  FacilityIcon,
  PlusIcon,
  SearchIcon,
  TagIcon,
} from '../icons';
import { Link } from 'react-router-dom';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { MIN_SEARCH_QUERY_LENGTH } from '../constants';
import { SearchIssueLink } from './SearchIssueLink';
import { AccountType } from '../types';
import { localeCompareSv } from '../utils';

type SearchFunction = {
  name: string;
  link: string;
  icon: JSX.Element;
  onClick: (event: MouseEvent<HTMLAnchorElement>) => void;
};

type SearchModalState = {
  filteredSearchFunctions: SearchFunction[];
};

export const SearchModal = observer(() => {
  const {
    uiStore,
    userStore: { me },
    searchModalStore,
    organizationStore: { organization },
  } = useStore();

  const state = useLocalObservable<SearchModalState>(() => ({
    get filteredSearchFunctions(): SearchFunction[] {
      const searchFunctions = [];

      if (me && me.role >= AccountType.Admin) {
        searchFunctions.push({
          name: 'Skapa användare',
          link: '/organization/create-user',
          icon: <AddUserIcon />,
          onClick: uiStore.toggleIsSearchModalOpen,
        });

        searchFunctions.push(
          {
            name: 'Skapa fastighet',
            link: '/organization/facilities/create-facility',
            icon: <FacilityIcon className="h-4 w-4" />,
            onClick: uiStore.toggleIsSearchModalOpen,
          },
          {
            name: 'Skapa företag',
            link: '/organization/companies/create-company',
            icon: <CompanyIcon className="h-4 w-4" />,
            onClick: uiStore.toggleIsSearchModalOpen,
          },
        );

        if (organization?.hasIssueCostsFeature) {
          searchFunctions.push({
            name: 'Skapa artikel',
            link: '/organization/articles/create-article',
            icon: <TagIcon className="w-4" />,
            onClick: uiStore.toggleIsSearchModalOpen,
          });
        }
      }

      searchFunctions.push({
        name: 'Skapa ärende',
        link: '#',
        icon: (
          <PlusIcon className="w-4 rounded-full border border-solid border-black text-black" />
        ),
        onClick: (event: MouseEvent<HTMLAnchorElement>) => {
          event.preventDefault();

          if (!uiStore.isCreateIssueOpen) {
            uiStore.toggleIsCreateIssueOpen();
          }
          uiStore.toggleIsSearchModalOpen();
        },
      });

      const { lowerCasedQuery } = searchModalStore;

      if (lowerCasedQuery.length < MIN_SEARCH_QUERY_LENGTH) {
        return [];
      }

      return searchFunctions
        .filter((searchFn) =>
          searchFn.name.toLowerCase().includes(lowerCasedQuery),
        )
        .sort((a, b) => localeCompareSv(a.name, b.name));
    },
  }));

  return (
    <DialogOverlay
      className="absolute top-0 z-50 flex h-screen w-screen items-start justify-center bg-black bg-opacity-40"
      isOpen
      onDismiss={uiStore.toggleIsSearchModalOpen}
    >
      <DialogContent
        aria-label="dialog"
        className="mt-24 flex w-full max-w-screen-md justify-center px-4 focus:outline-none"
      >
        <div className="w-full rounded-md bg-white p-7 shadow-lg">
          <div className="flex items-center justify-between">
            <div className="flex w-full items-center">
              <SearchIcon className="mr-4 w-5 flex-shrink-0" />
              <input
                placeholder="Sök"
                className="w-full font-semibold outline-none"
                value={searchModalStore.query}
                onChange={(e) => searchModalStore.setQuery(e.target.value)}
              />
            </div>
            {searchModalStore.query !== '' && (
              <button
                className="flex-shrink-0"
                onClick={() => searchModalStore.setQuery('')}
              >
                <CrossIcon className="w-5" />
              </button>
            )}
          </div>
          {state.filteredSearchFunctions.length !== 0 && (
            <div className="mt-7 pl-1">
              {state.filteredSearchFunctions.map((searchFn, index) => (
                <Link
                  key={searchFn.name}
                  className={`flex items-center ${index !== 0 ? 'mt-2' : ''}`}
                  to={searchFn.link}
                  onClick={searchFn.onClick}
                >
                  {searchFn.icon}
                  <span className="ml-4">{searchFn.name}</span>
                </Link>
              ))}
            </div>
          )}
          {searchModalStore.issues !== null && (
            <div className="mt-7 pl-1">
              {searchModalStore.issues.length !== 0 ? (
                <>
                  {searchModalStore.issues.map((issue, index) => (
                    <SearchIssueLink
                      key={issue.id}
                      issue={issue}
                      me={me!}
                      className={index !== 0 ? 'mt-2' : ''}
                      onClick={uiStore.toggleIsSearchModalOpen}
                    />
                  ))}
                  {searchModalStore.hasMoreIssues && (
                    <Link
                      className="mt-6 flex items-center pl-1"
                      to={`/search?q=${searchModalStore.query}`}
                      onClick={uiStore.toggleIsSearchModalOpen}
                    >
                      <span className="text-xs">Se alla</span>
                      <ChevronDownIcon className="ml-3 w-6 -rotate-90" />
                    </Link>
                  )}
                </>
              ) : (
                <div className="text-sm">Inga ärenden matchade din sökning</div>
              )}
            </div>
          )}
        </div>
      </DialogContent>
    </DialogOverlay>
  );
});
