import React, { FC, memo, SVGProps } from 'react';

export const ExclamationMarkIcon: FC<SVGProps<SVGSVGElement>> = memo(
  (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="w-4"
      {...props}
    >
      <path d="M12 20a8 8 0 100-16 8 8 0 000 16zm0 2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-6h2v2h-2v-2zm0-10h2v8h-2V6z" />
    </svg>
  ),
);
