import { Popover, positionDefault } from '@reach/popover';
import { runInAction } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { useStore } from '../hooks';
import { PhoneIcon, LetterIcon } from '../icons';
import { UserInstance } from '../models';
import { nameToInitials, nameToStrongColor } from '../utils';

interface UserTooltipState {
  showTooltip: boolean;
  setShowTooltip: (showTooltip: boolean, delay: number) => void;
  isMouseOver: boolean;
  setIsMouseOver: (isMouseOver: boolean) => void;
}

interface UserTooltipProps {
  disabled?: boolean;
  user: UserInstance;
}

export const UserTooltip: FC<UserTooltipProps> = observer(
  ({ disabled = false, user, children }) => {
    const {
      organizationStore: { organization },
    } = useStore();

    const targetRef = useRef<HTMLDivElement>(null);
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const state = useLocalObservable<UserTooltipState>(() => ({
      showTooltip: false,
      setShowTooltip(showTooltip, delay) {
        timeoutRef.current = setTimeout(() => {
          runInAction(() => {
            state.showTooltip = showTooltip;
          });
        }, delay);
      },
      isMouseOver: false,
      setIsMouseOver(isMouseOver) {
        state.isMouseOver = isMouseOver;
      },
    }));

    useEffect(() => {
      if (disabled) {
        state.showTooltip && state.setShowTooltip(false, 0);
        return;
      }

      if (state.isMouseOver) {
        state.setShowTooltip(true, 1000);
      } else {
        state.setShowTooltip(false, 200);
      }

      return () => clearTimeout(timeoutRef.current!);
    }, [disabled, state.isMouseOver]);

    const initials = nameToInitials(user.name);

    const bgColor = useMemo(() => nameToStrongColor(user.name), [user.name]);

    return (
      <>
        <div
          ref={targetRef}
          onMouseOver={() => state.setIsMouseOver(true)}
          onMouseLeave={() => state.setIsMouseOver(false)}
        >
          {children}
        </div>
        {state.showTooltip && !disabled && (
          <Popover
            onMouseOver={() => state.setIsMouseOver(true)}
            onMouseLeave={() => state.setIsMouseOver(false)}
            targetRef={targetRef}
            position={positionDefault}
            style={{ position: 'absolute', zIndex: 99 }}
          >
            <div className="w-64 rounded-xl bg-white p-4 text-left shadow-lg">
              <div
                style={{ backgroundColor: bgColor }}
                className="t-10 mb-4 flex h-24 w-24 items-center justify-center rounded-full border-2 border-solid border-pink-600"
              >
                {user.image ? (
                  <img
                    className="h-full w-full rounded-full"
                    src={user.image}
                  />
                ) : (
                  <span className="text-4xl text-white">{initials}</span>
                )}
              </div>
              <div>
                <span className="font-bold text-gray-500">{user.name}</span>
                <span className="mb-4 block text-indigo-600">
                  {organization!.name}
                </span>
                <div className="mb-1 flex items-center">
                  <LetterIcon className="mr-2 inline w-5 flex-shrink-0 text-gray-500" />
                  <a
                    className="text-xs text-indigo-600"
                    href={`mailto:${user.email}`}
                  >
                    {user.email}
                  </a>
                </div>
                {user.phone_number && (
                  <div className="flex items-center">
                    <PhoneIcon className="mr-2 inline w-5 flex-shrink-0 text-gray-500" />
                    <a
                      className="text-xs text-indigo-600"
                      href={`tel:${user.phone_number}`}
                    >
                      {user.phone_number}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </Popover>
        )}
      </>
    );
  },
);
