import { applySnapshot, flow, types as t } from 'mobx-state-tree';
import { Article } from './Article';
import { withRequest } from '../extensions';

export const ArticleStore = t
  .model('ArticleStore', {
    articles: t.maybeNull(t.array(Article)),
  })
  .views((self) => ({
    get activeArticles() {
      if (!self.articles) return [];

      return self.articles.filter((article) => article.is_active);
    },
  }))
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;

    return {
      fetchArticles: flow(function* () {
        const response = yield request({
          method: 'GET',
          url: `/api/articles/`,
          data: {
            limit: 9999,
          },
        });

        self.articles = response.data.results;
      }),
      createArticle: flow(function* (name: string, price: number) {
        const article = {
          number: self.articles!.length + 1,
          name,
          price,
          is_active: true,
        };
        const { data: newArticle } = yield request({
          method: 'POST',
          url: `/api/articles/`,
          data: article,
        });
        if (newArticle) {
          self.articles!.push(newArticle);
        }
      }),
      reset() {
        applySnapshot(self, {
          articles: null,
        });
      },
    };
  });
