import React, { FC, memo, SVGProps } from 'react';

export const CarReturnIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23.51 19.24"
    fill="currentColor"
    className="w-4"
    {...props}
  >
    <path d="M21.51 10.24v-3a4 4 0 00-4-4h-3.74a4.08 4.08 0 010 .61 4.91 4.91 0 01-.19 1.39h3.89a2 2 0 012 2v3h-10V9.1a5.29 5.29 0 01-1.06.11h-.9v1a2 2 0 00-2 2v5a2 2 0 004 0h10a2 2 0 004 0v-5a2 2 0 00-2-1.97zm-13 4a1 1 0 01-1-1 1 1 0 111 1zm12 0a1 1 0 01-1-1 1 1 0 111 1z" />
    <path d="M12.31 3.85a3.86 3.86 0 01-3.86 3.86h-5l1.03 1.07a1 1 0 01-.71 1.7 1 1 0 01-.71-.29L.29 7.42A1 1 0 01.29 6l2.77-2.77a1 1 0 011.42 0 1 1 0 010 1.41L3.41 5.71h5A1.86 1.86 0 108.45 2H3.77a1 1 0 110-2h4.68a3.86 3.86 0 013.86 3.85z" />
  </svg>
));
