import { observer } from 'mobx-react-lite';
import React from 'react';
import { FEEDBACK_COLORS } from '../../../constants';
import { InfoBox } from '../../../components/InfoBox';

type FeedbackGradeProps = {
  grade: number;
  satisfaction: number;
  speed: number;
};

export const FeedbackGrade = observer(
  ({ grade, satisfaction, speed }: FeedbackGradeProps) => (
    <div className="w-full rounded bg-white p-7">
      <div className="mb-5 flex items-center justify-between">
        <span className="mr-5 text-lg font-semibold">Feedback betyg</span>
        <InfoBox content="Genomsnitt av alla givna återkopplingar." />
      </div>
      <div className="flex justify-center md:justify-start">
        <div
          className={`mr-7 inline-flex h-20 w-20 items-center justify-center rounded-full text-3xl font-semibold text-white ${
            FEEDBACK_COLORS[Math.max(grade - 1, 0)]
          }`}
        >
          {grade}
        </div>
        <div className="flex flex-col justify-between">
          <div className="flex items-center">
            <div
              className={`inline-flex h-9 w-9 items-center justify-center rounded-full font-semibold text-white ${
                FEEDBACK_COLORS[Math.max(satisfaction - 1, 0)]
              }`}
            >
              {satisfaction}
            </div>
            <div className="ml-3 text-sm font-semibold">Nöjdhet</div>
          </div>
          <div className="flex items-center">
            <div
              className={`inline-flex h-9 w-9 items-center justify-center rounded-full font-semibold text-white ${
                FEEDBACK_COLORS[Math.max(speed - 1, 0)]
              }`}
            >
              {speed}
            </div>
            <div className="ml-3 text-sm font-semibold">Hastighet</div>
          </div>
        </div>
      </div>
    </div>
  ),
);
