import { IntervalType } from '../types';

const addIntervalFn = {
  [IntervalType.Day]: function (currentDate: Date, amount: number) {
    currentDate.setDate(currentDate.getDate() + amount);
  },
  [IntervalType.Week]: function (currentDate: Date, amount: number) {
    currentDate.setDate(currentDate.getDate() + 7 * amount);
  },
  [IntervalType.Month]: function (currentDate: Date, amount: number) {
    currentDate.setMonth(currentDate.getMonth() + amount);
  },
  [IntervalType.Year]: function (currentDate: Date, amount: number) {
    currentDate.setFullYear(currentDate.getFullYear() + amount);
  },
};

export const addInterval = (
  currentDate: Date,
  interval: IntervalType,
  amount: number,
) => {
  return addIntervalFn[interval](currentDate, amount);
};
