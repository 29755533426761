import {
  getYear,
  startOfDay,
  startOfMonth,
  startOfYear,
  format,
  getDate,
  subYears,
  subMonths,
  subWeeks,
  subDays,
  addYears,
  addMonths,
  addWeeks,
  addDays,
  endOfYear,
  endOfMonth,
  endOfDay,
  startOfWeek as _startOfWeek,
  endOfWeek as _endOfWeek,
  getMonth,
  getWeek,
  differenceInWeeks,
  isBefore,
} from 'date-fns';
import { sv } from 'date-fns/locale';
import { DashboardPeriod } from '../types';

const startOfWeek = (date: Date) =>
  _startOfWeek(date, { locale: sv, weekStartsOn: 1 });
const endOfWeek = (date: Date) =>
  _endOfWeek(date, { locale: sv, weekStartsOn: 1 });

function mod(n: number, m: number) {
  return ((n % m) + m) % m;
}

export function periodToDates(
  period: DashboardPeriod,
): [startDate: Date, endDate: Date] {
  if (period === DashboardPeriod.Year) {
    return [startOfYear(new Date()), endOfYear(new Date())];
  }
  if (period === DashboardPeriod.Month) {
    return [startOfMonth(new Date()), endOfMonth(new Date())];
  }
  if (period === DashboardPeriod.Week) {
    return [startOfWeek(new Date()), endOfWeek(new Date())];
  }

  // period === DASHBOARD_PERIOD.Day
  return [startOfDay(new Date()), endOfDay(new Date())];
}

export function getPreviousPeriod(
  period: DashboardPeriod,
  startDate: Date,
): [startDate: Date, endDate: Date] {
  if (period === DashboardPeriod.Year) {
    const startOfLastYear = startOfYear(subYears(startDate, 1));
    const endOfLastYear = endOfYear(startOfLastYear);

    return [startOfLastYear, endOfLastYear];
  }
  if (period === DashboardPeriod.Month) {
    const startOfLastMonth = startOfMonth(subMonths(startDate, 1));
    const endOfLastMonth = endOfMonth(startOfLastMonth);

    return [startOfLastMonth, endOfLastMonth];
  }
  if (period === DashboardPeriod.Week) {
    const startOfLastWeek = startOfWeek(subWeeks(startDate, 1));
    const endOfLastWeek = endOfWeek(startOfLastWeek);

    return [startOfLastWeek, endOfLastWeek];
  }

  // period === DASHBOARD_PERIOD.Day
  const startOfYesterday = startOfDay(subDays(startDate, 1));
  const endOfYesterday = endOfDay(startOfYesterday);

  return [startOfYesterday, endOfYesterday];
}

export function getNextPeriod(
  period: DashboardPeriod,
  startDate: Date,
): [startDate: Date, endDate: Date] {
  if (period === DashboardPeriod.Year) {
    const startOfNextYear = startOfYear(addYears(startDate, 1));
    const endOfNextYear = endOfYear(startOfNextYear);

    return [startOfNextYear, endOfNextYear];
  }
  if (period === DashboardPeriod.Month) {
    const startOfNextMonth = startOfMonth(addMonths(startDate, 1));
    const endOfNextMonth = endOfMonth(startOfNextMonth);

    return [startOfNextMonth, endOfNextMonth];
  }
  if (period === DashboardPeriod.Week) {
    const startOfNextWeek = startOfWeek(addWeeks(startDate, 1));
    const endOfNextWeek = endOfWeek(startOfNextWeek);

    return [startOfNextWeek, endOfNextWeek];
  }

  // period === DASHBOARD_PERIOD.Day
  const startOfTomorrow = startOfDay(addDays(startDate, 1));
  const endOfTomorrow = endOfDay(startOfTomorrow);

  return [startOfTomorrow, endOfTomorrow];
}

export function formatPeriodDates(
  period: DashboardPeriod,
  startDate: Date,
  endDate: Date,
): string {
  if (period === DashboardPeriod.Year) {
    return getYear(startDate).toString();
  }
  if (period === DashboardPeriod.Month) {
    const dateString = format(startDate, 'MMMM yyyy', { locale: sv });

    return dateString.charAt(0).toUpperCase() + dateString.slice(1);
  }
  if (period === DashboardPeriod.Week) {
    const startDay = getDate(startDate);
    const endDay = getDate(endDate);

    const isSameYear = getYear(startDate) === getYear(endDate);
    if (!isSameYear) {
      return `${startDay} ${format(startDate, 'MMMM yyyy', {
        locale: sv,
      })} - ${endDay} ${format(endDate, 'MMMM yyyy', {
        locale: sv,
      })}`;
    }

    const isSameMonth = getMonth(startDate) === getMonth(endDate);
    if (!isSameMonth) {
      return `${startDay} ${format(startDate, 'MMMM', {
        locale: sv,
      })} - ${endDay} ${format(endDate, 'MMMM yyyy', {
        locale: sv,
      })}`;
    }

    return `${startDay} - ${endDay} ${format(startDate, 'MMMM yyyy', {
      locale: sv,
    })}`;
  }

  // period === DASHBOARD_PERIOD.Day
  const day = getDate(startDate);
  return `${day} ${format(startDate, 'MMMM yyyy', {
    locale: sv,
  })}`;
}

export function getLabelsAndData(
  period: DashboardPeriod,
  data: any,
  startDate: Date,
  endDate: Date,
) {
  let labels, currentPeriod: any, previousPeriod: any;

  if (period === DashboardPeriod.Year) {
    labels = [
      'jan',
      'feb',
      'mar',
      'apr',
      'maj',
      'jun',
      'jul',
      'aug',
      'sep',
      'okt',
      'nov',
      'dec',
    ];
    currentPeriod = Array.from({ length: 12 }, () => 0);
    previousPeriod = Array.from({ length: 12 }, () => 0);

    data.current_period.forEach((el: any) => {
      const month = new Date(el).getMonth();

      currentPeriod[month]++;
    });
    data.previous_period.forEach((el: any) => {
      const month = new Date(el).getMonth();

      previousPeriod[month]++;
    });
  }
  if (period === DashboardPeriod.Month) {
    const weekDifference = differenceInWeeks(endDate, startDate);
    const lastWeekOfLastYear = getWeek(endOfYear(subYears(startDate, 1)), {
      locale: sv,
    });

    const currentWeekCounter: { [key: number]: number } = {};
    const previousWeekCounter: { [key: number]: number } = {};
    const weekOrder: number[] = [];

    const startOfCurrentMonth = startOfMonth(startDate);
    const startOfNextMonth = addMonths(startOfCurrentMonth, 1);

    for (
      let i = startOfCurrentMonth, week;
      isBefore(i, startOfNextMonth);
      i = addDays(i, 1)
    ) {
      week = getWeek(i, { locale: sv });
      if (!(week in currentWeekCounter)) {
        weekOrder.push(week);
        currentWeekCounter[week] = 0;
      }
    }

    data.current_period.forEach((el: any) => {
      const week = getWeek(new Date(el), { locale: sv });

      currentWeekCounter[week]++;
    });
    data.previous_period.forEach((el: any) => {
      const week = getWeek(new Date(el), { locale: sv });

      if (!previousWeekCounter[week]) previousWeekCounter[week] = 0;
      previousWeekCounter[week]++;
    });

    labels = [];
    currentPeriod = [];
    previousPeriod = [];

    weekOrder.forEach((week) => {
      labels.push(`v.${week}`);
      currentPeriod.push(currentWeekCounter[week]);

      // We have to take previous years into account.
      const correspondingPreviousWeek =
        mod(week - weekDifference, lastWeekOfLastYear) || lastWeekOfLastYear;

      previousPeriod.push(previousWeekCounter[correspondingPreviousWeek] || 0);
    });
  }
  if (period === DashboardPeriod.Week) {
    labels = ['mån', 'tis', 'ons', 'tors', 'fre', 'lör', 'sön'];
    currentPeriod = Array.from({ length: 7 }, () => 0);
    previousPeriod = Array.from({ length: 7 }, () => 0);

    data.current_period.forEach((el: any) => {
      let day = new Date(el).getDay();
      day = day === 0 ? 6 : day - 1;

      currentPeriod[day]++;
    });
    data.previous_period.forEach((el: any) => {
      let day = new Date(el).getDay();
      day = day === 0 ? 6 : day - 1;

      previousPeriod[day]++;
    });
  }
  if (period === DashboardPeriod.Day) {
    labels = Array.from(
      { length: 24 },
      (_el, i) => `${i.toString().length === 1 ? '0' : ''}${i}:00`,
    );
    currentPeriod = Array.from({ length: 24 }, () => 0);
    previousPeriod = Array.from({ length: 24 }, () => 0);

    data.current_period.forEach((el: any) => {
      const hour = new Date(el).getHours();

      currentPeriod[hour]++;
    });
    data.previous_period.forEach((el: any) => {
      const hour = new Date(el).getHours();

      previousPeriod[hour]++;
    });
  }

  return [labels, currentPeriod, previousPeriod];
}
