import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { JournalIcon } from '../icons';
import { UserInstance } from '../models';
import { SearchIssueInstance } from '../models/SearchIssue';

interface Props {
  issue: SearchIssueInstance;
  me: UserInstance;
  className?: string;
  onClick?: () => void;
}

export const SearchIssueLink: FC<Props> = ({
  issue,
  me,
  className,
  onClick,
}) => {
  let to = '';

  if (issue.resolved_at) {
    to = `/issues/complete/${issue.id}`;
  } else if (issue.assigned_to === me || issue.co_workers?.includes(me)) {
    to = `/issues/mine/${issue.id}`;
  } else {
    // The only remaining possibilty is that the issue is open,
    // and the user is not assigned to or a co-worker on it
    to = `/issues/${issue.id}`;
  }

  return (
    <Link
      key={issue.id}
      className={`flex items-center ${className}`}
      to={to}
      onClick={onClick}
    >
      <JournalIcon
        className={`h-4 w-4 flex-shrink-0 ${
          issue.has_subcontractor_cost ? 'text-black' : 'text-gray-300'
        }`}
      />
      <div className="ml-4 truncate text-sm">
        #{issue.number} - {issue.formattedCreatedAt} - {issue.issue}
      </div>
    </Link>
  );
};
