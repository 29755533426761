import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
  ActiveFilters,
  Avatar,
  FadeInDiv,
  Pagination,
  RoundingFilterPicker,
  SlideInMenu,
  TableHeaderCell,
  UserSelect,
} from '../components';
import { RoundingStatusTag } from '../components/RoundingStatusTag';
import { useStore } from '../hooks';
import {
  ChevronDownIcon,
  FilterIcon,
  PaperClipIcon,
  UploadIcon,
  UserSelectIcon,
  UserSwitchIcon,
} from '../icons';
import { InfoIcon } from '../icons/InfoIcon';
import { RoundingTaskInstance, UserInstance } from '../models';
import { RoundingStatus, RoundingType } from '../types';
import { RoundingFilter } from '../types/RoundingFilter';
import { formatDate } from '../utils';

export const ClosedRoundsPage = observer(() => {
  const {
    roundingStore,
    uiStore,
    userStore: { me },
  } = useStore();

  const {
    handleClosedRoundingTasksSearchChange,
    closedRoundingTasks,
    numClosedRoundingTasksPages,
    closedRoundingTasksPage,
    closedRoundingTasksQuery,
    closedRoundingTasksIsAscending: isAscending,
    closedRoundingTasksFilter: filter,
    closedRoundingActiveFilters,
    downloadClosedRoundingCSV,
  } = roundingStore;

  const navigate = useNavigate();

  const setFilter = (newFilter: RoundingFilter) => {
    const searchParams = new URLSearchParams(closedRoundingTasksQuery);
    searchParams.delete('page');

    if (filter === newFilter) {
      if (isAscending) {
        searchParams.delete('sort');
      } else {
        searchParams.set('sort', filter);
      }
    } else {
      searchParams.set('sort', `-${newFilter}`);
    }

    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const setCurrentPage = (page: number) => {
    const searchParams = new URLSearchParams(location.search);

    if (page === 1) {
      searchParams.delete('page');
    } else {
      searchParams.set('page', `${page}`);
    }

    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleReportIssue = (task: RoundingTaskInstance) => {
    roundingStore.setRoundingIssue(
      task.id,
      task.rounding_step!.facilityName,
      task.rounding_step!.company?.name || '',
    );
    uiStore.toggleIsCreateIssueOpen();
  };

  const isMineFilterActive =
    new URLSearchParams(closedRoundingTasksQuery).get(
      RoundingFilter.AssignedTo,
    ) === me?.id.toString();

  const toggleMineFilter = () => {
    const searchParams = new URLSearchParams(location.search);

    const hasAssignedToFilter = searchParams.has(RoundingFilter.AssignedTo);
    const currentFilter = searchParams.get(RoundingFilter.AssignedTo);

    searchParams.delete(RoundingFilter.AssignedTo);

    if (currentFilter !== me?.id.toString() || !hasAssignedToFilter) {
      searchParams.append(RoundingFilter.AssignedTo, me?.id.toString() || '');
    }

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  useEffect(() => {
    handleClosedRoundingTasksSearchChange(location.search);
  }, [location.search]);

  if (closedRoundingTasks === null) return null;

  return (
    <>
      <FadeInDiv className="flex h-full flex-col px-7 pb-7 md:p-14">
        <div className="mb-5 rounded-md bg-white p-7 text-xl font-extrabold text-indigo-900 md:mb-10 md:w-fit">
          Genomförda
        </div>
        <div className="mb-3 flex justify-between space-x-6 space-y-2">
          <div className="flex min-w-0 flex-wrap items-center space-y-2 whitespace-nowrap">
            <SlideInMenu
              title="Filter"
              icon={
                <div className="mr-2 mt-2 flex items-center space-x-2 px-1.5 py-0.5 text-sm text-gray-900 md:mr-4">
                  <FilterIcon /> <span>Filter</span>
                </div>
              }
              isMenuExpanded={uiStore.isRoundingFilterPickerOpen}
              onClick={uiStore.toggleIsRoundingPickerOpen}
            >
              <RoundingFilterPicker />
            </SlideInMenu>
            {closedRoundingActiveFilters.length > 0 && (
              <ActiveFilters filters={closedRoundingActiveFilters} />
            )}
            <div />
          </div>
          <div className="flex items-start space-x-1 text-sm md:space-x-4">
            <button
              className={`flex items-center space-x-1 rounded px-1.5 py-0.5 transition-colors duration-200 hover:bg-pink-500 hover:text-white ${
                isMineFilterActive ? 'text-pink-500' : ''
              }`}
              onClick={toggleMineFilter}
            >
              <span>{isMineFilterActive ? 'Mina' : 'Alla'}</span>
              <UserSwitchIcon />
            </button>
            <button
              onClick={downloadClosedRoundingCSV}
              className="flex items-center space-x-1 rounded-md px-1.5 py-0.5 text-gray-900 transition-colors duration-200 hover:bg-gray-900 hover:text-white"
            >
              <span>Exportera</span>
              <UploadIcon />
            </button>
          </div>
        </div>
        {closedRoundingTasks.length === 0 ? (
          <div className="flex-1 rounded-md bg-white px-7 py-7 text-center md:py-28">
            <p className="mt-5 text-2xl font-extrabold text-indigo-900 md:text-3xl">
              Det finns inga ronderingar
            </p>
          </div>
        ) : (
          <>
            <table className="w-full rounded-md bg-white text-gray-900">
              <thead>
                <tr className="text-left">
                  <th className="w-6" />
                  <TableHeaderCell
                    title="Namn"
                    isAscending={isAscending}
                    isActive={filter === RoundingFilter.Name}
                    onClick={() => setFilter(RoundingFilter.Name)}
                  />
                  <TableHeaderCell
                    title="Fastighet"
                    isAscending={isAscending}
                    isActive={filter === RoundingFilter.Facility}
                    onClick={() => setFilter(RoundingFilter.Facility)}
                  />
                  <TableHeaderCell
                    title="Företag"
                    isAscending={isAscending}
                    isActive={filter === RoundingFilter.Company}
                    onClick={() => setFilter(RoundingFilter.Company)}
                    className="hidden xl:table-cell"
                  />
                  <TableHeaderCell
                    title="Avslutas"
                    isAscending={isAscending}
                    isActive={filter === RoundingFilter.EndsAt}
                    onClick={() => setFilter(RoundingFilter.EndsAt)}
                    className="hidden md:table-cell"
                  />
                  <TableHeaderCell
                    title="Status"
                    isAscending={isAscending}
                    className="hidden lg:table-cell"
                    isActive={filter === RoundingFilter.Status}
                    onClick={() => setFilter(RoundingFilter.Status)}
                  />
                  <TableHeaderCell
                    title="Genomförd"
                    isAscending={isAscending}
                    isActive={filter === RoundingFilter.ApprovedAt}
                    onClick={() => setFilter(RoundingFilter.ApprovedAt)}
                    className="hidden md:table-cell"
                  />
                  <TableHeaderCell
                    title="Tilldelad"
                    className="hidden pr-7 lg:table-cell"
                    isAscending={isAscending}
                    isActive={filter === RoundingFilter.AssignedTo}
                    onClick={() => setFilter(RoundingFilter.AssignedTo)}
                  />
                </tr>
              </thead>
              <tbody>
                {closedRoundingTasks.map((task, index) => (
                  <RoundingRow
                    key={task.id}
                    task={task}
                    index={index}
                    onReportIssue={handleReportIssue}
                  />
                ))}
              </tbody>
            </table>
            <div className="pb-9 pt-5 md:pb-12 md:pt-7">
              {numClosedRoundingTasksPages > 1 && (
                <Pagination
                  page={closedRoundingTasksPage}
                  numPages={numClosedRoundingTasksPages}
                  onChange={setCurrentPage}
                />
              )}
            </div>
          </>
        )}
      </FadeInDiv>
    </>
  );
});

interface RoundingRowProps {
  task: RoundingTaskInstance;
  index: number;
  onReportIssue: (roundingTask: RoundingTaskInstance) => void;
}

interface RoundingRowState {
  isExpanded: boolean;
  toggleIsExpanded: () => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

const RoundingRow: FC<RoundingRowProps> = observer(
  ({ index, task, onReportIssue }) => {
    const {
      uiStore: { isTablet, isMobile },
      userStore: { users },
    } = useStore();

    const state = useLocalObservable<RoundingRowState>(() => ({
      isExpanded: false,
      toggleIsExpanded() {
        state.isExpanded = !state.isExpanded;
      },
      isLoading: false,
      setIsLoading(isLoading) {
        state.isLoading = isLoading;
      },
    }));

    const bgColor = index % 2 === 0 ? 'bg-gray-50' : '';

    if (task.rounding_step === null) return null;

    return (
      <>
        <tr key={task.id} className={`border-t border-gray-200 ${bgColor}`}>
          <td className="w-6 pl-7 text-center">
            <div className="flex items-center">
              <button
                onClick={state.toggleIsExpanded}
                className="rounded-md p-1 transition-colors hover:bg-gray-100"
              >
                <ChevronDownIcon
                  className={`w-6 transition ${
                    state.isExpanded ? '' : '-rotate-90'
                  }`}
                />
              </button>
            </div>
          </td>
          <td className="py-5 pl-7 text-sm md:text-base">
            {task.rounding_step.name}
          </td>
          <td className="py-5 pl-7 text-sm md:text-base">
            {task.rounding_step.facilityName}
          </td>
          <td className="hidden py-5 pl-7 xl:table-cell">
            {task.rounding_step.company?.name || 'Inget företag'}
          </td>
          <td className="hidden whitespace-nowrap py-5 pl-7 md:table-cell">
            {formatDate(task.ends_at).date}
          </td>
          <td className="hidden p-7 py-5 lg:table-cell">
            <div className="inline-block">
              <RoundingStatusTag
                type={
                  task.isActionRequired
                    ? RoundingStatus.ActionIsRequired
                    : task.status
                }
              />
            </div>
          </td>
          <td className="hidden whitespace-nowrap py-5 pl-7 md:table-cell">
            {task.approved_at ? formatDate(task.approved_at).date : ''}
          </td>
          <td className="hidden px-7 py-5 lg:table-cell">
            <UserSelect
              users={users as UserInstance[]}
              onSelect={(user) => task.assign(user!.id)}
              disabledUsers={task.assigned_to ? [task.assigned_to] : []}
              renderButton={() => {
                return task.assigned_to ? (
                  <Avatar user={task.assigned_to} border />
                ) : (
                  <UserSelectIcon className="w-8" />
                );
              }}
              disabled={task.status === RoundingStatus.Approved}
              tooltip
            />
          </td>
        </tr>
        {state.isExpanded && (
          <tr className={bgColor}>
            <td className="px-4 pb-4 text-gray-900" colSpan={8}>
              {(isTablet || isMobile) && (
                <div className="flex flex-col space-y-2 px-4 pb-4">
                  <span>{task.rounding_step.name}</span>
                  <span>{task.rounding_step.facilityName}</span>
                  <span>{task.rounding_step.companyName}</span>
                  <span>{formatDate(task.ends_at).date}</span>
                  <div className="w-fit">
                    <RoundingStatusTag
                      type={
                        task.isActionRequired
                          ? RoundingStatus.ActionIsRequired
                          : task.status
                      }
                    />
                  </div>
                  <div>
                    <UserSelect
                      users={users as UserInstance[]}
                      onSelect={(user) => task.assign(user!.id)}
                      disabledUsers={task.assigned_to ? [task.assigned_to] : []}
                      renderButton={() => {
                        return task.assigned_to ? (
                          <Avatar user={task.assigned_to} border />
                        ) : (
                          <UserSelectIcon className="w-8" />
                        );
                      }}
                      disabled={task.status === RoundingStatus.Approved}
                      tooltip
                    />
                  </div>
                </div>
              )}
              <div className="flex flex-col rounded-md bg-white p-4 shadow lg:flex-row">
                {task.isActionRequired && (
                  <div className="mr-12 flex items-center pb-4">
                    <div className="rounded-full bg-pink-100 p-0.5">
                      <InfoIcon className="w-5 text-red-500" />
                    </div>
                  </div>
                )}
                <div className="flex flex-1 flex-col justify-between rounded-md bg-gray-50 lg:flex-row">
                  <div className="grid grid-cols-1 lg:grid-cols-3">
                    <div className="flex flex-col p-6">
                      <span className="font-semibold">
                        {RoundingType[task.rounding_step.rounding_type]}
                      </span>
                      <span className="text-xl font-light">
                        {task.rounding_step.setpoints}
                      </span>
                      <span className="font-light">
                        {task.rounding_step.instruction}
                      </span>
                    </div>
                    {task.issues.length > 0 && (
                      <div className="flex max-w-96 flex-col p-6">
                        <span className="font-semibold">Kopplade ärenden</span>
                        {task.issues.map((issue) => (
                          <Link
                            to={`/issues/${issue.id}`}
                            className="truncate font-light text-indigo-600"
                            key={issue.id}
                          >
                            #{issue.id} - {formatDate(issue.created_at).date} -{' '}
                            {issue.issue}
                          </Link>
                        ))}
                      </div>
                    )}
                    {(task.comment && task.comment.length > 0) ||
                    (task.files && task.files.length > 0) ? (
                      <div className="flex max-w-96 flex-col p-6">
                        <span className="font-semibold">Dokument</span>

                        {task.comment && task.comment.length > 0 && (
                          <div className="mt-2"> {task.comment}</div>
                        )}

                        {task.files && task.files.length > 0 && (
                          <div className="mt-2">
                            <ul className="flex flex-wrap">
                              {task.files.map((currentFile, index) => (
                                <li key={index}>
                                  <a
                                    className="flex items-center text-indigo-900"
                                    href={currentFile.file}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <PaperClipIcon />
                                    <span
                                      className="ml-1 mr-3 truncate underline"
                                      style={{ maxWidth: '250px' }}
                                    >
                                      {currentFile.name}
                                    </span>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    ) : null}
                  </div>

                  <div className="flex flex-col text-white">
                    <button
                      className="flex flex-1 items-center space-x-2 rounded-t-md bg-red-500 p-5 font-semibold lg:rounded-tl-none"
                      onClick={() => onReportIssue(task)}
                    >
                      <InfoIcon className="w-5 flex-shrink-0" />
                      <span className="whitespace-nowrap">Åtgärd krävs</span>
                    </button>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        )}
      </>
    );
  },
);
