import React, { useEffect, FC } from 'react';
import { Menu, MenuButton, MenuItem, MenuPopover } from '@reach/menu-button';
import { positionMatchWidth } from '@reach/popover';
import { UserInstance } from '../models';
import { Observer, observer, useLocalObservable } from 'mobx-react-lite';
import { Avatar } from './Avatar';
import { DeleteIcon, UserSelectIcon } from '../icons';
import { useMouseDownOnResizeRef } from '../hooks';
import { localeCompareSv } from '../utils';
import { UserTooltip } from './UserTooltip';

type UserMenuItemProps = {
  user: null | UserInstance;
  onSelect: (selected: null | UserInstance) => void;
  disabled?: boolean;
  border: boolean;
};

const UserMenuItem = observer(
  ({ border, user, onSelect, disabled = false }: UserMenuItemProps) => (
    <MenuItem
      disabled={disabled}
      className={`group flex w-full select-none flex-col items-baseline p-3 text-sm ${
        disabled
          ? 'text-gray-300'
          : 'cursor-pointer hover:bg-indigo-100 focus:bg-indigo-100'
      }`}
      onSelect={() => onSelect(user)}
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center">
          <div className="mr-2">
            {user && <Avatar user={user} border={border} />}
          </div>
          <span>{user?.name || 'Ingen tilldelad'}</span>
        </div>
      </div>
    </MenuItem>
  ),
);

interface UserSelectProps {
  users: UserInstance[];
  assignedUser?: UserInstance | null;
  selected?: null | UserInstance;
  onSelect: (selected: null | UserInstance) => void;
  onDelete?: () => void;
  renderButton: (selected?: null | UserInstance) => JSX.Element;
  disabled?: boolean;
  disabledUsers?: UserInstance[] | null;
  tooltip?: boolean;
}

interface UserSelectState {
  users: UserInstance[];
  searchText: string;
  setSearchText: (searchText: string) => void;
  sortedUsers: UserInstance[];
  filteredUsers: UserInstance[];
  handleSearchChange: (event: any) => void;
  reset: () => void;
  isMouseOver: boolean;
  setIsMouseOver: (isMouseOver: boolean) => void;
}

export const UserSelect: FC<UserSelectProps> = observer(
  ({
    users,
    selected,
    onSelect,
    onDelete,
    renderButton,
    disabled = false,
    disabledUsers,
    assignedUser,
    tooltip = false,
  }) => {
    const ref = useMouseDownOnResizeRef();
    const state = useLocalObservable<UserSelectState>(() => ({
      users: users,
      searchText: '',
      setSearchText(text) {
        state.searchText = text;
      },
      get sortedUsers(): UserInstance[] {
        return state.users
          .slice()
          .sort((a, b) => localeCompareSv(a.name, b.name));
      },
      get filteredUsers(): UserInstance[] {
        const searchText = state.searchText.toLowerCase();
        return state.sortedUsers.filter(
          (user) =>
            user.name.toLowerCase().includes(searchText) ||
            user.email.toLowerCase().includes(searchText),
        );
      },
      handleSearchChange(event: any) {
        state.setSearchText(event.target.value);
      },
      reset() {
        state.setSearchText('');
      },
      isMouseOver: false,
      setIsMouseOver(isMouseOver) {
        state.isMouseOver = isMouseOver;
      },
    }));

    useEffect(() => {
      state.users = users;
    }, [users]);

    return (
      <div
        className="relative"
        onMouseOver={() => state.setIsMouseOver(true)}
        onMouseLeave={() => state.setIsMouseOver(false)}
      >
        {!disabled && state.isMouseOver && onDelete && (
          <button
            className="absolute -right-1 -top-1 z-50 rounded-full"
            onClick={onDelete}
          >
            <DeleteIcon />
          </button>
        )}
        <Menu>
          {({ isExpanded }) => (
            <Observer>
              {() => (
                <div className="relative h-8 w-8">
                  {tooltip && selected ? (
                    <UserTooltip user={selected} disabled={isExpanded}>
                      <MenuButton
                        className={`rounded-full ${
                          disabled ? 'cursor-default' : ''
                        }`}
                        ref={isExpanded ? ref : undefined}
                        disabled={disabled}
                      >
                        {renderButton(selected)}
                      </MenuButton>
                    </UserTooltip>
                  ) : (
                    <MenuButton
                      className={`rounded-full ${
                        disabled ? 'cursor-default' : ''
                      }`}
                      ref={isExpanded ? ref : undefined}
                      disabled={disabled}
                    >
                      {renderButton(selected)}
                    </MenuButton>
                  )}

                  {isExpanded && (
                    <MenuPopover
                      position={positionMatchWidth}
                      className="absolute z-50 my-1 max-h-96 w-full min-w-48 overflow-y-auto rounded-md border border-gray-200 bg-white shadow-lg"
                    >
                      <div className="flex items-center justify-between p-3 font-bold">
                        <input
                          onBlur={state.reset}
                          value={state.searchText}
                          onChange={state.handleSearchChange}
                          placeholder="Sök"
                          type="text"
                          className="w-3/4 focus:outline-none"
                        />
                        <UserSelectIcon className="w-8" />
                      </div>
                      <div className="reach-menu-items focus:outline-none">
                        {state.filteredUsers.length === 0 && (
                          <div className="p-3">Inga användare hittades</div>
                        )}
                        {state.filteredUsers.map((user) => {
                          const isDisabled =
                            disabledUsers?.some((u) => u.id === user.id) ||
                            assignedUser?.id === user.id;

                          return (
                            <UserMenuItem
                              border={
                                assignedUser
                                  ? assignedUser.id === user.id
                                  : false
                              }
                              disabled={isDisabled}
                              key={user.id}
                              user={user}
                              onSelect={onSelect}
                            />
                          );
                        })}
                      </div>
                    </MenuPopover>
                  )}
                </div>
              )}
            </Observer>
          )}
        </Menu>
      </div>
    );
  },
);
