import { Instance, flow, types as t } from 'mobx-state-tree';
import { IsoDate, maybeNullReference, RoundingStatus } from '../types';
import { IssueInstance } from './Issue';
import { RoundingStep } from './RoundingStep';
import { User } from './User';
import { withRequest } from '../extensions';
import { RoundingTaskFile } from './RoundingTaskFile';

export const RoundingTask = t
  .model('RoundingTask', {
    id: t.identifierNumber,
    status: t.enumeration('Status', Object.values(RoundingStatus)),
    issues: t.array(
      t.model({ id: t.number, created_at: IsoDate, issue: t.string }),
    ),
    rounding_step: maybeNullReference(RoundingStep),
    isGhost: t.maybeNull(t.boolean),
    approved_at: t.maybeNull(IsoDate),
    created_at: t.maybeNull(IsoDate),
    ends_at: IsoDate,
    assigned_to: maybeNullReference(User),
    comment: t.maybeNull(t.string),
    files: t.maybeNull(t.array(RoundingTaskFile)),
  })
  .views((self) => {
    return {
      get isActionRequired() {
        return self.status === RoundingStatus.Waiting && self.issues.length > 0;
      },
    };
  })
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;
    return {
      addIssue(issue: IssueInstance) {
        self.issues.push({
          id: issue.id,
          created_at: issue.created_at,
          issue: issue.issue,
        });
      },
      approve: flow(function* (task) {
        const { files, comment } = task;
        const data = new FormData();

        data.append('status', RoundingStatus.Approved);
        data.append('approved_at', new Date().toISOString());

        if (comment) {
          data.append('comment', comment);
        }

        if (files) {
          files.forEach((file: any) => data.append('files', file));
        }

        try {
          const response = yield request({
            url: `/api/rounding_tasks/${self.id}/`,
            method: 'PATCH',
            data: data,
          });

          self.approved_at = new Date();
          self.status = RoundingStatus.Approved;
          self.comment = response.data.comment;

          if (response.data.files && self.files) {
            self.files.clear();
            response.data.files.forEach((file: any) => self.files?.push(file));
          }
        } catch (error) {
          self.approved_at = null;
          self.status = RoundingStatus.Waiting;
          self.comment = null;
          self.files = null;
        }
      }),
      assign: flow(function* (userId: number) {
        const currentAssignedTo = self.assigned_to;
        self.assigned_to = userId as any;

        try {
          yield request({
            url: `/api/rounding_tasks/${self.id}/`,
            method: 'PATCH',
            data: {
              assigned_to: userId,
            },
          });
        } catch (error) {
          self.assigned_to = currentAssignedTo;
        }
      }),
    };
  });

export type RoundingTaskInstance = Instance<typeof RoundingTask>;
