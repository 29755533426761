import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronDownIcon } from '../icons';

export const IssueDetailsButton = ({ to }: { to: string }) => (
  <Link
    to={to}
    className="group flex h-8 w-8 items-center justify-center rounded-full bg-white transition-colors duration-200 hover:bg-indigo-600 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-800"
  >
    <ChevronDownIcon className="w-6 -rotate-90 transition duration-200 group-hover:text-white" />
  </Link>
);
