import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import {
  ExclamationMarkIcon,
  CarIcon,
  ClockIcon,
  UserIcon,
} from '../../../icons';
import { useStore } from '../../../hooks';
import { useNavigate } from 'react-router-dom';
import { AccountType } from '../../../types';

type RightNowProps = {
  numInProgressIssues: number;
  numWaitingIssues: number;
  numParkedCars: number;
  numMyIssues: number;
  showParkedCars: boolean;
};

export const RightNow = observer(
  ({
    numInProgressIssues,
    numWaitingIssues,
    numParkedCars,
    numMyIssues,
    showParkedCars,
  }: RightNowProps) => {
    const navigate = useNavigate();

    const {
      userStore: { me },
    } = useStore();

    const state = useLocalObservable(() => ({
      navigateWithFilters(to: string) {
        const url = new URL(to, window.location.origin);
        const filterSearchParams = new URLSearchParams(location.search);
        for (const [key, value] of filterSearchParams.entries()) {
          if (key === 'assigned_to_or_co_worker') {
            url.searchParams.set('assigned_to_or_co_worker', value);
          } else {
            url.searchParams.append(key, value);
          }
        }

        navigate(`${url.pathname}?${url.searchParams.toString()}`);
      },
    }));

    return (
      <div className="w-full rounded bg-white p-7">
        <div className="text-lg font-semibold">Just nu</div>
        <div className="mt-5 flex flex-col flex-wrap justify-between gap-x-7 gap-y-5 2xl:flex-row">
          <button
            className="flex items-center"
            onClick={() =>
              state.navigateWithFilters(
                `/issues?assigned_to_or_co_worker=${me!.id}`,
              )
            }
          >
            <div className="flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-md bg-rose-300">
              <UserIcon className="w-5 text-white" />
            </div>
            <div className="ml-3 text-left">
              <div className="text-sm font-semibold">Mina aktiva ärenden</div>
              <div className="text-xl font-extrabold leading-tight">
                {numMyIssues}
              </div>
            </div>
          </button>
          {me!.role > AccountType.Worker && (
            <button
              className="flex w-52 items-center"
              onClick={() =>
                state.navigateWithFilters('/issues?status=in_progress')
              }
            >
              <div className="flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-md bg-indigo-600">
                <ClockIcon className="w-6 text-white" />
              </div>
              <div className="ml-3 text-left">
                <div className="text-sm font-semibold">Pågående ärenden</div>
                <div className="text-xl font-extrabold leading-tight">
                  {numInProgressIssues}
                </div>
              </div>
            </button>
          )}
          <button
            className="flex w-52 items-center"
            onClick={() => state.navigateWithFilters('/issues?status=waiting')}
          >
            <div className="flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-md bg-yellow-400 text-white">
              <ExclamationMarkIcon className="w-6" />
            </div>
            <div className="ml-3 text-left">
              <div className="text-sm font-semibold">Ohanterade ärenden</div>
              <div className="text-xl font-extrabold leading-tight">
                {numWaitingIssues}
              </div>
            </div>
          </button>
          {showParkedCars && (
            <button
              className="flex w-52 items-center"
              onClick={() => navigate('/parking')}
            >
              <div className="flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-md bg-pink-500 text-white">
                <CarIcon className="w-6" />
              </div>
              <div className="ml-3 text-left">
                <div className="text-sm font-semibold">Parkerade bilar</div>
                <div className="text-xl font-extrabold leading-tight">
                  {numParkedCars}
                </div>
              </div>
            </button>
          )}
        </div>
      </div>
    );
  },
);
