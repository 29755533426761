import React, { useEffect, useRef } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useStore } from '../../hooks';
import {
  AssignedToCard,
  ContactCard,
  DateCard,
  DeleteButton,
  Feedback,
  Card,
  IssueAccordion,
  Notes,
  OpenCloseButton,
  StatusBar,
  SubcontractorBar,
  TimeReport,
  ImageSlider,
} from './components';
import {
  AdditionalInformation,
  ConfirmPopup,
  DeletePopup,
  FadeInDiv,
} from '../../components';
import { IssueInstance } from '../../models';
import { IssueContent } from './components/IssueContent';
import { IssueMetaData, IssueStatus } from '../../types';
import { NOTES_PER_PAGE } from '../../constants';

interface State {
  isDeletePopupOpen: boolean;
  openDeletePopup: () => void;
  closeDeletePopup: () => void;
  deleteIssue: (issueId: number) => void;

  isIssuePopupOpen: boolean;
  openIssuePopup: () => void;
  closeIssuePopup: () => void;
  closeIssue: (issue: IssueInstance) => void;
  reopenIssue: (issue: IssueInstance) => void;

  notePage: number;
  setNotePage: (page: number) => void;
}

interface IssueParams {
  issueId: string;
}

export const IssuePage = observer(() => {
  const {
    issueStore,
    uiStore,
    organizationStore: { organization },
  } = useStore();
  const { issueId } = useParams<keyof IssueParams>() as IssueParams;
  const noteRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const issue = issueStore.issues.get(issueId);

  const state = useLocalObservable<State>(() => ({
    isDeletePopupOpen: false,
    openDeletePopup() {
      state.isDeletePopupOpen = true;
    },
    closeDeletePopup() {
      state.isDeletePopupOpen = false;
    },
    async deleteIssue(issueId) {
      try {
        await issueStore.deleteIssue(issueId);
        // The history location will not have a key if it's the initial location
        // in the stack. We use this to differentiate between just directing the
        // user back in the history, or forcing them back to the issue overview.
        if (location.key) {
          navigate(-1);
        } else {
          navigate(
            location.pathname.substring(0, location.pathname.lastIndexOf('/')),
            { replace: true },
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
    async closeIssue(issue) {
      try {
        state.closeIssuePopup();
        navigate(`/issues/complete/${issueId}`, { replace: true });

        await issue.closeIssue();
      } catch (error) {
        navigate(`/issues/${issueId}`, { replace: true });
      }
    },
    async reopenIssue(issue) {
      try {
        state.closeIssuePopup();
        navigate(`/issues/${issue.id}`);

        await issue.reopenIssue();
      } catch (error) {
        navigate(`/issues/complete/${issue.id}`);
      }
    },
    isIssuePopupOpen: false,
    openIssuePopup() {
      state.isIssuePopupOpen = true;
    },
    closeIssuePopup() {
      state.isIssuePopupOpen = false;
    },
    notePage: 1,
    setNotePage(page) {
      state.notePage = page;
    },
  }));

  useEffect(() => {
    issueStore.fetchIssue(issueId);
    state.setNotePage(1);
  }, [issueId]);

  useEffect(() => {
    if (!location.hash || !noteRef.current || !issue?.notes) return;

    const locationHash = location.hash.split('_');
    let noteId = '';

    if (locationHash[0] === '#note') {
      noteId = locationHash[1];
    }

    const index = issue.notes.findIndex(
      (note) => note.id.toString() === noteId,
    );

    if (index > -1) {
      const page = Math.ceil((index - 1) / NOTES_PER_PAGE) + 1;
      state.setNotePage(page);
      setTimeout(() => {
        noteRef.current!.scrollIntoView({ behavior: 'smooth' });
      }, 0);
    }
  }, [issueId, issue?.notes]);

  if (!issue) return null;

  const isClosedWithNoAssignee =
    issue.status === IssueStatus.Done && issue.assigned_to === null;
  const isClosedWithNoTimeReports =
    issue.status === IssueStatus.Done &&
    (issue.time_reports === null || issue.time_reports.length === 0);

  return (
    <FadeInDiv className="flex h-full flex-col">
      <div className="scrollbar-none mx-4 mb-5 flex-shrink-0 whitespace-nowrap md:mx-14 md:mt-14 lg:mb-10">
        <div className="flex flex-col gap-x-2 gap-y-7 px-7 md:grid md:grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] 2xl:flex 2xl:flex-row 2xl:flex-wrap 2xl:items-start">
          <Card title="Ärendenummer" border>
            {issue.number}
          </Card>
          <DateCard title="Inkommet" date={issue.created_at} border />
          {issue.resolved_at !== null && (
            <DateCard title="Avslutat" date={issue.resolved_at} border />
          )}
          <Card title="Typ av ärende" border>
            {issue.issue_type!.name}
          </Card>
          {issue.facility && (
            <Card title="Fastighet" border>
              {issue.facility.name}
            </Card>
          )}
          {issue.rounding_task !== null && (
            <Card title="Källa" border>
              Rondering
            </Card>
          )}
          {!isClosedWithNoAssignee && <AssignedToCard issue={issue} />}
        </div>
      </div>

      <div className="mx-4 flex md:mx-14">
        <div className="flex-grow xl:mr-10">
          <div className="mb-5 block rounded-md bg-white md:mb-10 xl:hidden">
            <div className="border-b border-solid border-indigo-100">
              <StatusBar issue={issue} />
            </div>
            {organization?.hasIssueCostsFeature &&
              !isClosedWithNoTimeReports && (
                <div className="border-b border-solid border-indigo-100">
                  <TimeReport issue={issue} />
                </div>
              )}
            <div className="pb-2">
              <SubcontractorBar issue={issue} />
            </div>
          </div>
          {issue.files && issue.files.length > 0 && (
            <div className="mb-5 md:mb-10">
              <ImageSlider slides={issue.files} />
            </div>
          )}

          {uiStore.isMobile ? (
            <IssueAccordion
              items={[
                {
                  key: 'issueDescription',
                  title: 'Ärendebeskrivning',
                  content: (
                    <div className="p-7">
                      <IssueContent issue={issue} />
                    </div>
                  ),
                },
              ]}
            />
          ) : (
            <div className="flex-grow rounded-md bg-white px-7 pb-7 pt-7">
              <span className="mb-7 hidden text-xl font-extrabold text-indigo-900 md:block">
                Ärendebeskrivning
              </span>

              <div className="max-w-2xl">
                <IssueContent issue={issue} />
              </div>
            </div>
          )}

          {issue.notes !== null && (
            <FadeInDiv className="mt-5 md:mt-10">
              <div ref={noteRef}>
                {uiStore.isMobile ? (
                  <IssueAccordion
                    items={[
                      {
                        key: 'notes',
                        title: 'Anteckningar',
                        content: (
                          <Notes
                            issue={issue}
                            page={state.notePage}
                            handlePageChange={state.setNotePage}
                          />
                        ),
                      },
                    ]}
                  />
                ) : (
                  <div className="rounded-md bg-white">
                    <Notes
                      issue={issue}
                      page={state.notePage}
                      handlePageChange={state.setNotePage}
                    />
                  </div>
                )}
              </div>
            </FadeInDiv>
          )}
          {issue.feedbacks !== null && issue.feedbacks.length !== 0 && (
            <FadeInDiv className="mt-5 md:mt-10">
              <IssueAccordion
                items={issue.feedbacks!.map((feedback, index, arr) => ({
                  key: feedback.id.toString(),
                  title:
                    arr.length > 1
                      ? `Feedback ${arr.length - index}`
                      : 'Feedback',
                  content: (
                    <Feedback
                      feedback={feedback}
                      index={index}
                      numFeedbacks={arr.length}
                    />
                  ),
                }))}
              />
            </FadeInDiv>
          )}
          {organization?.hasEntreFeature && (
            <FadeInDiv className="mt-5 md:mt-10">
              <AdditionalInformation
                fields={Object.values(IssueMetaData).map((key) => ({
                  key,
                  value: issue.meta_data?.get(key) || '',
                  isReadonly: key === IssueMetaData.EntreOrderStatus,
                }))}
                onBlur={issue.setMetaData}
              />
            </FadeInDiv>
          )}
          {uiStore.isMobile ? (
            <div className="mt-5">
              <IssueAccordion
                items={[
                  {
                    key: 'contact',
                    title: 'Kontaktperson',
                    content: <ContactCard issue={issue} />,
                  },
                ]}
              />
            </div>
          ) : (
            <div className="mt-5 block rounded-md bg-white md:mt-10 xl:hidden">
              <ContactCard issue={issue} />
            </div>
          )}

          <div className="mt-5 flex w-full flex-col xs:block xs:w-auto md:mt-10 xl:hidden">
            <OpenCloseButton onClick={state.openIssuePopup} issue={issue} />
            <div className="mb-5"></div>
            <DeleteButton onClick={() => state.openDeletePopup()} />
          </div>
          <div className="pb-5 md:pb-10"></div>
        </div>

        <div className="hidden w-full max-w-sm flex-shrink-0 self-start xl:block">
          <div className="mb-10 rounded-md bg-white">
            <div className="border-b border-solid border-indigo-100">
              <StatusBar issue={issue} />
            </div>
            {organization?.hasIssueCostsFeature &&
              !isClosedWithNoTimeReports && (
                <div className="relative border-b border-solid border-indigo-100">
                  <TimeReport issue={issue} />
                </div>
              )}
            <div className="border-b border-solid border-indigo-100">
              <SubcontractorBar issue={issue} />
            </div>
            <ContactCard issue={issue} />
          </div>

          <div className="mb-5">
            <OpenCloseButton onClick={state.openIssuePopup} issue={issue} />
          </div>
          <DeleteButton onClick={() => state.openDeletePopup()} />
          <div className="pb-5 md:pb-10"></div>
        </div>
      </div>

      {state.isDeletePopupOpen && (
        <DeletePopup
          onDismiss={state.closeDeletePopup}
          onConfirm={() => state.deleteIssue(issue.id)}
          title="Radera ärende"
        >
          Om du raderar ärendet går det inte att återställas. Är du säker på att
          du vill radera ärendet?
        </DeletePopup>
      )}
      {state.isIssuePopupOpen && (
        <>
          {issue.status !== IssueStatus.Done ? (
            <ConfirmPopup
              onDismiss={state.closeIssuePopup}
              onConfirm={() => state.closeIssue(issue)}
              title="Markera som åtgärdat"
              buttonText="Markera som åtgärdat"
            >
              Om du markerar ärendet som åtgärdat kommer en notis skickas till
              kontaktperson. Hen kommer även få möjlighet att lämna feedback.
            </ConfirmPopup>
          ) : (
            <ConfirmPopup
              onDismiss={state.closeIssuePopup}
              onConfirm={() => state.reopenIssue(issue)}
              title="Återöppna ärende"
              buttonText="Återöppna"
            >
              Om du återöppnar ärendet kommer en notis skickas till
              kontaktperson. Hen kommer även kunna lämna ytterligare feedback
              efter att ärendet åtgärdats.
            </ConfirmPopup>
          )}
        </>
      )}
    </FadeInDiv>
  );
});
