import React from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import linkifyHtml from 'linkify-html';
import { Issue, IssueNoteInstance } from '../../../models';
import { formatDate } from '../../../utils';
import { useMouseDownOnResizeRef, useStore } from '../../../hooks';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuPopover,
} from '@reach/menu-button';
import { ConfirmPopup, DeletePopup, Tooltip } from '../../../components';
import { getParentOfType } from 'mobx-state-tree';
import { Attachment } from './Attachment';
import { Lightbox } from './Lightbox';
import { CrossIcon, EditIcon } from '../../../icons';
import { TextArea } from './TextArea';
import escape from 'lodash.escape';

const EditDeleteNoteMenu = observer(({ note }: { note: IssueNoteInstance }) => {
  const ref = useMouseDownOnResizeRef();

  const state = useLocalObservable(() => ({
    editedNote: '',
    setEditedNote(note: string) {
      state.editedNote = note;
    },

    isEditPopupOpen: false,
    toggleEditPopup() {
      state.isEditPopupOpen = !state.isEditPopupOpen;

      if (state.isEditPopupOpen) {
        state.setEditedNote(note.content);
      }
    },

    isDeletePopupOpen: false,
    toggleDeletePopup() {
      state.isDeletePopupOpen = !state.isDeletePopupOpen;
    },

    saveEditedNote() {
      note.editContent(state.editedNote);

      state.setEditedNote('');
      state.toggleEditPopup();
    },
    cancelEditNote() {
      state.setEditedNote('');
      state.toggleEditPopup();
    },

    async deleteNote() {
      const issue = getParentOfType(note, Issue);
      await issue.deleteNote(note);

      state.toggleDeletePopup();
    },
  }));

  return (
    <>
      <Menu>
        {({ isExpanded }) => (
          <div>
            <MenuButton
              ref={isExpanded ? ref : undefined}
              className="flex w-full items-center justify-between focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-800"
            >
              <svg
                className={`transform fill-current text-indigo-900 transition-transform duration-300 hover:rotate-90 ${
                  isExpanded ? 'rotate-90' : ''
                }`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="16px"
                height="16px"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
              </svg>
            </MenuButton>

            {isExpanded && (
              <MenuPopover className="my-1 min-w-36 rounded-md border border-gray-200 bg-white shadow-lg">
                <MenuItems className="reach-menu-items focus:outline-none">
                  {!note.is_external && (
                    <MenuItem
                      className="group flex w-full cursor-pointer flex-col items-baseline p-3 hover:bg-indigo-100 focus:bg-indigo-100"
                      onSelect={state.toggleEditPopup}
                    >
                      <div className="flex w-full items-center justify-between">
                        <span className="text-sm">Redigera</span>
                        <EditIcon className="w-4 text-indigo-900" />
                      </div>
                    </MenuItem>
                  )}
                  <MenuItem
                    className="group flex w-full cursor-pointer flex-col items-baseline p-3 hover:bg-indigo-100 focus:bg-indigo-100"
                    onSelect={state.toggleDeletePopup}
                  >
                    <div className="flex w-full items-center justify-between">
                      <span className="text-sm">Radera</span>
                      <CrossIcon className="w-4 text-indigo-900" />
                    </div>
                  </MenuItem>
                </MenuItems>
              </MenuPopover>
            )}
          </div>
        )}
      </Menu>
      {state.isEditPopupOpen && (
        <ConfirmPopup
          onDismiss={state.toggleEditPopup}
          onConfirm={state.saveEditedNote}
          title="Redigera anteckning"
          buttonText="Spara"
        >
          <TextArea
            value={state.editedNote}
            onChange={(e) => state.setEditedNote(e.target.value)}
          />
        </ConfirmPopup>
      )}

      {state.isDeletePopupOpen && (
        <DeletePopup
          onDismiss={state.toggleDeletePopup}
          onConfirm={state.deleteNote}
          title="Radera anteckning"
        >
          Om du raderar anteckningen går det inte att återställas. Är du säker
          på att du vill radera anteckningen?
        </DeletePopup>
      )}
    </>
  );
});

const getNoteContent = (noteContent: string) => {
  return escape(noteContent).replace(/@\[(.+?)\]\([0-9]*\)/g, (match, name) => {
    return `<span class='text-indigo-600'">${name}</span>`;
  });
};

export const Note = observer(({ note }: { note: IssueNoteInstance }) => {
  const {
    userStore: { me },
  } = useStore();
  const formattedCreatedDate = formatDate(note.created_at);
  const updated_at = note.updated_at && formatDate(note.updated_at);

  const state = useLocalObservable(() => ({
    isLightboxOpen: false,
    initialSlide: 0,
    openLightbox(initialSlide: number) {
      state.isLightboxOpen = true;
      state.initialSlide = initialSlide;
    },
    closeLightbox() {
      state.isLightboxOpen = false;
    },
  }));

  return (
    <>
      <div className="mb-2 flex items-center justify-between">
        {note.author !== null && (
          <span className="mr-3 text-xs font-semibold text-gray-900">
            {note.author.name}
          </span>
        )}
        <div className="flex flex-shrink-0 items-center text-indigo-900">
          <div className="text-xs">
            {updated_at !== null && (
              <div className="hidden xs:inline">
                <Tooltip
                  label={`${updated_at.time} ${updated_at.date}`}
                  aria-label={`Redigerad ${updated_at.time} ${updated_at.date}`}
                >
                  <span className="mr-1">(redigerad)</span>
                </Tooltip>
              </div>
            )}
            <span className="mr-1">{formattedCreatedDate.time}</span>
            <span>{formattedCreatedDate.date}</span>
          </div>
        </div>
      </div>

      <div>
        <div
          className={`flex justify-between rounded-md border border-solid px-6 py-3 md:px-3 ${
            note.is_external ? 'border-pink-300 bg-pink-50' : 'border-gray-300'
          }`}
        >
          <div className="min-w-0">
            <div
              className="break-words pr-3 leading-7 text-gray-900"
              dangerouslySetInnerHTML={{
                __html: linkifyHtml(getNoteContent(note.content), {
                  className: 'text-pink-500 hover:underline',
                  rel: 'noopener',
                  target: '_blank',
                  defaultProtocol: 'https',
                }),
              }}
            />
            {note.files.length !== 0 && (
              <div className={note.content === '' ? '-mt-3' : ''}>
                {note.files.map((file, index) => (
                  <div
                    key={file.id}
                    className="mr-1 mt-1 inline-block md:mr-3 md:mt-3"
                  >
                    <Attachment
                      name={file.name}
                      onClick={() => state.openLightbox(index)}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="flex h-7 w-4 items-center">
            {note.author === me && <EditDeleteNoteMenu note={note} />}
          </div>
        </div>

        <div className="relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            fill={note.is_external ? '#FDF2F8' : 'white'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={`absolute -top-px left-3 h-3 w-3 stroke-current ${
              note.is_external ? 'text-pink-300' : 'text-gray-300'
            }`}
          >
            <polyline points="1,0 1,23 23,0"></polyline>
          </svg>
        </div>
      </div>
      {state.isLightboxOpen && (
        <Lightbox
          files={note.files}
          initialSlide={state.initialSlide}
          onClose={state.closeLightbox}
        />
      )}
    </>
  );
});
