import React, { FC } from 'react';
import { RoundingStatus } from '../types';

interface Props {
  type: RoundingStatus;
}

const colors = {
  [RoundingStatus.Waiting]: 'bg-yellow-400',
  [RoundingStatus.Approved]: 'bg-emerald-400',
  [RoundingStatus.ActionIsRequired]: 'bg-red-500',
};

const texts = {
  [RoundingStatus.Waiting]: 'Ohanterad',
  [RoundingStatus.Approved]: 'Genomförd',
  [RoundingStatus.ActionIsRequired]: 'Åtgärd krävs',
};

export const RoundingStatusTag: FC<Props> = ({ type }) => {
  const color = colors[type];
  const text = texts[type];

  return (
    <div
      className={`${color} flex items-center justify-center whitespace-nowrap rounded-md px-3 py-1 text-sm font-semibold text-white`}
    >
      {text}
    </div>
  );
};
