import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@reach/accordion';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect, useLayoutEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { FadeInDiv, Pagination, TableHeaderCell } from '../components';
import { TagIcon, ChevronDownIcon, EditIcon } from '../icons';
import { ARTICLES_PER_PAGE } from '../constants';
import { useHorizontalDragRef, useStore } from '../hooks';
import { ArticleInstance } from '../models';
import { AccountType } from '../types';
import { localeCompareSv } from '../utils';

type Filter = '' | 'number' | 'name' | 'price';

type State = {
  indices: number[];
  toggleItem: (toggledIndex: number) => void;

  query: URLSearchParams;
  currentPage: number;
  filter: Filter;
  isAscending: boolean;
  handleSearchChange: (search: string) => void;
  setFilter: (newFilter: Filter) => void;

  numPages: number;
  startIndex: number;
  endIndex: number;
  setCurrentPage: (page: number) => void;

  sortedArticles: ArticleInstance[];
  filteredArticles: ArticleInstance[];

  canCreateAndEdit: boolean;
};

export const ArticlesPage = observer(() => {
  const { userStore, articleStore, uiStore } = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useHorizontalDragRef();

  const state = useLocalObservable<State>(() => ({
    indices: [],
    toggleItem(toggledIndex) {
      const index = state.indices.indexOf(toggledIndex);
      if (index !== -1) {
        state.indices.splice(index, 1);
      } else {
        state.indices.push(toggledIndex);
      }
    },

    query: new URLSearchParams(location.search),
    get currentPage(): number {
      const pageParam = state.query.get('page');

      return pageParam ? Number(pageParam) : 1;
    },
    get filter(): Filter {
      const sortParam = state.query.get('sort');

      return (sortParam ? sortParam.replace('-', '') : '') as Filter;
    },
    get isAscending(): boolean {
      const sortParam = state.query.get('sort');

      return sortParam !== null && sortParam[0] !== '-';
    },
    handleSearchChange(search) {
      state.query = new URLSearchParams(search);
      // We want to close all accordion items when
      // the user changes the filter or page.
      state.indices = [];
    },
    setFilter(newFilter) {
      const { query, filter, isAscending } = state;
      const searchParams = new URLSearchParams(query);
      searchParams.delete('page');

      if (filter === newFilter) {
        if (isAscending) {
          searchParams.delete('sort');
        } else {
          searchParams.set('sort', filter);
        }
      } else {
        searchParams.set('sort', `-${newFilter}`);
      }
      navigate(`${location.pathname}?${searchParams.toString()}`);
    },

    get numPages() {
      return Math.ceil(articleStore.activeArticles.length / ARTICLES_PER_PAGE);
    },
    get startIndex(): number {
      return (state.currentPage - 1) * ARTICLES_PER_PAGE;
    },
    get endIndex(): number {
      return state.startIndex + ARTICLES_PER_PAGE;
    },
    setCurrentPage(page) {
      const searchParams = new URLSearchParams(state.query);

      if (page === 1) {
        searchParams.delete('page');
      } else {
        searchParams.set('page', `${page}`);
      }
      navigate(`${location.pathname}?${searchParams.toString()}`);
    },

    get sortedArticles(): ArticleInstance[] {
      const articles = articleStore.activeArticles.slice();
      const { filter, isAscending } = state;

      if (filter === '') return articles;

      if (filter === 'name') {
        return articles.sort((a, b) => {
          if (isAscending) [b, a] = [a, b];

          return localeCompareSv(b.name, a.name);
        });
      }

      return articles.sort((a, b) => {
        if (isAscending) [b, a] = [a, b];

        return b[filter] - a[filter];
      });
    },
    get filteredArticles(): ArticleInstance[] {
      const { sortedArticles, startIndex, endIndex } = state;

      return sortedArticles.slice(startIndex, endIndex);
    },

    get canCreateAndEdit() {
      return userStore.me!.role >= AccountType.Admin;
    },
  }));

  useLayoutEffect(() => {
    state.handleSearchChange(location.search);
  }, [location.search]);

  useEffect(() => {
    articleStore.fetchArticles();
  }, []);

  if (articleStore.articles === null) return null;

  return (
    <FadeInDiv className="flex h-full flex-col bg-neutral-100">
      <div
        ref={ref}
        className="scrollbar-none mb-4 w-full flex-shrink-0 overflow-x-auto whitespace-nowrap px-4 md:mb-0 md:flex md:flex-col md:overflow-hidden md:px-14 md:pb-10 md:pt-14 lg:flex-row lg:space-x-9"
      >
        <div className="inline-block w-full rounded-md bg-white px-7 py-7 md:mt-2 lg:mt-0 lg:max-w-xs">
          <div className="flex flex-col">
            <span className="whitespace-nowrap text-base font-semibold text-gray-900">
              Antal artiklar
            </span>
            <span className="mt-1 text-xl font-extrabold text-indigo-900">
              {articleStore.activeArticles.length}
            </span>
          </div>
        </div>
        {state.canCreateAndEdit && (
          <div className="mt-4 flex flex-col justify-end md:mt-2 lg:mr-10">
            <Link
              className="text flex items-center justify-center rounded-md bg-white p-3 text-gray-900 transition duration-300 hover:bg-indigo-600 hover:text-white"
              to={`/organization/articles/create-article`}
            >
              <TagIcon className="h-5 w-5" />
              <span className="ml-3 text-base font-semibold">
                Skapa ny artikel
              </span>
            </Link>
          </div>
        )}
      </div>

      {articleStore.activeArticles.length === 0 ? (
        <div className="mx-4 flex-1 rounded-md bg-white px-7 py-7 text-center md:mx-14 md:py-28">
          <p className="mt-5 text-2xl font-extrabold text-indigo-900 md:text-3xl">
            Det finns inga artiklar
          </p>
        </div>
      ) : (
        <>
          {uiStore.isMobile ? (
            <div className="mx-4">
              <Accordion
                index={state.indices}
                onChange={state.toggleItem}
                className="w-full rounded-md bg-white font-semibold text-gray-900"
              >
                <div className="border-b border-gray-200 px-7 py-4">
                  <span className="font-semibold">Artiklar</span>
                </div>
                {state.filteredArticles.map((article, index) => (
                  <AccordionItem
                    key={article.id}
                    className={`${
                      index !== state.filteredArticles.length - 1
                        ? 'border-b border-gray-200'
                        : ''
                    }`}
                  >
                    <AccordionButton className="flex w-full items-center justify-between px-7 py-4 focus:outline-none">
                      <h3 className="break-all">{article.name}</h3>
                      <ChevronDownIcon
                        className={`w-6 flex-shrink-0 transform transition-transform ${
                          state.indices.includes(index) ? 'rotate-180' : ''
                        }`}
                      />
                    </AccordionButton>

                    <AccordionPanel className="border-t border-gray-200 bg-gray-50 px-7 py-7 outline-none">
                      <div>
                        <span className="mr-4 inline-block w-20 whitespace-nowrap text-sm font-semibold text-gray-900">
                          Nr.
                        </span>
                        <span className="break-all border border-transparent font-normal text-gray-900">
                          {article.number}
                        </span>
                      </div>
                      <div className="mt-4">
                        <span className="mr-4 inline-block w-20 whitespace-nowrap text-sm font-semibold text-gray-900">
                          Namn
                        </span>
                        <span className="break-all border border-transparent font-normal text-gray-900">
                          {article.name}
                        </span>
                      </div>
                      <div className="mt-4">
                        <span className="mr-4 inline-block w-20 whitespace-nowrap text-sm font-semibold text-gray-900">
                          Pris (kr)
                        </span>
                        <span className="break-all border border-transparent font-normal text-gray-900">
                          {article.price}
                        </span>
                      </div>
                      {state.canCreateAndEdit && (
                        <Link
                          key={article.name}
                          className="mt-7 flex justify-center rounded-md bg-white p-3 text-black transition duration-300 hover:bg-indigo-600 hover:text-white"
                          to={`/organization/articles/edit-article/${article.id}`}
                        >
                          <EditIcon />
                          <span className="ml-3 font-semibold">
                            Redigera artikel
                          </span>
                        </Link>
                      )}
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          ) : (
            <div className="mx-14 flex-1">
              <table className="w-full rounded-md bg-white">
                <thead>
                  <tr>
                    <TableHeaderCell
                      title="Nr."
                      isActive={state.filter === 'number'}
                      isAscending={state.isAscending}
                      onClick={() => state.setFilter('number')}
                      className="hidden lg:table-cell"
                    />
                    <TableHeaderCell
                      title="Namn"
                      isActive={state.filter === 'name'}
                      isAscending={state.isAscending}
                      onClick={() => state.setFilter('name')}
                    />
                    <TableHeaderCell
                      title="Pris (kr)"
                      isActive={state.filter === 'price'}
                      isAscending={state.isAscending}
                      onClick={() => state.setFilter('price')}
                    />
                    <th className="w-80"></th>
                  </tr>
                </thead>
                <tbody>
                  {state.filteredArticles.map((article, index, arr) => (
                    <tr
                      key={article.id}
                      className={`border-t border-gray-200 ${
                        index % 2 === 0 ? 'bg-gray-50' : ''
                      } ${index !== arr.length - 1 ? 'border-b' : ''}`}
                    >
                      <td className="relative hidden h-16 py-3 pl-7 lg:table-cell">
                        {article.number}
                      </td>
                      <td className="relative h-16 py-3 pl-7">
                        <span className="border border-transparent">
                          {article.name}
                        </span>
                      </td>
                      <td className="relative h-16 py-3 pl-7">
                        <span className="border border-transparent">
                          {article.price}
                        </span>
                      </td>
                      <td className="relative px-7 text-right">
                        {state.canCreateAndEdit && (
                          <Link
                            key={article.name}
                            className="inline-block rounded-md bg-white p-3 text-black transition duration-300 hover:bg-indigo-600 hover:text-white"
                            to={`/organization/articles/edit-article/${article.id}`}
                          >
                            <EditIcon />
                          </Link>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}

      <div className="pb-9 pt-5 md:pb-12 md:pt-7">
        {state.numPages > 1 && (
          <Pagination
            page={state.currentPage}
            numPages={state.numPages}
            onChange={state.setCurrentPage}
          />
        )}
      </div>
    </FadeInDiv>
  );
});
