import React, { FC, memo, SVGProps } from 'react';

export const UploadImageIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 74 74"
    fill="none"
    className="w-4"
    {...props}
  >
    <circle
      cx="37"
      cy="37"
      r="36.5"
      fill="#fff"
      stroke="#D4D4D8"
      strokeDasharray="4 4"
    ></circle>
    <path
      fill="#D4D4D8"
      d="M49.248 40.656a7.485 7.485 0 107.485 7.485 7.494 7.494 0 00-7.485-7.485zm0 13.61a6.124 6.124 0 116.124-6.125 6.132 6.132 0 01-6.124 6.124z"
    ></path>
    <path
      fill="#D4D4D8"
      d="M49.929 47.46v-4.082h-1.361v4.083h-4.083v1.36h4.083v4.083h1.36v-4.082h4.084V47.46h-4.083zm-23.136 2.043h-1.36v4.763h-6.805v-7.158a4.756 4.756 0 012.977-4.418l8.982-3.628c.126-.051.265-.085.398-.129.523 2.55 2.998 4.446 6.015 4.446 3.016 0 5.494-1.898 6.013-4.45.132.043.272.077.395.128l2.183.877.508-1.262-2.177-.876a7.1 7.1 0 00-1.002-.323.659.659 0 01-.476-.649v-3.326a7.475 7.475 0 002.721-5.77v-5.443A7.478 7.478 0 0037.68 14.8h-1.36a7.494 7.494 0 00-7.486 7.485v5.444a7.476 7.476 0 002.722 5.77v3.329a.658.658 0 01-.476.648 7.116 7.116 0 00-1.005.325l-8.982 3.628a6.111 6.111 0 00-3.826 5.679v7.839a.68.68 0 00.68.68h23.816v-1.36h-14.97v-4.764zm9.526-33.342h1.361a6.116 6.116 0 016.124 6.124v1.269c-1.7-.423-3.815-2.206-4.654-3.029a.711.711 0 00-.817-.222.68.68 0 00-.402.48 6.601 6.601 0 01-6.143 4.224h-1.593v-2.722a6.132 6.132 0 016.124-6.124zm-6.124 11.567v-1.36h1.593a7.973 7.973 0 007.045-4.287c1.058.928 3.097 2.517 4.971 2.858v2.79a6.132 6.132 0 01-6.124 6.124h-1.36a6.132 6.132 0 01-6.125-6.125zm6.124 7.486h1.361a7.448 7.448 0 003.403-.826v2.436a2.042 2.042 0 00.649 1.505c-.231 2.056-2.282 3.69-4.732 3.69s-4.498-1.63-4.731-3.687a2.046 2.046 0 00.648-1.504v-2.44a7.437 7.437 0 003.402.826z"
    ></path>
  </svg>
));
