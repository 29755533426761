import React, { FC, memo, SVGProps } from 'react';

export const PaperClipIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    stroke="currentColor"
    className="w-4"
    {...props}
  >
    <path
      d="M21.4,11.1l-9.2,9.2c-2.3,2.3-6.1,2.3-8.5,0s-2.3-6.1,0-8.5L13,2.6C14.5,1,17,1,18.6,2.6s1.6,4.1,0,5.7
l-9.2,9.2c-0.8,0.8-2,0.8-2.8,0s-0.8-2,0-2.8l8.5-8.5"
    />
  </svg>
));
