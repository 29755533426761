import axios from 'axios';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { FadeInDiv, Logo, Label } from '../components';
import { ChevronDownIcon } from '../icons';
import { InputWithError } from '../components/InputWithError';
import { useNavigate } from 'react-router-dom';

interface FormData {
  email: string;
}

enum SubmitState {
  Idle = 'idle',
  Loading = 'loading',
  Error = 'error',
  Success = 'success',
}

interface State {
  submitState: SubmitState;
  setSubmitState: (submitState: SubmitState) => void;
  onSubmit: (email: string) => void;
}

interface Props {
  onSubmit: (email: string) => void;
  submitState: SubmitState;
}

export const ForgotPasswordForm: FC<Props> = ({ onSubmit, submitState }) => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    shouldUnregister: true,
    reValidateMode: 'onSubmit',
  });

  return (
    <FadeInDiv className="mx-4 flex max-w-md flex-1 flex-col items-start justify-center md:flex-row">
      <div className="relative">
        <button
          className="mb-7 mr-4 flex items-center justify-center rounded-md bg-white py-3 pl-1 pr-3 text-gray-900 transition-colors hover:bg-indigo-900 hover:text-white md:absolute md:right-0 md:mb-0"
          onClick={() => navigate('/')}
        >
          <ChevronDownIcon className="w-6 rotate-90" />
          <span className="ml-1 font-semibold">Tillbaka</span>
        </button>
      </div>

      <div className="w-full rounded-md bg-white p-7 shadow-xl md:p-14">
        <div className="mb-14 flex justify-center">
          <div className="w-36">
            <Logo />
          </div>
        </div>
        <div className="mb-5 text-xl font-extrabold text-indigo-900">
          Glömt ditt lösenord?
        </div>
        <form onSubmit={handleSubmit(({ email }) => onSubmit(email))}>
          <Label required>E-post</Label>
          <InputWithError
            type="text"
            placeholder="namn.namnsson@e-post.se"
            {...register('email', {
              required: 'Fyll i detta fältet',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Ange en giltig e-post',
              },
            })}
            error={errors.email?.message}
          />
          <div className="mt-5">
            <button
              disabled={submitState !== SubmitState.Idle}
              type="submit"
              className="inline-flex items-center rounded-md bg-indigo-900 p-3 font-semibold text-white"
            >
              Återställ lösenord
            </button>
          </div>
        </form>
      </div>
    </FadeInDiv>
  );
};

const ResetLinkScreen = () => {
  const navigate = useNavigate();

  return (
    <FadeInDiv>
      <div className="mx-4 max-w-md rounded-md bg-white p-14 shadow-xl">
        <div className="m-auto mb-14 w-36">
          <Logo />
        </div>
        <div className="mb-5 text-xl font-extrabold text-indigo-900">
          Återställningslänk har skickats!
        </div>
        <span className="text-gray-900">
          En återställningslänk och instruktioner har nu skickats till din
          e-postadress. Hittar du inte mejlet kan det finnas i din skräppost.
        </span>
      </div>
      <button
        onClick={() => navigate('/', { replace: true })}
        className="m-auto mt-7 flex rounded-md bg-white p-3 text-gray-900 transition-colors hover:bg-indigo-900 hover:text-white"
      >
        <ChevronDownIcon className="w-6 rotate-90" />
        <span className="ml-1 font-semibold">Tillbaka till inloggning</span>
      </button>
    </FadeInDiv>
  );
};

export const ForgotPasswordPage = observer(() => {
  const state = useLocalObservable<State>(() => ({
    submitState: SubmitState.Idle,
    setSubmitState(submitState) {
      state.submitState = submitState;
    },
    async onSubmit(email) {
      try {
        state.setSubmitState(SubmitState.Loading);
        await axios.post('/api/reset_password/', { email });
        state.setSubmitState(SubmitState.Success);
      } catch (error) {
        console.error(error);
        state.setSubmitState(SubmitState.Error);
      }
    },
  }));

  return (
    <FadeInDiv className="flex h-screen w-full items-center justify-center">
      {state.submitState === SubmitState.Success ? (
        <ResetLinkScreen />
      ) : (
        <ForgotPasswordForm
          onSubmit={state.onSubmit}
          submitState={state.submitState}
        />
      )}
    </FadeInDiv>
  );
});
