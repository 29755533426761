import React from 'react';

export const ErrorMessage = () => {
  return (
    <div className="w-full bg-yellow-400 px-4 py-4 md:px-14 md:py-5">
      <span className="font-semibold text-gray-900">
        Oops, det verkar som något gick snett! Prova gärna igen om en stund.
      </span>
    </div>
  );
};
