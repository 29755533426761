import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC } from 'react';
import { DeleteIcon, UploadImageIcon } from '../icons';

interface Props {
  imageSrc: string;
  onDelete: () => void;
  onClick: () => void;
}

interface State {
  isMouseOver: boolean;
  setIsMouseOver: (isMouseOver: boolean) => void;
}

export const UploadImageButton: FC<Props> = observer(
  ({ imageSrc, onDelete, onClick }) => {
    const state = useLocalObservable<State>(() => ({
      isMouseOver: false,
      setIsMouseOver(isMouseOver) {
        state.isMouseOver = isMouseOver;
      },
    }));
    return (
      <button
        type="button"
        className="relative h-20 w-20 rounded-full"
        onMouseOver={() => state.setIsMouseOver(true)}
        onMouseLeave={() => state.setIsMouseOver(false)}
        onClick={onClick}
      >
        {imageSrc ? (
          <img src={imageSrc} className="w-20 rounded-full" />
        ) : (
          <UploadImageIcon className="w-20" />
        )}
        {state.isMouseOver && imageSrc && (
          <div
            className="absolute right-0 top-0 cursor-pointer rounded-full"
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          >
            <DeleteIcon />
          </div>
        )}
      </button>
    );
  },
);
