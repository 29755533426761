import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { ExclamationMarkIcon, UserIcon } from '../../../icons';
import { useStore } from '../../../hooks';
import { useNavigate } from 'react-router-dom';
import { roundingFilters } from '../../../types/RoundingFilter';

type RoundsProps = {
  numWaitingRounds: number;
  numMyRounds: number;
};

export const Rounds = observer(
  ({ numWaitingRounds, numMyRounds }: RoundsProps) => {
    const navigate = useNavigate();

    const {
      userStore: { me },
    } = useStore();

    const state = useLocalObservable(() => ({
      navigateWithFilters(to: string) {
        const url = new URL(to, window.location.origin);
        const filterSearchParams = new URLSearchParams(location.search);
        const filterKeys = Object.keys(roundingFilters);

        // Only use parameters that matches RoundingFilter
        for (const [key, value] of filterSearchParams.entries()) {
          if (filterKeys.includes(key)) {
            url.searchParams.append(key, value);
          }

          // Since we are using IssueFilter we update parameter to work with RoundsFilter
          if (key === 'assigned_to_or_co_worker') {
            url.searchParams.set('assigned_to', value);
          }
        }

        navigate(`${url.pathname}?${url.searchParams.toString()}`);
      },
    }));

    return (
      <div className="w-full rounded bg-white p-7">
        <div className="text-lg font-semibold">Rondering</div>
        <div className="mt-5 flex flex-col flex-wrap justify-between gap-x-7 gap-y-5 2xl:flex-row">
          <button
            className="flex items-center"
            onClick={() =>
              state.navigateWithFilters(`/rounds?assigned_to=${me!.id}`)
            }
          >
            <div className="flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-md bg-rose-300">
              <UserIcon className="w-5 text-white" />
            </div>
            <div className="ml-3 text-left">
              <div className="text-sm font-semibold">Mina ronderingar</div>
              <div className="text-xl font-extrabold leading-tight">
                {numMyRounds}
              </div>
            </div>
          </button>
          <button
            className="flex items-center"
            onClick={() => state.navigateWithFilters('/rounds?status=waiting')}
          >
            <div className="flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-md bg-yellow-400 text-white">
              <ExclamationMarkIcon className="w-6" />
            </div>
            <div className="ml-3 text-left">
              <div className="text-sm font-semibold">
                Ohanterade ronderingar
              </div>
              <div className="text-xl font-extrabold leading-tight">
                {numWaitingRounds}
              </div>
            </div>
          </button>
        </div>
      </div>
    );
  },
);
