import React, { FC, memo, SVGProps } from 'react';

export const UndoIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    className="w-4"
    viewBox="0 0 24 24"
    {...props}
  >
    <g
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3 9h13a5 5 0 0 1 0 10H7M3 9l4-4M3 9l4 4" />
    </g>
    <rect x="0" y="0" width="18" height="18" fill="rgba(0, 0, 0, 0)" />
  </svg>
));
