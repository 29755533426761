import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FormEvent, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  FadeInDiv,
  FormError,
  InfoBox,
  InputWithError,
  Label,
  Select,
} from '../components';
import { SaveButton } from '../components/SaveButton';
import { useStore } from '../hooks';
import { FrequencyType } from '../models/Settings';
import { SubmitState } from '../types';

export interface ParkingRestrictionFormData {
  frequency: number | null;
  frequencyType: FrequencyType | null;
  redirectUrl: string;
}

const FREQUENCY_TYPE_TEXTS = {
  [FrequencyType.Month]: 'Månad',
  [FrequencyType.Week]: 'Vecka',
  [FrequencyType.Year]: 'År',
};

interface ParkingRestrictionState {
  submitState: SubmitState;
  setSubmitState: (submitState: SubmitState) => void;

  handleSubmit: (data: ParkingRestrictionFormData) => void;
  reset: () => void;
  handleDelete: (e: FormEvent<HTMLFormElement>) => void;
}

export const ParkingRestrictionPage = observer(() => {
  const {
    organizationStore: { organization },
  } = useStore();

  const { settings } = organization!;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    setValue,
    watch,
  } = useForm<ParkingRestrictionFormData>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      frequency: settings?.parking?.restriction_frequency,
      frequencyType: settings?.parking
        ?.restriction_frequency_type as FrequencyType,
      redirectUrl: settings?.parking?.restriction_redirect_url,
    },
  });

  const frequency = watch('frequency');
  const frequencyType = watch('frequencyType');

  const state = useLocalObservable<ParkingRestrictionState>(() => ({
    submitState: SubmitState.None,
    setSubmitState(submitState) {
      state.submitState = submitState;
    },

    async handleSubmit(formData) {
      state.setSubmitState(SubmitState.Pending);
      await organization!.setParkingSettings(formData);
      // When the parking settings changes we need to reset the form data
      // so that we can keep track of whether the data is dirty.
      reset(formData);
      state.setSubmitState(SubmitState.Success);
    },
    reset() {
      state.setSubmitState(SubmitState.None);
      setValue('frequency', null);
      setValue('frequencyType', null);
      setValue('redirectUrl', '');
    },
    async handleDelete(e) {
      e.preventDefault();

      state.setSubmitState(SubmitState.Pending);
      await organization!.deleteParkingSettings();
      state.setSubmitState(SubmitState.Success);

      reset({
        frequency: null,
        frequencyType: null,
        redirectUrl: '',
      });
    },
  }));

  const shouldDeleteOnSave = Boolean(
    settings?.parking && !frequencyType && !frequency,
  );

  useEffect(() => {
    // We want to reset the submitState if any data in the form changes
    // so the user can save the new changes.
    if (isDirty && state.submitState !== SubmitState.None)
      state.setSubmitState(SubmitState.None);
  }, [isDirty]);

  return (
    <FadeInDiv className="h-full w-full p-4 md:p-14">
      <div className="rounded-md bg-white p-7 md:inline-block">
        <span className="w-full text-xl font-extrabold text-indigo-900">
          Besöksparkering
        </span>
      </div>
      <form
        onSubmit={
          shouldDeleteOnSave
            ? state.handleDelete
            : handleSubmit(state.handleSubmit)
        }
      >
        <div className="mt-5 w-full rounded-lg bg-white px-4 py-6 text-gray-900 md:mt-10">
          <div className="flex flex-col text-sm">
            <span className="font-semibold">Begränsa besöksparkering</span>
            <span className="font-light">
              Konfigurera frekvens på hur ofta besökare får parkera inom en viss
              tid.
            </span>
          </div>
          <div className="my-2 rounded-lg bg-gray-50 px-4 py-6">
            <Label required>Frekvens</Label>
            <div className="flex items-center gap-2">
              <div className="w-14">
                <InputWithError
                  style={{ textAlign: 'center' }}
                  placeholder="2"
                  type="number"
                  error={errors.frequency?.message}
                  {...register('frequency', {
                    required: 'Fyll i detta fält',
                    min: {
                      value: 1,
                      message: 'Minsta värdet är 1',
                    },
                    valueAsNumber: true,
                  })}
                />
              </div>

              <span className="whitespace-nowrap text-xs font-light">
                gånger per
              </span>
              <Controller
                control={control}
                name="frequencyType"
                rules={{ required: 'Fyll i detta fält' }}
                render={({ field: { onChange } }) => {
                  return (
                    <div className="w-28">
                      <Select
                        placeholder="Vecka"
                        items={Object.values(FrequencyType).map((value) => ({
                          name: FREQUENCY_TYPE_TEXTS[value],
                          value: value,
                        }))}
                        onSelect={(selected) => onChange(selected.value)}
                        selected={
                          frequencyType
                            ? {
                                name: FREQUENCY_TYPE_TEXTS[frequencyType],
                                value: frequencyType,
                              }
                            : null
                        }
                      />
                      {errors.frequencyType?.message && (
                        <FormError>{errors.frequencyType?.message}</FormError>
                      )}
                    </div>
                  );
                }}
              />
              {settings?.parking && (
                <button
                  type="button"
                  className="font-light text-red-500 underline underline-offset-2"
                  onClick={state.reset}
                >
                  Ta bort
                </button>
              )}
            </div>
          </div>
          <div className="max-w-xl px-4">
            <div className="mb-2 flex items-center gap-2 font-semibold">
              <span>Beställningssidan</span>
              <InfoBox content="Ange länk till beställningsformulär" />
            </div>
            <InputWithError
              {...register('redirectUrl', {
                validate: (value) => {
                  if (!value) return true;
                  return (
                    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/.test(
                      value,
                    ) || 'Ogiltig url'
                  );
                },
              })}
              placeholder="https://exempel.se"
              error={errors.redirectUrl?.message}
            />
          </div>
        </div>
        <div className="mt-5 md:mt-7">
          <SaveButton
            disabled={[SubmitState.Pending, SubmitState.Success].includes(
              state.submitState,
            )}
            isSaved={state.submitState === SubmitState.Success}
          />
        </div>
      </form>
    </FadeInDiv>
  );
});
