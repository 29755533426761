import React from 'react';
import { observer } from 'mobx-react-lite';
import { CrossIcon, ImageIcon } from '../../../icons';

type AttachmentProps = {
  name: string;
  onClick?: () => void;
  onDelete?: () => void;
};

export const Attachment = observer(
  ({ name, onClick, onDelete }: AttachmentProps) => (
    <div
      className={`inline-flex items-center rounded-md border border-solid border-gray-200 px-3 py-2 text-sm text-gray-900 md:py-3 ${
        onClick &&
        `cursor-pointer hover:border-indigo-600 hover:bg-indigo-600 hover:text-white`
      }`}
      onClick={onClick}
      style={{ maxWidth: '250px' }}
    >
      <ImageIcon className="mr-3 w-4 text-gray-300" />
      <span className="truncate">{name}</span>
      {onDelete && (
        <span
          className="ml-6 h-3 w-3 cursor-pointer text-gray-500 hover:text-pink-500"
          onClick={onDelete}
        >
          <CrossIcon className="w-3" />
        </span>
      )}
    </div>
  ),
);
