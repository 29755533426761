import React, { FC, memo, SVGProps } from 'react';

export const DustingOffHandsIcon: FC<SVGProps<SVGSVGElement>> = memo(
  (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 43 44"
      fill="none"
      className="w-4"
      {...props}
    >
      <path
        fill="currentColor"
        d="M7.332 26.99l-.68-.477a3.144 3.144 0 01.254-5.294 3.146 3.146 0 013.458-4.934A3.14 3.14 0 0113 13.715c.516-.127 4.908-1.426 7.914-2.317l-2.54-1.779a3.145 3.145 0 113.576-5.176c9.389 6.58 7.25 5.041 9.162 6.517l2.308-.406a1.3 1.3 0 011.5 1.051c1.184 6.674.775 4.413 2.239 12.735a1.3 1.3 0 01-1.052 1.5l-1.283.226a3.064 3.064 0 00-1.952 1.217 5.014 5.014 0 01-.664.741l.825.578a1.3 1.3 0 01.322 1.806c-4.084 5.843-1.44 2.054-7.421 10.588a1.3 1.3 0 01-1.81.319l-1.066-.746a3.066 3.066 0 00-2.24-.528 5 5 0 01-3.655-.851l-9.946-6.971a3.15 3.15 0 01.115-5.23zm27.15-2.844l.646-.113c-1.3-7.42-1.067-6.097-2.013-11.458l-1.95.344a1.3 1.3 0 01-1.016-.25c-1.987-1.534-.358-.36-9.317-6.639a1.208 1.208 0 00-1.659.277 1.237 1.237 0 00.316 1.719l4.204 2.946a.973.973 0 01-.36 1.746c-.38.1-4.029 1.182-7.224 2.119l7.431 5.209-.483-2.75a3.145 3.145 0 116.189-1.129c1.942 11.047 1.598 8.97 1.769 10.298a5.471 5.471 0 013.47-2.318h-.002zM18.28 37.6c.65.459 1.454.646 2.24.523a5 5 0 013.655.852l.536.376c4.568-6.518 3.393-4.828 6.677-9.527l-1.624-1.138a1.3 1.3 0 01-.54-.894c-.315-2.447.015-.427-1.895-11.283a1.193 1.193 0 00-2.142-.495 1.269 1.269 0 00-.22.954l.89 5.057a.972.972 0 01-1.515.963l-10.195-7.145a1.2 1.2 0 10-1.377 1.966l6.98 4.891a.972.972 0 01-1.116 1.592l-8.57-6.007a1.202 1.202 0 10-1.381 1.97c9.244 6.478 8.645 6.033 8.774 6.198a.97.97 0 01-1.317 1.397l-6.98-4.892a1.2 1.2 0 00-1.377 1.966l6.98 4.892a.971.971 0 11-1.116 1.591l-3.931-2.755a1.202 1.202 0 10-1.38 1.97l9.948 6.972-.004.006z"
      ></path>
      <path
        fill="currentColor"
        d="M11.414 12.357a.972.972 0 01-1.352.238l-2.356-1.652a.972.972 0 111.113-1.588l2.359 1.653a.972.972 0 01.236 1.349zm3.24-3.079a.972.972 0 01-1.914.337l-.499-2.837a.971.971 0 011.914-.337l.499 2.837z"
      ></path>
    </svg>
  ),
);
