import { useLayoutEffect } from 'react';
import { useStore } from '../hooks';

/**
 * Convenience component for logging out the user on a matching route.
 */
export const LogOut = () => {
  const rootStore = useStore();

  useLayoutEffect(() => {
    rootStore.reset();
  }, []);

  return null;
};
