import debounce from 'lodash.debounce';
import { applySnapshot, flow, types as t } from 'mobx-state-tree';
import {
  MIN_SEARCH_QUERY_LENGTH,
  SEARCH_ISSUES_PER_PAGE,
  REQUEST_DEBOUNCE_WAIT,
} from '../constants';
import { withRequest } from '../extensions';
import { SearchIssue, SEARCH_ISSUE_FIELDS } from './SearchIssue';

const SearchIssueArray = t.array(SearchIssue);

export const SearchModalStore = t
  .model('SearchModalStore', {
    query: '',
    issues: t.maybeNull(SearchIssueArray),
    issuesCount: 0,
  })
  .views((self) => ({
    get lowerCasedQuery() {
      return self.query.toLowerCase();
    },
    get hasMoreIssues() {
      return self.issues ? self.issues.length < self.issuesCount : false;
    },
  }))
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;

    return {
      fetchIssues: flow(function* (query: string) {
        const res = yield request({
          method: 'GET',
          url: '/api/issues/',
          params: {
            search: query,
            limit: SEARCH_ISSUES_PER_PAGE,
            fields: SEARCH_ISSUE_FIELDS,
          },
        });

        // If the query used for this request doesn't match the current query
        // in the store this is an outdated request we can just ignore.
        if (query !== self.query) return;

        if (self.issues === null) {
          self.issues = SearchIssueArray.create();
        }

        self.issues.replace(res.data.results);
        self.issuesCount = res.data.count;
      }),
    };
  })
  .actions((self) => ({
    debouncedFetchIssues: debounce(self.fetchIssues, REQUEST_DEBOUNCE_WAIT),
  }))
  .actions((self) => ({
    setQuery(query: string) {
      self.query = query;

      if (query.length < MIN_SEARCH_QUERY_LENGTH) {
        self.issues = null;
        self.issuesCount = 0;

        return;
      }

      self.debouncedFetchIssues(query);
    },
    reset() {
      applySnapshot(self, {
        query: '',
        issues: null,
        issuesCount: 0,
      });
    },
  }));
