import React, { FC, memo } from 'react';

const LogoWhite = memo(() => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 543.21 117.93"
      className="fill-current text-white"
    >
      <g>
        <path d="M231.09 33.09a8.12 8.12 0 1116.23 0v50.6a8.12 8.12 0 11-16.23 0zM289.45 90.81c-19.5 0-33.18-14.41-33.18-32.09S270 26.64 289.45 26.64h13.44a7.87 7.87 0 110 15.74h-13.44c-9.93 0-16.95 7.38-16.95 16.34s7 16.35 16.95 16.35h13.44a7.87 7.87 0 010 15.74zM347.56 90.81c-19.49 0-33.17-14.41-33.17-32.09s13.68-32.08 33.17-32.08H361a7.87 7.87 0 110 15.74h-13.44c-9.92 0-16.95 7.38-16.95 16.34s7 16.35 16.95 16.35H361a7.87 7.87 0 010 15.74zM406.65 92.38c-20.1 0-34.15-15-34.15-33.66s14.05-33.66 34.15-33.66 34.14 15 34.14 33.66-14.17 33.66-34.14 33.66zm0-51.58a17.57 17.57 0 00-17.92 17.92 17.92 17.92 0 0035.84 0 17.58 17.58 0 00-17.92-17.92zM449.75 8.11a8.11 8.11 0 0116.22 0V84a8.11 8.11 0 01-16.22 0zM509.07 92.38c-20.1 0-34.14-15-34.14-33.66s14-33.66 34.14-33.66 34.14 15 34.14 33.66-14.16 33.66-34.14 33.66zm0-51.58a17.58 17.58 0 00-17.92 17.92 17.92 17.92 0 0035.84 0 17.57 17.57 0 00-17.92-17.92zM175.88 90.81v19a8.11 8.11 0 11-16.22 0V33.3a6.72 6.72 0 016.66-6.66h22.76c19.37 0 33.06 14.4 33.06 32.08s-13.69 32.09-33.06 32.09zm13.2-15.74c9.81 0 16.83-7.39 16.83-16.35a16.38 16.38 0 00-16.83-16.34h-13.2v32.69z" />
      </g>
      <g>
        <path d="M38.26 50a47.2 47.2 0 00-21.55 29.22 8.44 8.44 0 01-10 6.19h-.09A8.44 8.44 0 01.25 75.12a64.34 64.34 0 0140-44.81V48.9c-.68.36-1.34.74-1.99 1.1z" />
        <path d="M95 34.93v21a43.66 43.66 0 00-3.83-3.21A47.36 47.36 0 0038.26 50c-1.58 1-3.13 2.09-3.41 2.28s-1.22.91-1.32 1A8.79 8.79 0 0122 50.2c-2.06-3.42-1.67-8.44 3-11.69.21-.15 2.91-2 4.55-3A66.06 66.06 0 0152.68 27 64.61 64.61 0 0195 34.93z" />
        <path d="M127 87h-17c-.1-1.27-.25-2.53-.45-3.79a47.41 47.41 0 00-28.5-36.3c-.75-.31-1.3-.54-1.67-.71A8.5 8.5 0 0174.55 35c1.28-3 5.35-6.75 11.35-4.43l1.67.68a64.45 64.45 0 0138.73 49.36A63.46 63.46 0 01127 87zM64.69 13.62a6.19 6.19 0 01-4.43 2.31 80.81 80.81 0 00-8.52 1 83.41 83.41 0 00-30.35 11.69 81.06 81.06 0 00-9.52 7.46l-.79.68a6.18 6.18 0 01-6.94.78L4 37.47a6.19 6.19 0 01-1.14-10.16l.74-.64A91.5 91.5 0 0114.73 18 96 96 0 0149.61 4.58a94.81 94.81 0 019.83-1.16A6.19 6.19 0 0166 8.62v.16a6.17 6.17 0 01-1.31 4.84z" />
        <rect
          x={75.26}
          y={4.34}
          width={14.22}
          height={14.22}
          rx={6.19}
          transform="rotate(-76.06 82.37 11.455)"
        />
        <path d="M125.18 106.14a8.44 8.44 0 01-10.18 6.2L36.16 92.77A8.43 8.43 0 0130 82.55v-.09a8.44 8.44 0 0110.25-6.16L110 93.64a46.43 46.43 0 00-.48-10.39l-.63-4.08c-.31-2.13-1.12-6.1 2.64-9.15a8.47 8.47 0 018.34-1.32c4.52 1.74 5.23 6.12 5.76 8.37.21.89.37 1.72.65 3.54a63.92 63.92 0 01-1.1 25.53z" />
      </g>
    </svg>
  );
});

const LogoBlack = memo(() => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 543.21 117.93"
      className="fill-current text-gray-900"
    >
      <g>
        <path d="M231.09 33.09a8.12 8.12 0 1116.23 0v50.6a8.12 8.12 0 11-16.23 0zM289.45 90.81c-19.5 0-33.18-14.41-33.18-32.09S270 26.64 289.45 26.64h13.44a7.87 7.87 0 110 15.74h-13.44c-9.93 0-16.95 7.38-16.95 16.34s7 16.35 16.95 16.35h13.44a7.87 7.87 0 010 15.74zM347.56 90.81c-19.49 0-33.17-14.41-33.17-32.09s13.68-32.08 33.17-32.08H361a7.87 7.87 0 110 15.74h-13.44c-9.92 0-16.95 7.38-16.95 16.34s7 16.35 16.95 16.35H361a7.87 7.87 0 010 15.74zM406.65 92.38c-20.1 0-34.15-15-34.15-33.66s14.05-33.66 34.15-33.66 34.14 15 34.14 33.66-14.17 33.66-34.14 33.66zm0-51.58a17.57 17.57 0 00-17.92 17.92 17.92 17.92 0 0035.84 0 17.58 17.58 0 00-17.92-17.92zM449.75 8.11a8.11 8.11 0 0116.22 0V84a8.11 8.11 0 01-16.22 0zM509.07 92.38c-20.1 0-34.14-15-34.14-33.66s14-33.66 34.14-33.66 34.14 15 34.14 33.66-14.16 33.66-34.14 33.66zm0-51.58a17.58 17.58 0 00-17.92 17.92 17.92 17.92 0 0035.84 0 17.57 17.57 0 00-17.92-17.92zM175.88 90.81v19a8.11 8.11 0 11-16.22 0V33.3a6.72 6.72 0 016.66-6.66h22.76c19.37 0 33.06 14.4 33.06 32.08s-13.69 32.09-33.06 32.09zm13.2-15.74c9.81 0 16.83-7.39 16.83-16.35a16.38 16.38 0 00-16.83-16.34h-13.2v32.69z" />
      </g>
      <g>
        <path d="M38.26 50a47.2 47.2 0 00-21.55 29.22 8.44 8.44 0 01-10 6.19h-.09A8.44 8.44 0 01.25 75.12a64.34 64.34 0 0140-44.81V48.9c-.68.36-1.34.74-1.99 1.1z" />
        <path d="M95 34.93v21a43.66 43.66 0 00-3.83-3.21A47.36 47.36 0 0038.26 50c-1.58 1-3.13 2.09-3.41 2.28s-1.22.91-1.32 1A8.79 8.79 0 0122 50.2c-2.06-3.42-1.67-8.44 3-11.69.21-.15 2.91-2 4.55-3A66.06 66.06 0 0152.68 27 64.61 64.61 0 0195 34.93z" />
        <path d="M127 87h-17c-.1-1.27-.25-2.53-.45-3.79a47.41 47.41 0 00-28.5-36.3c-.75-.31-1.3-.54-1.67-.71A8.5 8.5 0 0174.55 35c1.28-3 5.35-6.75 11.35-4.43l1.67.68a64.45 64.45 0 0138.73 49.36A63.46 63.46 0 01127 87zM64.69 13.62a6.19 6.19 0 01-4.43 2.31 80.81 80.81 0 00-8.52 1 83.41 83.41 0 00-30.35 11.69 81.06 81.06 0 00-9.52 7.46l-.79.68a6.18 6.18 0 01-6.94.78L4 37.47a6.19 6.19 0 01-1.14-10.16l.74-.64A91.5 91.5 0 0114.73 18 96 96 0 0149.61 4.58a94.81 94.81 0 019.83-1.16A6.19 6.19 0 0166 8.62v.16a6.17 6.17 0 01-1.31 4.84z" />
        <rect
          x={75.26}
          y={4.34}
          width={14.22}
          height={14.22}
          rx={6.19}
          transform="rotate(-76.06 82.37 11.455)"
        />
        <path d="M125.18 106.14a8.44 8.44 0 01-10.18 6.2L36.16 92.77A8.43 8.43 0 0130 82.55v-.09a8.44 8.44 0 0110.25-6.16L110 93.64a46.43 46.43 0 00-.48-10.39l-.63-4.08c-.31-2.13-1.12-6.1 2.64-9.15a8.47 8.47 0 018.34-1.32c4.52 1.74 5.23 6.12 5.76 8.37.21.89.37 1.72.65 3.54a63.92 63.92 0 01-1.1 25.53z" />
      </g>
    </svg>
  );
});

const LogoBlue = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 543.21 117.93">
      <g>
        <path
          d="M231.09 33.09a8.12 8.12 0 1116.23 0v50.6a8.12 8.12 0 11-16.23 0zM289.45 90.81c-19.5 0-33.18-14.41-33.18-32.09S270 26.64 289.45 26.64h13.44a7.87 7.87 0 110 15.74h-13.44c-9.93 0-16.95 7.38-16.95 16.34s7 16.35 16.95 16.35h13.44a7.87 7.87 0 010 15.74zM347.56 90.81c-19.49 0-33.17-14.41-33.17-32.09s13.68-32.08 33.17-32.08H361a7.87 7.87 0 110 15.74h-13.44c-9.92 0-16.95 7.38-16.95 16.34s7 16.35 16.95 16.35H361a7.87 7.87 0 010 15.74zM406.65 92.38c-20.1 0-34.15-15-34.15-33.66s14.05-33.66 34.15-33.66 34.14 15 34.14 33.66-14.17 33.66-34.14 33.66zm0-51.58a17.57 17.57 0 00-17.92 17.92 17.92 17.92 0 0035.84 0 17.58 17.58 0 00-17.92-17.92zM449.75 8.11a8.11 8.11 0 0116.22 0V84a8.11 8.11 0 01-16.22 0zM509.07 92.38c-20.1 0-34.14-15-34.14-33.66s14-33.66 34.14-33.66 34.14 15 34.14 33.66-14.16 33.66-34.14 33.66zm0-51.58a17.58 17.58 0 00-17.92 17.92 17.92 17.92 0 0035.84 0 17.57 17.57 0 00-17.92-17.92zM175.88 90.81v19a8.11 8.11 0 11-16.22 0V33.3a6.72 6.72 0 016.66-6.66h22.76c19.37 0 33.06 14.4 33.06 32.08s-13.69 32.09-33.06 32.09zm13.2-15.74c9.81 0 16.83-7.39 16.83-16.35a16.38 16.38 0 00-16.83-16.34h-13.2v32.69z"
          fill="#302e81"
        />
      </g>
      <g id="prefix__Icon">
        <path
          d="M38.26 50a47.2 47.2 0 00-21.55 29.22 8.44 8.44 0 01-10 6.19h-.09A8.44 8.44 0 01.25 75.12a64.34 64.34 0 0140-44.81V48.9c-.68.36-1.34.74-1.99 1.1z"
          fill="#fea4af"
        />
        <path
          d="M95 34.93v21a43.66 43.66 0 00-3.83-3.21A47.36 47.36 0 0038.26 50c-1.58 1-3.13 2.09-3.41 2.28s-1.22.91-1.32 1A8.79 8.79 0 0122 50.2c-2.06-3.42-1.67-8.44 3-11.69.21-.15 2.91-2 4.55-3A66.06 66.06 0 0152.68 27 64.61 64.61 0 0195 34.93z"
          fill="#ec4899"
        />
        <path
          d="M127 87h-17c-.1-1.27-.25-2.53-.45-3.79a47.41 47.41 0 00-28.5-36.3c-.75-.31-1.3-.54-1.67-.71A8.5 8.5 0 0174.55 35c1.28-3 5.35-6.75 11.35-4.43l1.67.68a64.45 64.45 0 0138.73 49.36A63.46 63.46 0 01127 87z"
          fill="#5046e5"
        />
        <path
          d="M64.69 13.62a6.19 6.19 0 01-4.43 2.31 80.81 80.81 0 00-8.52 1 83.41 83.41 0 00-30.35 11.69 81.06 81.06 0 00-9.52 7.46l-.79.68a6.18 6.18 0 01-6.94.78L4 37.47a6.19 6.19 0 01-1.14-10.16l.74-.64A91.5 91.5 0 0114.73 18 96 96 0 0149.61 4.58a94.81 94.81 0 019.83-1.16A6.19 6.19 0 0166 8.62v.16a6.17 6.17 0 01-1.31 4.84z"
          fill="#fbcd13"
        />
        <rect
          x={75.26}
          y={4.34}
          width={14.22}
          height={14.22}
          rx={6.19}
          transform="rotate(-76.06 82.37 11.455)"
          fill="#fbcd13"
        />
        <path
          d="M125.18 106.14a8.44 8.44 0 01-10.18 6.2L36.16 92.77A8.43 8.43 0 0130 82.55v-.09a8.44 8.44 0 0110.25-6.16L110 93.64a46.43 46.43 0 00-.48-10.39l-.63-4.08c-.31-2.13-1.12-6.1 2.64-9.15a8.47 8.47 0 018.34-1.32c4.52 1.74 5.23 6.12 5.76 8.37.21.89.37 1.72.65 3.54a63.92 63.92 0 01-1.1 25.53z"
          fill="#302e81"
        />
      </g>
    </svg>
  );
});

interface Props {
  color?: 'white' | 'blue' | 'black';
}

const LOGOS = {
  white: LogoWhite,
  blue: LogoBlue,
  black: LogoBlack,
};

export const Logo: FC<Props> = ({ color = 'blue' }) => {
  const Component = LOGOS[color];

  return <Component />;
};
