import React, { FC, memo, SVGProps } from 'react';

export const CheckCircleIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 17"
    fill="none"
    className="w-4"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 14.767a6.4 6.4 0 100-12.8 6.4 6.4 0 000 12.8zm0 1.6a8 8 0 110-16 8 8 0 010 16z"
    ></path>
    <path
      stroke="currentColor"
      strokeWidth="1.8"
      d="M13.696 5.906l-4.64 4.926-2.751-2.75"
    ></path>
  </svg>
));
