import axios from 'axios';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  BrokenLinkScreen,
  FadeInDiv,
  Logo,
  Label,
  InputWithError,
} from '../components';

interface FormData {
  password: string;
  confirmPassword: string;
}

enum LoadingState {
  Pending = 'pending',
  Error = 'error',
  Success = 'success',
}

interface State {
  token: string;
  setToken: (token: string) => void;

  initialLoadingState: LoadingState;
  setInitialLoadingState: (state: LoadingState) => void;

  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;

  verifyResetPasswordToken: () => void;

  onSubmit: (password: string) => void;
}

export const CreatePasswordPage = observer(() => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    shouldUnregister: true,
    reValidateMode: 'onSubmit',
  });
  const password = useRef({});
  password.current = watch('password', '');

  const state = useLocalObservable<State>(() => ({
    token: '',
    setToken(token) {
      state.token = token;
    },

    initialLoadingState: LoadingState.Pending,
    setInitialLoadingState(loadingState) {
      state.initialLoadingState = loadingState;
    },

    isSubmitting: false,
    setIsSubmitting(isSubmitting) {
      state.isSubmitting = isSubmitting;
    },

    async verifyResetPasswordToken() {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');

      if (token === null) {
        state.setInitialLoadingState(LoadingState.Error);
        return;
      }

      try {
        await axios('/api/reset_password_confirm/', {
          params: {
            token,
          },
        });

        state.setToken(token);
        state.setInitialLoadingState(LoadingState.Success);
      } catch (error) {
        state.setInitialLoadingState(LoadingState.Error);
      }
    },

    async onSubmit(password) {
      const { isSubmitting, token } = state;
      if (isSubmitting) return;

      try {
        state.setIsSubmitting(true);

        await axios.post('/api/reset_password_confirm/', {
          token,
          password,
        });

        navigate('/', { replace: true });
      } catch (error) {
        console.error(error);
      } finally {
        state.setIsSubmitting(false);
      }
    },
  }));

  useEffect(() => {
    state.verifyResetPasswordToken();
  }, []);

  if (state.initialLoadingState === LoadingState.Pending) {
    return null;
  }

  if (state.initialLoadingState === LoadingState.Error) {
    return <BrokenLinkScreen />;
  }

  return (
    <FadeInDiv>
      <div className="flex h-screen w-full items-center justify-center">
        <div className="mx-4 w-full max-w-screen-md rounded-md bg-white p-14 shadow-xl">
          <div className="flex justify-center">
            <div className="mb-14 w-36">
              <Logo />
            </div>
          </div>
          <div className="mb-5 whitespace-nowrap text-xl font-extrabold text-indigo-900">
            Skapa nytt lösenord
          </div>

          <form
            onSubmit={handleSubmit(({ password }) => state.onSubmit(password))}
          >
            <div className="flex w-full flex-col space-y-6 sm:flex-row sm:space-y-0">
              <div className="mr-5 w-full">
                <Label required>Nytt lösenord</Label>
                <InputWithError
                  type="password"
                  placeholder="**********"
                  {...register('password', {
                    required: 'Fyll i detta fältet',
                    minLength: {
                      value: 6,
                      message: 'Lösenordet måste minst vara 6 tecken',
                    },
                  })}
                  error={errors.password?.message}
                />
              </div>
              <div className="mr-5 w-full">
                <Label required>Repetera lösenord</Label>
                <InputWithError
                  type="password"
                  placeholder="**********"
                  {...register('confirmPassword', {
                    validate: (value) =>
                      value === password.current || 'Lösenorden matchar inte',
                  })}
                  error={
                    errors.confirmPassword && !errors.password
                      ? errors.confirmPassword.message
                      : ''
                  }
                />
              </div>
            </div>
            <div className="mt-7">
              <button className="rounded-md bg-indigo-900 p-3">
                <span className="font-semibold text-white">Skapa Lösenord</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </FadeInDiv>
  );
});
