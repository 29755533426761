import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks';
import {
  LoginPage,
  LoggedInPage,
  CreatePasswordPage,
  ForgotPasswordPage,
} from '../../pages';
import { Routes, Route } from 'react-router-dom';

export default observer(function App() {
  const { authStore } = useStore();

  return (
    <div className="h-screen w-screen bg-neutral-100">
      {!authStore.isLoggedIn ? (
        <Routes>
          <Route path="/create-password" element={<CreatePasswordPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="*" element={<LoginPage />} />
        </Routes>
      ) : (
        <LoggedInPage />
      )}
    </div>
  );
});
