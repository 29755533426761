import React, { FC } from 'react';
import { Popup } from '.';

interface DeletePopupState {
  onDismiss: () => void;
  onConfirm: () => void;
  title: string;
}

export const DeletePopup: FC<DeletePopupState> = ({
  onDismiss,
  onConfirm,
  title,
  children,
}) => {
  return (
    <Popup isOpen onDismiss={onDismiss}>
      <div>
        <span className="mb-2 block text-xl font-semibold text-gray-900">
          {title}
        </span>
        <div className="text-gray-900">{children}</div>
        <div className="flex pt-5">
          <button
            onClick={onConfirm}
            className="rounded-md bg-rose-300 p-3 font-semibold text-white transition duration-300 hover:bg-red-500"
          >
            Ja, Radera
          </button>
          <button
            onClick={onDismiss}
            className="ml-3 rounded-md border border-gray-200 p-3 font-semibold"
          >
            Nej, Avbryt
          </button>
        </div>
      </div>
    </Popup>
  );
};
