export enum RoundingType {
  T1 = 'T1 - Utemiljö',
  T11 = 'T1.1 - Vegetationsytor',
  T111 = 'T1.11 - Gräsytor',
  T112 = 'T1.12 - Träd, buskar, häckar och rabatter',
  T12 = 'T1.2 - Markbeläggningar',
  T121 = 'T1.21 - Ytor av sten, tegelm betongplattor och asfalt',
  T122 = 'T1.22 - Grusade ytor',
  T123 = 'T1.23 - Ytor med träbeläggning',
  T13 = 'T1.3 - Fast utrustning på tomtmark',
  T131 = 'T1.31 - Inhägnader och inpasseringsanordningar',
  T138 = 'T1.38 - Diverse fast utrustning på tomtmark',
  T1381 = 'T1.381 - Stolpar',
  T1383 = 'T1.383 - Papperskorgar och askkoppar på tomatmark och fasader',
  T18 = 'T1.8 - Diverse på tomtmark',
  T181 = 'T1.81 - Utrymmen för avfallshantering (miljöstationer)',
  T183 = 'T1.83 - Uteplatser',

  T2 = 'T2 - Byggnad utvändigt',
  T21 = 'T2.1 - Yttertak, skärmtak',
  T211 = 'T2.11 - Takbeläggningar och tätskikt',
  T212 = 'T2.12 -Takavvattning',
  T214 = 'T2.14 - Skorstenar, ventilationshuvar',
  T215 = 'T2.15 - Takfönster, takluckor, lanternniner, kupoler',
  T216 = 'T2.16 - Brandgasventilationer',
  T22 = 'T2.2 - Fasader',
  T221 = 'T2.21 - Fasadytor',
  T222 = 'T2.22 - Balkonger, loftgångar',
  T223 = 'T2.23 - Fönster, fönsterdörrar',
  T22311 = 'T2.2311 - Fönster och fönsterdörrar till gemensamma utrymmen och driftutrymmen',
  T224 = 'T2.24 - Entréer, portar',
  T2241 = 'T2.241 - Entrépartier, dörrar',
  T2243 = 'T2.243 - Manuella portar',
  T2244 = 'T2.244 - Maskindrivna portar',
  T2245 = 'T2.245 - Entréplan och -trappor, ramper',
  T2246 = 'T2.246 - Källartrappor',
  T2247 = 'T2.247 - Lastbryggor',

  T3 = 'T3 - Byggnad invändigt',
  T31 = 'T3.1 - Driftutrymmen',
  T311 = 'T3.11 - Utrymmen för fastighetsförvaltningen',
  T312 = 'T3.12 - Driftrum för installationssystem',
  T313 = 'T3.13 - Avfallsrum',
  T319 = 'T3.19 - Övriga driftutrymmen',
  T32 = 'T3.2 - Gemensamma utrymmen',
  T321 = 'T3.21 - Kommunikationsutrymmen',
  T3211 = 'T3.211 - Entréer invändigt',
  T3212 = 'T3.212 - Trapphus',
  T3213 = 'T3.213 - Korridorer',
  T3214 = 'T3.214 - Inglasade gårdar, gallerior',
  T323 = 'T3.23 - Fritidsutrymmen',
  T3231 = 'T3.231 - Bastuutrymmen',
  T3232 = 'T3.232 - Aktivitetsutrymmen',
  T324 = 'T3.24 - Förvaringsutrymmen',
  T325 = 'T3.25 - Gemensamma garage och parkeringshus',
  T326 = 'T3.26 - Skyddsrum',
  T329 = 'T3.29 - Övriga allmänna utrymmen',
  T34 = 'T3.4 - Husgrunder',
  T35 = 'T3.5 - Kallvindar',

  T4 = 'T4 - VA-, VVS-, kyl- och rocessmediesystem',
  T412 = 'T4.12 - Spillvattennät',
  T413 = 'T4.13 - Dagvattennät',
  T414 = 'T4.14 - Dränvattennät',
  T415 = 'T4.15 - Grundvattennät',
  T416 = 'T4.16 - Fjärrvärmenät',
  T417 = 'T4.17 - Fjärrkylnät',
  T421 = 'T4.21 - Tappvattensystem i byggnad',
  T43 = 'T4.3 - Avloppsvattensystem i byggnad',
  T44 = 'T4.4 - Brandsläckningssystem',
  T441 = 'T4.41 - Vattensprinklersystem',
  T444 = 'T4.44 - Brandpostsystem i byggnad',
  T4442 = 'T4.442 - Handbrandsläckare',
  T45 = 'T4.5 - Kylsystem i byggnad',
  T451 = 'T4.51 - Kylkompressoraggregat',
  T46 = 'T4.6 - Värmesystem i byggnad',
  T462 = 'T4.62 - Värmeproduktionsinstallationer',
  T4621 = 'T4.621 - Pannor med olja som bränsleslag',
  T4624 = 'T4.624 - Elpannor',
  T4625 = 'T4.625 - Värmepumpar',
  T4626 = 'T4.626 - Apparater i undercentraler för fjärrvärme',
  T4628 = 'T4.628 - Fläktluftvärmare',
  T463 = 'T4.63 - Värmedistributionsinstallationer',
  T4631 = 'T4.631 - Givare för utetemperatur',
  T4632 = 'T4.632 - Givare, mätare och styr och övervakningsutrustningsapparater i värmedistributionssystem',
  T4633 = 'T4.633 - Shuntgrupper',
  T4634 = 'T4.634 - Pumpar',
  T4635 = 'T4.635 - Rörledningar och ventiler',
  T4636 = 'T4.636 - Expansionskärl',
  T4637 = 'T4.637 - Värmare',
  T47 = 'T4.7 - Luftbehandlingssystem',
  T471 = 'T4.71 - Luftbehandlingsinstallationer',
  T4711 = 'T4.711 - Luftbehandlingsaggregat',
  T4712 = 'T4.712 - Luftfilter och filtervakter',
  T4713 = 'T4.713 - Luftvärmare',
  T4714 = 'T4.714 - Luftkylare',
  T4716 = 'T4.716 - Ventilationsvärmeväxlare',
  T472 = 'T4.72 - Luftdistributionsinstallationer',
  T4721 = 'T4.721 - Givare, mätare, styr- och övervakningsenheter i luftdistributionssystem',
  T4722 = 'T4.722 - Fläktar',
  T4723 = 'T4.723 - Ventilationskanaler',
  T4724 = 'T4.724 - Luftspjäll',
  T4725 = 'T4.725 - Spjäll till skydd mot spridning av brand och brandgas',
  T4726 = 'T4.726 - Brandgasfläktar',
  T473 = 'T4.73 - Luftdon, skyddsgaller och skyddsnät',

  T5 = 'T5 - Elsystem',
  T51 = 'T5.1 - Elkraftsystem',
  T511 = 'T5.11 - System för eldistribution',
  T5111 = 'T5.111 - Högspänningssystem för eldistribution',
  T5112 = 'T5.112 - Lågspänningssystem för eldistribution',
  T512 = 'T5.12 - Belysningssystem',
  T513 = 'T5.13 - Elvärmesystem',
  T514 = 'T5.14 - Motordriftsystem',
  T515 = 'T5.15 - Reserv- eller nödkraftsystem',
  T5151 = 'T5.151 - Motordriven reservkraft',
  T5152 = 'T5.152 - Batteridriven reserv eller nödkraft',
  T518 = 'T5.18 - Diverse elkraftsystem',
  T52 = 'T5.2 - System för spänningsutjämning',
  T521 = 'T5.21 - Åskskyddssystem',
  T522 = 'T5.22 - System för potentialutjämnad närmiljö',

  T6 = 'T6 - Tele och datasystem',
  T61 = 'T6.1 - Teletekniska säkerhetssystem',
  T611 = 'T6.11 - System för branddetektering och brandlarm',
  T612 = 'T6.12 - Inbrottslarmsystem',
  T613 = 'T6.13 - System för entré och passerkontroll',
  T618 = 'T6.18 - Diverse teletekniska säkerhetssystem',
  T62 = 'T6.2 - Telesignalssystem',
  T7 = 'T7 - Transportsystem',
  T71 = 'T7.1 - Anläggningar för avfallshantering',
  T72 = 'T7.2 - Hissar ',
  T721 = 'T7.21 - Linhissar',
  T722 = 'T7.22 - Hydraulhissar',
  T723 = 'T7.23 - Kedjehissar',
  T728 = 'T7.28 - Diverse hissar',

  T8 = 'T8 - Styr och övervakningssystem',
}
