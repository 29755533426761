import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import debounce from 'lodash.debounce';
import { useStore } from '../hooks';
import { RecurringParkingFilter } from '../types';
import {
  TableHeaderCell,
  FadeInDiv,
  Pagination,
  Daterangepicker,
} from '../components';
import DownloadFile from 'js-file-download';
import { format } from 'date-fns';
import { RefreshIcon, UploadIcon, SearchIcon } from '../icons';
import { REQUEST_DEBOUNCE_WAIT } from '../constants';
import { useLocation, useNavigate } from 'react-router-dom';

type State = {
  setCurrentPage: (page: number) => void;
  setFilter: (newFilter: RecurringParkingFilter) => void;
  setDateRange: (dates: any) => void;

  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  debouncedSetSearch: (search: string) => void;
  setSearch: (search: string) => void;

  isDownloading: boolean;
  setIsDownloading: (downloading: boolean) => void;
  downloadCSV: () => void;
};

/**
 * A page component rendering all recurring parkings.
 */
export const RecurringParkingPage = observer(() => {
  const {
    recurringParkingStore,
    uiStore,
    authStore: { authRequest },
  } = useStore();
  const location = useLocation();
  const navigate = useNavigate();
  const searchInputRef = useRef<HTMLInputElement>(null);

  const state = useLocalObservable<State>(() => ({
    setCurrentPage(page) {
      const searchParams = new URLSearchParams(
        recurringParkingStore.parkingQuery,
      );

      if (page === 1) {
        searchParams.delete('page');
      } else {
        searchParams.set('page', `${page}`);
      }

      navigate(`${location.pathname}?${searchParams.toString()}`);
    },
    setFilter(newFilter) {
      const { filter, isAscending } = recurringParkingStore;
      const searchParams = new URLSearchParams(
        recurringParkingStore.parkingQuery,
      );
      searchParams.delete('page');

      if (filter === newFilter) {
        if (isAscending) {
          searchParams.delete('sort');
        } else {
          searchParams.set('sort', filter);
        }
      } else {
        searchParams.set('sort', `-${newFilter}`);
      }

      navigate(`${location.pathname}?${searchParams.toString()}`);
    },
    setDateRange(dates: [Date, Date]) {
      uiStore.toggleDatepickerOpen();

      const [start, end] = dates;
      const { parkingSearch } = recurringParkingStore;
      const searchParams = new URLSearchParams(parkingSearch);

      searchParams.set('from', format(start, 'yyyy-MM-dd'));
      searchParams.set('to', format(end, 'yyyy-MM-dd'));

      navigate(`${location.pathname}?${searchParams.toString()}`);
    },

    searchTerm: '',
    setSearchTerm(searchTerm) {
      state.searchTerm = searchTerm;
    },
    setSearch(search) {
      const searchParams = new URLSearchParams(
        recurringParkingStore.parkingQuery,
      );

      if (search) {
        searchParams.delete('page');
        searchParams.set('search', search);
      } else {
        searchParams.delete('search');
      }

      navigate(`${location.pathname}?${searchParams.toString()}`);
    },
    debouncedSetSearch: debounce((search: string) => {
      state.setSearch(search);
    }, REQUEST_DEBOUNCE_WAIT),

    isDownloading: false,
    setIsDownloading(downloading) {
      state.isDownloading = downloading;
    },
    async downloadCSV() {
      const { dateRange, parkingQuery } = recurringParkingStore;
      const [from_dt, to_dt] = dateRange;

      try {
        state.setIsDownloading(true);

        const { data } = await authRequest({
          method: 'GET',
          url: '/api/parkings/recurrent/export/',
          responseType: 'blob',
          params: {
            from_dt: from_dt,
            to_dt: to_dt,
            search: parkingQuery.get('search'),
            ordering: parkingQuery.get('sort'),
          },
        });

        const from = format(from_dt, 'yyyy-MM-dd');
        const to = format(to_dt, 'yyyy-MM-dd');
        const filename = `recurrent-parkings-${from}/${to}.csv`;

        DownloadFile(data, filename);
      } catch (error) {
        console.error(error);
      } finally {
        state.setIsDownloading(false);
      }
    },
  }));

  useLayoutEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    state.setSearchTerm(searchParams.get('search') || '');
    recurringParkingStore.handleRecurringParkingSearchChange(location.search);
  }, [location.search]);

  useEffect(() => {
    // We reset the search in the recurring parking store when the user
    // leaves the page as to not have wrong dates in the datepicker
    // etc. when revisiting the page.
    return () => recurringParkingStore.resetRecurringParkingSearch();
  }, []);

  const { recurringParking, numParkingPages } = recurringParkingStore;

  if (recurringParking === null) {
    return null;
  }

  return (
    <FadeInDiv className="flex h-full flex-col">
      <div className="mx-4 mb-5 flex flex-wrap space-y-5 md:mx-14 md:mb-10 md:mt-14 lg:space-y-5 xl:space-x-10 xl:space-y-0">
        <div className="flex flex-1 md:relative lg:inline-flex lg:flex-none">
          <div
            className="inline-flex w-full cursor-pointer lg:space-x-10"
            onClick={() => uiStore.toggleDatepickerOpen()}
          >
            <div className="flex w-1/2 flex-col rounded-l-md border-r border-indigo-100 bg-white p-7 lg:w-auto lg:rounded-md">
              <span className="text-base font-semibold text-gray-900">
                Från
              </span>
              <span className="mt-1 text-xs font-extrabold text-indigo-900 lg:text-xl">
                {recurringParkingStore.dateRange[0].toLocaleDateString(
                  'sv-SE',
                  {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  },
                )}
              </span>
            </div>
            <div className="flex w-1/2 flex-col rounded-r-md bg-white p-7 lg:w-auto lg:rounded-md">
              <span className="text-base font-semibold text-gray-900">
                Till
              </span>
              <span className="mt-1 text-xs font-extrabold text-indigo-900 lg:text-xl">
                {recurringParkingStore.dateRange[1].toLocaleDateString(
                  'sv-SE',
                  {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  },
                )}
              </span>
            </div>
          </div>
          <Daterangepicker
            dateRange={recurringParkingStore.dateRange}
            onApply={(dates) => state.setDateRange(dates)}
            onCancel={() => uiStore.toggleDatepickerOpen()}
            show={uiStore.isDatepickerOpen}
          />
        </div>
        <div className="relative flex w-full lg:space-x-5 xl:w-auto">
          <div className="flex w-full space-x-5 lg:w-auto lg:justify-between xl:flex-col xl:space-x-0">
            <div
              className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-md bg-white p-3 transition-colors hover:bg-indigo-600 hover:text-white"
              onClick={() =>
                recurringParkingStore.handleRecurringParkingSearchChange(
                  location.search,
                )
              }
            >
              <RefreshIcon className="w-6" />
            </div>
            <div className="z-20 flex flex-1 overflow-hidden rounded-md border border-white bg-white md:focus-within:border-indigo-600 md:focus-within:text-indigo-600 lg:flex-none">
              <div className="flex w-full">
                <span
                  className="flex h-10 w-10 cursor-pointer items-center justify-center p-3 transition-colors hover:bg-indigo-600 hover:text-white"
                  onClick={() => searchInputRef.current?.focus()}
                >
                  <SearchIcon className="w-6" />
                </span>
                <input
                  ref={searchInputRef}
                  type="text"
                  placeholder="Sök"
                  className="absolut h-10 flex-1 p-3.5 pl-1.5 text-black outline-none md:w-0 md:p-0 md:pl-0 md:transition-all md:focus:p-3.5 lg:focus:w-56"
                  onChange={(e) => {
                    state.setSearchTerm(e.target.value);
                    state.debouncedSetSearch(e.target.value);
                  }}
                  value={state.searchTerm}
                />
              </div>
            </div>
          </div>
          <button
            disabled={state.isDownloading}
            className="left-12 hidden h-10 cursor-pointer items-center self-end whitespace-nowrap rounded-md bg-white p-3 transition-colors hover:bg-indigo-600 hover:text-white lg:flex xl:absolute"
            onClick={state.downloadCSV}
          >
            <UploadIcon className="w-4" />
            <span className="ml-3 font-semibold">Exportera lista</span>
          </button>
        </div>
      </div>
      {recurringParking.length !== 0 ? (
        <div className="mx-4 flex-1 md:mx-14">
          <table className="w-full rounded-md bg-white">
            <thead>
              <tr className="text-left">
                <TableHeaderCell
                  className="hidden lg:block"
                  title="Registreringsnummer"
                  isActive={
                    recurringParkingStore.filter ===
                    RecurringParkingFilter.RegistrationNumber
                  }
                  isAscending={recurringParkingStore.isAscending}
                  onClick={() =>
                    state.setFilter(RecurringParkingFilter.RegistrationNumber)
                  }
                />
                <TableHeaderCell
                  className="lg:hidden"
                  title="Reg.nr"
                  isActive={
                    recurringParkingStore.filter ===
                    RecurringParkingFilter.RegistrationNumber
                  }
                  isAscending={recurringParkingStore.isAscending}
                  onClick={() =>
                    state.setFilter(RecurringParkingFilter.RegistrationNumber)
                  }
                />
                <TableHeaderCell
                  title="Tidstämpel"
                  isActive={
                    recurringParkingStore.filter === RecurringParkingFilter.Time
                  }
                  isAscending={recurringParkingStore.isAscending}
                  onClick={() => state.setFilter(RecurringParkingFilter.Time)}
                />

                <TableHeaderCell
                  title="Tillfällen"
                  isActive={
                    recurringParkingStore.filter ===
                    RecurringParkingFilter.Occurences
                  }
                  isAscending={recurringParkingStore.isAscending}
                  onClick={() =>
                    state.setFilter(RecurringParkingFilter.Occurences)
                  }
                />
              </tr>
            </thead>
            <tbody>
              {recurringParking.map((parking, index, arr) => (
                <tr
                  key={index}
                  className={`border-t border-gray-200 ${
                    index % 2 === 0 ? 'bg-gray-50' : ''
                  } ${index !== arr.length - 1 ? 'border-b' : ''}`}
                >
                  <td className="py-5 pl-5 text-xs xs:pl-7 md:text-base">
                    {parking.registration_number}
                  </td>
                  <td className="py-5 pl-5 text-xs xs:pl-7 md:text-base">
                    {parking.latest_parking.getHours()}:
                    {(parking.latest_parking.getMinutes() < 10 ? '0' : '') +
                      parking.latest_parking.getMinutes()}
                  </td>
                  <td className="py-5 pl-5 text-xs xs:pl-7 md:text-base">
                    {parking.occurences}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="mx-4 flex-1 rounded-md bg-white px-7 py-7 text-center md:mx-14 md:py-28">
          <p className="text-2xl font-extrabold text-indigo-900 md:text-3xl">
            Det finns inga parkerade bilar.
          </p>
        </div>
      )}

      <div className="pb-9 pt-5 md:pb-12 md:pt-7">
        {numParkingPages! > 1 && (
          <Pagination
            page={recurringParkingStore.page}
            numPages={numParkingPages!}
            onChange={state.setCurrentPage}
          />
        )}
      </div>
    </FadeInDiv>
  );
});
