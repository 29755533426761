import React from 'react';
import { IssuePriority } from '../types';

interface Props {
  type: IssuePriority;
}

const colors = {
  [IssuePriority.None]: 'bg-gray-200 text-gray-500',
  [IssuePriority.Low]: 'bg-indigo-600',
  [IssuePriority.Medium]: 'bg-rose-300',
  [IssuePriority.High]: 'bg-pink-500',
};

const texts = {
  [IssuePriority.None]: 'Ingen',
  [IssuePriority.Low]: 'Låg',
  [IssuePriority.Medium]: 'Medium',
  [IssuePriority.High]: 'Hög',
};

export const PriorityTag: React.FC<Props> = ({ type }) => {
  const color = colors[type];
  const text = texts[type];

  return (
    <div
      className={`${color} flex items-center justify-center rounded-md px-3 py-1 text-sm font-semibold text-white`}
    >
      {text}
    </div>
  );
};
