export enum IssuesSortFilter {
  None = '',
  Number = 'number',
  IssueType = 'issue_type__name',
  CreatedAt = 'created_at',
  Facility = 'facility__name',
  Status = 'status',
  Company = 'company__name',
  Priority = 'priority',
  AssignedTo = 'assigned_to__name',
}
