import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  FadeInDiv,
  UserSelect,
  InputWithError,
  Label,
} from '../components';
import { useStore } from '../hooks';
import { CheckIcon, UserSelectIcon } from '../icons';
import { UserInstance } from '../models';

interface State {
  isSubmitting: boolean;
  setIsSubmitting: (isSubmiting: boolean) => void;
  onSubmit: (facilityName: string) => void;
  assignments: UserInstance[];
  addAssignment: (user: UserInstance) => void;
  removeAssignment: (user: UserInstance) => void;
}

export const CreateFacilityPage = observer(() => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ facilityName: string }>({
    shouldUnregister: true,
    reValidateMode: 'onSubmit',
  });

  const navigate = useNavigate();
  const {
    organizationStore,
    userStore: { users },
  } = useStore();

  const state = useLocalObservable<State>(() => ({
    isSubmitting: false,
    setIsSubmitting(isSubmiting) {
      state.isSubmitting = isSubmiting;
    },
    async onSubmit(facilityName: string) {
      try {
        this.setIsSubmitting(true);
        await organizationStore.createFacility(facilityName, state.assignments);
        navigate('/organization/facilities');
      } finally {
        state.setIsSubmitting(false);
      }
    },
    assignments: [],
    addAssignment(user) {
      state.assignments.push(user);
    },
    removeAssignment(user) {
      const index = state.assignments.findIndex((a) => a.id === user.id);

      if (index > -1) {
        state.assignments.splice(index, 1);
      }
    },
  }));

  return (
    <FadeInDiv className="p-4 md:p-14">
      <div className="block rounded-md bg-white p-7 md:inline-block">
        <span className="text-xl font-extrabold text-indigo-900">
          Skapa ny fastighet
        </span>
      </div>
      <form
        onSubmit={handleSubmit(({ facilityName }) =>
          state.onSubmit(facilityName),
        )}
      >
        <div className="mt-5 rounded-md bg-white p-7 md:mt-10">
          <div className="max-w-80">
            <div className="mb-5">
              <Label>Automatisk tilldelning</Label>
              <div className="mb-2 flex space-x-1">
                {state.assignments.map((user, index) => (
                  <Avatar
                    key={user.id}
                    user={user}
                    onDelete={() => state.removeAssignment(user)}
                    border={index === 0}
                    tooltip
                  />
                ))}
                {state.assignments.length === 0 && (
                  <UserSelect
                    users={users as UserInstance[]}
                    disabledUsers={state.assignments}
                    onSelect={(user) => state.addAssignment(user!)}
                    renderButton={() => <UserSelectIcon className="h-8 w-8" />}
                  />
                )}
              </div>
              <div className="text-xs text-gray-300">
                Ange en användare som automatiskt ska tilldelas ärenden som
                skapas för denna fastigheten
              </div>
            </div>
            <div className="flex flex-col">
              <Label required>Namn</Label>
              <InputWithError
                type="text"
                placeholder="Namn"
                {...register('facilityName', {
                  required: 'Fyll i detta fältet',
                  maxLength: {
                    value: 50,
                    message: 'Max 50 tecken',
                  },
                })}
                error={errors.facilityName?.message}
              />
            </div>
          </div>
        </div>
        <button
          disabled={state.isSubmitting}
          type="submit"
          className="mt-5 flex w-full items-center justify-center rounded-md bg-white p-3 font-semibold text-gray-900 transition-colors hover:bg-indigo-600 hover:text-white md:mt-7 md:w-auto md:justify-start"
        >
          <CheckIcon className="h-5 w-5" />
          <span className="ml-3">Skapa</span>
        </button>
      </form>
    </FadeInDiv>
  );
});
