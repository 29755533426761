import React from 'react';
import { FadeInDiv } from './FadeInDiv';
import { Logo } from './Logo';
import { BrokenLinkIcon } from '../icons/BrokenLinkIcon';

export const BrokenLinkScreen = () => {
  return (
    <FadeInDiv className="mx-auto flex w-full max-w-4xl flex-col items-center px-4 py-20 md:py-14">
      <div className="mb-11 w-44 md:mb-14">
        <Logo />
      </div>
      <div className="w-full rounded-md bg-white px-7 pb-14 pt-7 text-center shadow-md md:px-14 md:pb-14 md:pt-14">
        <BrokenLinkIcon className="mb-7 inline-block w-8 text-indigo-900" />
        <h2 className="mb-7 text-2xl font-extrabold text-indigo-900 md:text-3xl">
          Whops!
        </h2>
        <p>Det ser ut som att denna länken inte längre är aktuell.</p>
      </div>
    </FadeInDiv>
  );
};
