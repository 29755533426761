import { DateObj, useDayzed } from 'dayzed';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import {
  add,
  sub,
  startOfISOWeek,
  startOfMonth,
  startOfDay,
  getDaysInMonth,
  isSameDay,
} from 'date-fns';
import { ChevronDownIcon } from '../icons';

const DAYS = ['mån', 'tis', 'ons', 'tors', 'fre', 'lör', 'sön'];

interface CalenderRangeProps {
  selected: Date[];
  onDateSelected: (date: DateObj) => void;
  onPredefinedDateSelected: (start: Date, end: Date) => void;
  showOutsideDays: boolean;
  firstDayOfWeek?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  dateFormatOptions: Intl.DateTimeFormatOptions;
  minDate?: Date;
  maxDate?: Date;
}

const CalenderRange: FC<CalenderRangeProps> = ({
  selected,
  onDateSelected,
  onPredefinedDateSelected,
  showOutsideDays,
  firstDayOfWeek = 1,
  dateFormatOptions,
  minDate,
  maxDate,
}) => {
  const { calendars, getBackProps, getForwardProps, getDateProps } = useDayzed({
    selected,
    onDateSelected,
    showOutsideDays,
    firstDayOfWeek,
    monthsToDisplay: 2,
    minDate,
    maxDate,
  });

  const {
    year: prevMonthYear,
    month: prevMonth,
    weeks: prevMonthWeeks,
  } = calendars[0];
  const { year, month, weeks } = calendars[1];

  const selectDates = (start: Date, end: Date) => {
    onPredefinedDateSelected(start, end);
  };

  const currentDay = new Date(new Date().setHours(0, 0, 0, 0));

  return (
    <>
      <div className="mb-7 hidden flex-wrap lg:flex">
        <button
          type="button"
          className="mb-2.5 mr-2.5 rounded-md border border-gray-100 p-3 font-semibold leading-none text-gray-900 hover:border-indigo-100 hover:bg-indigo-50"
          onClick={() => {
            const startOfWeek = startOfISOWeek(currentDay);
            selectDates(startOfWeek, add(startOfWeek, { days: 6 }));
          }}
        >
          Denna vecka
        </button>
        <button
          type="button"
          className="mb-2.5 mr-2.5 rounded-md border border-gray-100 p-3 font-semibold leading-none text-gray-900 hover:border-indigo-100 hover:bg-indigo-50"
          onClick={() =>
            selectDates(
              startOfDay(currentDay),
              sub(startOfDay(currentDay), { days: 7 }),
            )
          }
        >
          Senaste 7 dagarna
        </button>
        <button
          type="button"
          className="mb-2.5 mr-2.5 rounded-md border border-gray-100 p-3 font-semibold leading-none text-gray-900 hover:border-indigo-100 hover:bg-indigo-50"
          onClick={() => {
            const lastWeek = sub(currentDay, { weeks: 1 });
            const startOfLastWeek = startOfISOWeek(lastWeek);
            selectDates(startOfLastWeek, add(startOfLastWeek, { days: 6 }));
          }}
        >
          Förra veckan
        </button>
        <button
          type="button"
          className="mb-2.5 mr-2.5 rounded-md border border-gray-100 p-3 font-semibold leading-none text-gray-900 hover:border-indigo-100 hover:bg-indigo-50"
          onClick={() => {
            const currentMonth = startOfMonth(currentDay);
            selectDates(
              currentMonth,
              add(currentMonth, { days: getDaysInMonth(currentMonth) - 1 }),
            );
          }}
        >
          Denna månaden
        </button>
        <button
          type="button"
          className="mb-2.5 mr-2.5 rounded-md border border-gray-100 p-3 font-semibold leading-none text-gray-900 hover:border-indigo-100 hover:bg-indigo-50"
          onClick={() => {
            selectDates(currentDay, sub(currentDay, { days: 29 }));
          }}
        >
          Senaste 30 dagarna
        </button>
        <button
          type="button"
          className="mb-2.5 rounded-md border border-gray-100 p-3 font-semibold leading-none text-gray-900 hover:border-indigo-100 hover:bg-indigo-50"
          onClick={() => {
            const lastMonth = startOfMonth(sub(currentDay, { months: 1 }));
            selectDates(
              startOfMonth(lastMonth),
              add(lastMonth, { days: getDaysInMonth(lastMonth) - 1 }),
            );
          }}
        >
          Förra månaden
        </button>
      </div>
      <div className="mb-6 flex justify-between">
        <div className="flex w-full flex-col md:mr-9 md:w-60">
          <div className="mb-5 flex items-center justify-between md:justify-start">
            <button
              type="button"
              {...getBackProps({ calendars })}
              className="rounded-full bg-gray-50 p-2 text-gray-900 hover:bg-indigo-600 hover:text-white focus:outline-none"
            >
              <ChevronDownIcon className="w-6 rotate-90" />
            </button>
            <span className="text-xl font-semibold md:ml-12">
              {new Date(prevMonthYear, prevMonth).toLocaleDateString(
                'sv-SE',
                dateFormatOptions,
              )}
            </span>
            <button
              type="button"
              {...getForwardProps({ calendars })}
              className="rounded-full bg-gray-50 p-2 text-gray-900 hover:bg-indigo-600 hover:text-white focus:outline-none md:hidden"
            >
              <ChevronDownIcon className="w-6 -rotate-90" />
            </button>
          </div>
          <div className="mt-1 grid grid-cols-7 gap-y-2">
            {DAYS.map((day) => (
              <div
                key={day}
                className="flex flex-1 items-center justify-center font-medium text-gray-300"
              >
                {day}
              </div>
            ))}
            {prevMonthWeeks.map((week, weekIndex) =>
              week.map((dateObj, index) => {
                const key = `${prevMonthWeeks}${prevMonthYear}${weekIndex}${index}`;
                if (!dateObj) {
                  return null;
                }
                const { date, selected: isSelected } = dateObj;
                const isInRange =
                  date >= selected[0] &&
                  date <= selected[1] &&
                  !isSameDay(selected[0], selected[1]) &&
                  date.getMonth() === prevMonth;

                return (
                  <div
                    key={key}
                    className={`flex items-center justify-center text-sm font-normal ${
                      isInRange ? 'bg-indigo-100' : ''
                    } ${
                      +date === +selected[0] && isInRange
                        ? 'from-white-1/2 to-indigo-100-1/2 bg-gradient-to-r'
                        : +date === +selected[1] && isInRange
                        ? 'from-white-1/2 to-indigo-100-1/2 bg-gradient-to-l'
                        : ''
                    }`}
                  >
                    <button
                      type="button"
                      {...getDateProps({
                        dateObj,
                        disabled: date.getMonth() !== prevMonth,
                      })}
                      className={`flex h-8 w-8 items-center justify-center rounded-full focus:border focus:border-indigo-300 focus:outline-none ${
                        isSelected && date.getMonth() === prevMonth
                          ? 'bg-indigo-600 text-white'
                          : date.getMonth() === prevMonth
                          ? 'text-gray-900'
                          : 'text-gray-300'
                      }`}
                    >
                      {date.getDate()}
                    </button>
                  </div>
                );
              }),
            )}
          </div>
        </div>
        <div className="hidden w-60 flex-col md:flex">
          <div className="mb-5 flex items-center justify-end">
            <span className="mr-12 text-xl font-semibold">
              {new Date(year, month).toLocaleDateString(
                'sv-SE',
                dateFormatOptions,
              )}
            </span>
            <button
              type="button"
              {...getForwardProps({ calendars })}
              className="rounded-full bg-gray-50 p-2 text-gray-900 hover:bg-indigo-600 hover:text-white focus:outline-none"
            >
              <ChevronDownIcon className="w-6 -rotate-90" />
            </button>
          </div>
          <div className="mt-1 grid grid-cols-7 gap-y-2">
            {DAYS.map((day) => (
              <div
                key={day}
                className="flex flex-1 items-center justify-center font-medium text-gray-300"
              >
                {day}
              </div>
            ))}
            {weeks.map((week, weekIndex) =>
              week.map((dateObj, index) => {
                const key = `${prevMonthWeeks}${prevMonthYear}${weekIndex}${index}`;
                if (!dateObj) {
                  return null;
                }
                const { date, selected: isSelected } = dateObj;
                const isInRange =
                  date >= selected[0] &&
                  date <= selected[1] &&
                  +selected[0] !== +selected[1] &&
                  date.getMonth() === month;
                return (
                  <div
                    key={key}
                    className={`flex items-center justify-center text-sm font-normal ${
                      isInRange ? 'bg-indigo-100' : ''
                    } ${
                      +date === +selected[0] && date.getMonth() === month
                        ? 'from-white-1/2 to-indigo-100-1/2 bg-gradient-to-r'
                        : +date === +selected[1] && date.getMonth() === month
                        ? 'from-white-1/2 to-indigo-100-1/2 bg-gradient-to-l'
                        : ''
                    }`}
                  >
                    <button
                      type="button"
                      {...getDateProps({
                        dateObj,
                        disabled: date.getMonth() !== month,
                      })}
                      className={`flex h-8 w-8 items-center justify-center rounded-full focus:border focus:border-indigo-300 focus:outline-none ${
                        isSelected && date.getMonth() === month
                          ? 'bg-indigo-600 text-white'
                          : date.getMonth() === month
                          ? 'text-gray-900'
                          : 'text-gray-300'
                      }`}
                    >
                      {date.getDate()}
                    </button>
                  </div>
                );
              }),
            )}
          </div>
        </div>
      </div>
    </>
  );
};

interface DaterangepickerState {
  selectedDates: [Date, Date];
  dateIndex: 0 | 1;
  setDateIndex: (index: 0 | 1) => void;
  firstDate: Date;
  setFirstDate: (date: Date) => void;
  secondDate: Date;
  setSecondDate: (date: Date) => void;
  handleOnDateSelect: (dateObj: DateObj) => void;
  handlePredefinedDateSelect: (start: Date, end: Date) => void;
  shouldRender: boolean;
  setShouldRender: (shouldRender: boolean) => void;
  dateFormatOptions?: Intl.DateTimeFormatOptions;
}

interface DaterangepickerProps {
  dateRange: [Date, Date];
  onApply: (dates: [Date, Date]) => void;
  onCancel: () => void;
  show: boolean;
  className?: string;
  dateFormatOptions?: Intl.DateTimeFormatOptions;
  minDate?: Date;
  maxDate?: Date;
}

export const Daterangepicker: FC<DaterangepickerProps> = observer(
  ({
    dateRange,
    onApply,
    onCancel,
    show,
    className,
    dateFormatOptions,
    minDate,
    maxDate,
  }) => {
    const state = useLocalObservable<DaterangepickerState>(() => ({
      firstDate: dateRange[0],
      setFirstDate(date) {
        state.firstDate = date;
      },
      secondDate: dateRange[1],
      setSecondDate(date) {
        state.secondDate = date;
      },
      dateIndex: 1,
      setDateIndex(index) {
        state.dateIndex = index;
      },
      get selectedDates() {
        let dates: [Date, Date];
        if (state.firstDate < state.secondDate) {
          dates = [state.firstDate, state.secondDate];
        } else {
          dates = [state.secondDate, state.firstDate];
        }
        return dates;
      },
      handleOnDateSelect(dateObj) {
        if (state.dateIndex === 0) {
          state.firstDate = dateObj.date;
          state.secondDate = dateObj.date;
          state.setDateIndex(1);
        } else {
          state.secondDate = dateObj.date;
          state.setDateIndex(0);
        }
      },
      handlePredefinedDateSelect(start, end) {
        state.firstDate = start;
        state.secondDate = end;
        state.setDateIndex(0);
      },
      shouldRender: show,
      setShouldRender(shouldRender: boolean) {
        state.shouldRender = shouldRender;
      },
    }));

    useEffect(() => {
      if (show) state.setShouldRender(true);
    }, [show]);

    // We wait for animation to end
    // before we stop rendering component.
    const onAnimationEnd = () => {
      if (!show) state.setShouldRender(false);
    };

    if (!state.shouldRender) return null;

    return (
      <div
        className={`fixed inset-0 z-50 overflow-hidden rounded-t-md md:static ${className} ${
          show ? 'md:animate-fadeIn' : 'md:animate-fadeOut'
        }`}
        onAnimationEnd={onAnimationEnd}
      >
        <span
          className={`absolute inset-0 bg-indigo-900 bg-opacity-30 md:hidden ${
            show ? 'animate-fadeIn' : 'animate-fadeOut'
          }`}
        ></span>
        <div
          className={`absolute bottom-0 left-0 right-0 md:bottom-auto md:right-auto md:top-full md:mt-7 ${
            show
              ? 'animate-slideUp md:animate-none'
              : 'animate-slideDown md:animate-none'
          }`}
          onAnimationEnd={onAnimationEnd}
        >
          <div className="rounded-t-md bg-white p-7	shadow-lg md:rounded-md">
            <CalenderRange
              selected={state.selectedDates}
              onDateSelected={state.handleOnDateSelect}
              onPredefinedDateSelected={state.handlePredefinedDateSelect}
              showOutsideDays
              dateFormatOptions={
                dateFormatOptions || { month: 'short', year: 'numeric' }
              }
              minDate={minDate}
              maxDate={maxDate}
            />
            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => onApply(state.selectedDates)}
                className="rounded-md bg-indigo-600 p-3 font-semibold text-white"
              >
                Tillämpa
              </button>
              <button
                type="button"
                onClick={() => onCancel()}
                className="ml-3 rounded-md border border-gray-200 p-3 font-semibold text-gray-900"
              >
                Avbryt
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  },
);
