import { flow, types as t, applySnapshot } from 'mobx-state-tree';
import debounce from 'lodash.debounce';
import { withRequest } from '../extensions';
import {
  ClosedIssuesSortFilter,
  IssuesFilter,
  IssuesSortFilter,
  IssueStatus,
} from '../types';
import { ISSUES_PER_PAGE, REQUEST_DEBOUNCE_WAIT } from '../constants';
import {
  Issue,
  IssueInstance,
  IssueSnapshotIn,
  LIST_ISSUE_FIELDS,
} from './Issue';
import { IssueFilter, IssueFilterInstance } from './IssueFilter';
import fileDownload from 'js-file-download';
import { getDateRangeParams } from '../utils';

const getActiveFilterParams = (filters: IssueFilterInstance[]) => {
  const searchParams = new URLSearchParams();

  filters?.forEach((f) => {
    const value =
      typeof f.value === 'string' ? f.value : f.value?.id.toString();

    if (!value) return;

    if (
      f.name === IssuesFilter.CreatedAt ||
      f.name === IssuesFilter.ResolvedAt
    ) {
      for (const [key, val] of getDateRangeParams(value, f.name).entries()) {
        searchParams.append(key, val);
      }
    } else {
      searchParams.append(f.name, value);
    }
  });

  return searchParams;
};

const IssueReferenceArray = t.array(
  t.safeReference(Issue, { acceptsUndefined: false }),
);

export const IssueStore = t
  .model('IssueStore', {
    issues: t.map(Issue),

    closedIssues: t.maybeNull(IssueReferenceArray),
    numClosedIssues: 0,
    closedIssuesSearch: '',
    closedIssuesActiveFilters: t.maybeNull(t.array(IssueFilter)),

    openIssues: t.maybeNull(IssueReferenceArray),
    numOpenIssues: 0,
    openIssuesSearch: '',
    openIssuesActiveFilters: t.maybeNull(t.array(IssueFilter)),

    dashboardSearch: '',
    dashboardActiveFilters: t.maybeNull(t.array(IssueFilter)),
  })
  .views((self) => ({
    get closedIssuesQuery(): URLSearchParams {
      return new URLSearchParams(self.closedIssuesSearch);
    },
    get openIssuesQuery(): URLSearchParams {
      return new URLSearchParams(self.openIssuesSearch);
    },
    get dashboardQuery(): URLSearchParams {
      return new URLSearchParams(self.dashboardSearch);
    },
  }))
  .views((self) => ({
    get closedIssuesPage() {
      const pageParam = self.closedIssuesQuery.get('page');

      return pageParam ? Number(pageParam) : 1;
    },
    get closedIssuesSortFilter(): ClosedIssuesSortFilter {
      const sortParam = self.closedIssuesQuery.get('sort');

      if (!sortParam) return '' as ClosedIssuesSortFilter;

      return sortParam.replace('-', '') as ClosedIssuesSortFilter;
    },
    get closedIssuesIsAscending() {
      const sortParam = self.closedIssuesQuery.get('sort');

      return sortParam !== null && sortParam[0] !== '-';
    },
    get numClosedIssuesPages() {
      return Math.ceil(self.numClosedIssues / ISSUES_PER_PAGE);
    },
    get closedIssuesActiveFilterParams() {
      return getActiveFilterParams(
        self.closedIssuesActiveFilters as IssueFilterInstance[],
      );
    },
  }))
  .views((self) => ({
    get openIssuesPage() {
      const pageParam = self.openIssuesQuery.get('page');

      return pageParam ? Number(pageParam) : 1;
    },
    get openIssuesSortFilter(): IssuesSortFilter {
      const sortParam = self.openIssuesQuery.get('sort');

      if (!sortParam) return '' as IssuesSortFilter;

      return sortParam.replace('-', '') as IssuesSortFilter;
    },
    get openIssuesIsAscending() {
      const sortParam = self.openIssuesQuery.get('sort');

      return sortParam !== null && sortParam[0] !== '-';
    },
    get numOpenIssuesPages() {
      return Math.ceil(self.numOpenIssues / ISSUES_PER_PAGE);
    },
    get openIssuesActiveFilterParams() {
      return getActiveFilterParams(
        self.openIssuesActiveFilters as IssueFilterInstance[],
      );
    },
    get dashboardActiveFilterParams() {
      return getActiveFilterParams(
        self.dashboardActiveFilters as IssueFilterInstance[],
      );
    },
  }))
  .extend(withRequest)
  .actions((self) => ({
    mergeIssues(issues: IssueSnapshotIn[]) {
      const issuesToMerge: { [key: string]: IssueSnapshotIn } = {};

      issues.forEach((issue: IssueSnapshotIn) => {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const existingIssue = self.issues.get(issue.id.toString());

        if (existingIssue) {
          // Listing issues doesn't include notes, feedback, files and time_reports since they are
          // too expensive to include in the listing, so if the issue is already loaded we update
          // it rather than overwrite it so that we don't throw away any potentially loaded data.
          const { notes, feedbacks, files, time_reports, ...partialSnapshot } =
            issue;

          existingIssue.merge(partialSnapshot);
        } else {
          issuesToMerge[issue.id] = issue;
        }
      });

      self.issues.merge(issuesToMerge);
    },
    deleteUnusedIssues() {
      self.issues.forEach((issue) => {
        if (
          !self.closedIssues?.includes(issue) &&
          !self.openIssues?.includes(issue)
        ) {
          self.issues.delete(issue.id.toString());
        }
      });
    },
  }))
  .actions((self) => {
    const { request } = self;

    let latestClosedPromise: null | Promise<any>;
    let latestOpenPromise: null | Promise<any>;
    let latestMyPromise: null | Promise<any>;

    return {
      fetchClosedIssues: flow(function* () {
        const params = new URLSearchParams();
        params.append('status', IssueStatus.Done);
        params.append('limit', ISSUES_PER_PAGE.toString());
        params.append(
          'offset',
          ((self.closedIssuesPage - 1) * ISSUES_PER_PAGE).toString(),
        );
        params.append('fields', LIST_ISSUE_FIELDS);
        params.append(
          'ordering',
          self.closedIssuesQuery.get('sort') || '-resolved_at',
        );

        for (const [
          key,
          value,
        ] of self.closedIssuesActiveFilterParams.entries()) {
          params.append(key, value);
        }

        const promise = request({
          method: 'GET',
          url: '/api/issues/',
          params,
        });

        latestClosedPromise = promise;

        const {
          data: { results, count },
        } = yield promise;
        // If another closed issues request has been fired before this one
        // resolves we throw away this result since it's not relevant anymore.
        if (promise !== latestClosedPromise) return;

        const issueIds = results.map((issue: IssueSnapshotIn) => issue.id);

        self.mergeIssues(results);
        self.closedIssues = issueIds;
        self.numClosedIssues = count;
        self.deleteUnusedIssues();
      }),
      fetchOpenIssues: flow(function* () {
        // We need to use URLSearchParams in order to structure the status
        // query parameters like ?status=waiting&status=in_progress
        const params = new URLSearchParams();
        params.append('status', IssueStatus.Waiting);
        params.append('status', IssueStatus.InProgress);
        params.append('limit', ISSUES_PER_PAGE.toString());
        params.append('fields', LIST_ISSUE_FIELDS);
        params.append(
          'offset',
          ((self.openIssuesPage - 1) * ISSUES_PER_PAGE).toString(),
        );

        params.append(
          'ordering',
          self.openIssuesQuery.get('sort') || '-created_at',
        );

        for (const [
          key,
          value,
        ] of self.openIssuesActiveFilterParams.entries()) {
          // If the user filters the issue by status, we have to remove the current
          // statuses from the params before we append a new one.
          if (key === 'status') params.delete('status');
          params.append(key, value);
        }

        const promise = request({
          method: 'GET',
          url: '/api/issues/',
          params,
        });

        latestOpenPromise = promise;

        const {
          data: { results, count },
        } = yield promise;
        // If another open issues request has been fired before this one
        // resolves we throw away this result since it's not relevant anymore.
        if (promise !== latestOpenPromise) return;

        const issueIds = results.map((issue: IssueSnapshotIn) => issue.id);

        self.mergeIssues(results);
        self.openIssues = issueIds;
        self.numOpenIssues = count;
        self.deleteUnusedIssues();
      }),
      fetchIssue: flow(function* (issueId: string) {
        const { data: issue } = yield request({
          method: 'GET',
          url: `/api/issues/${issueId}/`,
        });

        const existingIssue = self.issues.get(issue.id.toString());

        if (existingIssue) {
          existingIssue.merge(issue);
        } else {
          self.issues.set(issue.id.toString(), issue);
        }
      }),
      createIssue: flow(function* (issue: any) {
        const { files, extra_data, ...rest } = issue;
        const data = new FormData();

        Object.keys(rest).forEach((key) => data.append(key, issue[key]));

        if (files) {
          files.forEach((file: any) => data.append('files', file));
        }
        if (extra_data) {
          data.append('extra_data', JSON.stringify(extra_data));
        }

        const { data: newIssue } = yield request({
          method: 'POST',
          url: '/api/issues/',
          data,
        });
        self.issues.put(newIssue);

        return newIssue as IssueInstance;
      }),
      deleteIssue: flow(function* (issueId: number) {
        yield request({
          method: 'DELETE',
          url: `/api/issues/${issueId}/`,
        });

        self.issues.delete(issueId.toString());
      }),
      reset() {
        applySnapshot(self, {
          issues: {},

          closedIssues: null,
          numClosedIssues: 0,
          closedIssuesSearch: '',
          closedIssuesActiveFilters: null,

          openIssues: null,
          numOpenIssues: 0,
          openIssuesSearch: '',
          openIssuesActiveFilters: null,

          dashboardSearch: '',
          dashboardActiveFilters: null,
        });
      },
    };
  })
  .actions((self) => {
    const { request } = self;

    return {
      downloadOpenIssuesCSV: flow(function* () {
        const params = new URLSearchParams();
        params.append('status', IssueStatus.Waiting);
        params.append('status', IssueStatus.InProgress);
        params.append(
          'ordering',
          self.openIssuesQuery.get('sort') || '-resolved_at',
        );

        for (const [
          key,
          value,
        ] of self.openIssuesActiveFilterParams.entries()) {
          params.append(key, value);
        }

        try {
          const { data } = yield request({
            url: '/api/issues/export/',
            method: 'GET',
            responseType: 'blob',
            params,
          });
          const date = new Date().toLocaleDateString('sv-SE');
          const time = new Date().toLocaleTimeString('sv-SE');
          fileDownload(data, `openIssues-${date}_${time}.csv`);
        } catch (error) {
          // No need to handle this error since the general error
          // message will be shown to the user and they can try again.
        }
      }),
      downloadClosedIssuesCSV: flow(function* () {
        const params = new URLSearchParams();
        params.append('status', IssueStatus.Done);
        params.append(
          'ordering',
          self.closedIssuesQuery.get('sort') || '-resolved_at',
        );

        for (const [
          key,
          value,
        ] of self.closedIssuesActiveFilterParams.entries()) {
          params.append(key, value);
        }

        try {
          const { data } = yield request({
            url: '/api/issues/export/',
            method: 'GET',
            responseType: 'blob',
            params,
          });
          const date = new Date().toLocaleDateString('sv-SE');
          const time = new Date().toLocaleTimeString('sv-SE');
          fileDownload(data, `closedIssues-${date}_${time}.csv`);
        } catch (error) {
          // No need to handle this error since the general error
          // message will be shown to the user and they can try again.
        }
      }),
    };
  })
  .actions((self) => {
    // If we would derive the filters from the current state each IssueFilter
    // instance would become a completely independent mst tree which causes
    // all the references to be null, to prevent this from happening we need
    // to explicitly set the filters in the store.
    return {
      setOpenIssuesActiveFilters() {
        self.openIssuesActiveFilters = [] as any;

        for (const [key, value] of self.openIssuesQuery) {
          if (Object.values(IssuesFilter).includes(key as IssuesFilter)) {
            self.openIssuesActiveFilters!.push({ name: key, value });
          }
        }
      },
      setClosedIssuesActiveFilters() {
        self.closedIssuesActiveFilters = [] as any;

        for (const [key, value] of self.closedIssuesQuery) {
          if (Object.values(IssuesFilter).includes(key as IssuesFilter)) {
            self.closedIssuesActiveFilters!.push({ name: key, value });
          }
        }
      },
      setDashboardActiveFilters() {
        self.dashboardActiveFilters = [] as any;

        for (const [key, value] of self.dashboardQuery) {
          if (Object.values(IssuesFilter).includes(key as IssuesFilter)) {
            self.dashboardActiveFilters!.push({ name: key, value });
          }
        }
      },
    };
  })
  .actions((self) => {
    const debouncedFetchClosedIssues = debounce(
      self.fetchClosedIssues,
      REQUEST_DEBOUNCE_WAIT,
    );
    const debouncedFetchOpenIssues = debounce(
      self.fetchOpenIssues,
      REQUEST_DEBOUNCE_WAIT,
    );

    return {
      // We debounce issues requests that result from the user
      // navigating as to not overwhelm the network when navigating
      // back and forth quickly.
      handleClosedIssuesSearchChange(search: string) {
        self.closedIssuesSearch = search;
        self.setClosedIssuesActiveFilters();
        debouncedFetchClosedIssues();
      },
      handleOpenIssuesSearchChange(search: string) {
        self.openIssuesSearch = search;
        self.setOpenIssuesActiveFilters();
        debouncedFetchOpenIssues();
      },
      handleDashboardSearchChange(search: string) {
        self.dashboardSearch = search;
        self.setDashboardActiveFilters();
      },
    };
  });
