import React, { FC, useMemo } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useStore } from '../hooks';
import { BellIcon, ChevronDownIcon, CrossIcon3 } from '../icons';
import { NotificationInstance } from '../models/Notification';
import { formatDate, timeSince } from '../utils';
import { NotificationType } from '../types/NotificationTypes';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@reach/accordion';
import { AtIcon } from '../icons/AtIcon';
import { useNavigate } from 'react-router-dom';

interface NotificationsListState {
  indices: number[];
  toggleItem: (toggledIndex: number) => void;
  handleOnClick: (notification: NotificationInstance) => void;
}

export const NotificationsList = observer(() => {
  const {
    uiStore,
    notificationStore: {
      oldNotifications,
      todaysNotifications,
      fetchOldNotifications,
      hasMoreOldNotications,
    },
  } = useStore();
  const navigate = useNavigate();

  const state = useLocalObservable<NotificationsListState>(() => ({
    indices: [0, 1],
    toggleItem(toggledIndex: number) {
      const index = state.indices.indexOf(toggledIndex);

      if (index !== -1) {
        state.indices.splice(index, 1);
      } else {
        state.indices.push(toggledIndex);
      }
    },
    handleOnClick(notification) {
      notification.read();
      uiStore.toggleIsNotificationListOpen();

      if (notification.type === NotificationType.Tagged) {
        navigate(`/issues/${notification.issue.id}#note_${notification.note}`);
      } else if (notification.type === NotificationType.Assigned) {
        navigate(`/issues/${notification.issue.id}`);
      }
    },
  }));

  if (!oldNotifications || !todaysNotifications) return null;

  return (
    <div className="scrollbar-none flex h-full w-full flex-col overflow-y-auto bg-white px-4 py-4 shadow-md md:w-[31rem] md:px-14 md:py-12">
      <div className="mb-10 flex items-center justify-end md:justify-between">
        <span className="hidden whitespace-nowrap text-3xl font-extrabold text-indigo-900 md:block">
          Aviseringar
        </span>
        <button onClick={uiStore.toggleIsNotificationListOpen}>
          <CrossIcon3 className="w-7 text-gray-300" />
        </button>
      </div>
      <div className="mb-7 block md:hidden">
        <span className="whitespace-nowrap text-3xl font-extrabold text-indigo-900">
          Aviseringar
        </span>
      </div>
      <Accordion
        index={state.indices}
        onChange={state.toggleItem}
        className="space-y-11"
      >
        <AccordionItem>
          <AccordionButton className="w-full">
            <div className="mb-6 flex w-full items-center justify-between">
              <span className="text-xl font-bold">Idag</span>
              <ChevronDownIcon
                className={`w-6 transform text-gray-400 transition-transform ${
                  state.indices.includes(0) ? 'rotate-180' : ''
                }`}
              />
            </div>
          </AccordionButton>
          <AccordionPanel>
            {todaysNotifications.length > 0 ? (
              <div className="space-y-4">
                {todaysNotifications.map((notification) => (
                  <Notification
                    key={notification.id}
                    notification={notification}
                    isToday
                    onClick={() => state.handleOnClick(notification)}
                  />
                ))}
              </div>
            ) : (
              <span className="text-gray-500">Du har inga nya aviseringar</span>
            )}
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton className="w-full">
            <div className="mb-6 flex w-full items-center justify-between">
              <span className="text-xl font-bold">Äldre</span>
              <ChevronDownIcon
                className={`w-6 transform text-gray-400 transition-transform ${
                  state.indices.includes(1) ? 'rotate-180' : ''
                }`}
              />
            </div>
          </AccordionButton>
          <AccordionPanel>
            {oldNotifications.length > 0 ? (
              <div className="space-y-4">
                {oldNotifications.map((notification) => (
                  <Notification
                    key={notification.id}
                    notification={notification}
                    onClick={() => state.handleOnClick(notification)}
                  />
                ))}
              </div>
            ) : (
              <span className="text-gray-500">
                Du har inga gamla aviseringar
              </span>
            )}
          </AccordionPanel>

          {hasMoreOldNotications && (
            <button
              className="mt-3 flex items-center pl-1"
              onClick={fetchOldNotifications}
            >
              <span className="text-xs">Visa mer</span>
              <ChevronDownIcon className="ml-1 w-6 -rotate-90" />
            </button>
          )}
        </AccordionItem>
      </Accordion>
    </div>
  );
});

interface NotificationProps {
  notification: NotificationInstance;
  onClick: () => void;
  isToday?: boolean;
}

const Notification: FC<NotificationProps> = observer(
  ({ notification, onClick, isToday = false }) => {
    const createdAt = useMemo(() => {
      return isToday
        ? formatDate(notification.created_at).time
        : timeSince(notification.created_at);
    }, [notification.created_at, isToday]);

    return (
      <div
        onClick={onClick}
        className="flex cursor-pointer items-center space-x-4"
      >
        <div className="relative">
          {notification.type === NotificationType.Assigned ? (
            <NotificationBell isRead={notification.read_at !== null} />
          ) : (
            <NotificationAt isRead={notification.read_at !== null} />
          )}
          {notification.read_at === null && (
            <div className="absolute right-0.5 top-0.5 h-3 w-3 rounded-full bg-red-500" />
          )}
        </div>

        <div className="flex w-96 flex-1 flex-col justify-center">
          <div className="space-x-2">
            <span className="leading-4">
              {notification.creator?.name || 'Någon'}
            </span>
            <span className="text-xs text-gray-500">{createdAt}</span>
          </div>
          <span className="text-sm leading-4 text-gray-500">
            {notification.type === NotificationType.Assigned ? (
              <>
                Du har blivit tilldelad ärendet #{notification.issue.number} av{' '}
                <span className="underline">
                  {notification.creator?.name || 'Någon'}
                </span>
              </>
            ) : (
              <>
                <span className="underline">
                  {notification.creator?.name || 'Någon'}
                </span>{' '}
                har nämnt dig i ärendet #{notification.issue.number}
              </>
            )}
          </span>
        </div>
      </div>
    );
  },
);

const NotificationBell = ({ isRead }: { isRead: boolean }) => {
  return (
    <div
      className={`flex h-12 w-12 items-center justify-center rounded-full ${
        isRead ? 'bg-gray-300' : 'bg-yellow-400'
      }`}
    >
      <BellIcon className="w-5 text-white" />
    </div>
  );
};

const NotificationAt = ({ isRead }: { isRead: boolean }) => {
  return (
    <div
      className={`flex h-12 w-12 items-center justify-center rounded-full ${
        isRead ? 'bg-gray-300' : 'bg-pink-500'
      }`}
    >
      <AtIcon className="w-5 text-white" />
    </div>
  );
};
