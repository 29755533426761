// @ts-nocheck
import React, { useLayoutEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { DashboardPeriod } from '../../../types';
import { PeriodController } from './PeriodController';
import { getLabelsAndData } from '../../../utils';
import { observer } from 'mobx-react-lite';

type IssueTypeChartProps = {
  title: string;
  tooltip: string;
  color: string;
  period: DashboardPeriod;
  startDate: Date;
  endDate: Date;
  onPrevious: () => void;
  onNext: () => void;
  onChangePeriod: (period: DashboardPeriod) => void;
  periods: any;
};

export const LineChart = observer(
  ({
    title,
    tooltip,
    color,
    period,
    startDate,
    endDate,
    onPrevious,
    onNext,
    onChangePeriod,
    periods,
  }: IssueTypeChartProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const chartRef = useRef(null);

    useLayoutEffect(() => {
      const ctx = canvasRef.current!.getContext('2d');

      chartRef.current = new Chart(ctx, {
        type: 'line',
        data: {},
        options: {
          responsive: true,
          elements: {
            point: {
              radius: 0,
            },
          },
          plugins: {
            legend: {
              position: 'top',
              align: 'end',
              onClick: null,
              labels: {
                usePointStyle: true,
                boxWidth: 5,
                boxHeight: 5,
                padding: 15,
              },
            },
            tooltip: {
              intersect: false,
              usePointStyle: true,
              boxWidth: 8,
              borderWidth: 1.5,
            },
          },
          scales: {
            y: {
              min: 0,
              suggestedMax: 20,
              grid: {
                drawBorder: false,
                display: false,
              },
              ticks: {
                maxTicksLimit: 3,
              },
            },
            x: {
              ticks: {
                drawBorder: false,
                maxTicksLimit: 12,
              },
            },
          },
        },
      });

      return () => {
        chartRef.current.destroy();
      };
    }, []);

    useLayoutEffect(() => {
      const chart = chartRef.current;

      const [labels, currentPeriod, previousPeriod] = getLabelsAndData(
        period,
        periods,
        startDate,
        endDate,
      );

      chart.data.labels.pop();
      chart.data.datasets.forEach((dataset) => {
        dataset.data.pop();
      });

      chart.data.labels = labels;
      chart.data.datasets = [
        {
          label: 'Vald period',
          data: currentPeriod,
          borderColor: color,
          backgroundColor: 'white',
          fill: false,
          cubicInterpolationMode: 'monotone',
          tension: 0.4,
          borderWidth: 2,
        },
        {
          label: 'Föregående period',
          data: previousPeriod,
          borderColor: '#71717A',
          backgroundColor: 'white',
          fill: false,
          cubicInterpolationMode: 'monotone',
          tension: 0.4,
          borderDash: [15, 10],
          borderWidth: 2,
        },
      ];

      chart.update();
    }, [periods]);

    return (
      <div className="w-full rounded bg-white p-7">
        <PeriodController
          title={title}
          tooltip={tooltip}
          period={period}
          startDate={startDate}
          endDate={endDate}
          onPrevious={onPrevious}
          onNext={onNext}
          onChangePeriod={onChangePeriod}
        />
        <div className="flex-shrink-0 2xl:mr-10">
          <canvas ref={canvasRef} />
        </div>
      </div>
    );
  },
);
