import React, { FC, memo, SVGProps } from 'react';

export const AtIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 22"
    fill="currentColor"
    className="w-4"
    {...props}
  >
    <path
      fill="currentColor"
      d="M19.344 10.48c-.083 1.917-.558 3.44-1.427 4.571-.86 1.123-2.021 1.685-3.481 1.685-.644 0-1.202-.139-1.673-.416a2.641 2.641 0 01-1.056-1.19c-.749 1.048-1.726 1.572-2.931 1.572-1.093 0-1.94-.46-2.538-1.381-.6-.921-.824-2.138-.674-3.65.135-1.236.445-2.332.932-3.29.494-.966 1.116-1.708 1.864-2.224.75-.517 1.558-.775 2.426-.775 1.07 0 1.984.247 2.74.741l.708.483-.573 6.491c-.075.592-.011 1.045.191 1.36.21.313.54.47.988.47.69 0 1.266-.4 1.73-1.2.464-.81.719-1.865.764-3.168.127-2.613-.401-4.62-1.584-6.02-1.175-1.407-2.92-2.11-5.233-2.11-1.445 0-2.733.366-3.864 1.1-1.13.734-2.021 1.778-2.673 3.133-.65 1.348-1.018 2.894-1.1 4.638-.12 2.65.412 4.698 1.595 6.143 1.183 1.453 2.953 2.18 5.312 2.18.621 0 1.272-.072 1.954-.214.681-.135 1.269-.319 1.763-.55l.427 1.729c-.457.3-1.075.539-1.853.719-.771.187-1.55.28-2.336.28-1.977 0-3.661-.4-5.054-1.201-1.393-.794-2.433-1.97-3.122-3.527C.885 15.31.589 13.456.679 11.3c.09-2.066.561-3.92 1.415-5.559.853-1.64 2.014-2.905 3.481-3.796C7.05 1.047 8.712.598 10.562.598c1.879 0 3.496.404 4.851 1.213 1.355.8 2.37 1.95 3.043 3.447.674 1.498.97 3.238.888 5.222zM8.057 11.67c-.082.99.004 1.742.258 2.258.255.51.667.764 1.236.764.367 0 .715-.161 1.044-.483.33-.322.603-.786.82-1.393l.472-5.312a2.823 2.823 0 00-.899-.146c-.86 0-1.53.367-2.01 1.101-.472.734-.779 1.804-.92 3.212z"
    ></path>
  </svg>
));
