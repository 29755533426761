import React from 'react';
import { observer } from 'mobx-react-lite';
import { IssueInstance, UserInstance } from '../../../models';
import { Avatar } from '../../../components';
import { useStore } from '../../../hooks';
import { IssueStatus } from '../../../types';
import { UserSelect } from '../../../components/UserSelect';
import { UserSelectIcon } from '../../../icons';
import { Card } from './Card';

export const AssignedToCard = observer(
  ({ issue }: { issue: IssueInstance }) => {
    const {
      userStore: { users, me },
    } = useStore();

    if (users === null || me === null) return null;

    return (
      <Card title="Tilldelad">
        <div className="flex items-center space-x-2">
          {issue.status !== IssueStatus.Done && (
            <UserSelect
              users={users}
              disabledUsers={issue.co_workers as UserInstance[]}
              assignedUser={issue.assigned_to}
              selected={issue.assigned_to}
              onSelect={
                !issue.assigned_to
                  ? (user) => issue.assignTo(user ? user.id : null)
                  : (user) => issue.addCoWorker(user!.id)
              }
              renderButton={(selected) => (
                <span
                  className={`block truncate font-semibold ${
                    selected ? 'text-indigo-900' : 'text-gray-500'
                  }`}
                >
                  <UserSelectIcon className="w-8" />
                </span>
              )}
            />
          )}
          {issue.assigned_to && (
            <div className="relative">
              <UserSelect
                users={users}
                disabledUsers={issue.co_workers as UserInstance[]}
                assignedUser={issue.assigned_to}
                selected={issue.assigned_to}
                onSelect={(user) => {
                  issue.assignTo(user ? user.id : null);
                }}
                onDelete={() => issue.assignTo(null)}
                renderButton={(selected) => <Avatar user={selected!} border />}
                disabled={issue.status === IssueStatus.Done}
                tooltip
              />
            </div>
          )}
          {issue.co_workers && issue.co_workers!.length > 0 && (
            <div className="flex space-x-2">
              {issue.co_workers.filter(Boolean).map((user) => (
                <Avatar
                  key={user!.id}
                  user={user!}
                  onDelete={
                    issue.status !== IssueStatus.Done
                      ? () => issue.deleteCoWorker(user!.id)
                      : undefined
                  }
                  tooltip
                />
              ))}
            </div>
          )}
        </div>
      </Card>
    );
  },
);
