import React from 'react';
import { Avatar } from '../../../components';
import { useStore } from '../../../hooks';
import { Checkbox } from '../../IssuePage/components/Checkbox';
import { observer } from 'mobx-react-lite';

type Assignment = {
  id: string;
  user: number;
  issue_types: number[];
  rounding_types?: string[];
};

type Props = {
  assignments: Assignment[];
  onChange: (assignment: {
    user: number;
    checked: boolean;
    assignmentType: number | string;
    index: number;
  }) => void;
  assignmentTypes: { name: string; id: number | string }[];
  type: 'category' | 'rounding';
};

const TABLE_NAMES = {
  category: 'Kategori',
  rounding: 'Ronderingstyp',
};

const getAssignmentChoices = (
  assignment: Assignment,
  type: 'category' | 'rounding',
) => {
  return type === 'category'
    ? assignment.issue_types
    : assignment.rounding_types!;
};

export const AssignmentTable = observer(
  ({ assignments, assignmentTypes, onChange, type }: Props) => {
    const {
      userStore: { users },
    } = useStore();

    return (
      <table className="w-full table-fixed">
        <thead>
          <tr>
            <th className="truncate p-4 text-left font-bold uppercase text-pink-500">
              {TABLE_NAMES[type]}
            </th>
            {assignments.map((a) => {
              const user = users?.find((u) => u.id === a.user);

              return (
                <th key={a.id} className="p-4">
                  {user ? (
                    <div className="flex items-center gap-2 text-sm font-semibold">
                      <Avatar user={user} tooltip />
                      <span className="hidden lg:block">{user.name}</span>
                    </div>
                  ) : (
                    ''
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {assignmentTypes.map((assignmentType) => (
            <tr className="odd:bg-gray-50" key={assignmentType.id}>
              <td className="truncate p-4 text-sm font-semibold">
                {assignmentType.name}
              </td>
              {assignments.map((assignment, index) => (
                <td className="p-4" key={assignment.id}>
                  <Checkbox
                    checked={getAssignmentChoices(assignment, type)?.some(
                      (issueTypeId) => issueTypeId === assignmentType.id,
                    )}
                    onChange={(checked) =>
                      onChange({
                        user: assignment.user,
                        checked,
                        assignmentType: assignmentType.id,
                        index,
                      })
                    }
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  },
);
