import React, { FC, memo, SVGProps } from 'react';

export const ArticleSelectIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    className="w-4"
    {...props}
  >
    <circle
      cx="15"
      cy="15"
      r="14.5"
      fill="#fff"
      stroke="#BBB"
      strokeDasharray="4 4"
    />
    <path
      d="M21.246 9.322a.285.285 0 0 0-.022.567c.309.038.55.277.593.585l.604 4.315a.965.965 0 0 1-.273.816l-6.604 6.605a.621.621 0 0 1-.885 0 .285.285 0 1 0-.403.403 1.2 1.2 0 0 0 1.691 0l6.604-6.605c.34-.34.5-.821.434-1.298l-.602-4.315a1.257 1.257 0 0 0-1.09-1.071.285.285 0 0 0-.047-.002Z"
      fill="#BBB"
    />
    <path
      d="M15.227 8.01a1.454 1.454 0 0 0-1.206.416l-6.695 6.696a1.124 1.124 0 0 0 0 1.584l5.172 5.173a1.124 1.124 0 0 0 1.584 0l6.694-6.697a1.43 1.43 0 0 0 .405-1.209l-.618-4.429a1.175 1.175 0 0 0-1.018-1.001l-4.318-.532Zm-.07.567 4.318.532a.6.6 0 0 1 .523.514l.619 4.43a.859.859 0 0 1-.244.726l-6.695 6.697a.545.545 0 0 1-.777 0L7.73 16.302a.545.545 0 0 1 0-.777l6.695-6.696a.883.883 0 0 1 .733-.252Z"
      fill="#BBB"
    />
    <path
      d="M18.43 10.774a1.514 1.514 0 0 0-2.136 0 1.515 1.515 0 0 0 0 2.135 1.513 1.513 0 0 0 2.135 0 1.514 1.514 0 0 0 0-2.135Zm-.404.403c.37.37.369.96 0 1.329a.935.935 0 0 1-1.329 0 .936.936 0 0 1 0-1.329c.37-.37.96-.37 1.329 0ZM10.563 15.64a.285.285 0 0 0 0 .403l1.671 1.671a.285.285 0 0 0 .403-.403l-1.67-1.671a.277.277 0 0 0-.404 0ZM10.102 15.178a.296.296 0 1 1-.42-.42.296.296 0 0 1 .42.42ZM8.807 15.673a.285.285 0 0 0 0 .403l1.67 1.672a.285.285 0 1 0 .404-.404l-1.67-1.67a.273.273 0 0 0-.404 0ZM11.343 18.21a.295.295 0 0 0 0 .418.297.297 0 0 0 .418 0 .293.293 0 0 0 .064-.322.295.295 0 0 0-.482-.096ZM10.912 13.708a.287.287 0 0 0-.21.083.285.285 0 0 0 0 .403l1.67 1.672a.285.285 0 1 0 .404-.403l-1.67-1.672a.286.286 0 0 0-.194-.083ZM13.238 16.328a.296.296 0 1 0 .419.418.296.296 0 0 0-.419-.418Z"
      fill="#BBB"
    />
  </svg>
));
