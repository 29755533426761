import debounce from 'lodash.debounce';
import { types as t, Instance, flow } from 'mobx-state-tree';
import { REQUEST_DEBOUNCE_WAIT, ROUNDS_PER_PAGE } from '../constants';
import { withRequest } from '../extensions';
import { RoundingFilter, roundingFilters } from '../types/RoundingFilter';
import { RoundingTask } from './RoundingTask';
import fileDownload from 'js-file-download';
import { RoundingFilterInstance, RoundingFilterModel } from './RoundingFilter';
import { getDateRangeParams } from '../utils';
import { RoundingStatus } from '../types';

const getActiveFilterParams = (filters: RoundingFilterInstance[]) => {
  const searchParams = new URLSearchParams();

  filters.forEach((f) => {
    const value =
      typeof f.value === 'string' ? f.value : f.value?.id.toString();

    if (!value) return;

    if (
      f.name === RoundingFilter.ApprovedAt ||
      f.name === RoundingFilter.EndsAt
    ) {
      for (const [key, val] of getDateRangeParams(value, f.name)) {
        searchParams.append(key, val);
      }
    }

    searchParams.append(f.name, value);
  });

  return searchParams;
};

const RoundingIssue = t.model('RoundingIssue', {
  roundingTaskId: t.number,
  facilityName: t.string,
  companyName: t.string,
});

export const RoundingStore = t
  .model('RoundingStore', {
    roundingTasks: t.maybeNull(t.array(RoundingTask)),
    roundingTasksSearch: '',
    roundingIssue: t.maybeNull(RoundingIssue),
    roundingActiveFilters: t.array(RoundingFilterModel),
    numRoundingTasks: 0,

    closedRoundingTasks: t.maybeNull(t.array(RoundingTask)),
    closedRoundingTasksSearch: '',
    closedRoundingActiveFilters: t.array(RoundingFilterModel),
    numClosedRoundingTasks: 0,
  })
  .views((self) => {
    return {
      get roundingTasksQuery() {
        return new URLSearchParams(self.roundingTasksSearch);
      },
      get closedRoundingTasksQuery() {
        return new URLSearchParams(self.closedRoundingTasksSearch);
      },
    };
  })
  .views((self) => {
    return {
      get roundingTasksPage() {
        const pageParam = self.roundingTasksQuery.get('page');

        return pageParam ? Number(pageParam) : 1;
      },
      get closedRoundingTasksPage() {
        const pageParam = self.closedRoundingTasksQuery.get('page');

        return pageParam ? Number(pageParam) : 1;
      },
      get numRoundingTasksPages() {
        return Math.ceil(self.numRoundingTasks / ROUNDS_PER_PAGE);
      },
      get numClosedRoundingTasksPages() {
        return Math.ceil(self.numClosedRoundingTasks / ROUNDS_PER_PAGE);
      },
      get roundingTasksFilter(): RoundingFilter {
        const sortParam = self.roundingTasksQuery.get('sort');

        if (!sortParam) return '' as RoundingFilter;

        return sortParam.replace('-', '') as RoundingFilter;
      },
      get closedRoundingTasksFilter(): RoundingFilter {
        const sortParam = self.closedRoundingTasksQuery.get('sort');

        if (!sortParam) return '' as RoundingFilter;

        return sortParam.replace('-', '') as RoundingFilter;
      },
      get roundingTasksIsAscending() {
        const sortParam = self.roundingTasksQuery.get('sort');

        return sortParam !== null && sortParam[0] !== '-';
      },
      get closedRoundingTasksIsAscending() {
        const sortParam = self.closedRoundingTasksQuery.get('sort');

        return sortParam !== null && sortParam[0] !== '-';
      },
      get roundingFilterParams() {
        return getActiveFilterParams(self.roundingActiveFilters);
      },
      get closedRoundingFilterParams() {
        return getActiveFilterParams(self.closedRoundingActiveFilters);
      },
      get roundingTasksSortFilter(): RoundingFilter {
        const sortParam = self.roundingTasksQuery.get('sort');

        if (!sortParam) return '' as RoundingFilter;

        return sortParam.replace('-', '') as RoundingFilter;
      },
      get closedRoundingTasksSortFilter(): RoundingFilter {
        const sortParam = self.closedRoundingTasksQuery.get('sort');

        if (!sortParam) return '' as RoundingFilter;

        return sortParam.replace('-', '') as RoundingFilter;
      },
    };
  })
  .views((self) => {
    return {
      get roundingOrdering() {
        const orderingFilter = roundingFilters[self.roundingTasksSortFilter];

        return !orderingFilter
          ? 'ends_at'
          : self.roundingTasksIsAscending
          ? orderingFilter
          : `-${orderingFilter}`;
      },
      get closedRoundingOrdering() {
        const orderingFilter =
          roundingFilters[self.closedRoundingTasksSortFilter];

        return !orderingFilter
          ? 'ends_at'
          : self.closedRoundingTasksIsAscending
          ? orderingFilter
          : `-${orderingFilter}`;
      },
    };
  })
  .actions((self) => {
    return {
      setRoundingIssue(
        roundingTaskId: number,
        facilityName: string,
        companyName: string,
      ) {
        self.roundingIssue = {
          roundingTaskId,
          facilityName,
          companyName,
        };
      },
      resetRoundingIssue() {
        self.roundingIssue = null;
      },
    };
  })
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;

    return {
      fetchRoundingTasks: flow(function* () {
        const params = new URLSearchParams();
        params.append('status', RoundingStatus.ActionIsRequired);
        params.append('status', RoundingStatus.Waiting);
        params.append('limit', String(ROUNDS_PER_PAGE));
        params.append(
          'offset',
          String((self.roundingTasksPage - 1) * ROUNDS_PER_PAGE),
        );
        params.append('ordering', self.roundingOrdering);

        for (const [key, value] of self.roundingFilterParams.entries()) {
          params.append(key, value);
        }

        try {
          const { data } = yield request({
            method: 'GET',
            url: '/api/rounding_tasks/',
            params,
          });

          const { results: roundingTasks, count } = data;

          self.roundingTasks = roundingTasks;
          self.numRoundingTasks = count;
        } catch (error) {
          // No need to do anything here since the general error will be shown
          // and the user can try again.
        }
      }),
      fetchClosedRoundingTasks: flow(function* () {
        const params = new URLSearchParams();
        params.append('status', RoundingStatus.Approved);
        params.append('limit', String(ROUNDS_PER_PAGE));
        params.append(
          'offset',
          String((self.closedRoundingTasksPage - 1) * ROUNDS_PER_PAGE),
        );
        params.append('ordering', self.closedRoundingOrdering);

        for (const [key, value] of self.closedRoundingFilterParams.entries()) {
          params.append(key, value);
        }

        try {
          const { data } = yield request({
            method: 'GET',
            url: '/api/rounding_tasks/',
            params,
          });

          const { results: roundingTasks, count } = data;

          self.closedRoundingTasks = roundingTasks;
          self.numClosedRoundingTasks = count;
        } catch (error) {
          // No need to do anything here since the general error will be shown
          // and the user can try again.
        }
      }),
      // If we would derive the filters from the current state each RoundingFilter
      // instance would become a completely independent mst tree which causes
      // all the references to be null, to prevent this from happening we need
      // to explicitly set the filters in the store.
      setRoundingActiveFilters() {
        self.roundingActiveFilters = [] as any;

        for (const [key, value] of self.roundingTasksQuery) {
          if (Object.values(RoundingFilter).includes(key as RoundingFilter)) {
            self.roundingActiveFilters.push({ name: key, value });
          }
        }
      },
      setClosedRoundingActiveFilters() {
        self.closedRoundingActiveFilters = [] as any;

        for (const [key, value] of self.closedRoundingTasksQuery) {
          if (Object.values(RoundingFilter).includes(key as RoundingFilter)) {
            self.closedRoundingActiveFilters.push({ name: key, value });
          }
        }
      },
    };
  })
  .actions((self) => {
    const { request } = self;

    let isLoading = false;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return {
      downloadRoundingCSV: flow(function* () {
        if (isLoading) return;

        isLoading = true;

        const params = new URLSearchParams();
        params.append('time_zone', timeZone);
        params.append('ordering', self.roundingOrdering);
        params.append('status', RoundingStatus.ActionIsRequired);
        params.append('status', RoundingStatus.Waiting);

        for (const [key, value] of self.roundingFilterParams.entries()) {
          params.append(key, value);
        }

        try {
          const { data } = yield request({
            method: 'GET',
            url: '/api/rounding_tasks/export/',
            params,
          });
          const date = new Date().toLocaleDateString('sv-SE');
          const time = new Date().toLocaleTimeString('sv-SE');
          fileDownload(data, `rounding-${date}-${time}.csv`);
        } catch {
          // No need to do anything here since the general error will be shown
          // and the user can try again.
        } finally {
          isLoading = false;
        }
      }),
      downloadClosedRoundingCSV: flow(function* () {
        if (isLoading) return;

        isLoading = true;

        const params = new URLSearchParams();
        params.append('time_zone', timeZone);
        params.append('ordering', self.closedRoundingOrdering);
        params.append('status', RoundingStatus.Approved);

        for (const [key, value] of self.closedRoundingFilterParams.entries()) {
          params.append(key, value);
        }

        try {
          const { data } = yield request({
            method: 'GET',
            url: '/api/rounding_tasks/export/',
            params,
          });
          const date = new Date().toLocaleDateString('sv-SE');
          const time = new Date().toLocaleTimeString('sv-SE');
          fileDownload(data, `closedRounding-${date}-${time}.csv`);
        } catch {
          // No need to do anything here since the general error will be shown
          // and the user can try again.
        } finally {
          isLoading = false;
        }
      }),
    };
  })
  .actions((self) => {
    const debouncedFetchRoundingTasks = debounce(
      self.fetchRoundingTasks,
      REQUEST_DEBOUNCE_WAIT,
    );

    const debouncedFetchClosedRoundingTasks = debounce(
      self.fetchClosedRoundingTasks,
      REQUEST_DEBOUNCE_WAIT,
    );

    return {
      // We debounce rounding tasks requests that result from the user
      // navigating as to not overwhelm the network when navigating
      // back and forth quickly.
      handleRoundingTasksSearchChange(search: string) {
        self.roundingTasksSearch = search;
        self.setRoundingActiveFilters();
        debouncedFetchRoundingTasks();
      },
      handleClosedRoundingTasksSearchChange(search: string) {
        self.closedRoundingTasksSearch = search;
        self.setClosedRoundingActiveFilters();
        debouncedFetchClosedRoundingTasks();
      },
    };
  });

export type RoundingIssueInstance = Instance<typeof RoundingIssue>;
