import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { MouseEvent, useLayoutEffect, FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FadeInDiv, SearchIssueLink } from '../components';
import { MIN_SEARCH_QUERY_LENGTH } from '../constants';
import { useStore } from '../hooks';
import {
  AddUserIcon,
  ChevronDownIcon,
  PlusIcon,
  TagIcon,
  CompanyIcon,
  FacilityIcon,
} from '../icons';
import { AccountType } from '../types';
import { localeCompareSv } from '../utils';

const ShowMoreButton: FC<{ onClick: () => void }> = ({ onClick }) => (
  <button className="mt-3 flex items-center pl-1" onClick={onClick}>
    <span className="text-xs">Visa mer</span>
    <ChevronDownIcon className="ml-1 w-6 -rotate-90" />
  </button>
);

type SearchFunction = {
  name: string;
  link: string;
  icon: JSX.Element;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
};

type SearchModalState = {
  filteredSearchFunctions: SearchFunction[];
};

export const SearchPage = observer(() => {
  const location = useLocation();
  const {
    searchStore,
    userStore: { me },
    organizationStore: { organization },
    uiStore,
  } = useStore();

  const state = useLocalObservable<SearchModalState>(() => ({
    get filteredSearchFunctions(): SearchFunction[] {
      const searchFunctions: SearchFunction[] = [];

      if (me && me.role >= AccountType.Admin) {
        searchFunctions.push({
          name: 'Skapa användare',
          link: '/organization/create-user',
          icon: <AddUserIcon />,
        });

        searchFunctions.push(
          {
            name: 'Skapa fastighet',
            link: '/organization/facilities/create-facility',
            icon: <FacilityIcon className="h-4 w-4" />,
            onClick: uiStore.toggleIsSearchModalOpen,
          },
          {
            name: 'Skapa företag',
            link: '/organization/companies/create-company',
            icon: <CompanyIcon className="h-4 w-4" />,
            onClick: uiStore.toggleIsSearchModalOpen,
          },
        );

        if (organization?.hasIssueCostsFeature) {
          searchFunctions.push({
            name: 'Skapa artikel',
            link: '/organization/articles/create-article',
            icon: <TagIcon className="w-4" />,
          });
        }
      }

      searchFunctions.push({
        name: 'Skapa ärende',
        link: '#',
        icon: (
          <PlusIcon className="w-4 rounded-full border border-solid border-black text-black" />
        ),
        onClick: (event: MouseEvent<HTMLAnchorElement>) => {
          event.preventDefault();

          if (!uiStore.isCreateIssueOpen) {
            uiStore.toggleIsCreateIssueOpen();
          }
        },
      });

      const { lowerCasedQ } = searchStore;

      if (lowerCasedQ.length < MIN_SEARCH_QUERY_LENGTH) {
        return [];
      }

      return searchFunctions
        .filter((searchFn) => searchFn.name.toLowerCase().includes(lowerCasedQ))
        .sort((a, b) => localeCompareSv(a.name, b.name));
    },
  }));

  useLayoutEffect(() => {
    searchStore.handleSearchChange(location.search);
  }, [location.search]);

  const { myIssues, issues } = searchStore;

  return (
    <FadeInDiv className="px-4 pb-4 pt-0 md:p-14">
      <div className="rounded bg-white p-7">
        <div className="text-xl font-semibold">
          Sökresultat för &quot;{searchStore.q}&quot;
        </div>

        {state.filteredSearchFunctions.length !== 0 && (
          <div className="mt-7 pl-1">
            {state.filteredSearchFunctions.map((searchFn, index) => (
              <Link
                key={searchFn.name}
                className={`flex items-center ${index !== 0 ? 'mt-2' : ''}`}
                to={searchFn.link}
                onClick={searchFn.onClick}
              >
                {searchFn.icon}
                <span className="ml-4">{searchFn.name}</span>
              </Link>
            ))}
          </div>
        )}
      </div>

      {myIssues !== null && issues !== null && (
        <FadeInDiv>
          {myIssues.length !== 0 && (
            <div>
              <div className="py-3 pl-7 text-sm font-bold uppercase md:text-base">
                Mina ärenden
              </div>
              <div className="rounded bg-white p-7">
                {myIssues.map((issue, index) => (
                  <SearchIssueLink
                    key={issue.id}
                    issue={issue}
                    me={me!}
                    className={index !== 0 ? 'mt-2' : ''}
                  />
                ))}
                {searchStore.hasMoreMyIssues && (
                  <ShowMoreButton onClick={searchStore.fetchMyIssues} />
                )}
              </div>
            </div>
          )}

          {issues.length !== 0 && (
            <div>
              <div className="py-3 pl-7 text-sm font-bold uppercase md:text-base">
                Ärenden
              </div>
              <div className="rounded bg-white p-7">
                {issues.map((issue, index) => (
                  <SearchIssueLink
                    key={issue.id}
                    issue={issue}
                    me={me!}
                    className={index !== 0 ? 'mt-2' : ''}
                  />
                ))}
                {searchStore.hasMoreIssues && (
                  <ShowMoreButton onClick={searchStore.fetchIssues} />
                )}
              </div>
            </div>
          )}
        </FadeInDiv>
      )}
    </FadeInDiv>
  );
});
