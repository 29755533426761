import React, { FC, memo, SVGProps } from 'react';

export const ImageIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    enableBackground="new 0 0 15.8 15.8"
    viewBox="0 0 15.8 15.8"
    xmlns="http://www.w3.org/2000/svg"
    className="w-4"
    {...props}
  >
    <g transform="translate(-2.25 -2.25)">
      <path
        fill="currentColor"
        d="m7.4 9.7c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2 2.2 1 2.2 2.2-0.9 2.2-2.2 2.2zm0-3c-0.4 0-0.7 0.3-0.7 0.7s0.3 0.8 0.7 0.8 0.8-0.3 0.8-0.8-0.4-0.7-0.8-0.7z"
      />
      <path
        fill="currentColor"
        d="m15 2.3h-10c-1.5 0-2.8 1.2-2.8 2.7v10c0 1.5 1.2 2.7 2.8 2.7h10c1.5 0 2.8-1.2 2.8-2.7v-10c0-1.5-1.3-2.7-2.8-2.7zm1.2 12.7c0 0.7-0.6 1.2-1.2 1.2h-8.5l6.8-9 3 4v3.8zm-2.4-9.4c-0.3-0.4-0.9-0.4-1.2 0l-8 10.7c-0.5-0.1-0.9-0.6-0.9-1.2v-10.1c0-0.7 0.6-1.2 1.2-1.2h10c0.7 0 1.2 0.6 1.2 1.2v3.8l-2.3-3.2z"
      />
    </g>
  </svg>
));
