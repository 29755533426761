import React from 'react';
import { observer } from 'mobx-react-lite';
import Linkify from 'linkify-react';
import { IssueInstance } from '../../../models';
import { localeCompareSv } from '../../../utils';

export const IssueContent = observer(({ issue }: { issue: IssueInstance }) => {
  return (
    <>
      <Linkify
        as="p"
        className="break-words leading-7 text-gray-700"
        options={{
          className: 'text-pink-500 hover:underline',
          rel: 'noopener',
          target: '_blank',
          defaultProtocol: 'https',
        }}
      >
        {issue.issue}
      </Linkify>
      {issue.extra_data && (
        <div className="mt-5 flex items-center">
          {Array.from(issue.extra_data.entries())
            .sort((a, b) => localeCompareSv(a[0], b[0]))
            .map(([key, value]) => (
              <div key={key} className="mr-5 inline-flex items-center">
                <div
                  className={`mr-4 flex h-6 w-6 items-center justify-center rounded-md border border-solid ${
                    value
                      ? 'border-indigo-600 bg-indigo-600'
                      : 'border-gray-200'
                  }`}
                >
                  {value && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="w-5 stroke-current text-white"
                    >
                      <path d="M20 6L9 17l-5-5" />
                    </svg>
                  )}
                </div>
                <span className="text-xs font-semibold text-black">
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </span>
              </div>
            ))}
        </div>
      )}
    </>
  );
});
