import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { FeedbackInstance } from '../../../models';
import { formatDate } from '../../../utils';
import { FEEDBACK_COLORS } from '../../../constants';

export const FeedbackDot: FC<{ grade: number }> = ({ grade }) => (
  <span
    className={`inline-flex h-9 w-9 items-center justify-center rounded-full font-semibold text-white ${
      FEEDBACK_COLORS[grade - 1]
    }`}
  >
    {grade}
  </span>
);

const FeedbackScore: FC<{ feedback: FeedbackInstance }> = ({ feedback }) => (
  <div className="flex-shrink-0 2xl:w-64">
    <div className="mb-2 flex items-center justify-between">
      <span className="font-semibold text-gray-900">Betyg</span>
      <FeedbackDot grade={feedback.grade} />
    </div>
    <div className="flex items-center justify-between">
      <span className="text-gray-900">Nöjdhet</span>
      <span className="inline-flex h-9 w-9 items-center justify-center font-semibold">
        {feedback.satisfaction}
      </span>
    </div>
    <div className="flex items-center justify-between">
      <span className="text-gray-900">Hastighet</span>
      <span className="inline-flex h-9 w-9 items-center justify-center font-semibold">
        {feedback.speed}
      </span>
    </div>
  </div>
);

type FeedbackProps = {
  feedback: FeedbackInstance;
  index: number;
  numFeedbacks: number;
};

export const Feedback = observer(
  ({ feedback, index, numFeedbacks }: FeedbackProps) => {
    const isLastFeedback = index === numFeedbacks - 1;
    const formattedDate = formatDate(feedback.created_at).date;

    return (
      <div
        key={feedback.id}
        className={`flex flex-col border-solid border-indigo-100 2xl:flex-row ${
          !isLastFeedback ? 'border-b' : ''
        }`}
      >
        <div
          className={`flex-grow border-solid border-indigo-100 p-7 xl:max-w-2xl 2xl:border-b-0 ${
            feedback.hasContent ? 'border-b 2xl:border-r' : ''
          }`}
        >
          <div className="mb-3 flex items-center justify-between">
            <span className="text-sm text-indigo-900">{formattedDate}</span>
          </div>

          {feedback.hasContent ? (
            <p className="leading-7 text-gray-700">{feedback.content}</p>
          ) : (
            <FeedbackScore feedback={feedback} />
          )}
        </div>

        {feedback.hasContent && (
          <div className="w-auto px-7 py-5 2xl:px-14">
            <FeedbackScore feedback={feedback} />
          </div>
        )}
      </div>
    );
  },
);
