import React, { FC, memo, SVGProps } from 'react';

export const UsersIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="w-4"
    {...props}
  >
    <path
      d="M14.815 16.5855V14.9374C14.815 14.0632 14.4666 13.2249 13.8466 12.6067C13.2268 11.9885 12.3859 11.6414 11.5091 11.6414H4.89765C4.02088 11.6414 3.17997 11.9886 2.56014 12.6067C1.94017 13.2249 1.5918 14.0632 1.5918 14.9374V16.5855"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5093 5.04921C11.5093 6.86966 10.0293 8.34541 8.20364 8.34541C6.37794 8.34541 4.89795 6.86957 4.89795 5.04921C4.89795 3.22885 6.37794 1.75317 8.20364 1.75317C10.0293 1.75317 11.5093 3.22885 11.5093 5.04921Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.7733 16.5856V14.9374C19.7727 14.2071 19.529 13.4978 19.0801 12.9205C18.6315 12.3433 18.0032 11.9311 17.2939 11.7485"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9883 1.86035C14.9432 2.10412 15.7393 2.76011 16.1592 3.64932C16.5791 4.53853 16.5791 5.56824 16.1592 6.45745C15.7393 7.34666 14.9432 8.00275 13.9883 8.2465"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));
