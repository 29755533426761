import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useStore } from '../../hooks';
import { KebabMenu } from '..';
import { Logo } from '../Logo';
import {
  ExclamationMarkIcon,
  TagIcon,
  CarIcon,
  DashboardIcon,
  LogoIcon,
  RoundingIcon,
  CogwheelIcon,
} from '../../icons';
import {
  SideMenuLink,
  SideMenuAccordion,
  parkingSubPages,
  issuesSubPages,
  defaultSettingsSubPages,
  SubPageLink,
} from '.';
import { roundsSubPages } from './subPages';
import { RoundingFilter } from '../../types/RoundingFilter';
import { AccountType } from '../../types';

interface SideMenuState {
  pathname: string;
  setPathname: (pathname: string) => void;

  isIssuesSubPageExpanded: boolean;
  toggleIsIssuesSubPageExpanded: () => void;
  showIssuesSubPage: boolean;

  isRoundsSubPageExpanded: boolean;
  toggleIsRoundsSubPageExpanded: () => void;
  showRoundsSubPage: boolean;

  isParkingSubPageExpanded: boolean;
  toggleIsParkingSubPageExpanded: () => void;
  showParkingSubPage: boolean;

  isSettingsSubPageExpanded: boolean;
  toggleIsSettingsSubPageExpanded: () => void;

  settingsSubPages: SubPageLink[];
}

export const SideMenu = observer(() => {
  const location = useLocation();
  const {
    uiStore,
    userStore: { me },
    organizationStore: { organization },
  } = useStore();

  const state = useLocalObservable<SideMenuState>(() => ({
    pathname: location.pathname,
    setPathname(pathname) {
      state.pathname = pathname;
    },
    isIssuesSubPageExpanded: false,
    toggleIsIssuesSubPageExpanded() {
      state.isIssuesSubPageExpanded = !state.isIssuesSubPageExpanded;
    },
    get showIssuesSubPage(): boolean {
      return (
        state.isIssuesSubPageExpanded ||
        (state.pathname.includes('/issues') &&
          !state.pathname.includes('/issues/mine'))
      );
    },
    isRoundsSubPageExpanded: false,
    toggleIsRoundsSubPageExpanded() {
      state.isRoundsSubPageExpanded = !state.isRoundsSubPageExpanded;
    },
    get showRoundsSubPage(): boolean {
      return (
        state.isRoundsSubPageExpanded || state.pathname.includes('/rounds')
      );
    },
    isParkingSubPageExpanded: false,
    toggleIsParkingSubPageExpanded() {
      state.isParkingSubPageExpanded = !state.isParkingSubPageExpanded;
    },
    get showParkingSubPage(): boolean {
      return (
        state.isParkingSubPageExpanded || state.pathname.includes('/parking')
      );
    },
    isSettingsSubPageExpanded: false,
    toggleIsSettingsSubPageExpanded() {
      state.isSettingsSubPageExpanded = !state.isSettingsSubPageExpanded;
    },
    get settingsSubPages() {
      const subPages: SubPageLink[] = [];

      if (shouldShowArticles) {
        subPages.push({
          to: '/organization/articles',
          text: 'Artiklar',
          icon: <TagIcon className="w-6" />,
        });
      }

      if (shouldShowParking) {
        subPages.push({
          to: '/organization/parking',
          text: 'Besöksparkering',
          icon: <CarIcon className="w-6" />,
        });
      }
      return [...defaultSettingsSubPages, ...subPages];
    },
  }));

  if (organization === null || me === null) return null;

  const { isMenuExpanded } = uiStore;

  const shouldShowIssues = uiStore.shouldShowIssues(organization, me);
  const shouldShowRounds = uiStore.shouldShowRounds(organization, me);
  const shouldShowParking = uiStore.shouldShowParking(organization);
  const shouldShowRecurringParking = uiStore.shouldShowRecurringParking(
    organization,
    me,
  );
  const shouldShowArticles = uiStore.shouldShowArticles(organization);
  const shouldShowSettings = uiStore.shouldShowOrganization(me);

  const today = new Date();
  const nextMonth = new Date();
  nextMonth.setMonth(nextMonth.getMonth() + 1);

  const roundsSearchParams = new URLSearchParams();
  roundsSearchParams.append(
    RoundingFilter.EndsAt,
    `${today.toLocaleDateString('sv-SE')} / ${nextMonth.toLocaleDateString(
      'sv-SE',
    )}`,
  );

  if (me.role < AccountType.Admin) {
    roundsSearchParams.append(RoundingFilter.AssignedTo, me.id.toString());
  }

  useLayoutEffect(() => {
    state.setPathname(location.pathname);
  }, [location.pathname]);

  return (
    <div
      className={`flex h-screen flex-col overflow-y-auto bg-white px-7 pb-9 pt-7 text-gray-900 ${
        isMenuExpanded ? 'w-80' : ''
      }`}
    >
      <div
        className={`flex justify-between ${
          isMenuExpanded ? 'pb-16' : 'flex-col items-baseline pb-3'
        }`}
      >
        {isMenuExpanded ? (
          <div className="w-24">
            <Logo color="black" />
          </div>
        ) : (
          <LogoIcon className="w-6" />
        )}
        <button
          className={`${
            isMenuExpanded ? '' : 'mb-5 mt-3'
          } transition-colors duration-300 hover:text-pink-500 focus:outline-none`}
          onClick={uiStore.toggleMenuExpanded}
        >
          <KebabMenu flip={isMenuExpanded} />
        </button>
      </div>

      <div className="flex flex-1 flex-col space-y-6">
        {shouldShowIssues && (
          <SideMenuLink to="/dashboard">
            <DashboardIcon className="h-6 w-6" />
            {isMenuExpanded && <span>Dashboard</span>}
          </SideMenuLink>
        )}

        {shouldShowIssues && (
          <SideMenuAccordion
            to="/issues"
            isMenuExpanded={isMenuExpanded}
            showSubPages={!isMenuExpanded || state.showIssuesSubPage}
            subPageLinks={issuesSubPages}
            onChevronClick={state.toggleIsIssuesSubPageExpanded}
            end={
              state.pathname.includes('/issues/mine') ||
              state.pathname.includes('/issues/complete')
            }
          >
            <ExclamationMarkIcon className="w-6" />
            {isMenuExpanded && <span>Ärenden</span>}
          </SideMenuAccordion>
        )}

        {shouldShowRounds && (
          <SideMenuAccordion
            to={`/rounds?${roundsSearchParams.toString()}`}
            showSubPages={!isMenuExpanded || state.showRoundsSubPage}
            isMenuExpanded={isMenuExpanded}
            subPageLinks={roundsSubPages}
            onChevronClick={state.toggleIsRoundsSubPageExpanded}
            end
          >
            <div className="flex w-6 justify-center">
              <RoundingIcon />
            </div>
            {isMenuExpanded && <span>Rondering</span>}
          </SideMenuAccordion>
        )}

        {shouldShowParking && (
          <SideMenuAccordion
            to="/parking"
            showSubPages={!isMenuExpanded || state.showParkingSubPage}
            isMenuExpanded={isMenuExpanded}
            subPageLinks={shouldShowRecurringParking ? parkingSubPages : null}
            onChevronClick={state.toggleIsParkingSubPageExpanded}
            end
          >
            <CarIcon className="w-6" />
            {isMenuExpanded && <span>Besöksparkering</span>}
          </SideMenuAccordion>
        )}
      </div>
      {shouldShowSettings && (
        <SideMenuAccordion
          to="#"
          disableActiveClass
          isMenuExpanded={isMenuExpanded}
          showSubPages={state.isSettingsSubPageExpanded}
          subPageLinks={state.settingsSubPages}
          onChevronClick={state.toggleIsSettingsSubPageExpanded}
          onClick={state.toggleIsSettingsSubPageExpanded}
        >
          <CogwheelIcon className="w-6" />
          {isMenuExpanded && <span>Inställningar</span>}
        </SideMenuAccordion>
      )}
    </div>
  );
});
