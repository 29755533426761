export enum FacilityMetaData {
  EntreProjectNo = 'EntreProjectNo',
}

export enum CompanyMetaData {
  EntreCustNo = 'EntreCustNo',
}

export enum UserMetaData {
  EntreEmployeeNo = 'EntreEmployeeNo',
}

export enum IssueMetaData {
  EntreOrderNo = 'EntreOrderNo',
  EntreOrderStatus = 'EntreOrderStatus',
}
