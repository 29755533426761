import { observer } from 'mobx-react-lite';
import React, { FC, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  StatusTag,
  Pagination,
  TableHeaderCell,
  IssueDetailsButton,
  TagSelect,
  FadeInDiv,
  Avatar,
  UserSelect,
  SlideInMenu,
  ActiveFilters,
} from '../components';
import { useStore } from '../hooks';
import { IssueFilterInstance, IssueInstance, UserInstance } from '../models';
import {
  IssuePriority,
  IssuesFilter,
  IssuesSortFilter,
  IssueStatus,
} from '../types';
import {
  UserSelectIcon,
  HighFiveIcon,
  UploadIcon,
  FilterIcon,
  UserSwitchIcon,
} from '../icons';
import { IssuesFilterPicker } from '../components/IssuesFilterPicker';

/**
 * A page component rendering all open issues.
 */
export const IssuesPage = observer(() => {
  const {
    issueStore,
    uiStore,
    userStore: { me, users },
  } = useStore();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    openIssues: issues,
    numOpenIssues: issuesCount,
    openIssuesQuery: query,
    openIssuesSortFilter: filter,
    openIssuesIsAscending: isAscending,
    numOpenIssuesPages: numPages,
    openIssuesPage: page,
    openIssuesActiveFilters,
  } = issueStore;

  const isMineFilterActive =
    new URLSearchParams(query).get(IssuesFilter.AssignedTo) ===
    me?.id.toString();

  const setFilter = (newFilter: string) => {
    const searchParams = new URLSearchParams(query);
    searchParams.delete('page');

    if (filter === newFilter) {
      if (isAscending) {
        searchParams.delete('sort');
      } else {
        searchParams.set('sort', filter);
      }
    } else {
      searchParams.set('sort', `-${newFilter}`);
    }

    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const toggleMineFilter = () => {
    const searchParams = new URLSearchParams(location.search);

    const hasAssignedToFilter = searchParams.has(IssuesFilter.AssignedTo);
    const currentFilter = searchParams.get(IssuesFilter.AssignedTo);

    searchParams.delete(IssuesFilter.AssignedTo);

    if (currentFilter !== me?.id.toString() || !hasAssignedToFilter) {
      searchParams.append(IssuesFilter.AssignedTo, me?.id.toString() || '');
    }

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const setCurrentPage = (page: number) => {
    const searchParams = new URLSearchParams(query);

    if (page === 1) {
      searchParams.delete('page');
    } else {
      searchParams.set('page', `${page}`);
    }

    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  useLayoutEffect(() => {
    issueStore.handleOpenIssuesSearchChange(location.search);
  }, [location.search]);

  if (issues === null || users === null || me === null) return null;

  return (
    <FadeInDiv className="flex h-full flex-col">
      <div className="mx-4 mb-5 rounded-md bg-white px-7 py-7 md:mx-14 md:mb-10 md:mt-14 lg:max-w-xs">
        <div className="flex flex-col">
          <span className="font-semibold text-gray-900">Ärenden</span>
          <span className="mt-1 text-xl font-extrabold text-indigo-900">
            {issuesCount}
          </span>
        </div>
      </div>
      <div className="mx-4 mb-3 flex justify-between space-x-6 space-y-2 md:mx-14">
        <div className="flex min-w-0 flex-wrap items-center space-y-2 whitespace-nowrap">
          <SlideInMenu
            title="Filter"
            icon={
              <div className="mr-2 mt-2 flex items-center space-x-2 px-1.5 py-0.5 text-sm text-gray-900 md:mr-4">
                <FilterIcon /> <span>Filter</span>
              </div>
            }
            isMenuExpanded={uiStore.isIssuesFilterPickerOpen}
            onClick={uiStore.toggleIsIssuesFilterPickerOpen}
          >
            <IssuesFilterPicker />
          </SlideInMenu>
          {openIssuesActiveFilters !== null && (
            <ActiveFilters<IssueFilterInstance>
              filters={openIssuesActiveFilters}
            />
          )}
        </div>
        <div className="flex items-start space-x-1 text-sm md:space-x-4">
          <button
            className={`flex items-center space-x-1 rounded px-1.5 py-0.5 transition-colors duration-200 hover:bg-pink-500 hover:text-white ${
              isMineFilterActive ? 'text-pink-500' : ''
            }`}
            onClick={toggleMineFilter}
          >
            <span>{isMineFilterActive ? 'Mina' : 'Alla'}</span>
            <UserSwitchIcon />
          </button>
          <button
            onClick={issueStore.downloadOpenIssuesCSV}
            className="flex items-center space-x-1 rounded-md px-1.5 py-0.5 text-gray-900 transition-colors duration-200 hover:bg-gray-900 hover:text-white"
          >
            <span>Exportera</span>
            <UploadIcon />
          </button>
        </div>
      </div>
      {issues.length !== 0 ? (
        <div className="mx-4 flex-1 md:mx-14">
          <table className="w-full rounded-md bg-white">
            <thead>
              <tr className="text-left">
                <TableHeaderCell
                  title="Fastighet"
                  isActive={filter === IssuesSortFilter.Facility}
                  isAscending={isAscending}
                  onClick={() => setFilter(IssuesSortFilter.Facility)}
                />
                <TableHeaderCell
                  className="xl:table-cell"
                  title="Typ av ärende"
                  isActive={filter === IssuesSortFilter.IssueType}
                  isAscending={isAscending}
                  onClick={() => setFilter(IssuesSortFilter.IssueType)}
                />
                <TableHeaderCell
                  className="hidden xl:table-cell"
                  title="Ärendenr."
                  isActive={filter === IssuesSortFilter.Number}
                  isAscending={isAscending}
                  onClick={() => setFilter(IssuesSortFilter.Number)}
                />
                <TableHeaderCell
                  className="hidden 2xl:table-cell"
                  title="Ålder"
                  isActive={filter === IssuesSortFilter.CreatedAt}
                  isAscending={isAscending}
                  onClick={() => setFilter(IssuesSortFilter.CreatedAt)}
                />
                <TableHeaderCell
                  className="hidden 2xl:table-cell"
                  title="Status"
                  isActive={filter === IssuesSortFilter.Status}
                  isAscending={isAscending}
                  onClick={() => setFilter(IssuesSortFilter.Status)}
                />
                <TableHeaderCell
                  className="hidden xl:table-cell"
                  title="Företag/organisation"
                  isActive={filter === IssuesSortFilter.Company}
                  isAscending={isAscending}
                  onClick={() => setFilter(IssuesSortFilter.Company)}
                />
                <TableHeaderCell
                  className="hidden lg:table-cell"
                  title="Prioritet"
                  isActive={filter === IssuesSortFilter.Priority}
                  isAscending={isAscending}
                  onClick={() => setFilter(IssuesSortFilter.Priority)}
                />
                <TableHeaderCell
                  className="hidden lg:table-cell"
                  title="Tilldelad"
                  isActive={filter === IssuesSortFilter.AssignedTo}
                  isAscending={isAscending}
                  onClick={() => setFilter(IssuesSortFilter.AssignedTo)}
                />
                <th></th>
              </tr>
            </thead>
            <tbody>
              {issues.map((issue, index, arr) => (
                <tr
                  key={issue.id}
                  className={`border-t border-gray-200 ${
                    index % 2 === 0 ? 'bg-gray-50' : ''
                  } ${index !== arr.length - 1 ? 'border-b' : ''}`}
                >
                  <td className="py-5 pl-5 text-xs xs:pl-7 md:text-base">
                    {issue.facility?.name || (
                      <span className="text-gray-500">Ingen angiven</span>
                    )}
                  </td>
                  <td className="py-5 pl-7 text-xs md:text-base xl:table-cell ">
                    {issue.issue_type?.name}
                  </td>
                  <td className="hidden py-5 pl-7 text-xs md:text-base xl:table-cell">
                    {issue.number}
                  </td>
                  <td className="hidden py-5 pl-7 text-xs md:text-base 2xl:table-cell">
                    {issue.age}
                  </td>
                  <td className="hidden py-5 pl-5 text-xs md:text-base 2xl:table-cell">
                    <div className="relative">
                      {uiStore.isMobile ? (
                        <div className="inline-block">
                          <StatusTag type={issue.status} />
                        </div>
                      ) : (
                        <TagSelect
                          type="status"
                          selected={issue.status}
                          onSelect={(status) => {
                            issue.setStatus(status as IssueStatus);
                          }}
                          disabled={issue.status !== IssueStatus.Waiting}
                        />
                      )}
                    </div>
                  </td>
                  <td className="hidden py-5 pl-7 text-xs md:text-base xl:table-cell">
                    {issue.company?.name || (
                      <span className="text-gray-500">Inget angivet</span>
                    )}
                  </td>
                  <td className="hidden min-w-32 py-5 pl-5 lg:table-cell">
                    <TagSelect
                      type="priority"
                      selected={issue.priority}
                      onSelect={(priority) => {
                        issue.setPriority(priority as IssuePriority);
                      }}
                    />
                  </td>
                  <td
                    className={`hidden min-w-40 max-w-full py-5 pl-7 text-xs md:text-base lg:table-cell ${
                      issue.assigned_to === null ? 'text-gray-500' : ''
                    }`}
                  >
                    <div className="relative w-full">
                      <IssueAssignes me={me} issue={issue} users={users} />
                    </div>
                  </td>

                  <td className="h-10 w-10 px-5 xs:px-7">
                    <IssueDetailsButton
                      to={`${location.pathname.replace(/\/$/, '')}/${issue.id}`}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="mx-4 flex-1 rounded-md bg-white px-7 py-7 text-center md:mx-14 md:py-28">
          <span className="inline-block w-12 md:w-20">
            <HighFiveIcon className="w-full text-indigo-900" />
          </span>
          <p className="mt-5 text-2xl font-extrabold text-indigo-900 md:text-3xl">
            Woho! Det finns inga aktiva ärenden!
          </p>
        </div>
      )}
      <div className="pb-9 pt-5 md:pb-12 md:pt-7">
        {numPages > 1 && (
          <Pagination
            page={page}
            numPages={numPages}
            onChange={setCurrentPage}
          />
        )}
      </div>
    </FadeInDiv>
  );
});

interface IssueAssignesProps {
  me: UserInstance;
  issue: IssueInstance;
  users: UserInstance[];
}

const IssueAssignes: FC<IssueAssignesProps> = observer(({ issue, users }) => {
  return (
    <div className="flex items-center -space-x-3">
      <div className="relative z-20">
        <UserSelect
          users={users}
          disabledUsers={issue.co_workers as UserInstance[]}
          assignedUser={issue.assigned_to}
          selected={issue.assigned_to}
          onSelect={(user) => {
            issue.assignTo(user ? user.id : null);
          }}
          onDelete={issue.assigned_to ? () => issue.assignTo(null) : undefined}
          renderButton={() => {
            return issue.assigned_to ? (
              <div className="flex items-center">
                <Avatar user={issue.assigned_to} border />
              </div>
            ) : (
              <UserSelectIcon className="w-8" />
            );
          }}
          disabled={issue.status === IssueStatus.Done}
          tooltip
        />
      </div>

      {issue.co_workers?.filter(Boolean).map((user, index) => (
        <div key={user!.id} style={{ zIndex: 19 - index }}>
          <Avatar
            user={user!}
            onDelete={() => issue.deleteCoWorker(user!.id)}
            tooltip
          />
        </div>
      ))}
      {issue.assigned_to && (
        <UserSelect
          users={users}
          disabledUsers={issue.co_workers as UserInstance[]}
          assignedUser={issue.assigned_to}
          selected={issue.assigned_to}
          onSelect={(user) => issue.addCoWorker(user!.id)}
          renderButton={() => <UserSelectIcon className="h-8 w-8" />}
          disabled={issue.status === IssueStatus.Done}
        />
      )}
    </div>
  );
});
