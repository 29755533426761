import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';

interface SideMenuLinkProps {
  to: string;
  onClick?: () => void;
  end?: boolean;
  disableActiveClass?: boolean;
}

export const SideMenuLink: FC<SideMenuLinkProps> = observer(
  ({ to, onClick, end = false, disableActiveClass = false, children }) => {
    return (
      <NavLink
        onClick={(event) => {
          if (to === '#') event.preventDefault();
          if (onClick) onClick();
        }}
        to={to}
        className={({ isActive }) =>
          `flex w-full font-semibold transition-transform duration-300 hover:translate-x-2 hover:text-pink-500 ${
            isActive && !disableActiveClass
              ? 'text-indigo-600 xs:hover:translate-x-0'
              : ''
          }`
        }
        end={end}
      >
        <div className="flex flex-1 items-center space-x-4">{children}</div>
      </NavLink>
    );
  },
);
