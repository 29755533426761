import React from 'react';
import { observer } from 'mobx-react-lite';
import { IssueInstance } from '../../../models';
import { IssueStatus } from '../../../types';

interface Props {
  issue: IssueInstance;
  onClick: () => void;
}

export const OpenCloseButton = observer(({ issue, onClick }: Props) => (
  <button
    onClick={() => onClick()}
    className="group flex flex-grow items-center justify-center rounded-md bg-indigo-500 p-3 transition duration-200 hover:bg-indigo-900"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="mr-3 inline h-auto w-5 stroke-current text-white transition duration-200"
    >
      {issue.status !== IssueStatus.Done ? (
        <polyline points="20 6 9 17 4 12"></polyline>
      ) : (
        <>
          <polyline points="9 14 4 9 9 4"></polyline>
          <path d="M20 20v-7a4 4 0 0 0-4-4H4"></path>
        </>
      )}
    </svg>
    <span className="text-left font-semibold text-white transition duration-200">
      {issue.status !== IssueStatus.Done
        ? 'Markera åtgärdat och avsluta ärende'
        : 'Återöppna ärende'}
    </span>
  </button>
));
