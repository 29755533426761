enum IntervalType {
  Year = 'year',
  Month = 'month',
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute',
  Second = 'second',
}

const SINGULAR = {
  year: 'år',
  month: 'mån',
  day: 'dag',
  hour: 'tim',
  minute: 'min',
  second: 'sek',
};

const PLURAL = {
  year: 'år',
  month: 'mån',
  day: 'dgr',
  hour: 'tim',
  minute: 'min',
  second: 'sek',
};

const MINUTE_IN_SECONDS = 60;
const HOUR_IN_SECONDS = MINUTE_IN_SECONDS * 60;
const DAY_IN_SECONDS = HOUR_IN_SECONDS * 24;
const MONTH_IN_SECONDS = DAY_IN_SECONDS * 30;
const YEAR_IN_SECONDS = MONTH_IN_SECONDS * 12;

/**
 * Returns a '18 min' type string given a start date and an optional end date that defaults to now.
 */
export function timeSince(startDate: Date, endDate = new Date()): string {
  const seconds = Math.floor((endDate.getTime() - startDate.getTime()) / 1000);

  let interval = Math.floor(seconds / YEAR_IN_SECONDS);
  let intervalType: IntervalType;
  let intervalString: string;

  if (interval >= 1) {
    intervalType = IntervalType.Year;
  } else {
    interval = Math.floor(seconds / MONTH_IN_SECONDS);
    if (interval >= 1) {
      intervalType = IntervalType.Month;
    } else {
      interval = Math.floor(seconds / DAY_IN_SECONDS);
      if (interval >= 1) {
        intervalType = IntervalType.Day;
      } else {
        interval = Math.floor(seconds / HOUR_IN_SECONDS);
        if (interval >= 1) {
          intervalType = IntervalType.Hour;
        } else {
          interval = Math.floor(seconds / MINUTE_IN_SECONDS);
          if (interval >= 1) {
            intervalType = IntervalType.Minute;
          } else {
            interval = seconds;
            intervalType = IntervalType.Second;
          }
        }
      }
    }
  }

  // Because of server and client time inconsistencies there could be
  // a negative amount of time since a date.
  if (interval < 0) {
    interval = 0;
    intervalString = PLURAL[IntervalType.Second];
  } else if (interval > 1) {
    intervalString = PLURAL[intervalType];
  } else {
    intervalString = SINGULAR[intervalType];
  }

  return `${interval} ${intervalString}`;
}
