export enum IssuesFilter {
  Facility = 'facility',
  IssueType = 'issue_type',
  Company = 'company',
  Priority = 'priority',
  AssignedTo = 'assigned_to_or_co_worker',
  CreatedAt = 'created_at',
  ResolvedAt = 'resolved_at',
  Status = 'status',
}
