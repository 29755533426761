import React from 'react';
import { observer } from 'mobx-react-lite';
import { IssueInstance } from '../../../models';
import { PhoneIcon, LetterIcon } from '../../../icons';

export const ContactCard = observer(({ issue }: { issue: IssueInstance }) => (
  <>
    <div className="mb-7 px-7 pt-7">
      {issue.reporter_name && (
        <>
          <div className="mb-2 hidden font-semibold text-gray-900 md:block">
            Kontaktperson
          </div>
          <div className="text-xl font-extrabold text-indigo-900">
            {issue.reporter_name}
          </div>
        </>
      )}
      {issue.company !== null && (
        <>
          <div className="mb-2 mt-4 font-semibold text-gray-900">
            Företag/organisation
          </div>
          <div className="mb-4 text-xl font-semibold text-indigo-900">
            {issue.company.name}
          </div>
        </>
      )}
    </div>
    <div className="px-7 pb-7">
      {issue.reporter_email && (
        <div className="flex items-center">
          <LetterIcon className="mr-5 inline w-5 flex-shrink-0" />

          <span className="text-pink-500">
            <a
              className="break-all hover:underline"
              href={`mailto:${issue.reporter_email}`}
            >
              {issue.reporter_email}
            </a>
          </span>
        </div>
      )}
      {issue.reporter_phone && (
        <div className="mt-5 flex items-center">
          <PhoneIcon className="mr-5 inline w-5 flex-shrink-0" />

          <span className="text-pink-500">
            <a
              className="break-all hover:underline"
              href={`tel:${issue.reporter_phone}`}
            >
              {issue.reporter_phone}
            </a>
          </span>
        </div>
      )}
    </div>
  </>
));
