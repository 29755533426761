// @ts-nocheck
import React, { useLayoutEffect, useMemo, useRef } from 'react';
import { DoughnutController } from 'chart.js';
import Chart from 'chart.js/auto';
import { DashboardPeriod } from '../../../types';
import { PeriodController } from './PeriodController';
import { observer } from 'mobx-react-lite';

class RoundedDoughnut extends DoughnutController {
  draw() {
    const ctx = this.chart.ctx;
    // There is no point in rendering an arc or its rounded corners
    // if it is of length 0.
    const arcs = this.getMeta().data.filter((arc) => arc.$context.raw !== 0);

    arcs.forEach((arc, i) => {
      arc.draw(ctx);

      const arcColor = arc.options.backgroundColor;
      const previousArc = arcs[i === 0 ? arcs.length - 1 : i - 1];
      const previousArcColor = previousArc.options.backgroundColor;

      const radius = (arc.outerRadius + arc.innerRadius) / 2;
      const thickness = (arc.outerRadius - arc.innerRadius) / 2;
      const startAngle = Math.PI - arc.startAngle - Math.PI / 2;
      const angle = Math.PI - arc.endAngle - Math.PI / 2;

      ctx.save();
      ctx.translate(arc.x, arc.y);

      ctx.fillStyle = arcColor;
      ctx.beginPath();
      ctx.arc(
        radius * Math.sin(angle),
        radius * Math.cos(angle),
        thickness,
        0,
        2 * Math.PI,
      );
      ctx.fill();

      ctx.fillStyle = i === 0 ? arcColor : previousArcColor;
      ctx.beginPath();
      ctx.arc(
        radius * Math.sin(startAngle),
        radius * Math.cos(startAngle),
        thickness,
        0,
        2 * Math.PI,
      );
      ctx.fill();

      ctx.restore();
    });
  }
}
RoundedDoughnut.id = 'roundedDoughnut';
RoundedDoughnut.defaults = DoughnutController.defaults;

Chart.register(RoundedDoughnut);

const ISSUE_TYPE_COLORS = [
  '#5046E5',
  '#EC4899',
  '#FBCD13',
  '#FEA4AF',
  '#302E81',
];

type IssueTypeChartProps = {
  period: DashboardPeriod;
  startDate: Date;
  endDate: Date;
  onPrevious: () => void;
  onNext: () => void;
  onChangePeriod: (period: DashboardPeriod) => void;
  issueTypes: { [key: string]: number };
};

export const IssueTypeChart = observer(
  ({
    period,
    startDate,
    endDate,
    onPrevious,
    onNext,
    onChangePeriod,
    issueTypes,
  }: IssueTypeChartProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const chartRef = useRef(null);

    useLayoutEffect(() => {
      const ctx = canvasRef.current!.getContext('2d');

      chartRef.current = new Chart(ctx, {
        type: 'roundedDoughnut',
        data: {},
        options: {
          cutout: '70%',
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
            datalabels: {
              display: true,
              backgroundColor: '#ccc',
              borderRadius: 3,
              font: {
                color: 'red',
                weight: 'bold',
              },
            },
          },
          elements: {
            arc: {
              borderWidth: 0,
            },
          },
          hover: { mode: null },
        },
      });

      return () => {
        chartRef.current.destroy();
      };
    }, []);

    useLayoutEffect(() => {
      const chart = chartRef.current;

      const labels = Object.keys(issueTypes);
      const data = Object.values(issueTypes);
      // Rendering all zeroes doesn't look good, so we render it as all ones instead.
      const isAllZeroes = data.every((element) => element === 0);
      if (isAllZeroes) {
        data.fill(1);
      }

      chart.data.labels.pop();
      chart.data.datasets.forEach((dataset) => {
        dataset.data.pop();
      });

      chart.data.labels = labels;
      chart.data.datasets = [
        {
          data,
          backgroundColor: ISSUE_TYPE_COLORS,
          hoverOffset: 4,
        },
      ];

      chart.update();
    }, [issueTypes]);

    const numIssuesTotal = useMemo(
      () => Object.values(issueTypes).reduce((acc, num) => acc + num, 0),
      [issueTypes],
    );

    return (
      <div className="w-full rounded bg-white p-7">
        <PeriodController
          title="Ärendetyper"
          tooltip="Distribution av olika typer av ärenden under den valda tidsperioden."
          period={period}
          startDate={startDate}
          endDate={endDate}
          onPrevious={onPrevious}
          onNext={onNext}
          onChangePeriod={onChangePeriod}
        />
        <div className="mt-10 flex flex-col items-center justify-around 2xl:flex-row">
          <div className="w-48 flex-shrink-0 2xl:mr-10">
            <canvas ref={canvasRef} />
          </div>
          <div className="mt-7 w-full 2xl:mt-0 2xl:w-auto">
            {Object.keys(issueTypes).map((issueType, i) => {
              const numIssues = issueTypes[issueType];

              return (
                <div key={issueType} className="mb-3 flex items-center">
                  <div
                    className="mr-3 h-3 w-3 flex-shrink-0 rounded-full"
                    style={{ backgroundColor: ISSUE_TYPE_COLORS[i] }}
                  />
                  <div className="gray-900 text-sm font-semibold">
                    {issueType} - {numIssues}{' '}
                    {numIssuesTotal !== 0 && (
                      <span>
                        ({Math.round((numIssues / numIssuesTotal) * 100)}%)
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  },
);
