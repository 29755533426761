import { Instance, types as t } from 'mobx-state-tree';
import { RoundingFilter } from '../types/RoundingFilter';
import { CompanyReference } from './Company';
import { FacilityReference } from './Facility';
import { UserReference } from './User';
import { ROUNDING_STATUS_TEXTS } from '../constants';
import { RoundingStatus } from '../types';

const FacilityFilter = t
  .model('FacilityFilter', {
    name: t.string,
    value: FacilityReference,
  })
  .views((self) => ({
    get displayName() {
      return self.value?.name;
    },
  }));

const UserFilter = t
  .model('UserFilter', {
    name: t.string,
    value: UserReference,
  })
  .views((self) => ({
    get displayName() {
      return self.value?.name;
    },
  }));

const CompanyFilter = t
  .model('CompanyFilter', {
    name: t.string,
    value: CompanyReference,
  })
  .views((self) => ({
    get displayName() {
      return self.value?.name;
    },
  }));

const DefaultFilter = t
  .model('DefaultFilter', {
    name: t.string,
    value: t.string,
  })
  .views((self) => ({
    get displayName() {
      if (self.name === RoundingFilter.Status) {
        return ROUNDING_STATUS_TEXTS[self.value as RoundingStatus];
      }
      return self.value;
    },
  }));

export const RoundingFilterModel = t.union(
  {
    dispatcher(snapshot) {
      switch (snapshot.name) {
        case RoundingFilter.Facility:
          return FacilityFilter;
        case RoundingFilter.Company:
          return CompanyFilter;
        case RoundingFilter.AssignedTo:
          return UserFilter;
        default:
          return DefaultFilter;
      }
    },
  },
  FacilityFilter,
  CompanyFilter,
  UserFilter,
  DefaultFilter,
);

export type RoundingFilterInstance = Instance<typeof RoundingFilterModel>;
