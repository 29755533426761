import React, { useLayoutEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useMouseDownOnResizeRef, useStore } from '../hooks';
import { Avatar, ErrorMessage, KebabMenu, Logo } from '.';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuPopover,
} from '@reach/menu-button';
import { BellIcon, ChevronDownIcon, PlusIcon, SearchIcon } from '../icons';

const NotificationButton = observer(() => {
  const { notificationStore, uiStore } = useStore();
  const { newCount, fetchNotifications } = notificationStore;

  const openNotificationList = () => {
    notificationStore.resetNotifications();
    uiStore.toggleIsNotificationListOpen();
    fetchNotifications();
  };

  if (newCount === null) return null;

  return (
    <button
      className="mr-3 flex items-center xs:mr-6 md:mr-0"
      onClick={openNotificationList}
    >
      <div className="relative flex h-8 w-8 items-center justify-center rounded-full bg-gray-100">
        <BellIcon className="w-5 text-gray-400" />
        {newCount !== 0 && (
          <span className="absolute -top-1.5 left-5 self-start rounded-full bg-red-500 px-1.5 py-[1px] text-xs text-white">
            {newCount < 100 ? newCount : '99+'}
          </span>
        )}
      </div>
    </button>
  );
});

const MeDropdown = observer(() => {
  const {
    authStore,
    userStore: { me },
  } = useStore();

  const ref = useMouseDownOnResizeRef();

  if (me === null) return null;

  return (
    <Menu>
      {({ isExpanded }) => (
        <div className="mr-3">
          <MenuButton
            ref={isExpanded ? ref : undefined}
            className="flex items-center"
          >
            <Avatar user={me} border />
            <div className="w-5 flex-shrink-0">
              <ChevronDownIcon
                className={`transform transition-transform ${
                  isExpanded ? 'rotate-180' : ''
                }`}
              />
            </div>
          </MenuButton>
          {isExpanded && (
            <MenuPopover className="z-50 my-1 min-w-36 rounded-md border border-gray-200 bg-white shadow-lg">
              <MenuItems className="reach-menu-items focus:outline-none">
                <MenuItem
                  className="group flex w-full cursor-pointer flex-col items-baseline p-3 hover:bg-indigo-100 focus:bg-indigo-100"
                  onSelect={authStore.logOut}
                >
                  <div className="flex w-full items-center justify-between">
                    <span className="text-sm">Logga ut</span>
                  </div>
                </MenuItem>
              </MenuItems>
            </MenuPopover>
          )}
        </div>
      )}
    </Menu>
  );
});

export const Header = observer(() => {
  const { uiStore } = useStore();

  const hasMountedRef = useRef(false);
  useLayoutEffect(() => {
    hasMountedRef.current = true;
  }, []);

  return uiStore.isMobile ? (
    <div className="sticky top-0 z-40">
      {uiStore.isShowingError && (
        <div className="relative z-50 w-full">
          <ErrorMessage />
        </div>
      )}
      <div
        className={`flex transform justify-between bg-neutral-100 text-indigo-900 transition-transform duration-300 ${
          uiStore.isShowingMobileHeader ? '' : '-translate-y-full'
        }`}
      >
        <div className="flex items-center">
          <button
            className="p-4 text-indigo-900 focus:outline-none"
            onClick={uiStore.openMobileMenu}
          >
            <KebabMenu />
          </button>
          <div className="ml-3 w-20">
            <Logo />
          </div>
        </div>
        <div className="mr-4 flex items-center self-stretch">
          <button
            onClick={uiStore.toggleIsCreateIssueOpen}
            className="mr-4 flex h-8 w-8 items-center justify-center rounded-md bg-indigo-600"
          >
            <PlusIcon className="w-6 text-white" />
          </button>
          <NotificationButton />
          <button
            className="flex items-center self-stretch px-4 text-indigo-900"
            onClick={uiStore.toggleIsSearchModalOpen}
          >
            <SearchIcon className="w-5" />
          </button>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="relative z-50">
        <div className="flex items-center justify-between bg-white shadow-md">
          <button
            className="flex items-center self-stretch px-14 text-black"
            onClick={uiStore.toggleIsSearchModalOpen}
          >
            <SearchIcon className="mr-2 w-5" />
            <span className="font-semibold">Sök</span>
          </button>
          <div className="flex items-center py-4 pr-7">
            <MeDropdown />
            <button
              onClick={uiStore.toggleIsCreateIssueOpen}
              className="mr-4 flex h-8 w-8 items-center justify-center rounded-md bg-indigo-600 transition hover:bg-indigo-900"
            >
              <PlusIcon className="w-6 text-white" />
            </button>
            <NotificationButton />
          </div>
        </div>
      </div>

      <div className={`sticky top-0 z-40`}>
        <div
          className={`absolute w-full transform ${
            hasMountedRef.current ? 'transition-transform duration-300' : ''
          } ${uiStore.isShowingError ? '' : '-translate-y-full'}`}
        >
          <ErrorMessage />
        </div>
      </div>
    </>
  );
});
